.ie,
.edge {

  .explore__item {
    width: 24.9%;

    .explore__ico-circle {
      margin-left: em(6, 29.86);
    }
  }
}

.edge {

  .form {

    &__label-event {
      max-width: em(308, 43);
    }
  }
}

.ie {
  .slider__image {
    height: 50vw;
  }

  .banner--landing {
    .banner__image {
      height: 50vw;
    }
  }
}
