@media screen and (min-width: $breakpoint-min) {
  .menu {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    height: 100vh;
    width: 1px;

    &.show-popup {
      z-index: 1;
    }

    &.fixed {
      .menu__buttons {
        position: fixed;
      }
    }

    &.opened {
      .menu {
        &__wrapper,
        &__footer {
          transform: translateX(0);
        }

        &__buttons {
          border-right: $black em(3) solid;
          background-color: $black;
          opacity: 1;
        }
      }
    }

    &__buttons {
      position: absolute;
      left: 0;
      z-index: 3;
      transform: translateZ(0);
      opacity: .85;

      &.init {
        transform: translateY(-50%);
        top: 50%;
      }
    }

    &__btn {
      @include background-gradient($white, $grey-4, 'vertical');

      display: block;
      position: relative;
      width: em(113, 14);
      height: em(110, 14);
      padding: em(24, 14) em(22, 14) em(20, 14);
      text-align: left;
      font: #{em(14)}/#{em(17.28, 14)} $robo-b;
      color: $black;
      overflow: visible;
      outline: 0;
      border: none;

      &.active {
        background: $black;
        color: $white;

        &:hover,
        &:focus {
          background: $black;
          color: $white;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: em(-16, 14);
          width: 0;
          border-bottom: transparent em(55, 14) solid;
          border-top: transparent em(55, 14) solid;
          border-left: em(14, 14) solid $black;

          .ie &,
          .edge & {
            right: em(-17, 14);
          }
        }

        &.menu__btn--search {
          path,
          circle {
            stroke: $white;
          }
        }
      }

      &:hover,
      &:focus {
        cursor: pointer;
        outline: 0;
        background: $grey-19;
      }

      &--burger {
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;

        // .bar {
        //   &:nth-child(3) {
        //     transform-origin: 100% 0;
        //     transform: scaleX(.5) translateZ(0);
        //     transition: transform .4s ease;
        //   }
        // }

        // &:hover {
        //   .bar {
        //     background-color: $white;

        //     &:nth-child(3) {
        //       transform: scaleX(1) translateZ(0);
        //     }
        //   }
        // }

        .icon-burger {
          width: em(55);
          height: em(55);
        }
      }

      &--search {
        text-align: center;

        .icon-search {
          pointer-events: none;
          width: em(45);
          height: em(45);
        }
      }
    }

    &__close-btn {
      position: absolute;
      top: em(42);
      right: em(33);
      border: none;
      background: none;

      .icon-close {
        width: em(52);
        height: em(52);
        display: block;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__wrapper {
      @include background-gradient($white, $grey-7, 'vertical');

      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      padding-left: em(113);
      transform: translateX(-100%);
      transition: transform .4s cubic-bezier(.71, .08, .35, .99) .2s;
    }

    &__content {
      display: none;
      position: relative;
      top: 50%;
      transform: translateY(-50%) translateZ(0);
      width: em(747);
      margin: 0 auto;

      &.active {
        display: block;
      }

      &__wrapper {
        display: table;
        width: 100%;
      }

      &--visit {
        .menu__links {
          width: percentage(1/6);
          vertical-align: top;
          padding-top: 0;

          .menu__link--big {
            width: 100%;
            margin-bottom: em(22, 12);
          }
        }

        .menu__content__wrapper {
          margin-top: em(86);
        }
      }

      // &--search {
      //   top: 40%;
      //   transform: translateY(-50%);
      // }
    }

    &__links,
    &__blocks,
    &__time,
    &__loc {
      display: table-cell;
    }

    &__links {
      width: percentage(2/3);
      vertical-align: middle;
      padding: em(21) em(30) 0 0;

      // &__inner {
      //   max-height: calc(78vh - #{em(137)});
      //   overflow-y: auto;
      // }
    }

    &__item {
      @include clearfix();

      position: relative;
      padding: em(12) 0 0;
      border-bottom: rgba($grey-8, .3) 1px dotted;

      &__content {
        float: left;
        position: relative;
        width: percentage(2/3);
      }
    }

    &__link {
      width: 50%;
      float: left;
      vertical-align: top;
      font: #{em(12)}/#{em(17, 12)} $robo-l;
      color: $grey-8;
      padding-right: em(10, 12);
      margin-bottom: em(12, 12);

      &:hover {
        text-decoration: underline;
      }

      &--title {
        font: #{em(14.4)}/#{em(17.28, 14.4)} $robo-bl;
        margin-left: em(-40, 14.4);
        color: $black;
        width: auto;
        float: none;
        vertical-align: middle;

        .ico-long-arrow-right-circle {
          margin-left: em(10, 14.4);

          &:before {
            font-size: em(20, 14.4);
            vertical-align: middle;
          }
        }

        &:hover {
          text-decoration: none;
        }
      }

      &--big {
        float: left;
        font-family: $robo-b;
        color: $grey-1;
        width: percentage(1/3);
      }

      &--small {
        width: auto;
        padding: 0 em(14, 14.4);
        border-left: $grey-8 1px solid;

        &:first-child {
          padding-left: 0;
          border-left: none;
        }
      }

      &__desc,
      &__date {
        display: block;
        font-size: em(10, 12);
      }

      &__desc {
        font-style: italic;
        line-height: em(14.4, 12);
      }

      &__date {
        font-family: $robo-b;
      }
    }

    &__ico {
      height: em(24, 14.4);
      vertical-align: middle;
      margin-left: em(5, 14.4);

      .ie & {
        width: em(40, 14.4);
      }
    }

    &__blocks {
      width: percentage(1/3);
    }

    .form-search {
      text-align: center;
      max-width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      .custom-input {
        max-width: em(632);
        background: none;
        font-family: $wal-b;
      }

      .form__control--event {
        @include placeholder() {
          font-family: $wal-b;
          padding-left: em(10, 51.6);
          letter-spacing: em(-1.55, 51.6);
          color: $grey;
        }

        font-size: em(51.6);
        line-height: em(61.92, 51.6);
        padding-left: em(10, 51.6);
        padding-top: em(10, 51.6);
        padding-bottom: em(10, 51.6);

      }

      .form__submit-event {
        background: none;
        right: em(6);
      }

      .icon-search {
        width: em(50);
        height: em(50);
        display: block;
      }
    }

    &__time {
      width: 50%;
      padding: 0 em(20);
      vertical-align: top;
      text-align: center;
      position: relative;

      &__item {
        background: $white-2;
        padding: em(18) em(12) em(8);
      }

      &__title,
      &__status,
      &__info {
        display: block;
        font-size: em(12);
        line-height: em(14.4, 12);
      }

      &__title {
        font-family: $robo-b;
        margin-top: em(5, 12);
      }

      &__status,
      &__info {
        font-family: $robo-l;
      }

      &__status {
        margin-top: em(9, 12);
        padding-bottom: em(15, 12);
        color: $grey-8;
      }

      &__info {
        margin-top: em(10, 12);
        padding-bottom: em(14, 12);

        &:last-child {
          padding-bottom: 0;
          color: $grey-1;
        }
      }

      &__detail {
        width: 50%;
        float: left;
        text-align: left;
        padding: em(10) em(7) em(6);

        &__wrapper {
          @include clearfix();
        }

        &__title,
        &__desc {
          display: block;
        }

        &__title {
          font: #{em(12)}/#{em(14.4, 12)} $robo-b;
        }

        &__desc {
          font: #{em(10)}/#{em(15, 10)} $robo-l;
          margin-top: em(8, 10);
        }
      }

      &__status,
      &__detail__wrapper,
      &__info:not(:last-child) {
        border-bottom: $grey 1px dotted;
      }

      &-revert {
        .menu__time__item {
          background: $white;
          border: 1px solid $grey-7;
        }
      }
    }

    &__loc {
      width: percentage(1/3);

      &__item {
        display: block;
        margin-bottom: em(17);
        box-shadow: 0 em(2) em(4) 0 rgba($black, .5);
        border-bottom: $moon em(4) solid;
        background-color: $white;
      }

      &__img {
        width: 100%;
      }

      &__caption {
        padding: em(5) 0 em(7) em(34);
      }

      &__title,
      &__desc {
        display: block;
      }

      &__title {
        font: #{em(12)}/#{em(14.4, 12)} $robo-b;
        color: $black;
      }

      &__desc {
        font: #{em(10)}/#{em(15, 10)} $robo-l;
        color: $grey-8;
      }
    }

    &__footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: em(40);
      padding: em(8) em(90) 0 em(171);
      background: $grey-1;
      text-align: center;
      transform: translateX(-100%);
      transition: transform .4s cubic-bezier(.71, .08, .35, .99) .2s;

      .top-navigation__list {
        vertical-align: top;
        padding: 0;

        &__link {
          font: #{em(12)}/#{em(16)} $robo-r;
          color: $white;
        }
      }

      &__social {
        display: inline-block;
      }
    }

    &__social-share {
      &.social-share {
        padding: 0 0 0 em(52);
      }
    }
  }
}

.menu {
  &__btn {
    border: none;

    &--burger {
      .bar {
        height: 2px;
        width: em(30, 14.4);
        background-color: $black;
        display: block;
        margin: em(6) auto;
      }

    }
  }

  &__block {
    display: block;
    width: 100%;
    margin-bottom: em(10);
    padding-bottom: em(7);
    box-shadow: 0 em(2) em(4) 0 rgba($black, .5);
    background-color: $white;

    &__image {
      position: relative;
      border-bottom: $moon em(4) solid;
    }

    &__img {
      display: block;
      width: 100%;
    }

    &__caption {
      position: absolute;
      top: em(13);
      left: em(14);
    }

    &__type,
    &__status {
      display: block;
      font-size: em(12);
      line-height: em(15.2, 12);
    }

    &__type {
      font-family: $robo-b;
      color: $moon;
      letter-spacing: em(1.25, 12);
    }

    &__status {
      font-family: $robo-l;
      color: $white;
      letter-spacing: em(-.07, 12);
    }

    &__title {
      display: block;
      font: #{em(12)}/#{em(17.2, 12)} $robo-b;
      color: $grey-2;
      padding: 0 em(15, 12);
      margin-top: em(3, 12);
      overflow: hidden;
      height: em(50, 12);
    }
  }
}

@media only screen and (min-width: em(1024)) and (max-width: em(1440)) {
  .menu {
    font-size: 16px;
  }
}

// @media only screen and (min-width: $breakpoint-min) and (orientation: portrait) {
//   .menu {
//     &__content {
//       top: 40%;
//     }
//   }
// }
