%date-background {
  content: '';
  position: absolute;
  bottom: em(-19);
  width: 50%;
  height: 100%;
  background-color: $grey;
}

.slider {
  position: relative;
  margin-bottom: em(35);

  &__wrapper {
    position: relative;
    overflow: hidden;
  }

  &__image {
    position: relative;
    width: 100%;
    z-index: 3;
  }

  &__content {
    position: absolute;
    left: 50%;
    bottom: em(38);
    width: em(304);
    transform: translateX(-50%);
    z-index: 6;
  }

  &__header {
    padding-bottom: em(10);
    margin-bottom: em(16);
    border-bottom: 1px solid $white;
    text-align: center;
    display: none;

    &.active {
      display: block;
    }
  }

  &__title {
    font: #{em(34)}/#{em(38, 34)} $wal-b;
    margin-bottom: em(8, 34);
  }

  &__bullet-wrapper {
    position: relative;
    text-align: center;
  }

  &__text,
  &__link {
    color: $white;
  }

  &__text {
    font: #{em(14, 18)}/#{em(18, 14)} $robo-r;
    margin-bottom: em(2, 14);
  }

  &__link {
    font: #{em(14, 14)}/#{em(18, 14)} $robo-r;
  }

  &__arrow-left,
  &__arrow-right {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &.disabled {
      pointer-events: none;
    }
  }

  &__arrow-left {
    margin-right: em(27);
  }

  &__loading {
    opacity: 0;

    &.running {
      opacity: 1;
      stroke-dasharray: 100;
      stroke-dashoffset: 100;
      animation: dash 5s linear .3s forwards infinite;
    }
  }

  .triangle-deco {
    bottom: em(11);
  }
}

@include keyframes(dash) {
  to {
    stroke-dashoffset: 0;
  }
}

.date {
  &__wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 7;
    width: em(306);
    height: em(54);
    transform: translateX(-50%);
    background-color: $grey;
    opacity: .86;
    text-align: center;

    &:before {
      @extend %date-background;

      left: 0;
      padding-right: 1px;
      z-index: 5;
      transform: skewY(13.5deg);
    }

    &:after {
      @extend %date-background;

      right: 0;
      z-index: 4;
      transform: skewY(-13.5deg);
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    width: 100%;
    height: em(90);
    margin-top: em(36);
    transform: translate(-50%, -50%);

    display: none;

    &.active {
      display: block;
    }
  }

  &__text {
    display: inline-block;
    margin: 0 em(10);
    width: em(55);
    line-height: em(32);
    color: $white;
  }

  &__number {
    font: #{em(38)}/#{em(20, 38)} $robo-l;
  }

  &__month {
    display: block;
    margin-top: 0;
    text-transform: uppercase;
    letter-spacing: em(.44);
    font: #{em(14)}/#{em(18, 14)} $robo-r;
  }
}

.bullets {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: auto;
  text-align: center;
}

.bullets-item {
  margin-bottom: em(5);

  &.active {
    .bullet {
      width: 5px;
      height: 5px;
    }
  }
}

.bullet {
  display: block;
  width: 3px;
  height: 3px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  border-radius: 50%;
  background-color: $blue;
  cursor: pointer;
}

.line-break {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 35%;
    right: em(-23);
    width: em(9);
    margin-top: em(-9);
    border: 1px solid $white;
    transform: translateX(-50%);
  }
}

@media (max-width: $breakpoint-max) {
  .slider {
    overflow: hidden;

    &__wrapper {
      &:before,
      &:after {
        bottom: em(24);
      }
    }

    &__content {
      position: relative;
      width: 100%;
      padding-top: em(10);
      padding-bottom: em(20);
      bottom: 0;
      border-bottom: 1px solid $black;
    }

    &__image {
      width: 176%;
      margin-left: -38%;
    }

    &__header {
      padding-left: em(40);
      padding-right: em(40);
    }

    &__text,
    &__arrow-left,
    &__arrow-right {
      color: $black;
    }

    &__text {
      padding-top: em(8);
    }

    &__path {
      stroke: $black;
    }

    &__loading {
      stroke-width: 3px;
    }

    .triangle-deco {
      top: auto;
      bottom: em(6);
      z-index: 1;
    }
  }

  .date {
    &__wrapper {
      position: relative;
      top: em(-50%);
      left: 0;
      z-index: 0;
      width: 100%;
      height: em(95);
      transform: translateX(0);
      background: none;

      &:before,
      &:after {
        z-index: 0;
        height: 120%;
      }
    }

    &__content {
      top: em(60);
    }

    &__number {
      font-size: em(36);
      line-height: em(20, 36);
    }

    &__month {
      font-size: em(12);
      line-height: em(30, 12);
    }
  }
}
