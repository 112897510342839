.infor {
  &__shop-image {
    margin-bottom: em(20);
    width: 100%;
  }

  &__shop-image-small {
    float: left;
    margin-right: em(23);
  }

  &__content {
    @extend %clearfix;
  }

  &__desc {
    overflow: hidden;
  }

  &__text-note {
    margin-bottom: em(5);
    font: #{em(14.4)/1} $robo-b;
    color: $grey-8;
  }

  &__text {
    &--spacing-bottom-small {
      margin-bottom: em(30, 14.4);
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .infor {
    &__shop-image-small {
      display: none;
    }

    &__content {
      padding-left: em(6);
      padding-right: em(6);
    }

    &__text-note {
      font-size: em(12);
    }
  }
}
