.accordion {

  &__item {
    border-top: 1px solid $grey;

    &.opened {
      .accordion__title {
        color: $lolla;
      }

      .accordion__title:after {
        content: $ico-minus;
        right: em(7, 20.74);
        font-size: em(25, 20.74);
      }
    }
  }

  &__item--last {
    border-bottom: 1px solid $grey;

    .cta {
      padding-left: em(7);
      padding-top: em(26);
      padding-bottom: em(26);
      background-color: $white;

      &--full {
        margin-top: 0;
      }
    }

    .cta__text {
      font: #{em(28.8)}/#{em(39, 28.8)} $wal-b;
      color: $black;
    }

    .cta__icon {
      color: $black;
    }

    .cta__ico {
      circle,
      path {
        stroke: $black;
      }
    }
  }

  &__content {
    display: none;
  }

  &__title {
    display: block;
    position: relative;
    padding-top: em(14, 20.74);
    padding-bottom: em(14, 20.74);
    padding-right: em(38, 20.74);
    color: $black;
    font: #{em(20.74)}/#{em(24.88, 20.74)} $wal-b;

    &::after {
      content: $ico-plus;
      position: absolute;
      top: 50%;
      right: em(5, 32);
      font-family: $sam;
      font-size: em(32, 20.74);
      transform: translateY(-50%);
    }

    &:hover {
      &:after {
        content: $ico-plus-hover;
      }
    }
  }

  &__wrapper {
    padding: 0 em(100);
    margin-bottom: em(150);
    position: relative;
    z-index: 1;
  }

  &--big {
    .accordion {
      &__item {
        border-top: none;
      }

      &__title {
        font-size: em(28.8);
        border-bottom: em(1, 28.8) solid $grey;
        padding: em(29, 28.8) em(50, 28.8) em(26, 28.8) em(7, 28.8);

        &:after {
          font-size: em(31, 28.8);
          right: em(18, 28.8);
        }
      }

      &__content {
        background-color: $white-3;
      }
    }

    .accordion__item {
      &.opened {
        .accordion__title {
          border-bottom: none;
        }

        .accordion__title:after {
          font-size: em(31, 33);
          right: em(18, 33);
        }
      }
    }
  }

  &--border {
    padding: em(35, 14.14) em(163, 14.14);
    border-top: em(28, 14.14) solid $white-4;
  }

  .infor__btn {
    .btn__text {
      min-width: em(225, 18);
      padding: em(12, 18);
      color: $black;
      background-color: $grey-13;
    }
  }
}

.rte {
  .accordion__title {
    color: $black;
  }
}

@media screen and (min-width: $breakpoint-min) {
  .accordion__wrapper--give {
    margin-bottom: 0;
  }

  .detail__desc-item {
    .accordion__item {
      &.opened {
        padding-bottom: em(20);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .accordion {
    &__wrapper {
      padding: 0 em(19) em(35);
      margin-bottom: em(70);
      border-bottom: 1px solid $grey-8;
    }

    &__item:not(.time-of-other__artists-content) {
      .time-of-other__show-artists {
        margin-bottom: em(-8, 20.74);
        padding-top: em(25, 20.74);
        padding-bottom: em(25, 20.74);
      }

      &.opened {
        .time-of-other__show-artists {
          margin-bottom: 0;
        }
      }
    }

    &__title {
      padding: em(22, 20.74) em(38, 20.74) em(14, 20.74) em(10, 20.74);
    }

    &__content {
      margin: 0;
    }

    &--big {
      // margin-bottom: em(38);

      .accordion__content {
        margin-left: auto;
        margin-right: auto;
      }

      .accordion__title {
        font-size: em(29);
        line-height: em(26.12, 24.88);
        padding: em(22, 29) em(44, 29) em(30, 29) 0;
      }

      .accordion__item {
        &.opened {
          .accordion__title:after {
            font-size: em(20, 29);
          }
        }
      }
    }

    &__item--last {
      .cta--full {
        margin-top: 0;
      }

      .cta__text {
        font: #{em(24.88)}/#{em(26.12, 24.88)} $wal-b;
        color: $black;
      }
    }
  }
}

.ie11 {
  .accordion--big {
    .accordion {
      &__title:after {
        font-size: .65em;
      }
    }

    .accordion__item {
      &.opened {
        .accordion__title:after {
          font-size: .8em;
        }
      }
    }
  }
}
