.custom-select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: em(16);
  text-align: left;
  color: $black;
  width: em(150);
  height: em(40);

  select {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 3;
    border: 0;
    background: transparent;
    opacity: 0;

    &:focus {
      outline: none;
    }
  }

  &__hidden {
    display: none;
    visibility: hidden;
    padding-right: em(10);
  }

  &__styled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: transparent;
    padding: em(8) em(26) em(8) em(15);
    transition: all .2s ease-in;
    user-select: none;
    cursor: pointer;

    &::after {
      font-family: $sam;
      content: $ico-arrow-down-bold;
      position: absolute;
      top: 50%;
      right: em(2);
      z-index: 1;
      transform: translateY(-50%);
    }
  }

  &__options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: em(170);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $nine-black;
  }

  &__item {
    margin: 0;
    padding: em(12) 0;
    text-indent: em(15);
    color: $white;
    overflow: hidden;
    position: relative;

    &.active {
      color: $yellow;

      .custom-select__item-text {
        &:after {
          width: 100%;
        }
      }


    }

    &:hover:not(.active) {
      .overlay-text {
        transform: translate3d(0, 0, 0);
      }

      .overlay-text__inner {
        transform: translate3d(0, 0, 0);
      }

      .custom-select__item-text {
        &:after {
          width: 100%;
        }
      }
    }

    &-text {
      position: relative;
      padding-bottom: 3px;

      &:after {
        content: '';
        width: 0;
        height: 2px;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $yellow;
        transition: width .5s cubic-bezier(.19, 1, .22, 1);
      }
    }
  }

  .overlay-text {
    position: absolute;
    top: 5px;
    display: inline-block;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate3d(-100%, 0, 0);
    overflow: hidden;
    transition: transform 1s cubic-bezier(.19, 1, .22, 1);
    z-index: 3;
  }

  .overlay-text__inner {
    transform: translate3d(100%, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 1s cubic-bezier(.19, 1, .22, 1);
    color: $yellow;
    z-index: 2;
  }

  &--scroll {
    font-size: em(14.4);
    font-family: $robo-l;
    width: 100%;
    height: em(58, 14.4);
    border-bottom: em(1, 14.4) solid $grey;

    .overlay-text {
      display: none;
    }

    .custom-select__options {
      background-color: transparent;
      max-height: em(192, 13.5);

      ul {
        // max-height: em(222);
        // overflow-y: auto;
        background-color: $nine-black;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
      }

      &:before {
        top: em(-9, 14.4);
        right: em(18, 14.4);
        border-left: em(9, 14.4) solid transparent;
        border-right: em(10, 14.4) solid transparent;
        border-bottom: em(9, 14.4) solid $black;
      }

    }

    .custom-select__styled {
      padding: em(20, 14.4) 0;
      font-size: em(14.4);
      color: $grey;

      &:after {
        color: $grey;
        content: $ico-arrow-down;
      }
    }

    .custom-select__item {

      &:hover,
      &.active {
        background-color: $grey-17;
        color: $black;

        .custom-select__item-text {
          border-bottom: 0;
          padding-bottom: 0;
          font-family: $robo-r;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  .custom-select--scroll &.selected {
    .custom-select__styled {
      color: $grey-8;
      font-family: $robo-r;

      &:after {
        color: $grey-8;
      }
    }
  }

  &--special {
    width: auto;
    min-width: em(230);
  }
}
