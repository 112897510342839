$rem-base: 16px;
$em-base: 16px;

//path
$font-path: '../fonts/';
$img-path: '../img/';
$icon-path: '../img/icons/';
$upload-path:'../img/upload/';
$icomoon-font-path: $font-path;

//fonts
$sam: 'sam';
$robo-bl: 'roboto-black';
$robo-b: 'roboto-bold';
$robo-l: 'roboto-light';
$robo-m: 'roboto-medium';
$robo-r: 'roboto-regular';
$wal-b: 'GT-Walsheim-Black';
$wal-o: 'GT-Walsheim-Black-Oblique';

//color
$white: #ffffff;
$white-1: #f8f8f8;
$white-2: #f2f2f2;
$white-3: #f5f5f5;
$white-4: #eaeaea;
$half-white: rgba($white, .5);
$black: #000000;
$black-1: #575757;
$half-black: rgba($black, .5);
$nine-black: rgba($black, .9);
$placeholder: #a40070;
$fieldset-border-color: #c0c0c0;

$grey: #9b9b9b;
$grey-1: #757575;
$grey-2: #4c4c4c;
$grey-3: #0b0b0b;

$grey-4: #d8d8d8;
$grey-5: #979797;
$grey-6: #e4e4e4;
$grey-7: #dddddd;
$grey-8: #4a4a4a;
$grey-9: #a5a5a5;
$grey-10: #212121;
$grey-11: #f3f3f3;
$grey-12: #5c5c5c;
$grey-13: #f5f5f5;
$grey-14: #eaeaea;
$grey-15: #a4a4a4;
$grey-16: #bebebe;
$grey-17: #eeeeee;
$grey-18: #cbcbcb;
$grey-19: #cacaca;

$moon: #fff200;
$moon-1: #ffd500;
$moon-2: #ffad00;
$moon-3: #ff8700;

$blue: #00e6ff;
$blue-1: #00baff;
$blue-2: #00a3ff;
$blue-3: #007bff;
$blue-4: #3C5898;
$blue-5: #065aff;

$lime: #aaff00;
$lime-1: #64ff00;
$lime-2: #00f21c;
$lime-3: #00dd1a;

$dolly: #005aff;
$dolly-1: #0037ff;
$dolly-2: #002fda;
$dolly-3: #0024a5;

$lolla: #ff00ae;
$lolla-1: #ff006a;
$lolla-2: #f10039;
$lolla-3: #e20039;

$yellow: #fef200;
$yellow-1: #ff9f00;

$purple: #412768;
$purple-1: #613e74;
$purple-2: #9055a5;
$purple-3: #4a318d;

$aqua: #00e6fe;

$green: #085e36;
$green-1: #5F6345;

$orange: #F99E1B;

$red: #ff0000;
// breakpoint
$breakpoint-min: em(768);
$breakpoint-max: em(767);

//grid
$grid-gutter: 22;
$container-width: 962 + $grid-gutter;
