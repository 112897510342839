.educational {
  position: relative;
  margin: 0 auto em(57);
  max-width: em(962);
  padding-bottom: em(100);
  padding-top: percentage(100/1000);
  margin-bottom: em(162, 18);
  border-bottom: 2px solid $black;

  &__left,
  &__right {
    width: percentage(1/2);
    float: left;
  }

  &__left {
    padding-right: em(26);
    text-align: right;
  }

  &__right {
    border-left: 1px solid $grey;
    padding-left: em(18);
    padding-top: em(100);
  }

  &__title {
    font: #{em(53.78)}/#{em(42, 53.78)} $wal-b;
    letter-spacing: em(-2.24, 53.78);
  }
}

.download-list {

  &__item {
    margin-bottom: em(20);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    color: $dolly;
    font: #{em(17.28)}/#{em(18.14)} $robo-m;
    letter-spacing: -5px;;

    &:hover {
      color: $black;
    }
  }

  &__ico,
  &__text {
    display: inline-block;
    vertical-align: middle;
    letter-spacing: normal;
  }

  &__ico {
    margin-right: em(15, 34);
    font-size: em(34, 17.28);
    line-height: em(36, 34);
  }

  &__text {
    width: 85%;
  }

}

@media screen and  (max-width: $breakpoint-max) {
  .educational {
    margin-bottom: em(65);

    &__left,
    &__right {
      padding-left: em(25);
      padding-right: em(25);
      width: 100%;
      float: none;

    }

    &__left {
      text-align: center;
    }

    &__right {
      border-left: none;
      margin-top: em(34px);
      padding-top: 0;
      overflow: hidden;
    }

    &__title {
      font: #{em(35.83)}/#{em(37.62, 35.83)} $wal-b;
      letter-spacing: em(-1.47, 35.83);
    }
  }

  .download-list {
    max-width: em(190);
    float: right;

    &__item {
      margin-bottom: em(57);
    }

    &__link {
      line-height: em(18.14, 17.28);
    }

    &__text {
      width: 72%;
    }
  }
}
