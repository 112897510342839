.category-infor {
  background-color: $grey-13;
}

.infor {
  &__title {
    font: #{em(15)/1} $robo-b;
    background-color: $grey-14;
    display: block;
  }

  &__text {
    &--small {
      font-size: em(9.8, 14.14);
    }

    .infor__link--light,
    .infor__text-bold {
      font-size: em(14.4, 14.4);
    }
  }

  &__heading {
    font: #{em(14.4)/1} $robo-b;
    color: $grey-8;
    margin-bottom: em(20, 14.4);

    &--no-space {
      margin-bottom: 0;
    }
  }

  &__inner {
    @extend %clearfix;
  }

  &__link {
    font: #{em(14.4)/1} $robo-b;
    color: $blue-3;
    display: inline-block;

    &--light {
      font-size: em(14.4);
      font-family: $robo-r;
    }
  }

  &__item {
    padding-left: 0;

    + .infor__item {
      border-top: em(1) solid $grey-5;
      padding-top: em(20);
      margin-top: em(20);
    }

    .infor__btn {
      margin: 0;
    }

    .infor__group {
      padding-left: em(14, 14.4);
    }

  }

  &__group {
    .infor__link--light {
      margin-top: 0;
    }
  }
}

.download-list__link--small {
  display: block;
  margin-bottom: em(20, 16);

  .download-list__ico {
    font-size: em(23, 16);
    top: inherit;
    bottom: 0;
  }

  .download-list__text {
    font: em(13.6) $robo-b;
  }
}

@include minquery($breakpoint-min) {
  .infor {
    &__inner {
      padding: em(50) em(60) em(30);

      &--small-top {
        padding-top: em(30);
        padding-bottom: em(50);
      }
    }

    &__heading {
      > .infor__title {
        // padding: em(4, 13.5) em(165, 13.5);
      }
    }

    &__title {
      padding: em(4, 15) em(60, 15);
    }

    &__link {
      margin-top: em(15, 14.4);

      &--light {
        margin-top: 0;
      }
    }

    &__list-half {
      column-count: 2;
      column-gap: em(20);

      li {
        margin-bottom: em(20);
      }
    }
  }

  .wrapper-cta {
    padding: em(22) em(82) em(30);

    .cta--full {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .infor {
    &__title {
      font-size: em(14);
      padding: em(10, 14) em(10, 14) em(9, 14) em(8, 14);
    }

    &__inner {
      padding: em(20) em(8);
    }

    &__link {
      margin-top: em(65, 14);
    }

    &__list-half {
      li {
        margin-bottom: em(20);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .wrapper-cta {
    .cta--full {
      margin-top: 0;
    }
  }

  .download-list__link--small {
    display: block;
    margin-bottom: em(29, 16);
    margin-top: em(21, 16);
    padding-left: em(11, 16);

    .download-list__ico {
      font-size: em(24, 16);
      margin-right: em(18, 24);
    }

    .download-list__text {
      font: em(13.6) $robo-b;
    }
  }
}
