.banner {
  position: relative;

  &__inner {
    text-align: center;
  }

  &__title {
    font: #{em(36.8)}/1 $wal-b;
    color: $black;
    display: block;
    margin-bottom: em(6);
    animation: slideUp .9s ease .5s 1 forwards;

    &--big {
      font-size: em(34);
      margin-bottom: em(6, 34);
    }
  }

  &__link {
    text-decoration: none;
    display: inline-block;
    line-height: 1;
    position: relative;
    padding-right: em(40);

    text-align: right;

    &:hover {
      opacity: .8;
    }
  }

  &__link-block {
    position: absolute;
    text-align: center;
  }

  &__link-title {
    font: #{em(16)}/1 $robo-b;
    display: inline-block;
    letter-spacing: 5px;
    color: $moon-3;

    &:after {
      @extend %icon;
      // content: $ico-long-arrow-right-circle;
      font-size: em(29, 14);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &--video {
      &:after {
        content: $ico-play;
      }
    }
  }

  &__arrow-ico {
    height: em(29);
    position: absolute;
    right: em(-29);
    top: 50%;
    transform: translateY(-50%);

    .ie & {
      width: em(58);
    }
  }

  &__date {
    font: #{em(14.4)}/1 $robo-l;
    color: $moon-2;
    text-transform: uppercase;
    letter-spacing: .5px;
    padding-right: em(5);
    display: block;
  }

  &__description {
    font: #{em(16)}/#{em(19, 16)} $robo-l;
    width: em(380);
    max-width: 95%;
    margin: 0 auto em(10);
    animation: slideUp .9s ease .9s 1 forwards;
    color: $grey-8;

    &--small {
      font-size: em(14.4);
      line-height: em(19, 14.4);
      color: $grey-8;
      width: em(380, 14.4);
    }
  }

  //for animation
  &__title,
  &__description {
    opacity: 0;
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: em(420px);
  }

  &__content-white {
    .banner__title,
    .banner__description {
      color: $white;
    }

    .downward {
      .ico-arrow-down {
        color: $white;
      }
    }
  }

  &__image {
    width: 100%;
  }

  &__desc-link {
    color: $black;
    text-decoration: underline;
  }

  &--hero {
    padding-top: em(135);
    min-height: em(571);

    .banner__image {
      width: auto;

      .ie & {
        width: em(320);
      }
    }

    &__content {
      max-width: em(505);
      padding: em(25) 0 em(14);
      margin: em(37) auto 0;
    }

    &--invert {
      background-color: $lolla;

      &:before {
        @include background-gradient(rgba($white, .85), transparent, 'vertical');

        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: em(200);
      }

      .banner__title,
      .banner__description,
      .banner__desc-link {
        color: $white;
      }

      .downward {
        .ico-arrow-down {
          color: $white;
        }

        &:hover {
          .ico-arrow-down {
            &:before,
            &:after {
              color: $moon;
            }
          }
        }
      }
    }
  }

  &--landing {
    margin-bottom: em(130);
    max-width: 100vw;
    overflow: hidden;

    .triangle-deco {
      bottom: em(48);
    }
  }

  &--detail {
    margin-bottom: em(36);
    overflow: hidden;

    .decotriangle--bottom {
      bottom: -21%;
      z-index: 0;

      &:before,
      &:after {
        background-color: $white;
      }

      &:before {
        width: 59%;
        transform: skewY(-13.5deg);
        transform-origin: 100% 0;
      }

      &:after {
        width: 41%;
        transform: skewY(10deg);
        transform-origin: 0 0;
      }
    }
  }

  &__link-default {
    padding-right: 0;

    &:hover {
      cursor: default;
      opacity: 1;
    }

    .banner__link-title {
      font: #{em(16)}/#{em(20.8, 16)} $wal-b;
      letter-spacing: 0;

      &:after {
        content: '';
      }
    }
  }

  &--small {
    .decotriangle--bottom {
      bottom: -18%;
      z-index: 0;

      &:before,
      &:after {
        top: -17%;
      }

      &:before {
        width: 54%;
      }

      &:after {
        width: 46%;
      }
    }
  }

  &__link-learn-teach {
    .banner__link-title,
    .banner__date {
      color: $green;
    }
  }

  &__link-collection {

    .banner__link-title,
    .banner__date {
      color: $green-1;
    }
  }

  &__link-learn-teach,
  &__link-collection {
    padding-right: 0;

    .banner__link-title {
      font: #{em(16)}/1 $wal-b;
      letter-spacing: 0;

      &:after {
        content: '';
      }
    }

    .banner__date {
      font: #{em(14.4)}/1 $robo-l;
      letter-spacing: 0;
      text-transform: capitalize;
    }
  }
}

@include keyframes(slideUp) {
  from {opacity: 0; transform: translate3d(0, 50%, 0);}
  to {opacity: 1; transform: none;}
}

.downward {
  display: inline-block;
  width: em(30);
  height: em(30);
  text-decoration: none;

  &:hover {
    cursor: pointer;

    .ico-arrow-down {
      &:before,
      &:after {
        color: $lolla;
      }
    }
  }

  .ico-arrow-down {
    position: relative;
    display: block;
    color: $black;
    font-size: em(30);

    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: arrowpointing forwards infinite 3s cubic-bezier(.52, .01, .16, 1);
    }

    &:after {
      content: $ico-arrow-down;
      animation-delay: 1.5s;
    }
  }
}

.module-header {
  &--no-banner {
    padding-top: em(135);
  }

  .title {
    padding: 0 em(115, 43);
    font: #{em(43)}/#{em(51.6, 43)} $wal-b;

    &__wrapper {
      margin-bottom: em(70);
      text-align: center;
    }
  }
}


@keyframes arrowpointing {
  0%,
  30% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(8px);
    opacity: 0;
  }

  85% {
    transform: translateY(-7px);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include minquery($breakpoint-min) {
  .banner {
    &__content {
      padding: em(30);
    }

    &__date {
      margin-top: em(5);
    }

    // &--landing1 {
    //   &:before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     background: rgba($white, 0);
    //     background: -moz-linear-gradient(top, rgba($white, 0) 0%, rgba($white, 0) 47%, rgba($white, 1) 100%);
    //     background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba($white, 0)), color-stop(47%, rgba($white, 0)), color-stop(100%, rgba($white, 1)));
    //     background: -webkit-linear-gradient(top, rgba($white, 0) 0%, rgba($white, 0) 47%, rgba($white, 1) 100%);
    //     background: -o-linear-gradient(top, rgba($white, 0) 0%, rgba($white, 0) 47%, rgba($white, 1) 100%);
    //     background: -ms-linear-gradient(top, rgba($white, 0) 0%, rgba($white, 0) 47%, rgba($white, 1) 100%);
    //     background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 0) 47%, rgba($white, 1) 100%);
    //     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
    //   }

    //   .banner__image {
    //     position: relative;
    //     z-index: -1;
    //   }
    // }

    &--hero {
      padding-top: 50%;

      .banner__inner {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
      }

      .banner__image {
        height: 40%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }

      &__content {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
    }
  }
}



@media screen and (max-width: $breakpoint-max) {
  .module-header {
    .title {
      padding: 0 em(20, 35.84);
      font: #{em(35.83)}/#{em(37.62, 35.83)} $wal-b;

      &__wrapper {
        margin-bottom: em(44);
      }
    }
  }

  .banner {
    // margin-bottom: em(61);
    padding-top: em(52);

    &__link-block {
      position: relative;
      // !important over CSS inline html desktop
      // top: em(280) !important; //sass-lint:disable-line no-important
      left: 0 !important; //sass-lint:disable-line no-important
      // !important over CSS inline html desktop
      top: 15% !important; //sass-lint:disable-line no-important
      width: 100%;
      padding: em(2) em(10);
      transform: none !important; //sass-lint:disable-line no-important
    }

    &__inner {
      position: absolute;
      bottom: 5%;
      width: 100%;
    }

    &__content {
      width: 100%;
      padding: em(5) em(10) em(10);
      position: relative;
    }

    &__title {
      font-size: em(30);
      margin-bottom: em(2, 30);
    }

    &__description {
      font-size: em(15);
      line-height: em(21);
      margin-bottom: em(10);

      &--small {
        line-height: em(16, 14.4);
        margin-bottom: em(22, 14.4);
      }
    }

    &__link-title {
      font-size: em(11);
      letter-spacing: 4px;
      margin-bottom: em(8);

      &:after {
        font-size: em(32, 11);
      }
    }

    &__date {
      padding-bottom: em(5);
    }

    &--hero {
      padding-top: em(100);
      margin-bottom: 0;

      .banner__image {
        max-width: 80%;
        margin: 0 auto;
        display: block;
      }

      .banner__title {
        font-size: em(24.88);
        line-height: em(26.13, 24.88);
        margin-bottom: em(15, 24.88);
      }
    }

    &--landing {
      margin-bottom: em(30);

      .banner__inner {
        bottom: 0;
      }

      // .banner__link-block {
      //   top: 42% !important; //sass-lint:disable-line no-important
      //   left: 50% !important; //sass-lint:disable-line no-important
      //   width: 100%;
      // }

      // .banner__link-block-bottom {
      //   top: 50% !important; //sass-lint:disable-line no-important
      //   left: 0 !important; //sass-lint:disable-line no-important
      //   right: 0 !important; //sass-lint:disable-line no-important
      //   width: 100%;
      //   transform: translateY(-50%);
      // }

      .banner__image {
        width: 270%;
        margin-left: -85%;
      }

      // .banner__content {
      //   padding: 0 em(30) em(23);
      // }

      .banner__title {
        font-size: em(24);
        margin-bottom: em(6, 24);
      }

      .banner__description {
        font-size: em(14);
        line-height: em(18, 14);
        margin-bottom: em(16, 14);
      }

      .triangle-deco {
        bottom: em(6);
        top: auto;
      }
    }

    &--detail {
      margin-bottom: em(15);
      padding-top: em(51);

      .decotriangle--bottom {
        bottom: -38%;

        &:before {
          width: 44%;
        }

        &:after {
          width: 56%;
          transform: skewY(14deg);
        }
      }
    }

    &--small {
      .decotriangle--bottom {
        bottom: -14%;

        &:before {
          width: 63%;
        }

        &:after {
          width: 37%;
          transform: skewY(10deg);
        }
      }
    }

    &--learn-teach {

      .banner__link-block {
        position: relative;
      }

      .banner__link {
        text-align: center;
      }

      .banner__content {
        position: relative;
      }
    }
  }
}
