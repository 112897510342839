.event-calendar {
  position: relative;
  z-index: 1;

  &__title {
    font: em(53.78) $wal-b;
    color: $black;
    letter-spacing: -.19px;
    text-align: center;
  }

  &__date {
    font: em(17.28) $robo-bl;
    color: $black;
    letter-spacing: em(3.5);
    text-align: center;
    text-transform: uppercase;
    margin-bottom: em(31, 53.78);
  }

  &__inner {
    display: block;
    width: 100%;
    margin-top: em(55);
  }

  .btn--cta {
    &.hidden {
      display: none;
    }
  }
}

.events-filter {
  display: table;
  width: 100%;
  max-width: em(800);
  margin: auto;
  border-bottom: 1px solid $grey;

  &__display {
    float: left;
    width: 50%;
    text-align: left;
  }

  &__type {
    float: right;
    width: 50%;
    text-align: right;

    .custom-select__styled:after {
      padding-right: em(2, 17.28);
    }
  }

  &__label {
    font: em(17.28) $robo-b;
    color: $grey-15;
    display: inline-block;
    vertical-align: top;
    padding-right: em(10, 17.28);
  }

  &__control {
    display: inline-block;
    vertical-align: top;
  }

  .custom-select {
    font: em(17.28) $wal-b;
    height: em(25, 17.28);
    width: em(135, 16.2);

    &::after {
      content: '';
      width: 99%;
      border-bottom: em(3) solid $black;
      display: block;
      position: absolute;
      left: 0;
      bottom: em(-8, 17.28);
    }

    &__styled {
      padding: 0 em(28) 0 em(5);
    }

    &__styled:after {
      font-size: em(11);
      right: em(5, 11);
    }

    &__options {
      left: em(-1, 16.2);
      top: em(28, 16.2);
      // margin-top: em(5, 19.44);
    }

    &__item {
      padding: em(5, 17.28) 0;
    }

    &__item:last-child {
      padding-bottom: em(15, 17.28);
    }
  }
}

.events-filter__type {
  .custom-select {
    width: em(95, 17.28);
  }
}

.slider-events {
  position: relative;
  width: 100%;
  max-width: em(800);
  margin: em(25, 15) auto em(72, 15);

  &__item {
    float: left;
    width: percentage(1/7);
    text-align: center;

    &::after {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      border-bottom: em(7) solid $white;
      z-index: -1;
    }
  }

  &__link {
    position: relative;
    border-bottom: em(7) solid $black;
    display: inline-block;
    vertical-align: middle;
    width: 92%;
    color: $black;
    padding-bottom: em(37);
    outline: none;
  }

  &__day {
    font: em(29.86) $wal-b;
    color: $black;
    text-align: left;
    padding-left: em(29, 29.86);
  }

  &__month {
    font: em(14.4) $robo-bl;
    color: $grey-8;
    text-align: left;
    padding-left: em(19, 14.4);
  }

  &__year {
    font: em(29.86) $wal-b;
    color: $black;
    text-align: center;
  }

  &__symbol {
    color: $black;
  }

  &__item-revert {
    .slider-events {
      &__day,
      &__month,
      &__year,
      &__symbol {
        color: $grey-16;
      }
    }
  }

  .slick-arrow {
    font-size: em(30);
    position: absolute;
    top: em(18, 30);
    padding: em(1);
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
  }

  .slick-prev {
    left: em(-43, 30);
  }

  .slick-next {
    right: em(-45, 30);
  }

  .slick-disabled {
    opacity: .4;
    cursor: default;
  }
}

.slider-events__item {
  &.current {
    .slider-events__link {
      border-bottom-color: $aqua;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-left: em(15) solid transparent;
        border-right: em(15) solid transparent;
        border-bottom: em(10) solid $aqua;
      }
    }

    .slider-events__month,
    .slider-events__day,
    .slider-events__year,
    .slider-events__symbol {
      color: $aqua;
    }
  }
}

@include minquery($breakpoint-min) {
  .slider-events {
    &__item {
      overflow: hidden;
    }

    &__day {
      line-height: em(20, 27.9937);
      padding-top: em(12, 27.9937);
      padding-bottom: em(34, 27.9937);
    }

    &__month {
      line-height: em(14.4, 14.4);
    }

    &__link {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__year {
      display: block;
      line-height: em(20, 27.9937);
      margin-top: 0;
      padding-top: em(25, 27.9937);
      padding-bottom: em(34, 27.9937);
    }
  }

  .event-calendar {
    padding-bottom: em(80, 18);

    .associated {
      &__img {
        width: 39%;
        padding-right: em(14);
      }

      &__time {
        text-align: left;
        padding-top: em(14, 16);
        padding-left: em(50, 16);
      }

      &__time__date {
        line-height: em(38, 51.6);
      }

      &__time__month {
        font-family: $robo-bl;
        margin-top: em(5, 12);
        margin-bottom: em(7, 12);
        padding-bottom: em(2, 12);
        padding-left: 0;
        padding-right: em(19, 12);
      }

      &__time__day {
        font-family: $robo-bl;
        line-height: em(12);
      }

      &__infor__title {
        max-width: em(308, 20.74);
        margin-bottom: em(8, 20.74);
      }

      &__infor__link {
        font-size: em(20.74);
        line-height: em(22, 20.74);
      }

      &__infor__star {
        margin-top: em(3, 14.4);
      }
    }
  }

  .slider-events__item:not(.current) {
    .slider-events__link {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: em(-7);
        width: 100%;
        border-bottom: em(8) solid $aqua;
        transform: scaleX(0);
        transition: transform .2s;
        transform-origin: right center;
        z-index: 5;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) scaleY(0);
        border-left: em(15) solid transparent;
        border-right: em(15) solid transparent;
        border-bottom: em(10) solid $aqua;
        transition: all .2s .1s;
        transform-origin: bottom center;
      }

      .slider-events__month,
      .slider-events__day,
      .slider-events__year,
      .slider-events__symbol {
        transition: color .2s;
      }
    }
  }

  .slider-events__item:not(.current) {
    &:hover {
      .slider-events__link {
        &::before {
          transform: scaleX(1);
          transform-origin: left center;
        }

        &::after {
          transform: translateX(-50%) scaleY(1);
          transform-origin: bottom center;
        }

        .slider-events__month,
        .slider-events__day,
        .slider-events__year,
        .slider-events__symbol {
          color: $aqua;
        }
      }
    }
  }

  .firefox {
    .slider-events__item:not(.current) {
      .slider-events__link {
        &::before {
          width: 101%;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .event-calendar {
    padding-bottom: em(96, 16);

    &__inner {
      margin-top: em(43);
    }
  }

  .events-filter {
    padding-top: em(42, 17.28);
    padding-bottom: em(11, 17.28);
    border-bottom: none;

    &::after {
      content: '';
      display: block;
      height: em(1);
      border-bottom: em(1) solid $grey;
      margin: em(45, 17.28) em(-11, 17.28) 0;
    }

    &__display {
      padding-left: em(8, 16);

      .custom-select {
        min-width: em(135, 17.28);
      }
    }

    &__type {
      .custom-select {
        width: em(95, 17.28);
        margin-right: em(11, 17.28);

        .custom-select__styled {
          text-align: right;
          padding-right: em(28);
        }
      }
    }

    &__label {
      display: block;
      padding-right: em(12, 17.28);
    }

    &__control {
      display: block;
      margin-top: em(-6);
      padding-right: em(2, 16);
    }

    .custom-select {
      &::after {
        bottom: em(-4);
      }
    }
  }

  .slider-events {
    width: auto;
    margin: em(26, 17.28) em(-11, 17.28) em(35, 17.28) 0;


    &__item {
      text-align: left;
      margin-left: em(15, 16);
      min-height: em(68, 16);
    }

    &__link {
      width: 98%;
      min-height: em(74, 16);
      padding-bottom: em(15);

      &::after {
        display: none;
      }
    }

    &__day {
      font-size: em(27.44);
      line-height: em(34, 27.44);
      text-align: center;
      padding-left: 0;
    }

    &__month {
      font-size: em(14);
      padding-right: em(70, 14);
      text-align: center;
    }

    &__year {
      font-size: em(29.86);
      line-height: em(34, 29.86);
      padding-top: em(8, 29.86);
    }

    .slick-prev,
    .slick-next {
      box-shadow: 0 0 em(140, 30) em(25, 30) $white;
    }

    .slick-prev {
      top: em(14, 30);
      left: 0;
      z-index: 3;
    }

    .slick-next {
      top: em(14, 30);
      right: 0;
    }

    .slick-next-line {
      width: em(67);
      border-bottom: em(4.5, 17.28) solid $black;
      bottom: 0;
      left: 99%;
    }
  }

  .events__list {
    .associated {
      &__time {
        padding-top: em(19, 16);
      }

      &__img {
        padding-right: em(128, 16);
        padding-left: em(10, 16);
      }

      &__img:before {
        bottom: em(-2);
        right: em(21);
      }

      &__time__date {
        font-size: em(38.42);
        line-height: em(21, 38.42);
      }

      &__time__month {
        font-size: em(10);
        padding-right: em(4, 10);
        padding-bottom: em(4, 10);
      }

      &__infor {
        padding-top: em(8, 16);
        margin-left: em(5, 16);
      }

      &__infor__title {
        margin-bottom: 0;
      }

      &__infor__star {
        font-size: em(12);
        margin-top: em(5, 12);
        text-transform: inherit;
      }
    }
  }
}
