.collection {
  @include deco-triangle($white-1, $grey-7, $moon);

  padding-top: em(90);
  padding-bottom: em(54);
  margin-bottom: em(228);
  z-index: 1;

  .desktop & {
    .container {
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: -16%;
        left: 0;
        width: 100%;
        bottom: -41%;
        background-color: $white;
        transition: transform .9s ease .5s;
        transform-origin: 100% 0;
        z-index: 2;
      }
    }

    &.animation {
      .container {
        &:before {
          transform: scaleX(0);
        }
      }
    }
  }

  &__title {
    font: #{em(43)}/#{em(51.6, 43)} $wal-b;
    letter-spacing: em(-.44, 43);
    position: relative;
    z-index: 1;
    padding-left: percentage(1/12);
    max-width: 40%;
  }

  &__desc {
    display: inline-block;
    margin-top: em(14, 14.4);
    margin-right: em(30, 14.4);
    font: #{em(14.4)}/#{em(22, 14.4)} $robo-l;
    letter-spacing: em(.52, 14.4);
    color: $grey-2;
  }

  .btn--cta {
    margin-top: em(42);
    width: em(269);

    .btn__text {
      background: $grey-7;
    }
  }

  .col-7 {
    padding-right: em(47);
    padding-left: em(54);
  }

  &__image {
    position: relative;
    z-index: 1;
    margin-top: em(-51);

    .ie & {
      height: em(356);
    }
  }

  .decotriangle--bottom {
    background: none;
    z-index: -1;

    &:before,
    &:after {
      background: $grey-7;
    }
  }
}

@media screen and  (max-width: $breakpoint-max) {
  .collection {
    padding-top: em(55);
    padding-bottom: em(44);
    margin-top: 8%;
    margin-bottom: 28%;

    .col-7 {
      padding: 0 em(15);
    }

    .col-4 {
      padding: 0 em(21);
    }

    &__title {
      padding-left: 0;
      font-size: em(39);
      text-align: center;
      max-width: 100%;
    }

    &__image {
      margin-top: em(21);
    }

    &__desc {
      margin: em(20, 14.4) em(5, 14.4) em(16, 14.4);
    }

    .btn--cta {
      width: em(280);
      margin-top: 0;
    }

    .btn__text {
      padding: em(13, 17.28);
    }

    .decotriangle--bottom {
      &:before {
        width: 85.5%;
      }

      &:after {
        width: 14.5%;
      }
    }
  }
}
