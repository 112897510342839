@charset "UTF-8";
.container, .row, .examples .hexagon__wrapper, .search, .time-of-other__content, .heading-instagram,
.footer-instagram, .download-item, .quarterly-list, .newsletter .text-left, .newsletter .newletter__content-success, .contact-us, .sam-Contact_RadioList, .slider-highlight, .infor__inner, .faq-item, .explore__list, .group-radio, .registration__form--emarketing .plan-form .checkbox-form, .museum-location__map, .museum-location__detail, .gallery-module__content, .infor__wrapper, .infor__content {
  *zoom: 1; }
  .container:before, .row:before, .examples .hexagon__wrapper:before, .search:before, .time-of-other__content:before, .heading-instagram:before,
  .footer-instagram:before, .download-item:before, .quarterly-list:before, .newsletter .text-left:before, .newsletter .newletter__content-success:before, .contact-us:before, .sam-Contact_RadioList:before, .slider-highlight:before, .infor__inner:before, .faq-item:before, .explore__list:before, .group-radio:before, .registration__form--emarketing .plan-form .checkbox-form:before, .museum-location__map:before, .museum-location__detail:before, .gallery-module__content:before, .infor__wrapper:before, .infor__content:before, .container:after, .row:after, .examples .hexagon__wrapper:after, .search:after, .time-of-other__content:after, .heading-instagram:after,
  .footer-instagram:after, .download-item:after, .quarterly-list:after, .newsletter .text-left:after, .newsletter .newletter__content-success:after, .contact-us:after, .sam-Contact_RadioList:after, .slider-highlight:after, .infor__inner:after, .faq-item:after, .explore__list:after, .group-radio:after, .registration__form--emarketing .plan-form .checkbox-form:after, .museum-location__map:after, .museum-location__detail:after, .gallery-module__content:after, .infor__wrapper:after, .infor__content:after {
    content: ' ';
    display: table; }
  .container:after, .row:after, .examples .hexagon__wrapper:after, .search:after, .time-of-other__content:after, .heading-instagram:after,
  .footer-instagram:after, .download-item:after, .quarterly-list:after, .newsletter .text-left:after, .newsletter .newletter__content-success:after, .contact-us:after, .sam-Contact_RadioList:after, .slider-highlight:after, .infor__inner:after, .faq-item:after, .explore__list:after, .group-radio:after, .registration__form--emarketing .plan-form .checkbox-form:after, .museum-location__map:after, .museum-location__detail:after, .gallery-module__content:after, .infor__wrapper:after, .infor__content:after {
    clear: both; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@font-face {
  font-family: 'sam';
  src: url("../fonts//sam.eot?wwzd2q");
  src: url("../fonts//sam.eot?wwzd2q#iefix") format("embedded-opentype"), url("../fonts//sam.ttf?wwzd2q") format("truetype"), url("../fonts//sam.woff?wwzd2q") format("woff"), url("../fonts//sam.svg?wwzd2q#sam") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sam' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ico-plus-hover:before {
  content: ""; }

.ico-arrow-down-bold:before {
  content: ""; }

.ico-list:before {
  content: ""; }

.ico-radio-inner:before {
  content: ""; }

.ico-radio-checked:before {
  content: ""; }

.ico-radio:before {
  content: ""; }

.ico-warning:before {
  content: ""; }

.ico-square-plus:before {
  content: ""; }

.ico-square-minus:before {
  content: ""; }

.ico-arrow-right-thick:before {
  content: ""; }

.ico-grid:before {
  content: ""; }

.ico-oval:before {
  content: ""; }

.ico-hexagon:before {
  content: ""; }

.ico-arrow-down:before {
  content: ""; }

.ico-arrow-left:before {
  content: ""; }

.ico-arrow-right:before {
  content: ""; }

.ico-arrow-up:before {
  content: ""; }

.ico-book:before {
  content: ""; }

.ico-bus:before {
  content: ""; }

.ico-calendar:before {
  content: ""; }

.ico-cancel:before {
  content: ""; }

.ico-car:before {
  content: ""; }

.ico-clock:before {
  content: ""; }

.ico-comment:before {
  content: ""; }

.ico-contact-us:before {
  content: ""; }

.ico-document:before {
  content: ""; }

.ico-donate:before {
  content: ""; }

.ico-download:before {
  content: ""; }

.ico-download-circle:before {
  content: ""; }

.ico-educator:before {
  content: ""; }

.ico-email:before {
  content: ""; }

.ico-email-black:before {
  content: ""; }

.ico-eye-open:before {
  content: ""; }

.ico-facebook:before {
  content: ""; }

.ico-filter:before {
  content: ""; }

.ico-food:before {
  content: ""; }

.ico-glasses:before {
  content: ""; }

.ico-globe:before {
  content: ""; }

.ico-hamburger:before {
  content: ""; }

.ico-info:before {
  content: ""; }

.ico-insta:before {
  content: ""; }

.ico-itinerary:before {
  content: ""; }

.ico-left-circle:before {
  content: ""; }

.ico-like:before {
  content: ""; }

.ico-link:before {
  content: ""; }

.ico-location-pin:before {
  content: ""; }

.ico-long-arrow-left-circle:before {
  content: ""; }

.ico-long-arrow-right-circle:before {
  content: ""; }

.ico-minus:before {
  content: ""; }

.ico-minus-circle:before {
  content: ""; }

.ico-mrt:before {
  content: ""; }

.ico-pen:before {
  content: ""; }

.ico-photos:before {
  content: ""; }

.ico-play:before {
  content: ""; }

.ico-plus:before {
  content: ""; }

.ico-plus-circle:before {
  content: ""; }

.ico-right-circle:before {
  content: ""; }

.ico-sam-facebook:before {
  content: ""; }

.ico-sam-insta:before {
  content: ""; }

.ico-sam-twitter:before {
  content: ""; }

.ico-sam-youtube:before {
  content: ""; }

.ico-search:before {
  content: ""; }

.ico-share:before {
  content: ""; }

.ico-shop:before {
  content: ""; }

.ico-slideshow:before {
  content: ""; }

.ico-target-audience:before {
  content: ""; }

.ico-tick:before {
  content: ""; }

.ico-tick-circle:before {
  content: ""; }

.ico-tickets:before {
  content: ""; }

.ico-tri-down:before {
  content: ""; }

.ico-tri-up:before {
  content: ""; }

.ico-twitter:before {
  content: ""; }

.ico-videos:before {
  content: ""; }

.ico-wheelchair:before {
  content: ""; }

.ico-youtube:before {
  content: ""; }

.ico-zoom-in:before {
  content: ""; }

.ico-zoom-out:before {
  content: ""; }

@font-face {
  font-family: "roboto-black";
  src: url("../fonts/roboto-black.eot");
  src: url("../fonts/roboto-black.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-black.woff") format("woff"), url("../fonts/roboto-black.ttf") format("truetype"), url("../fonts/roboto-black.svg#roboto-black") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "roboto-bold";
  src: url("../fonts/roboto-bold.eot");
  src: url("../fonts/roboto-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-bold.woff") format("woff"), url("../fonts/roboto-bold.ttf") format("truetype"), url("../fonts/roboto-bold.svg#roboto-bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "roboto-light";
  src: url("../fonts/roboto-light.eot");
  src: url("../fonts/roboto-light.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-light.woff") format("woff"), url("../fonts/roboto-light.ttf") format("truetype"), url("../fonts/roboto-light.svg#roboto-light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "roboto-medium";
  src: url("../fonts/roboto-medium.eot");
  src: url("../fonts/roboto-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-medium.woff") format("woff"), url("../fonts/roboto-medium.ttf") format("truetype"), url("../fonts/roboto-medium.svg#roboto-medium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "roboto-regular";
  src: url("../fonts/roboto-regular.eot");
  src: url("../fonts/roboto-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-regular.woff") format("woff"), url("../fonts/roboto-regular.ttf") format("truetype"), url("../fonts/roboto-regular.svg#roboto-regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "GT-Walsheim-Black";
  src: url("../fonts/GT-Walsheim-Black.eot");
  src: url("../fonts/GT-Walsheim-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/GT-Walsheim-Black.woff") format("woff"), url("../fonts/GT-Walsheim-Black.ttf") format("truetype"), url("../fonts/GT-Walsheim-Black.svg#GT-Walsheim-Black") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "GT-Walsheim-Black-Oblique";
  src: url("../fonts/GT-Walsheim-Black-Oblique.eot");
  src: url("../fonts/GT-Walsheim-Black-Oblique.eot?#iefix") format("embedded-opentype"), url("../fonts/GT-Walsheim-Black-Oblique.woff") format("woff"), url("../fonts/GT-Walsheim-Black-Oblique.ttf") format("truetype"), url("../fonts/GT-Walsheim-Black-Oblique.svg#GT-Walsheim-Black-Oblique") format("svg");
  font-weight: normal;
  font-style: normal; }

html {
  font-size: 16px; }
  html.freeze-lock {
    width: 100%;
    height: 100%; }

body {
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: scrollbar;
  font-family: "roboto-regular";
  position: relative; }
  body.freeze {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed; }
  body.no-scroll {
    overflow: hidden; }

span,
embed,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
output,
q,
samp,
small,
sub,
sup,
var,
i,
dl,
dt,
dd,
ol,
ul,
li,
form,
fieldset,
legend,
label,
main,
section,
article,
aside,
canvas,
details,
summary,
figure,
figcaption,
header,
footer,
hgroup,
menu,
nav,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

main,
section,
article,
aside,
details,
summary,
figure,
figcaption,
header,
footer,
hgroup,
menu,
nav {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

ol,
ul {
  list-style: none; }

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none; }

table,
caption,
tbody,
thead,
tfoot,
tr,
th,
td {
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset {
  margin: 0;
  padding: 0;
  border: 1px solid #c0c0c0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: none; }

input {
  background: none; }

input,
textarea,
select {
  border-radius: 0;
  appearance: none; }

button,
input,
textarea,
select,
optgroup {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  padding: 0; }

input,
select {
  vertical-align: middle; }

select,
button {
  text-transform: none; }

noscript {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 5px;
  text-align: center;
  color: #ffffff;
  background: #9b9b9b; }

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-weight: normal; }

img {
  max-width: 100%; }

* {
  box-sizing: border-box; }

.rte ul li:before, .custom-radio .custom-radio__label:before, .custom-radio .custom-radio__label:after, .faq-contact__title-link:after, .faq-contact__link-down:before, .banner__link-title:after, .newsletter .sam-Newsletter_CheckboxList .checkbox label:after, .contact-us .radio label:before, .contact-us .radio label:after, .popup-highlight__close:before, .slider-highlight__next, .slider-highlight__prev, .faq__close:before, .registration__form--emarketing .radio label:before, .registration__form--emarketing .radio label:after, section:not(.newsletter) .radio label:before, section:not(.newsletter) .radio label:after, .infor__note-clock:before, .infor__note-info:before {
  font-family: "sam";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.container {
  width: 61.5em;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 0.6875em; }

.row {
  position: relative;
  margin-left: -0.6875em;
  margin-right: -0.6875em; }
  @media screen and (min-width: 48em) {
    .row--flex {
      display: flex; } }

[class^='col-'],
[class*=' col-'] {
  padding-left: 0.6875em;
  padding-right: 0.6875em;
  float: left;
  position: relative; }

.loading-layer {
  position: relative; }
  .loading-layer:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 125em;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.5); }

.col-2 {
  width: 16.66667%; }

.col-4 {
  width: 33.33333%; }

.col-3 {
  width: 25%; }

.col-5 {
  width: 41.66667%; }

.col-6,
.col-xs-6 {
  width: 50%; }

.col-7 {
  width: 58.33333%; }

.col-8 {
  width: 66.66667%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33333%; }

.col-offset-1 {
  margin-left: 8.33333%; }

.col-push-5 {
  left: 41.66667%; }

.col-pull-2 {
  left: 16.66667%; }

.col-pull-1 {
  left: 8.33333%; }

.col-pull-6 {
  left: -50%; }

.hidden {
  display: none !important; }

.title {
  font: 3.225em/1.2em "GT-Walsheim-Black";
  color: #000000; }
  .title__wrapper {
    text-align: center;
    margin-bottom: 4.375em;
    position: relative;
    z-index: 1; }

.sub-title {
  display: block;
  font: 0.9em/1.5em "roboto-light";
  color: #4a4a4a;
  max-width: 50%;
  margin: 0.97222em auto 0; }

.desktop .title,
.desktop .sub-title {
  opacity: 0;
  transform: translate3d(0, 50%, 0);
  transition: all .9s ease .5s; }

.desktop .sub-title {
  transition-delay: .9s; }

.desktop .animation .title,
.desktop .animation .sub-title {
  transform: none;
  opacity: 1; }

.img-responsive {
  display: block;
  height: auto;
  width: 100%; }

.triangle-deco {
  position: absolute;
  left: 0;
  bottom: 50%;
  height: 50%;
  right: 50%;
  z-index: -2;
  background: #000000;
  transform: skewY(13.5deg);
  transform-origin: 0 0; }
  .triangle-deco:after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    right: -100%;
    transform: skewY(-26deg);
    background: inherit;
    transform-origin: 0 100%; }
  .triangle-deco--revert {
    transform: skewY(-13.5deg); }
    .triangle-deco--revert:after {
      transform: skewY(26deg); }

.decotriangle--bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: none;
  transform: none;
  z-index: -2; }
  .decotriangle--bottom:before, .decotriangle--bottom:after {
    top: 0;
    width: 50%;
    height: 100%;
    content: '';
    position: absolute; }
  .decotriangle--bottom:before {
    left: 0;
    padding-right: 1px;
    transform: skewY(13.5deg); }
  .decotriangle--bottom:after {
    transform: skewY(-13.5deg);
    right: 0; }

.decotriangle--left:before {
  width: 36.5%;
  transform-origin: 100% 0; }

.decotriangle--left:after {
  transform-origin: 0 0;
  width: 63.5%; }

.decotriangle--right:before {
  width: 63.5%;
  transform-origin: 100% 0; }

.decotriangle--right:after {
  transform-origin: 0 0;
  width: 36.5%; }

.decotriangle--revert:before {
  transform: skewY(-13.5deg); }

.decotriangle--revert:after {
  transform: skewY(13.5deg); }

.ico-clock--on, .ico-clock--close {
  position: relative; }
  .ico-clock--on:after, .ico-clock--close:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    z-index: 0; }
  .ico-clock--on:before, .ico-clock--close:before {
    position: relative;
    z-index: 1; }

.ico-clock--on:after {
  background: #aaff00; }

.ico-clock--close:after {
  background: #ff0000; }

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 10;
  display: none; }
  .overlay.on {
    display: block; }

.rte p {
  font: 0.9em/1.45833em "roboto-light";
  letter-spacing: -0.00625em;
  color: #4c4c4c; }
  .rte p > a {
    font-size: 1.11111em; }

.rte a {
  color: #007bff;
  font-size: 1em; }
  .rte a:hover {
    text-decoration: underline; }

.rte strong {
  font-family: "roboto-bold";
  font-weight: normal; }

.rte ol {
  list-style-type: decimal;
  list-style-position: inside; }

.rte li {
  position: relative; }

.rte ul li {
  padding-left: 0.9375em; }
  .rte ul li:before {
    content: "";
    font-size: 0.1875em;
    line-height: 7em;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0; }

.rte--general-bodycopy li {
  font: 0.9em/1.45833em "roboto-light";
  letter-spacing: -0.00625em;
  color: #4c4c4c; }

.rte--faq {
  font: 0.9em/1.45833em "roboto-light";
  color: #4c4c4c;
  letter-spacing: -0.00625em; }
  .rte--faq p,
  .rte--faq li {
    font-size: 1em; }

.rte--registration p {
  font: 0.9em/1.45833em "roboto-light";
  color: #4a4a4a;
  letter-spacing: -0.00625em; }

.rte--registration-note p {
  font: 0.75em/1.41667em "roboto-light";
  color: #9b9b9b; }

.rte--time p {
  padding-top: 2.08333em;
  color: #000000;
  font: 0.9em/1.5em "roboto-light";
  letter-spacing: 0; }

.rte--s-contact p {
  font: 0.9em/1.52778em "roboto-light";
  color: #757575; }

.rte--s-contact ul {
  margin-bottom: 5.625em; }

.rte--s-contact li {
  margin-bottom: 1.25em; }
  .rte--s-contact li:last-child {
    margin-bottom: 0; }

.rte--faq a {
  font-family: "roboto-medium"; }

.rte--artists p {
  color: #757575;
  font-family: "roboto-bold"; }

.arrow-ico circle,
.arrow-ico path {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-linecap: round; }

.btn {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: #000000;
  font-size: 100%; }
  .btn:hover {
    text-decoration: none;
    cursor: pointer; }
  .btn.disabled {
    pointer-events: none; }
  .btn--cta {
    text-align: center;
    border: #000000 0.125em solid;
    transform: translateZ(0);
    padding: 0; }
    .btn--cta .btn__text,
    .btn--cta .btn {
      background: #ffffff;
      transition: color .3s ease;
      display: block;
      padding: 1em;
      font: 1.08em/1.20023em "roboto-black"; }
    .btn--cta:before, .btn--cta:after {
      content: '';
      position: absolute;
      top: -0.125em;
      left: -0.0625em;
      right: -0.0625em;
      bottom: -0.125em;
      opacity: 0;
      animation: none; }
    .btn--cta:before {
      border: #005aff 0.125em solid;
      z-index: -1; }
    .btn--cta:after {
      border: #ff00ae 0.125em solid;
      z-index: -2; }
    .btn--cta:hover:before, .btn--cta:hover:after {
      opacity: 1; }
    .btn--cta:hover:before {
      animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite; }
    .btn--cta:hover:after {
      animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite; }
  .btn--small {
    text-transform: uppercase;
    color: #000000;
    padding: 0.625em 1em; }
    .btn--small .btn__text,
    .btn--small .ico-arrow {
      vertical-align: middle;
      display: inline-block; }
    .btn--small .btn__text {
      padding: 0;
      font-size: 0.625em; }
    .btn--small .ico-arrow {
      font-size: 0.875em;
      padding-right: 0.58824em; }

.button__wrapper {
  margin-top: 4.25em;
  text-align: center; }
  .button__wrapper .btn--cta {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    max-width: 14.0625em; }
    .button__wrapper .btn--cta .btn__text {
      padding: 0.8125em; }

@media screen and (max-width: 47.9375em) {
  .button__wrapper {
    margin-top: 2.6875em; } }

@-webkit-keyframes glitch {
  0% {
    transform: translate(0); }
  20% {
    transform: translate(-2px, 2px); }
  40% {
    transform: translate(-2px, -2px); }
  60% {
    transform: translate(2px, 2px); }
  80% {
    transform: translate(2px, -2px); }
  to {
    transform: translate(0); } }

@keyframes glitch {
  0% {
    transform: translate(0); }
  20% {
    transform: translate(-2px, 2px); }
  40% {
    transform: translate(-2px, -2px); }
  60% {
    transform: translate(2px, 2px); }
  80% {
    transform: translate(2px, -2px); }
  to {
    transform: translate(0); } }

.wrapper {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }
  .wrapper.freeze {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden; }

.contact-us-template,
.template-form {
  padding-top: 9.375em; }

.contact-us-page .newsletter {
  position: relative;
  z-index: 5; }

.landing-page__wrapper {
  position: relative;
  padding-top: 4.3125em;
  margin-top: 6.7%;
  z-index: 1; }
  .landing-page__wrapper:before, .landing-page__wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 90%;
    background-color: #ffffff;
    z-index: 0; }
  .landing-page__wrapper:before {
    left: 0;
    padding-right: 1px;
    transform: skewY(-13.5deg);
    background: linear-gradient(-166.5deg, #e4e4e4 0%, #ffffff 23.75em); }
  .landing-page__wrapper:after {
    right: 0;
    transform: skewY(13.5deg);
    background: linear-gradient(166.5deg, #e4e4e4 0%, #ffffff 23.75em); }
  .landing-page__wrapper > .triangle-deco {
    background: #ff00ae;
    background: -webkit-linear-gradient(left, #ff00ae, #ff00ae);
    background: linear-gradient(to right, #ff00ae, #ff00ae); }
    .landing-page__wrapper > .triangle-deco.triangle-deco--wrapper {
      z-index: -1; }
  .landing-page__wrapper .detail-block {
    position: relative;
    z-index: 2; }

@media screen and (max-width: 47.9375em) {
  .template-form {
    padding-left: 0.625em;
    padding-right: 0.625em; } }

@media print {
  .header,
  .footer,
  .nav,
  .edito-tools,
  .banner,
  .gallery-thumb,
  .media-content,
  .jw-controls,
  .gallery-show__button,
  marquee {
    display: none; }
  a:after,
  abbr:after {
    content: '' !important; } }

/*! jQuery UI - v1.12.1 - 2016-10-12
* http://jqueryui.com
* Includes: core.css, datepicker.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?scope=&folderName=base&cornerRadiusShadow=8px&offsetLeftShadow=0px&offsetTopShadow=0px&thicknessShadow=5px&opacityShadow=30&bgImgOpacityShadow=0&bgTextureShadow=flat&bgColorShadow=666666&opacityOverlay=30&bgImgOpacityOverlay=0&bgTextureOverlay=flat&bgColorOverlay=aaaaaa&iconColorError=cc0000&fcError=5f3f3f&borderColorError=f1a899&bgTextureError=flat&bgColorError=fddfdf&iconColorHighlight=777620&fcHighlight=777620&borderColorHighlight=dad55e&bgTextureHighlight=flat&bgColorHighlight=fffa90&iconColorActive=ffffff&fcActive=ffffff&borderColorActive=003eff&bgTextureActive=flat&bgColorActive=007fff&iconColorHover=555555&fcHover=2b2b2b&borderColorHover=cccccc&bgTextureHover=flat&bgColorHover=ededed&iconColorDefault=777777&fcDefault=454545&borderColorDefault=c5c5c5&bgTextureDefault=flat&bgColorDefault=f6f6f6&iconColorContent=444444&fcContent=333333&borderColorContent=dddddd&bgTextureContent=flat&bgColorContent=ffffff&iconColorHeader=444444&fcHeader=333333&borderColorHeader=dddddd&bgTextureHeader=flat&bgColorHeader=e9e9e9&cornerRadius=3px&fwDefault=normal&fsDefault=1em&ffDefault=Arial%2CHelvetica%2Csans-serif
* Copyright jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none; }

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse; }

.ui-helper-clearfix:after {
  clear: both; }

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
  /* support: IE8 */ }

.ui-front {
  z-index: 100; }

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
  pointer-events: none; }

/* Icons
----------------------------------*/
.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; }

.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block; }

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none; }

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0; }

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em; }

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 1px; }

.ui-datepicker .ui-datepicker-prev {
  left: 2px; }

.ui-datepicker .ui-datepicker-next {
  right: 2px; }

.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px; }

.ui-datepicker .ui-datepicker-next-hover {
  right: 1px; }

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px; }

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center; }

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0; }

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 45%; }

.ui-datepicker table {
  width: 100%;
  font-size: .9em;
  border-collapse: collapse;
  margin: 0 0 .4em; }

.ui-datepicker th {
  padding: .7em .3em;
  text-align: center;
  font-weight: bold;
  border: 0; }

.ui-datepicker td {
  border: 0;
  padding: 1px; }

.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: right;
  text-decoration: none; }

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0; }

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible; }

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left; }

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
  width: auto; }

.ui-datepicker-multi .ui-datepicker-group {
  float: left; }

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em; }

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%; }

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%; }

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%; }

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left; }

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0; }

/* RTL support */
.ui-datepicker-rtl {
  direction: rtl; }

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
  float: right; }

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px; }

/* Icons */
.ui-datepicker .ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  left: .5em;
  top: .3em; }

/* Component containers
----------------------------------*/
.ui-widget {
  font-family: Arial,Helvetica,sans-serif;
  font-size: 1em; }

.ui-widget .ui-widget {
  font-size: 1em; }

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: Arial,Helvetica,sans-serif;
  font-size: 1em; }

.ui-widget.ui-widget-content {
  border: 1px solid #c5c5c5; }

.ui-widget-content {
  border: 1px solid #dddddd;
  background: #ffffff;
  color: #333333; }

.ui-widget-content a {
  color: #333333; }

.ui-widget-header {
  border: 1px solid #dddddd;
  background: #e9e9e9;
  color: #333333;
  font-weight: bold; }

.ui-widget-header a {
  color: #333333; }

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: 1px solid #c5c5c5;
  background: #f6f6f6;
  font-weight: normal;
  color: #454545; }

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited,
a.ui-button,
a:link.ui-button,
a:visited.ui-button,
.ui-button {
  color: #454545;
  text-decoration: none; }

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  border: 1px solid #cccccc;
  background: #ededed;
  font-weight: normal;
  color: #2b2b2b; }

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited,
a.ui-button:hover,
a.ui-button:focus {
  color: #2b2b2b;
  text-decoration: none; }

.ui-visual-focus {
  box-shadow: 0 0 3px 1px #5e9ed6; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  border: 1px solid #003eff;
  background: #007fff;
  font-weight: normal;
  color: #ffffff; }

.ui-icon-background,
.ui-state-active .ui-icon-background {
  border: #003eff;
  background-color: #ffffff; }

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #ffffff;
  text-decoration: none; }

/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #dad55e;
  background: #fffa90;
  color: #777620; }

.ui-state-checked {
  border: 1px solid #dad55e;
  background: #fffa90; }

.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
  color: #777620; }

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: 1px solid #f1a899;
  background: #fddfdf;
  color: #5f3f3f; }

.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
  color: #5f3f3f; }

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #5f3f3f; }

.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: bold; }

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  opacity: .7;
  filter: Alpha(Opacity=70);
  /* support: IE8 */
  font-weight: normal; }

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  /* support: IE8 */
  background-image: none; }

.ui-state-disabled .ui-icon {
  filter: Alpha(Opacity=35);
  /* support: IE8 - See #6059 */ }

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  width: 16px;
  height: 16px; }

.ui-icon,
.ui-widget-content .ui-icon {
  background-image: url("images/ui-icons_444444_256x240.png"); }

.ui-widget-header .ui-icon {
  background-image: url("images/ui-icons_444444_256x240.png"); }

.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button:hover .ui-icon,
.ui-button:focus .ui-icon {
  background-image: url("images/ui-icons_555555_256x240.png"); }

.ui-state-active .ui-icon,
.ui-button:active .ui-icon {
  background-image: url("images/ui-icons_ffffff_256x240.png"); }

.ui-state-highlight .ui-icon,
.ui-button .ui-state-highlight.ui-icon {
  background-image: url("images/ui-icons_777620_256x240.png"); }

.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  background-image: url("images/ui-icons_cc0000_256x240.png"); }

.ui-button .ui-icon {
  background-image: url("images/ui-icons_777777_256x240.png"); }

/* positioning */
.ui-icon-blank {
  background-position: 16px 16px; }

.ui-icon-caret-1-n {
  background-position: 0 0; }

.ui-icon-caret-1-ne {
  background-position: -16px 0; }

.ui-icon-caret-1-e {
  background-position: -32px 0; }

.ui-icon-caret-1-se {
  background-position: -48px 0; }

.ui-icon-caret-1-s {
  background-position: -65px 0; }

.ui-icon-caret-1-sw {
  background-position: -80px 0; }

.ui-icon-caret-1-w {
  background-position: -96px 0; }

.ui-icon-caret-1-nw {
  background-position: -112px 0; }

.ui-icon-caret-2-n-s {
  background-position: -128px 0; }

.ui-icon-caret-2-e-w {
  background-position: -144px 0; }

.ui-icon-triangle-1-n {
  background-position: 0 -16px; }

.ui-icon-triangle-1-ne {
  background-position: -16px -16px; }

.ui-icon-triangle-1-e {
  background-position: -32px -16px; }

.ui-icon-triangle-1-se {
  background-position: -48px -16px; }

.ui-icon-triangle-1-s {
  background-position: -65px -16px; }

.ui-icon-triangle-1-sw {
  background-position: -80px -16px; }

.ui-icon-triangle-1-w {
  background-position: -96px -16px; }

.ui-icon-triangle-1-nw {
  background-position: -112px -16px; }

.ui-icon-triangle-2-n-s {
  background-position: -128px -16px; }

.ui-icon-triangle-2-e-w {
  background-position: -144px -16px; }

.ui-icon-arrow-1-n {
  background-position: 0 -32px; }

.ui-icon-arrow-1-ne {
  background-position: -16px -32px; }

.ui-icon-arrow-1-e {
  background-position: -32px -32px; }

.ui-icon-arrow-1-se {
  background-position: -48px -32px; }

.ui-icon-arrow-1-s {
  background-position: -65px -32px; }

.ui-icon-arrow-1-sw {
  background-position: -80px -32px; }

.ui-icon-arrow-1-w {
  background-position: -96px -32px; }

.ui-icon-arrow-1-nw {
  background-position: -112px -32px; }

.ui-icon-arrow-2-n-s {
  background-position: -128px -32px; }

.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px; }

.ui-icon-arrow-2-e-w {
  background-position: -160px -32px; }

.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px; }

.ui-icon-arrowstop-1-n {
  background-position: -192px -32px; }

.ui-icon-arrowstop-1-e {
  background-position: -208px -32px; }

.ui-icon-arrowstop-1-s {
  background-position: -224px -32px; }

.ui-icon-arrowstop-1-w {
  background-position: -240px -32px; }

.ui-icon-arrowthick-1-n {
  background-position: 1px -48px; }

.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px; }

.ui-icon-arrowthick-1-e {
  background-position: -32px -48px; }

.ui-icon-arrowthick-1-se {
  background-position: -48px -48px; }

.ui-icon-arrowthick-1-s {
  background-position: -64px -48px; }

.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px; }

.ui-icon-arrowthick-1-w {
  background-position: -96px -48px; }

.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px; }

.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px; }

.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px; }

.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px; }

.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px; }

.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px; }

.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px; }

.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px; }

.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px; }

.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px; }

.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px; }

.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px; }

.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px; }

.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px; }

.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px; }

.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px; }

.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px; }

.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px; }

.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px; }

.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px; }

.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px; }

.ui-icon-arrow-4 {
  background-position: 0 -80px; }

.ui-icon-arrow-4-diag {
  background-position: -16px -80px; }

.ui-icon-extlink {
  background-position: -32px -80px; }

.ui-icon-newwin {
  background-position: -48px -80px; }

.ui-icon-refresh {
  background-position: -64px -80px; }

.ui-icon-shuffle {
  background-position: -80px -80px; }

.ui-icon-transfer-e-w {
  background-position: -96px -80px; }

.ui-icon-transferthick-e-w {
  background-position: -112px -80px; }

.ui-icon-folder-collapsed {
  background-position: 0 -96px; }

.ui-icon-folder-open {
  background-position: -16px -96px; }

.ui-icon-document {
  background-position: -32px -96px; }

.ui-icon-document-b {
  background-position: -48px -96px; }

.ui-icon-note {
  background-position: -64px -96px; }

.ui-icon-mail-closed {
  background-position: -80px -96px; }

.ui-icon-mail-open {
  background-position: -96px -96px; }

.ui-icon-suitcase {
  background-position: -112px -96px; }

.ui-icon-comment {
  background-position: -128px -96px; }

.ui-icon-person {
  background-position: -144px -96px; }

.ui-icon-print {
  background-position: -160px -96px; }

.ui-icon-trash {
  background-position: -176px -96px; }

.ui-icon-locked {
  background-position: -192px -96px; }

.ui-icon-unlocked {
  background-position: -208px -96px; }

.ui-icon-bookmark {
  background-position: -224px -96px; }

.ui-icon-tag {
  background-position: -240px -96px; }

.ui-icon-home {
  background-position: 0 -112px; }

.ui-icon-flag {
  background-position: -16px -112px; }

.ui-icon-calendar {
  background-position: -32px -112px; }

.ui-icon-cart {
  background-position: -48px -112px; }

.ui-icon-pencil {
  background-position: -64px -112px; }

.ui-icon-clock {
  background-position: -80px -112px; }

.ui-icon-disk {
  background-position: -96px -112px; }

.ui-icon-calculator {
  background-position: -112px -112px; }

.ui-icon-zoomin {
  background-position: -128px -112px; }

.ui-icon-zoomout {
  background-position: -144px -112px; }

.ui-icon-search {
  background-position: -160px -112px; }

.ui-icon-wrench {
  background-position: -176px -112px; }

.ui-icon-gear {
  background-position: -192px -112px; }

.ui-icon-heart {
  background-position: -208px -112px; }

.ui-icon-star {
  background-position: -224px -112px; }

.ui-icon-link {
  background-position: -240px -112px; }

.ui-icon-cancel {
  background-position: 0 -128px; }

.ui-icon-plus {
  background-position: -16px -128px; }

.ui-icon-plusthick {
  background-position: -32px -128px; }

.ui-icon-minus {
  background-position: -48px -128px; }

.ui-icon-minusthick {
  background-position: -64px -128px; }

.ui-icon-close {
  background-position: -80px -128px; }

.ui-icon-closethick {
  background-position: -96px -128px; }

.ui-icon-key {
  background-position: -112px -128px; }

.ui-icon-lightbulb {
  background-position: -128px -128px; }

.ui-icon-scissors {
  background-position: -144px -128px; }

.ui-icon-clipboard {
  background-position: -160px -128px; }

.ui-icon-copy {
  background-position: -176px -128px; }

.ui-icon-contact {
  background-position: -192px -128px; }

.ui-icon-image {
  background-position: -208px -128px; }

.ui-icon-video {
  background-position: -224px -128px; }

.ui-icon-script {
  background-position: -240px -128px; }

.ui-icon-alert {
  background-position: 0 -144px; }

.ui-icon-info {
  background-position: -16px -144px; }

.ui-icon-notice {
  background-position: -32px -144px; }

.ui-icon-help {
  background-position: -48px -144px; }

.ui-icon-check {
  background-position: -64px -144px; }

.ui-icon-bullet {
  background-position: -80px -144px; }

.ui-icon-radio-on {
  background-position: -96px -144px; }

.ui-icon-radio-off {
  background-position: -112px -144px; }

.ui-icon-pin-w {
  background-position: -128px -144px; }

.ui-icon-pin-s {
  background-position: -144px -144px; }

.ui-icon-play {
  background-position: 0 -160px; }

.ui-icon-pause {
  background-position: -16px -160px; }

.ui-icon-seek-next {
  background-position: -32px -160px; }

.ui-icon-seek-prev {
  background-position: -48px -160px; }

.ui-icon-seek-end {
  background-position: -64px -160px; }

.ui-icon-seek-start {
  background-position: -80px -160px; }

/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first {
  background-position: -80px -160px; }

.ui-icon-stop {
  background-position: -96px -160px; }

.ui-icon-eject {
  background-position: -112px -160px; }

.ui-icon-volume-off {
  background-position: -128px -160px; }

.ui-icon-volume-on {
  background-position: -144px -160px; }

.ui-icon-power {
  background-position: 0 -176px; }

.ui-icon-signal-diag {
  background-position: -16px -176px; }

.ui-icon-signal {
  background-position: -32px -176px; }

.ui-icon-battery-0 {
  background-position: -48px -176px; }

.ui-icon-battery-1 {
  background-position: -64px -176px; }

.ui-icon-battery-2 {
  background-position: -80px -176px; }

.ui-icon-battery-3 {
  background-position: -96px -176px; }

.ui-icon-circle-plus {
  background-position: 0 -192px; }

.ui-icon-circle-minus {
  background-position: -16px -192px; }

.ui-icon-circle-close {
  background-position: -32px -192px; }

.ui-icon-circle-triangle-e {
  background-position: -48px -192px; }

.ui-icon-circle-triangle-s {
  background-position: -64px -192px; }

.ui-icon-circle-triangle-w {
  background-position: -80px -192px; }

.ui-icon-circle-triangle-n {
  background-position: -96px -192px; }

.ui-icon-circle-arrow-e {
  background-position: -112px -192px; }

.ui-icon-circle-arrow-s {
  background-position: -128px -192px; }

.ui-icon-circle-arrow-w {
  background-position: -144px -192px; }

.ui-icon-circle-arrow-n {
  background-position: -160px -192px; }

.ui-icon-circle-zoomin {
  background-position: -176px -192px; }

.ui-icon-circle-zoomout {
  background-position: -192px -192px; }

.ui-icon-circle-check {
  background-position: -208px -192px; }

.ui-icon-circlesmall-plus {
  background-position: 0 -208px; }

.ui-icon-circlesmall-minus {
  background-position: -16px -208px; }

.ui-icon-circlesmall-close {
  background-position: -32px -208px; }

.ui-icon-squaresmall-plus {
  background-position: -48px -208px; }

.ui-icon-squaresmall-minus {
  background-position: -64px -208px; }

.ui-icon-squaresmall-close {
  background-position: -80px -208px; }

.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px; }

.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px; }

.ui-icon-grip-solid-vertical {
  background-position: -32px -224px; }

.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px; }

.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px; }

.ui-icon-grip-diagonal-se {
  background-position: -80px -224px; }

/* Misc visuals
----------------------------------*/
/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 3px; }

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 3px; }

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 3px; }

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 3px; }

/* Overlays */
.ui-widget-overlay {
  background: #aaaaaa;
  opacity: .3;
  filter: Alpha(Opacity=30);
  /* support: IE8 */ }

.ui-widget-shadow {
  -webkit-box-shadow: 0px 0px 5px #666666;
  box-shadow: 0px 0px 5px #666666; }

@media screen and (min-width: 120em) {
  body {
    font-size: 22px; } }

@media screen and (min-width: 90.0625em) and (max-width: 119.9375em) {
  body {
    font-size: 18px; } }

@media screen and (min-width: 64em) and (max-width: 90em) {
  body {
    font-size: 15px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  body {
    font-size: 12px; } }

@media screen and (min-width: 48em) {
  .hidden-desktop {
    display: none; } }

@media screen and (max-width: 47.9375em) {
  .container {
    width: 100%; }
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10 {
    width: 100%; }
  .col-pull-6,
  .col-push-5,
  .col-pull-2,
  .col-pull-1 {
    left: 0; }
  .col-offset-1 {
    margin-left: 0; }
  .col-offset-revert-1 {
    margin-right: 0; }
  .title {
    font-size: 2.4375em;
    line-height: 1.2em; }
    .title__wrapper {
      margin-bottom: 0.5625em; }
  .sub-title {
    max-width: none;
    margin-top: 0.90278em; }
  .triangle-deco {
    top: -1.4375em; }
    .triangle-deco--bottom {
      top: 50%; }
    .triangle-deco--right:before {
      width: 87%; }
    .triangle-deco--right:after {
      width: 13%; }
  .hidden-mobile {
    display: none; }
  .landing-page__wrapper {
    margin-top: -3%; } }

.examples .wrapper {
  margin: 0 auto;
  width: 60.125em; }

.examples section {
  overflow: hidden; }

.examples .test {
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  height: auto; }
  .ie .examples .test {
    height: 23.875em; }

.examples .banner {
  width: 32em;
  margin: 3.125em auto;
  text-align: center; }

.examples .col-3 {
  float: left;
  width: 33.33%;
  padding: 1.25em; }

.examples .hexagon {
  position: relative;
  margin-bottom: 15%; }
  .ie .examples .hexagon svg {
    height: 13.375em; }
  .examples .hexagon__border {
    content: '';
    position: absolute;
    top: 0;
    left: 0.625em;
    right: 0.625em;
    bottom: 0;
    margin-top: 15%;
    border-left: #000000 0.0625em solid;
    border-right: #000000 0.0625em solid; }
  .examples .hexagon__top:before, .examples .hexagon__top:after, .examples .hexagon__bottom:before, .examples .hexagon__bottom:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    height: 0.0625em;
    background: #000000; }
  .examples .hexagon__top {
    position: absolute;
    top: 0;
    width: 100%; }
    .examples .hexagon__top:before {
      transform: skewY(-14deg);
      transform-origin: 0 0; }
    .examples .hexagon__top:after {
      right: 0;
      transform: skewY(14deg);
      transform-origin: 100% 0; }
  .examples .hexagon__bottom {
    position: absolute;
    bottom: 0;
    width: 100%; }
    .examples .hexagon__bottom:before {
      transform: skewY(14deg);
      transform-origin: 0 0; }
    .examples .hexagon__bottom:after {
      right: 0;
      transform: skewY(-14deg);
      transform-origin: 100% 0; }
  .examples .hexagon__content {
    padding: 1.25em;
    margin: 0 0.625em;
    position: relative; }

@media screen and (max-width: 5000px) {
  .examples {
    font-size: 22px; } }

@media screen and (max-width: 1700px) {
  .examples {
    font-size: 18px; } }

@media screen and (max-width: 1200px) {
  .examples {
    font-size: 16px; } }

@media screen and (max-width: 1023px) {
  .examples .wrapper {
    width: 100%; }
  .examples .banner {
    width: 70%; }
  .examples .col-3 {
    width: 100%;
    padding: 1.25em 0; }
  .examples .hexagon {
    margin-bottom: 14%; }
    .examples .hexagon__border {
      margin-top: 14%; } }

.form__control {
  border: none;
  width: 100%; }
  .form__control:focus {
    outline: 0;
    border-bottom: 1px solid #ffd500; }

.form__control--event {
  border-bottom: 1px solid #9b9b9b;
  padding: 0.32558em 1.4186em 0.32558em 0.37209em;
  box-sizing: border-box;
  font-size: 2.6875em; }

.form__label-event {
  display: inline-block;
  max-width: 7.02326em;
  font: 2.6875em/1.05em "GT-Walsheim-Black";
  letter-spacing: -0.03721em;
  vertical-align: bottom; }

.form__control--error {
  border-bottom: 1px solid #e20039; }

.custom-textarea {
  position: relative;
  border: 0.06944em solid #9b9b9b;
  padding-top: 1.875em;
  min-height: 12.15278em;
  box-shadow: 0 0 0 0.13889em #ffffff; }
  .custom-textarea__control,
  .custom-textarea .form-control {
    display: inline-block;
    padding: 0.69444em 1.18056em 1.31944em;
    border: none;
    font-family: "roboto-light";
    color: #000000;
    font-size: 0.9em;
    width: 100%;
    outline: none;
    z-index: 2;
    background-color: transparent;
    transition: all .4s;
    height: 12.15278em;
    resize: none;
    overflow-y: auto; }
  .custom-textarea__control:focus ~ .custom-textarea__label {
    font-size: 0.69444em;
    top: 0.76389em; }
  .custom-textarea .form-control:focus ~ .label-multiline {
    font-size: 0.69444em;
    top: 0.76389em; }
  .custom-textarea.input-has-value .label-multiline {
    font-size: 0.69444em;
    top: 0.76389em; }
  .custom-textarea__focus {
    border-color: #4a4a4a;
    box-shadow: 0 0 0 0.06944em #4a4a4a; }
  .custom-textarea .custom-textarea__label,
  .custom-textarea .label-multiline {
    font: 0.9em "roboto-light";
    color: #9b9b9b;
    position: absolute;
    z-index: 1;
    top: 1.18056em;
    left: 1.59722em;
    transition: all .3s; }

.custom-input {
  position: relative; }
  .custom-input__control {
    position: relative;
    font-size: 0.9em;
    border: none;
    width: 100%;
    padding-top: 1.18056em;
    padding-bottom: 1.45833em;
    border-bottom: 0.06944em solid #9b9b9b;
    outline: none;
    z-index: 2;
    background-color: transparent;
    font-family: "roboto-light";
    color: #979797;
    transition: all .4s; }
    .custom-input__control:focus {
      border-color: #4a4a4a;
      box-shadow: 0 0.06944em 0 #4a4a4a;
      padding-top: 2.15278em;
      padding-bottom: 0.48611em; }
      .custom-input__control:focus ~ .custom-input__label {
        top: 0.76389em;
        transform: none;
        font-size: 0.69444em; }
  .custom-input .custom-input__label {
    font: 0.9em "roboto-light";
    color: #9b9b9b;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: all .3s; }

.input-has-value .custom-input__control {
  font-family: "roboto-regular";
  color: #4a4a4a;
  padding-top: 2.15278em;
  padding-bottom: 0.48611em; }

.input-has-value .custom-input__label,
.input-has-value .custom-textarea__label {
  top: 0.76389em;
  transform: none;
  font-size: 0.69444em; }

.custom-radio,
.custom-checkbox {
  padding-right: 0.625em;
  margin-top: 0.875em; }
  .custom-radio .custom-checkbox__control,
  .custom-radio .custom-radio__control,
  .custom-checkbox .custom-checkbox__control,
  .custom-checkbox .custom-radio__control {
    position: absolute;
    left: -694.375em;
    opacity: 0; }
    .custom-radio .custom-checkbox__control:not(:checked) ~ label:after,
    .custom-radio .custom-radio__control:not(:checked) ~ label:after,
    .custom-checkbox .custom-checkbox__control:not(:checked) ~ label:after,
    .custom-checkbox .custom-radio__control:not(:checked) ~ label:after {
      opacity: 0; }
    .custom-radio .custom-checkbox__control:checked ~ label,
    .custom-radio .custom-radio__control:checked ~ label,
    .custom-checkbox .custom-checkbox__control:checked ~ label,
    .custom-checkbox .custom-radio__control:checked ~ label {
      color: #4a4a4a; }
      .custom-radio .custom-checkbox__control:checked ~ label:after,
      .custom-radio .custom-radio__control:checked ~ label:after,
      .custom-checkbox .custom-checkbox__control:checked ~ label:after,
      .custom-checkbox .custom-radio__control:checked ~ label:after {
        opacity: 1; }
      .custom-radio .custom-checkbox__control:checked ~ label .custom-radio__text,
      .custom-radio .custom-radio__control:checked ~ label .custom-radio__text,
      .custom-checkbox .custom-checkbox__control:checked ~ label .custom-radio__text,
      .custom-checkbox .custom-radio__control:checked ~ label .custom-radio__text {
        color: #4a4a4a; }

.custom-radio .custom-radio__label {
  position: relative;
  padding-left: 1.5625em;
  cursor: pointer; }
  .custom-radio .custom-radio__label:before, .custom-radio .custom-radio__label:after {
    position: absolute; }
  .custom-radio .custom-radio__label:before {
    content: "";
    font-size: 1em;
    color: #979797;
    top: 0.25em;
    left: 0; }
  .custom-radio .custom-radio__label:after {
    content: "";
    font-size: 1em;
    top: 0.125em;
    left: 0;
    color: #000000; }

.custom-radio__text {
  font-size: 0.875em;
  color: #979797; }

.custom-radio__control:checked ~ label .custom-control__specify {
  color: #4a4a4a;
  border-color: #4a4a4a; }
  .custom-radio__control:checked ~ label .custom-control__specify::-moz-placeholder {
    color: #4a4a4a; }
  .custom-radio__control:checked ~ label .custom-control__specify::-webkit-input-placeholder {
    color: #4a4a4a; }
  .custom-radio__control:checked ~ label .custom-control__specify:-ms-input-placeholder {
    color: #4a4a4a; }
  .custom-radio__control:checked ~ label .custom-control__specify::-ms-input-placeholder {
    color: #4a4a4a; }

.custom-checkbox {
  max-width: 21.875em; }
  .custom-checkbox--full {
    padding: 0.875em 0;
    margin-top: 0;
    max-width: 100%; }
    .custom-checkbox--full .custom-checkbox__text {
      max-width: 34.72222em; }
  .custom-checkbox .custom-checkbox__control {
    position: absolute;
    left: -9999px; }
    .custom-checkbox .custom-checkbox__control:checked ~ label:before {
      background-color: #000000;
      border-color: #000000; }
    .custom-checkbox .custom-checkbox__control:checked ~ label:after {
      opacity: 1; }
    .custom-checkbox .custom-checkbox__control:checked ~ label .custom-control__specify {
      border-color: #4a4a4a; }
      .custom-checkbox .custom-checkbox__control:checked ~ label .custom-control__specify::-moz-placeholder {
        color: #4a4a4a; }
      .custom-checkbox .custom-checkbox__control:checked ~ label .custom-control__specify::-webkit-input-placeholder {
        color: #4a4a4a; }
      .custom-checkbox .custom-checkbox__control:checked ~ label .custom-control__specify:-ms-input-placeholder {
        color: #4a4a4a; }
      .custom-checkbox .custom-checkbox__control:checked ~ label .custom-control__specify::-ms-input-placeholder {
        color: #4a4a4a; }
  .custom-checkbox .custom-checkbox__label {
    font: 0.9em/1.52778em "roboto-light";
    cursor: pointer;
    color: #9b9b9b;
    padding-left: 1.5625em;
    position: relative;
    display: inline-block; }
    .custom-checkbox .custom-checkbox__label:focus {
      outline: none; }
    .custom-checkbox .custom-checkbox__label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.13889em;
      width: 0.83333em;
      height: 0.83333em;
      border: 0.06944em solid #979797;
      background-color: #ffffff;
      border-radius: 0.125em; }
    .custom-checkbox .custom-checkbox__label:after {
      position: absolute;
      transition: opacity .2s;
      background-color: transparent;
      content: "";
      color: #ffffff;
      font-family: "sam";
      left: 0;
      top: 0.13889em;
      line-height: 0.90278em; }
  .custom-checkbox .custom-checkbox__text {
    display: inline-block;
    vertical-align: top; }
  .custom-checkbox .custom-checkbox__link {
    font-family: "roboto-bold";
    color: #007bff; }

.custom-input--date .custom-input__control {
  padding: 1.25em 0 1.11111em 6.94444em;
  color: #4a4a4a;
  font-family: "roboto-regular"; }
  .custom-input--date .custom-input__control::-moz-placeholder {
    color: #9b9b9b;
    opacity: 1;
    font-family: "roboto-light"; }
  .custom-input--date .custom-input__control::-webkit-input-placeholder {
    color: #9b9b9b;
    opacity: 1;
    font-family: "roboto-light"; }
  .custom-input--date .custom-input__control:-ms-input-placeholder {
    color: #9b9b9b;
    opacity: 1;
    font-family: "roboto-light"; }
  .custom-input--date .custom-input__control::-ms-input-placeholder {
    color: #9b9b9b;
    opacity: 1;
    font-family: "roboto-light"; }
  .custom-input--date .custom-input__control:focus ~ .custom-input__label {
    font-size: 0.9em;
    top: 50%;
    transform: translateY(-50%); }

.custom-input--date .custom-input__label {
  font-size: 0.9em;
  top: 50%;
  transform: translateY(-50%); }

@media screen and (max-width: 47.9375em) {
  .form__control--event {
    padding: 0.24242em 1.54545em 0.21212em 0.24242em;
    font-size: 2.0625em; }
  .form__label-event {
    font: 2.23937em/1.1em "GT-Walsheim-Black";
    letter-spacing: -0.03963em; }
  .custom-radio,
  .custom-checkbox,
  .custom-input {
    margin-bottom: 1.25em; }
  .custom-checkbox,
  .custom-radio {
    padding-right: 0; } }

.ie .explore__item,
.edge .explore__item {
  width: 24.9%; }
  .ie .explore__item .explore__ico-circle,
  .edge .explore__item .explore__ico-circle {
    margin-left: 0.20094em; }

.edge .form__label-event {
  max-width: 7.16279em; }

.ie .slider__image {
  height: 50vw; }

.ie .banner--landing .banner__image {
  height: 50vw; }

.more-resource {
  display: block;
  position: relative;
  margin-bottom: 3.75em;
  text-align: center;
  text-decoration: none;
  border: #9b9b9b 0.0625em solid;
  border-bottom: none;
  transition: opacity .3s ease;
  background-color: #ffffff; }
  .more-resource__wrapper {
    margin-bottom: 6.6875em;
    position: relative;
    z-index: 1; }
    .more-resource__wrapper .title__wrapper {
      margin-bottom: 4.375em; }
    .more-resource__wrapper--special {
      margin-bottom: 2.25em; }
  .more-resource:hover {
    opacity: .7; }
  .more-resource:before, .more-resource:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    height: 12%;
    border-bottom: #9b9b9b 0.0625em solid; }
  .more-resource:before {
    left: 0;
    padding-right: 1px;
    transform-origin: 0 0;
    transform: skewY(12deg); }
  .more-resource:after {
    right: 0;
    transform-origin: 100% 0;
    transform: skewY(-12deg); }
  .more-resource__image {
    display: block;
    width: 100%; }
    .more-resource__image__img {
      display: block;
      width: 100%; }
  .more-resource__type, .more-resource__title, .more-resource__date {
    display: block;
    color: #000000; }
  .more-resource__type {
    padding: 0.69444em;
    background-color: #fff200;
    font: 0.9em/1.05em "roboto-bold";
    letter-spacing: 0.09722em; }
  .more-resource__title {
    height: 3.81944em;
    overflow-y: hidden;
    font: 1.08em/1.04977em "GT-Walsheim-Black";
    padding: 0.63657em 1.15741em 0;
    letter-spacing: -0.02083em; }
  .more-resource__date {
    padding-top: 1.15741em;
    padding-bottom: 0.34722em;
    font: 1.08em/1.04977em "roboto-light";
    letter-spacing: 0.01215em;
    position: relative;
    z-index: 1; }
  .more-resource--special {
    padding-bottom: 1.4375em; }
    .more-resource--special .more-resource__image {
      position: relative; }
      .more-resource--special .more-resource__image:before, .more-resource--special .more-resource__image:after {
        content: '';
        position: absolute;
        bottom: -3.6875em;
        width: 50%;
        height: 40%;
        background: #ffffff; }
      .more-resource--special .more-resource__image:before {
        left: 0;
        padding-right: 1px;
        transform: skewY(14.5deg); }
      .more-resource--special .more-resource__image:after {
        right: 0;
        transform: skewY(-14.5deg); }
    .more-resource--special .more-resource__title {
      font: 1.29625em/1.04966em "GT-Walsheim-Black";
      height: 2.66104em;
      position: relative;
      margin-top: 1.10897em; }
    .more-resource--special .more-resource__text {
      font: 0.9em/1.5em "roboto-light";
      color: #000000;
      margin: 1.94444em 1.04167em 0;
      height: 4.72222em;
      overflow: hidden;
      display: block;
      position: relative; }
    .more-resource--special .list-tagline {
      display: block;
      margin: 1.0625em 1.0625em 0;
      position: relative;
      min-height: 1.25em; }
      .more-resource--special .list-tagline--empty {
        background: none; }
    .more-resource--special .tagline-item {
      font-size: 0.9em;
      line-height: 1.05em;
      padding-left: 0;
      padding-right: 0;
      letter-spacing: 0; }
      .more-resource--special .tagline-item:first-child {
        text-align: right;
        padding-right: 0.41667em; }
      .more-resource--special .tagline-item:last-child {
        text-align: left;
        padding-left: 0.41667em; }

.desktop .more-resource__wrapper .row .col-4 {
  opacity: 0;
  transition: all .9s; }
  .desktop .more-resource__wrapper .row .col-4:nth-child(1) {
    transform: translate3d(0, 50%, 0); }
  .desktop .more-resource__wrapper .row .col-4:nth-child(2) {
    transform: translate3d(0, 70%, 0);
    transition-delay: 0.2s, 0.2s; }
  .desktop .more-resource__wrapper .row .col-4:nth-child(3) {
    transform: translate3d(0, 80%, 0);
    transition-delay: 0.4s, 0.4s; }

.desktop .more-resource__wrapper .row.animation .col-4:nth-child(n) {
  transform: none;
  opacity: 1; }

@media screen and (max-width: 47.9375em) {
  .more-resource {
    margin: 0 1.1875em 15%; }
    .more-resource__wrapper {
      margin-bottom: 3.75em;
      padding-top: 1.125em;
      padding-bottom: 1.1875em;
      border-bottom: 1px solid #000000; }
      .more-resource__wrapper .title__wrapper {
        text-align: left;
        margin: 0 1.1875em 2em; }
      .more-resource__wrapper--special .title__wrapper {
        text-align: center;
        margin-bottom: 3.1875em; }
    .more-resource__type {
      padding: 0.5em; }
    .more-resource__title {
      height: auto;
      padding-top: 0.5em;
      overflow-y: initial; }
    .more-resource__date {
      padding-top: 1.9375em;
      padding-bottom: 0.1875em; }
    .more-resource--special {
      padding-bottom: 1em; }
      .more-resource--special .list-tagline {
        margin-top: 0.75em; }
        .more-resource--special .list-tagline--empty {
          min-height: auto; }
      .more-resource--special .more-resource__text {
        margin-top: 1.73611em; }
      .more-resource--special .more-resource__title {
        margin-top: 0.3125em;
        height: auto; } }

.collection {
  position: relative;
  padding-top: 4.3125em;
  margin-top: 6.7%;
  padding-top: 5.625em;
  padding-bottom: 3.375em;
  margin-bottom: 14.25em;
  z-index: 1; }
  .collection:before, .collection:after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 90%;
    background-color: #dddddd;
    z-index: -1; }
  .collection:before {
    left: 0;
    padding-right: 1px;
    transform: skewY(-13.5deg);
    background: linear-gradient(-166.5deg, #f8f8f8 0%, #dddddd 23.75em); }
  .collection:after {
    right: 0;
    transform: skewY(13.5deg);
    background: linear-gradient(166.5deg, #f8f8f8 0%, #dddddd 23.75em); }
  .collection > .triangle-deco {
    background: #fff200;
    background: -webkit-linear-gradient(left, #fff200, #fff200);
    background: linear-gradient(to right, #fff200, #fff200); }
    .collection > .triangle-deco.triangle-deco--wrapper {
      z-index: -2; }
  .desktop .collection .container:before {
    content: '';
    position: absolute;
    display: block;
    top: -16%;
    left: 0;
    width: 100%;
    bottom: -41%;
    background-color: #ffffff;
    transition: transform .9s ease .5s;
    transform-origin: 100% 0;
    z-index: 2; }
  .desktop .collection.animation .container:before {
    transform: scaleX(0); }
  .collection__title {
    font: 2.6875em/1.2em "GT-Walsheim-Black";
    letter-spacing: -0.01023em;
    position: relative;
    z-index: 1;
    padding-left: 8.33333%;
    max-width: 40%; }
  .collection__desc {
    display: inline-block;
    margin-top: 0.97222em;
    margin-right: 2.08333em;
    font: 0.9em/1.52778em "roboto-light";
    letter-spacing: 0.03611em;
    color: #4c4c4c; }
  .collection .btn--cta {
    margin-top: 2.625em;
    width: 16.8125em; }
    .collection .btn--cta .btn__text {
      background: #dddddd; }
  .collection .col-7 {
    padding-right: 2.9375em;
    padding-left: 3.375em; }
  .collection__image {
    position: relative;
    z-index: 1;
    margin-top: -3.1875em; }
    .ie .collection__image {
      height: 22.25em; }
  .collection .decotriangle--bottom {
    background: none;
    z-index: -1; }
    .collection .decotriangle--bottom:before, .collection .decotriangle--bottom:after {
      background: #dddddd; }

@media screen and (max-width: 47.9375em) {
  .collection {
    padding-top: 3.4375em;
    padding-bottom: 2.75em;
    margin-top: 8%;
    margin-bottom: 28%; }
    .collection .col-7 {
      padding: 0 0.9375em; }
    .collection .col-4 {
      padding: 0 1.3125em; }
    .collection__title {
      padding-left: 0;
      font-size: 2.4375em;
      text-align: center;
      max-width: 100%; }
    .collection__image {
      margin-top: 1.3125em; }
    .collection__desc {
      margin: 1.38889em 0.34722em 1.11111em; }
    .collection .btn--cta {
      width: 17.5em;
      margin-top: 0; }
    .collection .btn__text {
      padding: 0.75231em; }
    .collection .decotriangle--bottom:before {
      width: 85.5%; }
    .collection .decotriangle--bottom:after {
      width: 14.5%; } }

.exhibition {
  display: block;
  position: relative;
  margin-bottom: 3.75em;
  padding-bottom: 0.6875em;
  text-align: center;
  text-decoration: none;
  z-index: 1; }
  .exhibition .border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 12%;
    border-left: #9b9b9b 0.0625em solid;
    border-right: #9b9b9b 0.0625em solid;
    pointer-events: none; }
    .exhibition .border__top, .exhibition .border__bottom {
      position: absolute;
      width: 100%;
      height: 12%; }
      .exhibition .border__top:before, .exhibition .border__top:after, .exhibition .border__bottom:before, .exhibition .border__bottom:after {
        content: '';
        position: absolute;
        width: 50%;
        height: 100%; }
    .exhibition .border__top {
      top: 0; }
      .exhibition .border__top:before, .exhibition .border__top:after {
        border-top: #9b9b9b 0.0625em solid; }
      .exhibition .border__top:before {
        left: 0;
        padding-right: 1px;
        transform: skewY(-13.5deg);
        transform-origin: 0 0; }
      .exhibition .border__top:after {
        right: 0;
        transform: skewY(13.5deg);
        transform-origin: 100% 0; }
    .exhibition .border__bottom {
      bottom: 1px; }
      .exhibition .border__bottom:before, .exhibition .border__bottom:after {
        border-bottom: #9b9b9b 0.0625em solid; }
      .exhibition .border__bottom:before {
        left: 0;
        padding-right: 1px;
        transform: skewY(13.5deg);
        transform-origin: 0 0; }
      .exhibition .border__bottom:after {
        right: 0;
        transform: skewY(-13.5deg);
        transform-origin: 100% 0; }
  .exhibition:hover .exhibition__overlay {
    opacity: .2; }
  .exhibition__overlay {
    fill: #000000;
    opacity: 0;
    transition: opacity .3s ease; }
  .exhibition:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: "sam";
    color: #000000;
    font-size: 1.875em;
    margin-top: 0.26667em; }
  .exhibition--no-location .exhibition__status {
    margin-bottom: 1.2054em; }
  .exhibition--no-location:after {
    display: none; }
  .exhibition__wrapper {
    margin-bottom: 9.375em;
    text-align: center;
    position: relative;
    z-index: 1; }
    .exhibition__wrapper .container {
      margin-top: 4.375em; }
    .exhibition__wrapper .title__wrapper {
      margin-bottom: 0.125em; }
    .exhibition__wrapper--learning {
      margin-bottom: 7.375em; }
      .exhibition__wrapper--learning .container {
        margin-top: 0; }
  .exhibition__past {
    font: 0.9em/0.83333em "roboto-bold";
    letter-spacing: 0.01736em;
    color: #9b9b9b;
    position: relative;
    z-index: 1;
    text-decoration: none;
    margin-left: 1.04167em; }
    .exhibition__past:hover {
      opacity: .7; }
    .exhibition__past__arrow-ico {
      height: 1.04167em;
      margin-left: 0.27778em;
      vertical-align: middle;
      line-height: 1.11111em; }
      .ie .exhibition__past__arrow-ico {
        width: 2.08333em; }
      .exhibition__past__arrow-ico path,
      .exhibition__past__arrow-ico circle {
        stroke: #9b9b9b;
        stroke-width: 2.5; }
  .ie .exhibition__image {
    height: 16em; }
  .exhibition__title, .exhibition__status, .exhibition__info {
    display: block;
    margin-left: 1.06075em;
    margin-right: 1.06075em; }
  .exhibition__title, .exhibition__status {
    color: #000000; }
  .exhibition__title {
    font: 1.29625em/1.14609em "GT-Walsheim-Black";
    letter-spacing: -0.02122em;
    height: 3.43828em;
    overflow: hidden;
    margin-top: 0.24108em; }
  .exhibition__status {
    position: relative;
    font: 1.29625em/1.19961em "roboto-light";
    letter-spacing: 0.01205em;
    margin-top: 0.77146em;
    padding-bottom: 0.72324em;
    border-bottom: #000000 1px solid; }
  .exhibition__info {
    font: 0.9em/1.2em "roboto-black";
    letter-spacing: -0.01181em;
    color: #9b9b9b;
    margin-top: 0.55556em; }
  .exhibition--other {
    text-align: left; }
    .exhibition--other .border {
      position: absolute;
      top: 0;
      left: 0.5625em;
      right: 0.5625em;
      bottom: 0;
      margin-top: 14%;
      border-left: #9b9b9b 0.0625em solid;
      border-right: #9b9b9b 0.0625em solid;
      pointer-events: none; }
      .exhibition--other .border__top, .exhibition--other .border__bottom {
        position: absolute;
        width: 100%;
        height: 14%; }
        .exhibition--other .border__top:before, .exhibition--other .border__top:after, .exhibition--other .border__bottom:before, .exhibition--other .border__bottom:after {
          content: '';
          position: absolute;
          width: 50%;
          height: 100%; }
      .exhibition--other .border__top {
        top: 0; }
        .exhibition--other .border__top:before, .exhibition--other .border__top:after {
          border-top: #9b9b9b 0.0625em solid; }
        .exhibition--other .border__top:before {
          left: 0;
          padding-right: 1px;
          transform: skewY(-13.5deg);
          transform-origin: 0 0; }
        .exhibition--other .border__top:after {
          right: 0;
          transform: skewY(13.5deg);
          transform-origin: 100% 0; }
      .exhibition--other .border__bottom {
        bottom: 1px; }
        .exhibition--other .border__bottom:before, .exhibition--other .border__bottom:after {
          border-bottom: #9b9b9b 0.0625em solid; }
        .exhibition--other .border__bottom:before {
          left: 0;
          padding-right: 1px;
          transform: skewY(13.5deg);
          transform-origin: 0 0; }
        .exhibition--other .border__bottom:after {
          right: 0;
          transform: skewY(-13.5deg);
          transform-origin: 100% 0; }
    .exhibition--other:after {
      display: none; }
    .exhibition--other .exhibition__time {
      margin-bottom: 0.20833em; }
    .exhibition--other .tagline-item {
      padding-left: 1.5em;
      padding-right: 2em;
      font: 0.75em/1.33333em "roboto-light"; }
      .exhibition--other .tagline-item:first-child {
        padding-right: 1.66667em; }
      .exhibition--other .tagline-item:last-child {
        padding-left: 1.91667em; }
      .exhibition--other .tagline-item--active {
        font-family: "roboto-bold";
        line-height: 1.16667em; }
  .exhibition--learning .exhibition__title {
    margin: 0.72324em 1.2054em 0;
    padding-bottom: 0.9161em;
    border-bottom: #979797 1px solid;
    height: auto; }
  .exhibition--learning .exhibition__text {
    margin-top: 1.1039em;
    padding: 0 0.97403em;
    height: 5.90909em;
    overflow: hidden; }
  .exhibition__other-content {
    display: block;
    margin: 0 1.1875em 0 1.4375em;
    padding-bottom: 0.6875em; }
  .exhibition__heading {
    display: block;
    border-bottom: #000000 1px solid;
    padding-bottom: 0.5625em; }
  .exhibition__time {
    display: block;
    margin-top: 1.25em;
    color: #000000;
    font: 1.5em/0.83333em "roboto-light";
    letter-spacing: 0.01042em; }
  .exhibition__text {
    display: inline-block;
    color: #4a4a4a;
    font: 0.9625em/1.41169em "roboto-light";
    letter-spacing: -0.00649em; }
  .exhibition__text-title {
    display: block;
    color: #000000;
    font: 1.225em/0.95em "GT-Walsheim-Black";
    margin: 0.91837em 0 0.61224em; }

.other-exhibition__wrapper {
  padding-top: 3em;
  padding-bottom: 4.875em; }

.desktop .exhibition__wrapper .row .col-4,
.desktop .other-exhibition__wrapper .row .col-4 {
  opacity: 0;
  transition: all .9s; }
  .desktop .exhibition__wrapper .row .col-4:nth-child(1),
  .desktop .other-exhibition__wrapper .row .col-4:nth-child(1) {
    transform: translate3d(0, 50%, 0); }
  .desktop .exhibition__wrapper .row .col-4:nth-child(2),
  .desktop .other-exhibition__wrapper .row .col-4:nth-child(2) {
    transform: translate3d(0, 70%, 0);
    transition-delay: 0.2s, 0.2s; }
  .desktop .exhibition__wrapper .row .col-4:nth-child(3),
  .desktop .other-exhibition__wrapper .row .col-4:nth-child(3) {
    transform: translate3d(0, 80%, 0);
    transition-delay: 0.4s, 0.4s; }

.desktop .exhibition__wrapper .row.animation .col-4:nth-child(n),
.desktop .other-exhibition__wrapper .row.animation .col-4:nth-child(n) {
  transform: none;
  opacity: 1; }

@media screen and (max-width: 47.9375em) {
  .exhibition {
    margin: 0 1.1875em 14.5%; }
    .exhibition:after {
      font-size: 1.6875em; }
    .exhibition__wrapper {
      padding-bottom: 1.0625em; }
      .exhibition__wrapper--learning {
        padding-top: 0.5em;
        box-shadow: 0 0.0625em 0 #000000;
        margin-bottom: 4.875em;
        padding-bottom: 4.1875em; }
      .exhibition__wrapper .title__wrapper {
        margin-bottom: 0.8125em; }
    .exhibition__title {
      margin-top: 0.19286em; }
    .exhibition__status {
      margin-top: 0.38573em;
      padding-bottom: 0.53038em; }
      .exhibition__status:after {
        margin-top: 0.53038em; }
    .exhibition__info {
      margin-top: 0.41667em; }
    .exhibition__other-content {
      margin: 0 1.1875em 0 1.125em;
      padding-bottom: 0; }
    .exhibition__time {
      margin-top: 0.41667em; }
    .exhibition__text-title {
      margin: 1.12245em 0 0.30612em; }
    .exhibition--learning {
      margin-bottom: 19%; }
      .exhibition--learning .exhibition__title {
        margin-top: 0.53038em;
        padding-bottom: 0.81967em; }
      .exhibition--learning .exhibition__text {
        margin-top: 0.71429em;
        height: auto; }
  .exhibition--other .tagline-item {
    padding-left: 1.66667em;
    padding-right: 1.5em;
    font: 0.75em/1.33333em "roboto-light"; }
    .exhibition--other .tagline-item:first-child {
      padding-left: 1em;
      padding-right: 1.33333em; }
    .exhibition--other .tagline-item:last-child {
      padding-left: 1.75em; } }

@media screen and (max-width: 47.9375em) {
  .menu {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    max-height: 100%;
    z-index: 99;
    background: #ffffff;
    box-shadow: 0 0.1875em 0.25em 0 rgba(0, 0, 0, 0.1);
    transition: transform .3s ease;
    transform: translateZ(0); }
    .menu.show-popup {
      z-index: 1; }
    .menu.opened {
      overflow: auto; }
      .menu.opened .menu__btn--burger .bar {
        background-color: #9b9b9b; }
      .menu.opened .menu__btn--burger .bar:first-child {
        transform: translateY(8px) rotate(45deg); }
      .menu.opened .menu__btn--burger .bar:nth-child(2) {
        transform: rotate(-45deg); }
      .menu.opened .menu__btn--burger .bar:nth-child(3) {
        opacity: 0; }
    .menu.hide {
      transform: translateY(-100%); }
    .menu__top {
      position: relative;
      z-index: 1; }
    .menu__content {
      overflow: hidden;
      width: 100%;
      display: none;
      position: relative;
      z-index: 0; }
    .menu__logo {
      display: block;
      width: 6.25em;
      float: right;
      margin: 0.9375em 1.1875em 0; }
    .menu__btn--burger {
      margin: 0.6875em 1.3125em;
      background: none; }
      .menu__btn--burger .bar {
        width: 1.75em; }
    .menu__slider {
      width: 100%;
      background: #212121;
      padding: 0 1.1875em; }
      .menu__slider .slick-list {
        overflow: visible; }
    .menu__block {
      margin: 0.75em 0.3125em; }
    .menu__links {
      display: none; }
    .menu__item {
      background-color: #000000; }
      .menu__item.opened .menu__trigger {
        color: #fff200;
        border-bottom: 0; }
        .menu__item.opened .menu__trigger:after {
          content: ""; }
      .menu__item:last-child .menu__trigger {
        border-bottom: none; }
    .menu__trigger {
      font: 1.29625em/1.19961em "GT-Walsheim-Black";
      color: #ffffff;
      padding: 0.62681em 0;
      margin: 0 0.9161em;
      display: block;
      border-bottom: #4c4c4c 1px solid; }
      .menu__trigger--last {
        border-bottom: none; }
      .menu__trigger:after {
        content: "";
        font-family: "sam";
        font-size: 1.39826em;
        float: right; }
    .menu__link {
      font: 0.9em/1.2em "roboto-bold";
      color: #9b9b9b;
      padding: 0.79861em 2.43056em;
      background-color: #212121;
      box-shadow: 0 0.0625em 0.25em 0 rgba(0, 0, 0, 0.5);
      display: block; }
    .menu__footer {
      background-color: #212121;
      padding: 1.0625em 0 1.3125em;
      text-align: center;
      box-shadow: 0 0.0625em 0.25em 0 rgba(0, 0, 0, 0.5); }
      .menu__footer__li {
        display: inline-block; }
        .menu__footer__li:last-child .menu__footer__link:after {
          display: none; }
      .menu__footer__link {
        font: 0.625em/ 1.2em "roboto-bold";
        position: relative;
        color: #ffffff;
        letter-spacing: 0.091em;
        padding: 0 1.5em; }
        .menu__footer__link:after {
          content: '';
          position: absolute;
          right: -1px;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: #ffffff; }
    .menu__time,
    .menu .social-share, .menu__contact {
      background-color: #d8d8d8;
      padding: 1.125em;
      text-align: center; }
    .menu__time,
    .menu .social-share {
      position: relative; }
      .menu__time:after,
      .menu .social-share:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 1.25em;
        right: 1.25em;
        height: 1px;
        background-color: #000000; }
    .menu__status {
      font: 0.9em/1.2em "GT-Walsheim-Black";
      margin-right: 2.98611em;
      margin-left: 0.34722em;
      display: inline-block;
      width: 28%; }
    .menu .ico-clock {
      vertical-align: middle;
      display: inline-block; }
      .menu .ico-clock:before {
        font-size: 1.5em; }
    .menu__info {
      font: 0.625em/1.5em "roboto-bold";
      margin-left: 3.1em;
      display: inline-block;
      text-align: center;
      width: 32%;
      vertical-align: middle; }
    .menu .social-share {
      padding-bottom: 0.8125em;
      border-bottom: none; }
      .menu .social-share__item {
        font-size: 0.75em;
        margin: 0 1.75em; }
    .menu__contact__detail {
      font: 0.75em/1.25em "roboto-regular";
      display: inline-block; }
      .menu__contact__detail:last-child {
        margin-left: 1em; }
      .menu__contact__detail .ico-contact-us,
      .menu__contact__detail .ico-email {
        vertical-align: middle;
        margin-right: 0.25em; }
      .menu__contact__detail .ico-contact-us:before {
        font-size: 1.16667em; }
    .menu__search {
      position: absolute;
      left: 0;
      right: 0; }
      .menu__search .form-search {
        display: none;
        background: #ffffff;
        padding: 0.5em 1.25em 1.25em;
        border-top: rgba(0, 0, 0, 0.1) 1px solid;
        border-bottom: rgba(0, 0, 0, 0.1) 1px solid; }
      .menu__search .custom-input {
        margin-top: 0;
        margin-bottom: 0;
        font-family: "GT-Walsheim-Black"; }
      .menu__search .form__control--event {
        font-size: 1.555em;
        line-height: 1.20016em;
        padding-top: 0.64309em;
        padding-bottom: 0.64309em; }
        .menu__search .form__control--event::-moz-placeholder {
          font-family: "GT-Walsheim-Black";
          color: rgba(155, 155, 155, 0.51);
          padding-left: 0.60289em; }
        .menu__search .form__control--event::-webkit-input-placeholder {
          font-family: "GT-Walsheim-Black";
          color: rgba(155, 155, 155, 0.51);
          padding-left: 0.60289em; }
        .menu__search .form__control--event:-ms-input-placeholder {
          font-family: "GT-Walsheim-Black";
          color: rgba(155, 155, 155, 0.51);
          padding-left: 0.60289em; }
        .menu__search .form__control--event::-ms-input-placeholder {
          font-family: "GT-Walsheim-Black";
          color: rgba(155, 155, 155, 0.51);
          padding-left: 0.60289em; }
    .menu__btn-search {
      position: absolute;
      top: -2.3125em;
      left: 4.3125em;
      color: #000000; }
      .menu__btn-search:before {
        font-size: 1.375em; } }

@media screen and (min-width: 48em) {
  .menu {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    height: 100vh;
    width: 1px; }
    .menu.show-popup {
      z-index: 1; }
    .menu.fixed .menu__buttons {
      position: fixed; }
    .menu.opened .menu__wrapper, .menu.opened .menu__footer {
      transform: translateX(0); }
    .menu.opened .menu__buttons {
      border-right: #000000 0.1875em solid;
      background-color: #000000;
      opacity: 1; }
    .menu__buttons {
      position: absolute;
      left: 0;
      z-index: 3;
      transform: translateZ(0);
      opacity: .85; }
      .menu__buttons.init {
        transform: translateY(-50%);
        top: 50%; }
    .menu__btn {
      background: #ffffff;
      background: -webkit-linear-gradient(top, #ffffff, #d8d8d8);
      background: linear-gradient(to bottom, #ffffff, #d8d8d8);
      display: block;
      position: relative;
      width: 8.07143em;
      height: 7.85714em;
      padding: 1.71429em 1.57143em 1.42857em;
      text-align: left;
      font: 0.875em/1.23429em "roboto-bold";
      color: #000000;
      overflow: visible;
      outline: 0;
      border: none; }
      .menu__btn.active {
        background: #000000;
        color: #ffffff; }
        .menu__btn.active:hover, .menu__btn.active:focus {
          background: #000000;
          color: #ffffff; }
        .menu__btn.active:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: -1.14286em;
          width: 0;
          border-bottom: transparent 3.92857em solid;
          border-top: transparent 3.92857em solid;
          border-left: 1em solid #000000; }
          .ie .menu__btn.active:after,
          .edge .menu__btn.active:after {
            right: -1.21429em; }
        .menu__btn.active.menu__btn--search path,
        .menu__btn.active.menu__btn--search circle {
          stroke: #ffffff; }
      .menu__btn:hover, .menu__btn:focus {
        cursor: pointer;
        outline: 0;
        background: #cacaca; }
      .menu__btn--burger {
        position: absolute;
        top: 0;
        left: 0;
        text-align: center; }
        .menu__btn--burger .icon-burger {
          width: 3.4375em;
          height: 3.4375em; }
      .menu__btn--search {
        text-align: center; }
        .menu__btn--search .icon-search {
          pointer-events: none;
          width: 2.8125em;
          height: 2.8125em; }
    .menu__close-btn {
      position: absolute;
      top: 2.625em;
      right: 2.0625em;
      border: none;
      background: none; }
      .menu__close-btn .icon-close {
        width: 3.25em;
        height: 3.25em;
        display: block; }
      .menu__close-btn:hover {
        cursor: pointer; }
    .menu__wrapper {
      background: #ffffff;
      background: -webkit-linear-gradient(top, #ffffff, #dddddd);
      background: linear-gradient(to bottom, #ffffff, #dddddd);
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      padding-left: 7.0625em;
      transform: translateX(-100%);
      transition: transform 0.4s cubic-bezier(0.71, 0.08, 0.35, 0.99) 0.2s; }
    .menu__content {
      display: none;
      position: relative;
      top: 50%;
      transform: translateY(-50%) translateZ(0);
      width: 46.6875em;
      margin: 0 auto; }
      .menu__content.active {
        display: block; }
      .menu__content__wrapper {
        display: table;
        width: 100%; }
      .menu__content--visit .menu__links {
        width: 16.66667%;
        vertical-align: top;
        padding-top: 0; }
        .menu__content--visit .menu__links .menu__link--big {
          width: 100%;
          margin-bottom: 1.83333em; }
      .menu__content--visit .menu__content__wrapper {
        margin-top: 5.375em; }
    .menu__links, .menu__blocks, .menu__time, .menu__loc {
      display: table-cell; }
    .menu__links {
      width: 66.66667%;
      vertical-align: middle;
      padding: 1.3125em 1.875em 0 0; }
    .menu__item {
      position: relative;
      padding: 0.75em 0 0;
      border-bottom: rgba(74, 74, 74, 0.3) 1px dotted; }
      .menu__item:before, .menu__item:after {
        content: ' ';
        display: table; }
      .menu__item:after {
        clear: both; }
      .menu__item__content {
        float: left;
        position: relative;
        width: 66.66667%; }
    .menu__link {
      width: 50%;
      float: left;
      vertical-align: top;
      font: 0.75em/1.41667em "roboto-light";
      color: #4a4a4a;
      padding-right: 0.83333em;
      margin-bottom: 1em; }
      .menu__link:hover {
        text-decoration: underline; }
      .menu__link--title {
        font: 0.9em/1.2em "roboto-black";
        margin-left: -2.77778em;
        color: #000000;
        width: auto;
        float: none;
        vertical-align: middle; }
        .menu__link--title .ico-long-arrow-right-circle {
          margin-left: 0.69444em; }
          .menu__link--title .ico-long-arrow-right-circle:before {
            font-size: 1.38889em;
            vertical-align: middle; }
        .menu__link--title:hover {
          text-decoration: none; }
      .menu__link--big {
        float: left;
        font-family: "roboto-bold";
        color: #757575;
        width: 33.33333%; }
      .menu__link--small {
        width: auto;
        padding: 0 0.97222em;
        border-left: #4a4a4a 1px solid; }
        .menu__link--small:first-child {
          padding-left: 0;
          border-left: none; }
      .menu__link__desc, .menu__link__date {
        display: block;
        font-size: 0.83333em; }
      .menu__link__desc {
        font-style: italic;
        line-height: 1.2em; }
      .menu__link__date {
        font-family: "roboto-bold"; }
    .menu__ico {
      height: 1.66667em;
      vertical-align: middle;
      margin-left: 0.34722em; }
      .ie .menu__ico {
        width: 2.77778em; }
    .menu__blocks {
      width: 33.33333%; }
    .menu .form-search {
      text-align: center;
      max-width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
      .menu .form-search .custom-input {
        max-width: 39.5em;
        background: none;
        font-family: "GT-Walsheim-Black"; }
      .menu .form-search .form__control--event {
        font-size: 3.225em;
        line-height: 1.2em;
        padding-left: 0.1938em;
        padding-top: 0.1938em;
        padding-bottom: 0.1938em; }
        .menu .form-search .form__control--event::-moz-placeholder {
          font-family: "GT-Walsheim-Black";
          padding-left: 0.1938em;
          letter-spacing: -0.03004em;
          color: #9b9b9b; }
        .menu .form-search .form__control--event::-webkit-input-placeholder {
          font-family: "GT-Walsheim-Black";
          padding-left: 0.1938em;
          letter-spacing: -0.03004em;
          color: #9b9b9b; }
        .menu .form-search .form__control--event:-ms-input-placeholder {
          font-family: "GT-Walsheim-Black";
          padding-left: 0.1938em;
          letter-spacing: -0.03004em;
          color: #9b9b9b; }
        .menu .form-search .form__control--event::-ms-input-placeholder {
          font-family: "GT-Walsheim-Black";
          padding-left: 0.1938em;
          letter-spacing: -0.03004em;
          color: #9b9b9b; }
      .menu .form-search .form__submit-event {
        background: none;
        right: 0.375em; }
      .menu .form-search .icon-search {
        width: 3.125em;
        height: 3.125em;
        display: block; }
    .menu__time {
      width: 50%;
      padding: 0 1.25em;
      vertical-align: top;
      text-align: center;
      position: relative; }
      .menu__time__item {
        background: #f2f2f2;
        padding: 1.125em 0.75em 0.5em; }
      .menu__time__title, .menu__time__status, .menu__time__info {
        display: block;
        font-size: 0.75em;
        line-height: 1.2em; }
      .menu__time__title {
        font-family: "roboto-bold";
        margin-top: 0.41667em; }
      .menu__time__status, .menu__time__info {
        font-family: "roboto-light"; }
      .menu__time__status {
        margin-top: 0.75em;
        padding-bottom: 1.25em;
        color: #4a4a4a; }
      .menu__time__info {
        margin-top: 0.83333em;
        padding-bottom: 1.16667em; }
        .menu__time__info:last-child {
          padding-bottom: 0;
          color: #757575; }
      .menu__time__detail {
        width: 50%;
        float: left;
        text-align: left;
        padding: 0.625em 0.4375em 0.375em; }
        .menu__time__detail__wrapper:before, .menu__time__detail__wrapper:after {
          content: ' ';
          display: table; }
        .menu__time__detail__wrapper:after {
          clear: both; }
        .menu__time__detail__title, .menu__time__detail__desc {
          display: block; }
        .menu__time__detail__title {
          font: 0.75em/1.2em "roboto-bold"; }
        .menu__time__detail__desc {
          font: 0.625em/1.5em "roboto-light";
          margin-top: 0.8em; }
      .menu__time__status, .menu__time__detail__wrapper, .menu__time__info:not(:last-child) {
        border-bottom: #9b9b9b 1px dotted; }
      .menu__time-revert .menu__time__item {
        background: #ffffff;
        border: 1px solid #dddddd; }
    .menu__loc {
      width: 33.33333%; }
      .menu__loc__item {
        display: block;
        margin-bottom: 1.0625em;
        box-shadow: 0 0.125em 0.25em 0 rgba(0, 0, 0, 0.5);
        border-bottom: #fff200 0.25em solid;
        background-color: #ffffff; }
      .menu__loc__img {
        width: 100%; }
      .menu__loc__caption {
        padding: 0.3125em 0 0.4375em 2.125em; }
      .menu__loc__title, .menu__loc__desc {
        display: block; }
      .menu__loc__title {
        font: 0.75em/1.2em "roboto-bold";
        color: #000000; }
      .menu__loc__desc {
        font: 0.625em/1.5em "roboto-light";
        color: #4a4a4a; }
    .menu__footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 2.5em;
      padding: 0.5em 5.625em 0 10.6875em;
      background: #757575;
      text-align: center;
      transform: translateX(-100%);
      transition: transform 0.4s cubic-bezier(0.71, 0.08, 0.35, 0.99) 0.2s; }
      .menu__footer .top-navigation__list {
        vertical-align: top;
        padding: 0; }
        .menu__footer .top-navigation__list__link {
          font: 0.75em/1em "roboto-regular";
          color: #ffffff; }
      .menu__footer__social {
        display: inline-block; }
    .menu__social-share.social-share {
      padding: 0 0 0 3.25em; } }

.menu__btn {
  border: none; }
  .menu__btn--burger .bar {
    height: 2px;
    width: 2.08333em;
    background-color: #000000;
    display: block;
    margin: 0.375em auto; }

.menu__block {
  display: block;
  width: 100%;
  margin-bottom: 0.625em;
  padding-bottom: 0.4375em;
  box-shadow: 0 0.125em 0.25em 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff; }
  .menu__block__image {
    position: relative;
    border-bottom: #fff200 0.25em solid; }
  .menu__block__img {
    display: block;
    width: 100%; }
  .menu__block__caption {
    position: absolute;
    top: 0.8125em;
    left: 0.875em; }
  .menu__block__type, .menu__block__status {
    display: block;
    font-size: 0.75em;
    line-height: 1.26667em; }
  .menu__block__type {
    font-family: "roboto-bold";
    color: #fff200;
    letter-spacing: 0.10417em; }
  .menu__block__status {
    font-family: "roboto-light";
    color: #ffffff;
    letter-spacing: -0.00583em; }
  .menu__block__title {
    display: block;
    font: 0.75em/1.43333em "roboto-bold";
    color: #4c4c4c;
    padding: 0 1.25em;
    margin-top: 0.25em;
    overflow: hidden;
    height: 4.16667em; }

@media only screen and (min-width: 64em) and (max-width: 90em) {
  .menu {
    font-size: 16px; } }

.associated-events__wrapper {
  position: relative;
  z-index: 1;
  padding-top: 9em;
  padding-bottom: 10%; }

.associated__title {
  font: 3.36125em "GT-Walsheim-Black";
  color: #000000;
  letter-spacing: -.19px;
  text-align: center;
  margin-bottom: 1.00409em; }

.associated__content {
  border-bottom: 2px solid #000000; }

.associated__img {
  padding-right: 1.4375em; }
  .associated__img img {
    display: block;
    width: 100%; }

.associated__time {
  text-align: center;
  padding-top: 0.75em; }
  .associated__time__date, .associated__time__day, .associated__time__hour {
    display: block; }
    .associated__time__date.hidden, .associated__time__day.hidden, .associated__time__hour.hidden {
      display: none; }
  .associated__time__date {
    font-size: 3.225em;
    font-family: "GT-Walsheim-Black";
    color: #000000;
    line-height: 0.62016em; }
  .associated__time__day, .associated__time__month {
    font-family: "roboto-bold";
    font-size: 0.75em; }
  .associated__time__month {
    color: #000000;
    letter-spacing: 0.03083em;
    display: inline-block;
    padding: 0 0.58333em 0.41667em;
    border-bottom: 2px solid #979797;
    margin-bottom: 0.66667em; }
  .associated__time__day {
    color: #4a4a4a;
    text-transform: uppercase; }
  .associated__time__hour {
    font: 0.9em "roboto-light";
    color: #4a4a4a; }

.associated__infor {
  padding-top: 0.5625em; }
  .associated__infor__title {
    margin-bottom: 0.95238em; }
  .associated__infor__link {
    display: inline-block;
    font: 0.98762em/1.10897em "GT-Walsheim-Black";
    color: #000000;
    letter-spacing: -.44px;
    transition: color .3s; }
    .associated__infor__link:hover {
      color: #9b9b9b; }
  .associated__infor__tag {
    display: inline-block;
    min-width: 13.58333em;
    max-width: 20.83333em;
    margin-bottom: 1.16667em;
    padding: 0 1.5em 0 0.83333em;
    background-color: #fef200;
    font-size: 0.75em;
    line-height: 1.16667em;
    color: #000000;
    letter-spacing: 1.2px; }
    .associated__infor__tag .tag__highlight,
    .associated__infor__tag .tag__item {
      display: inline-block; }
    .associated__infor__tag .tag__highlight {
      font-family: "roboto-bold";
      padding-right: 0.83333em; }
    .associated__infor__tag .tag__item {
      position: relative;
      font-family: "roboto-light";
      padding-left: 0.83333em;
      padding-right: 0.83333em; }
      .associated__infor__tag .tag__item:before {
        content: '';
        position: absolute;
        top: 2px;
        bottom: 2px;
        left: 0;
        width: 1px;
        background-color: #000000; }
  .associated__infor__exhibition {
    font: 0.9em "roboto-light";
    letter-spacing: .25px;
    color: #9b9b9b; }
  .associated__infor__star {
    font: 0.9em "roboto-bold";
    letter-spacing: .23px;
    color: #065aff; }

@media screen and (min-width: 48em) {
  .associated {
    display: table;
    width: 100%;
    max-width: 50em;
    margin-left: auto;
    margin-right: auto; }
    .associated + .associated {
      margin-top: 0.9375em; }
    .associated__img {
      width: 41%;
      transition: opacity .3s; }
      .associated__img:hover {
        opacity: .7; }
    .associated__content {
      width: 59%; }
    .associated__img, .associated__content {
      display: table-cell;
      vertical-align: middle; }
    .associated__time, .associated__infor {
      display: inline-block;
      vertical-align: top; }
    .associated__time {
      min-width: 10.5625em; }
    .associated__infor__title {
      max-width: 12.054em;
      line-height: 1.44648em; }
  .desktop .associated {
    opacity: 0;
    transition: all .9s .5s;
    transform: translate3d(0, 50%, 0); }
    .desktop .associated.animation:nth-child(n) {
      transform: none;
      opacity: 1; } }

@media screen and (max-width: 47.9375em) {
  .associated {
    position: relative; }
    .associated__inner {
      padding: 0 0.75em; }
    .associated + .associated {
      margin-top: 1em; }
    .associated__title {
      font-size: 2.40125em;
      letter-spacing: -0.03826em;
      line-height: 0.91098em;
      margin-bottom: 0.75482em; }
    .associated__img {
      position: relative;
      padding-right: 6.875em;
      display: block; }
      .associated__img:before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 6.25em;
        height: 0.125em;
        background-color: #979797; }
      .associated__img picture {
        display: block; }
    .associated__time {
      position: absolute;
      top: 0;
      right: 0;
      max-width: 6.875em;
      width: 100%;
      padding-top: 1em;
      padding-left: 0.625em;
      text-align: left; }
      .associated__time__date {
        font-size: 3.225em;
        line-height: 0.65891em; }
      .associated__time__month {
        font-size: 0.75em;
        letter-spacing: 0.03083em;
        padding: 0 1.66667em 0 0;
        margin-bottom: 0; }
      .associated__time__day {
        font-size: 0.75em;
        letter-spacing: 0.05em;
        margin-top: 0.16667em;
        margin-bottom: -0.16667em; }
      .associated__time__hour {
        font-size: 0.9em; }
    .associated__infor {
      padding-bottom: 0.8125em;
      border-bottom: 1px solid #000000; }
      .associated__infor__link {
        font-size: 1.09158em;
        letter-spacing: -0.02122em; }
      .associated__infor__title {
        padding-right: 1.25em;
        margin-bottom: 0.42105em; }
      .associated__infor__star {
        text-transform: uppercase; }
    .associated__content {
      border: 0;
      padding: 0 0.3125em; } }

.sponsors {
  color: #000000;
  width: 83.33333%;
  margin-left: 8.33333%;
  letter-spacing: -0.3125em; }
  .sponsors .cta--full {
    margin-top: 0;
    margin-bottom: 3.125em; }
  .sponsors .cta .cta__icon {
    right: 0.8em; }
  .sponsors__wrapper {
    position: relative;
    z-index: 1;
    padding-bottom: 1.25em; }
    .sponsors__wrapper .title__wrapper {
      margin-bottom: 5.125em; }
  .sponsors__item {
    letter-spacing: normal;
    position: relative;
    display: inline-block;
    width: 33.33333%;
    vertical-align: top;
    text-align: center;
    margin-bottom: 1.4375em; }
    .sponsors__item.opened .ico-plus-circle:before {
      transform: rotate(45deg); }
    .sponsors__item.opened .sponsors__trigger:before, .sponsors__item.opened .sponsors__trigger:after {
      opacity: 1;
      transform: translate(0, 0); }
  .sponsors__trigger {
    margin-bottom: 0.875em;
    position: relative;
    width: 14em;
    display: block; }
    .sponsors__trigger:before, .sponsors__trigger:after {
      opacity: 0;
      transform: translate(0, -0.625em);
      transition: transform .4s ease, opacity .4s ease; }
    .sponsors__trigger:before {
      content: '';
      position: absolute;
      bottom: -1.5em;
      height: 0.5625em;
      left: 0;
      width: 100%;
      background-color: #000000; }
    .sponsors__trigger:after {
      content: '';
      position: absolute;
      border-bottom: #000000 0.625em solid;
      border-left: transparent 0.625em solid;
      border-right: transparent 0.625em solid;
      bottom: -0.9375em;
      margin-left: -0.6875em;
      left: 50%; }
    .sponsors__trigger:hover {
      cursor: pointer; }
  .sponsors__type {
    font: 0.75em/1.16667em "roboto-bold";
    letter-spacing: 0.1em;
    color: #ff9f00; }
  .sponsors__img {
    display: block;
    margin: 0.4375em auto 0; }
  .sponsors .ico-plus-circle {
    position: relative;
    margin-top: 0.75em;
    display: inline-block;
    width: 9em; }
    .sponsors .ico-plus-circle:before {
      display: inline-block;
      margin: 0 auto;
      padding: 0 0.1875em;
      text-align: center;
      color: #979797;
      font-size: 1.5em;
      background: #ffffff;
      position: relative;
      z-index: 1; }
    .sponsors .ico-plus-circle:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #979797;
      top: 50%;
      left: 0;
      z-index: 0; }
  .sponsors__content {
    display: none;
    text-align: left;
    background-color: #f5f5f5;
    padding: 2.75em 5.25em;
    border-top: #000000 2px solid; }
    .sponsors__content p:last-child {
      margin-bottom: 0; }
    .sponsors__content a {
      color: #005aff; }
    .sponsors__content .btn--cta {
      color: #000000; }
      .sponsors__content .btn--cta span {
        background-color: #f5f5f5; }
  .sponsors__title, .sponsors__text {
    display: block;
    font-size: 0.9em;
    line-height: 1.5em;
    margin-bottom: 1.59722em; }
    .sponsors__title:last-child, .sponsors__text:last-child {
      margin-bottom: 0; }
  .sponsors__title {
    font-family: "roboto-bold";
    color: #4a4a4a; }
  .sponsors__text {
    font-size: 0.9em;
    line-height: 1.5em;
    margin-bottom: 1.59722em;
    font-family: "roboto-light";
    color: #5c5c5c; }
  .sponsors__link {
    color: #005aff; }

@media screen and (min-width: 48em) {
  .sponsors__item:nth-child(3n + 1) .sponsors__content {
    margin-right: -200%; }
  .sponsors__item:nth-child(3n + 1) .sponsors__content::before {
    left: 0; }
  .sponsors__item:nth-child(3n + 2) .sponsors__trigger {
    margin-left: auto;
    margin-right: auto; }
  .sponsors__item:nth-child(3n + 2) .sponsors__content {
    margin-left: -100%;
    margin-right: -100%; }
    .sponsors__item:nth-child(3n + 2) .sponsors__content:before {
      left: 33.33333%; }
  .sponsors__item:nth-child(3n) .sponsors__trigger {
    margin-left: auto; }
  .sponsors__item:nth-child(3n) .sponsors__content {
    margin-left: -200%; }
    .sponsors__item:nth-child(3n) .sponsors__content:before {
      right: 0; } }

@media screen and (max-width: 47.9375em) {
  .sponsors {
    color: #000000;
    width: 100%;
    margin-left: 0; }
    .sponsors__wrapper .title__wrapper {
      margin-bottom: 2.5em;
      padding: 0 1.1875em; }
    .sponsors__wrapper .sub-title {
      margin-top: 1.94444em; }
    .sponsors__item {
      width: 100%;
      margin-bottom: 2.8125em; }
    .sponsors__trigger {
      width: 100%;
      padding: 0 0.5em; }
      .sponsors__trigger:before {
        width: 14.375em;
        left: 50%;
        margin-left: -7.1875em; }
    .sponsors__content {
      padding: 2.75em 1.25em;
      margin: 0 0.5em;
      border-top: 0; }
    .sponsors .ico-plus-circle {
      width: 100%; } }

.educational {
  position: relative;
  margin: 0 auto 3.5625em;
  max-width: 60.125em;
  padding-bottom: 6.25em;
  padding-top: 10%;
  margin-bottom: 9em;
  border-bottom: 2px solid #000000; }
  .educational__left, .educational__right {
    width: 50%;
    float: left; }
  .educational__left {
    padding-right: 1.625em;
    text-align: right; }
  .educational__right {
    border-left: 1px solid #9b9b9b;
    padding-left: 1.125em;
    padding-top: 6.25em; }
  .educational__title {
    font: 3.36125em/0.78096em "GT-Walsheim-Black";
    letter-spacing: -0.04165em; }

.download-list__item {
  margin-bottom: 1.25em; }
  .download-list__item:last-child {
    margin-bottom: 0; }

.download-list__link {
  color: #005aff;
  font: 1.08em/1.13375em "roboto-medium";
  letter-spacing: -5px; }
  .download-list__link:hover {
    color: #000000; }

.download-list__ico, .download-list__text {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: normal; }

.download-list__ico {
  margin-right: 0.44118em;
  font-size: 1.96759em;
  line-height: 1.05882em; }

.download-list__text {
  width: 85%; }

@media screen and (max-width: 47.9375em) {
  .educational {
    margin-bottom: 4.0625em; }
    .educational__left, .educational__right {
      padding-left: 1.5625em;
      padding-right: 1.5625em;
      width: 100%;
      float: none; }
    .educational__left {
      text-align: center; }
    .educational__right {
      border-left: none;
      margin-top: 2.125em;
      padding-top: 0;
      overflow: hidden; }
    .educational__title {
      font: 2.23937em/1.04996em "GT-Walsheim-Black";
      letter-spacing: -0.04103em; }
  .download-list {
    max-width: 11.875em;
    float: right; }
    .download-list__item {
      margin-bottom: 3.5625em; }
    .download-list__link {
      line-height: 1.04977em; }
    .download-list__text {
      width: 72%; } }

.faq-contact {
  background: #f8f8f8;
  background: -webkit-linear-gradient(top, #f8f8f8, #d8d8d8);
  background: linear-gradient(to bottom, #f8f8f8, #d8d8d8);
  padding: 3.125em 2.4375em 11.25em; }
  .faq-contact__item {
    margin-bottom: 2.5em; }
    .faq-contact__item-big-spacing {
      margin-bottom: 5.625em; }
  .faq-contact__title, .faq-contact__title-link {
    font: 1.08em/1.20023em "roboto-black";
    margin-bottom: 1.73611em;
    color: #000000;
    letter-spacing: 0.06944em;
    text-transform: uppercase; }
  .faq-contact__btn {
    margin-top: 2.1875em; }
    .faq-contact__btn .btn__text {
      padding: 0.58333em 2.33333em;
      font: 0.75em/1.2em "roboto-bold";
      background: #e4e4e4; }
  .faq-contact__down {
    padding-top: 3.125em;
    padding-bottom: 3.125em;
    margin-bottom: 3.125em;
    border-top: 0.20833em solid #cbcbcb;
    border-bottom: 0.20833em solid #cbcbcb; }
  .faq-contact__title {
    margin-bottom: 2.89352em; }
    .faq-contact__title-link:after {
      font-size: 0.83333em;
      content: "";
      padding-left: 0.69444em;
      font-weight: bold; }
    .faq-contact__title-link:hover {
      opacity: .6; }
    .faq-contact__title--no-spacing {
      margin-bottom: 0; }
  .faq-contact__link-down {
    font: 0.9em/1.11111em "roboto-black";
    color: #007bff;
    text-transform: uppercase;
    display: inline-block;
    padding-left: 2.08333em; }
    .faq-contact__link-down:before {
      content: "";
      margin-left: -2.08333em;
      padding-right: 1.04167em;
      display: inline-block; }
    .faq-contact__link-down:hover {
      text-decoration: underline; }
  .faq-contact--visit {
    background: linear-gradient(#f8f8f8, #d8d8d8 10%, #ffffff 60%);
    height: 100%;
    margin-top: 1.75em; }

@media only screen and (min-width: 48em) {
  .contact-us-template .faq-contact {
    margin-top: 2.1875em; } }

@media screen and (max-width: 47.9375em) {
  .faq-contact {
    margin-top: 1.125em;
    margin-left: 0.6875em;
    margin-right: 0.6875em;
    padding-bottom: 6.5625em;
    text-align: center; }
    .faq-contact--visit {
      background: linear-gradient(#f8f8f8, #d8d8d8 80%);
      padding-bottom: 12.8125em;
      margin-bottom: -12.5em;
      margin-top: 0; }
    .faq-contact__desc {
      color: #4a4a4a; }
    .faq-contact__btn {
      margin-top: 1em; }
  .list-call {
    margin-bottom: 3.375em; }
    .list-call__tell {
      color: #4a4a4a; } }

.search {
  margin: 0 auto;
  padding: 0 6.875em;
  max-width: 74em;
  position: relative;
  z-index: 1; }
  .search .form__control--event {
    font-size: 3.225em;
    font-family: "GT-Walsheim-Black";
    letter-spacing: -0.03004em;
    padding-right: 2.22868em;
    color: #9b9b9b; }
  .search .form__label-event {
    width: 32%; }
  .search .custom-input {
    width: 68%; }

.search__form {
  margin: 0 auto 4.1875em;
  position: relative;
  letter-spacing: -0.3125em;
  width: 100%; }
  .search__form .icon-close {
    display: block;
    width: 1.875em;
    height: 1.875em; }

.custom-input {
  display: inline-block;
  position: relative;
  margin-left: -0.125em;
  width: 100%;
  vertical-align: bottom; }
  .custom-input .form__submit-event {
    position: absolute;
    top: 50%;
    right: 0;
    border: none;
    padding: 0;
    background-color: #ffffff;
    cursor: pointer;
    transform: translateY(-50%); }
    .custom-input .form__submit-event .icon-search {
      display: block;
      width: 3.75em;
      height: 3.75em; }

@media screen and (max-width: 64em) {
  .search .form__label-event {
    width: 35%; }
  .search .custom-input {
    width: 65%; } }

@media screen and (max-width: 47.9375em) {
  .search {
    padding: 0 1.1875em; }
    .search .form__label-event {
      width: 100%; }
    .search .custom-input {
      width: 100%; }
  .search__form {
    margin-bottom: 2.5em;
    padding: 0 0.6875em;
    width: 100%; }
  .custom-input {
    display: block;
    margin-top: 6.375em;
    margin-left: 0;
    max-width: none; }
    .custom-input .form__submit-event {
      top: auto;
      bottom: 0;
      right: 0;
      transform: none; } }

@-webkit-keyframes changeicon {
  0% {
    transform: translate(-50%, -50%); }
  30% {
    transform: translate(-50%, -100%);
    opacity: 0; }
  66% {
    transform: translate(-50%, 100%);
    opacity: 0; }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1; } }

@keyframes changeicon {
  0% {
    transform: translate(-50%, -50%); }
  30% {
    transform: translate(-50%, -100%);
    opacity: 0; }
  66% {
    transform: translate(-50%, 100%);
    opacity: 0; }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1; } }

.time-of-other {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6.25em;
  max-width: 51.375em;
  padding: 0 0.6875em; }
  .time-of-other__heading {
    padding: 0 1.25em 3.125em 15.625em; }
  .time-of-other__sidebar, .time-of-other__right {
    float: left; }
  .time-of-other__sidebar {
    margin-right: 0.75em;
    border-top: 1px solid #9b9b9b;
    width: 14.125em; }
  .time-of-other__right {
    margin-left: 0.6875em;
    border-top: 1px solid #9b9b9b;
    width: 34.375em; }
    .time-of-other__right.rte--time {
      padding-top: 1.375em; }
      .time-of-other__right.rte--time p {
        padding-top: 0;
        margin-bottom: 1.75em; }
    .time-of-other__right .rte--artists p {
      margin-bottom: 0; }
  .time-of-other__title {
    color: #000000;
    font-family: "GT-Walsheim-Black";
    font-size: 3.225em;
    letter-spacing: 0;
    line-height: 1.2em; }
  .time-of-other__artists {
    margin-top: 1.625em; }
    .time-of-other__artists .time-of-other__show-artists {
      display: block;
      position: relative;
      padding-top: 0.62681em;
      padding-bottom: 0.62681em;
      padding-right: 1.83221em;
      color: #000000;
      font: 1.29625em/1.19961em "GT-Walsheim-Black"; }
      .time-of-other__artists .time-of-other__show-artists:hover {
        text-decoration: none; }
      .time-of-other__artists .time-of-other__show-artists::after {
        position: absolute;
        top: 50%;
        right: 0.15625em;
        font-size: 1.44648em;
        transform: translateY(-50%); }
        .ie .time-of-other__artists .time-of-other__show-artists::after {
          font-size: 0.64655em; }
    .time-of-other__artists .opened .time-of-other__show-artists {
      color: #ff00ae; }
      .time-of-other__artists .opened .time-of-other__show-artists::after {
        font-size: 1.54291em; }
        .ie .time-of-other__artists .opened .time-of-other__show-artists::after {
          font-size: 0.43103em; }
  .time-of-other__artists-content.opened {
    padding-bottom: 1.875em; }
    .time-of-other__artists-content.opened .time-of-other__show-artists {
      color: #ff00ae; }
      .time-of-other__artists-content.opened .time-of-other__show-artists::after {
        content: "";
        right: 0.33751em;
        font-size: 1.2054em; }

.list-tagline {
  display: inline-block;
  background-color: #fff200;
  letter-spacing: -5px; }

.tagline-item {
  position: relative;
  padding: 0 1.83333em 0 1.75em;
  color: #000000;
  font: 0.75em/1.5em "roboto-light";
  letter-spacing: 0.05833em;
  text-decoration: none;
  display: inline-block; }
  .tagline-item:first-child {
    padding-right: 2.08333em; }
  .tagline-item + .tagline-item::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: 1em;
    background-color: #000000;
    transform: translateY(-50%); }
  .tagline-item--active {
    font-family: "roboto-bold";
    letter-spacing: 0.1em;
    line-height: 1.2em; }

.detail-item {
  border-bottom: 1px solid #9b9b9b;
  padding-top: 1.625em;
  padding-bottom: 1.625em;
  text-align: right; }
  .detail-item__content, .detail-item__link {
    display: block;
    position: relative;
    padding-left: 0.3125em;
    padding-right: 2.5em; }
  .detail-item__link {
    color: #000000;
    text-decoration: none; }
  .detail-item__text {
    display: block;
    padding-right: 0.83333em;
    color: #000000;
    font-family: "roboto-bold";
    font-size: 0.9em;
    letter-spacing: 0;
    line-height: 1.2em; }
    .detail-item__text--light {
      color: #4c4c4c;
      font-family: "roboto-light";
      line-height: 1.5em; }
  .detail-item__ico {
    position: absolute;
    top: 50%;
    right: 0.5em;
    transform: translateY(-50%); }
    .detail-item__ico::before {
      font-family: "sam";
      font-size: 1.9375em; }
  .detail-item--calendar .detail-item__ico::before {
    content: ""; }
  .detail-item--location .detail-item__ico {
    right: 0.8125em; }
    .detail-item--location .detail-item__ico::before {
      content: ""; }
  .detail-item--clock {
    padding-bottom: 1.4375em; }
    .detail-item--clock .detail-item__ico::before {
      content: ""; }
  .detail-item--price {
    padding-top: 0.875em;
    padding-bottom: 1.125em; }
    .detail-item--price .detail-item__content {
      margin-bottom: 1em; }
    .detail-item--price .detail-item__ico:before {
      content: ""; }
    .detail-item--price .btn--cta {
      width: 100%; }
      .detail-item--price .btn--cta .btn__text {
        padding: 0.86806em;
        font-family: "roboto-bold";
        background-color: #ffffff; }
  .detail-item--book {
    padding-top: 0;
    padding-bottom: 0; }
    .detail-item--book .detail-item__link {
      padding-top: 0.9375em;
      padding-bottom: 1.0625em; }
      .detail-item--book .detail-item__link:hover {
        background-color: #000000;
        color: #9b9b9b; }
        .detail-item--book .detail-item__link:hover .detail-item__text {
          color: #ffffff; }
    .detail-item--book .detail-item__text {
      display: inline-block;
      margin: 0 auto;
      width: 8.88889em; }
    .detail-item--book .detail-item__ico::before {
      content: ""; }
  .detail-item--audience, .detail-item--globe {
    padding-top: 0;
    padding-bottom: 0; }
    .detail-item--audience .detail-item__link, .detail-item--globe .detail-item__link {
      padding-top: 1.625em;
      padding-bottom: 1.625em; }
      .detail-item--audience .detail-item__link:hover, .detail-item--globe .detail-item__link:hover {
        background-color: #000000;
        color: #9b9b9b;
        transition: all .3s; }
        .detail-item--audience .detail-item__link:hover .detail-item__text, .detail-item--globe .detail-item__link:hover .detail-item__text {
          color: #ffffff; }
  .detail-item--globe .detail-item__ico::before {
    content: ""; }
  .detail-item--audience .detail-item__ico::before {
    content: ""; }

.list-social {
  display: block;
  border-top: 1px solid #9b9b9b;
  border-bottom: 1px solid #9b9b9b; }
  .list-social .social-item {
    display: inline-block;
    padding: 0.75em 1.75em 0.375em 0.3125em; }
    .list-social .social-item:before {
      color: #ffffff; }
    .list-social .social-item--facebook {
      padding-right: 1.4375em; }
  .list-social .social_link {
    position: relative;
    display: inline-block;
    width: 1.5625em;
    height: 1.5625em;
    color: #000000;
    background-color: #ffffff;
    text-align: center;
    text-decoration: none;
    transform: translateZ(0); }
    .list-social .social_link .ico-facebook {
      font-size: 1.25em; }
    .list-social .social_link .ico-email-black {
      font-size: 0.9375em; }
    .list-social .social_link:hover .ico-twitter {
      color: #00baff; }
    .list-social .social_link:hover .ico-facebook {
      color: #3C5898; }
    .list-social .social_link:hover .ico-email-black {
      color: #ff00ae; }
    .list-social .social_link:hover:before {
      transition: transform .3s ease-out;
      transform: scaleY(1.01); }
    .list-social .social_link .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: color .2s ease-out .1s; }

@media only screen and (min-width: 48em) {
  .time-of-other__heading {
    display: inline-block;
    vertical-align: top;
    text-align: center; }
  .time-of-other__title {
    text-align: left; } }

@media screen and (max-width: 47.9375em) {
  .time-of-other__heading {
    padding: 3.125em 0 2.5em; }
  .time-of-other__title {
    font-size: 2.40125em;
    letter-spacing: -0.03826em;
    line-height: 0.95003em; }
  .time-of-other__sidebar, .time-of-other__right {
    width: auto;
    float: none; }
  .time-of-other__right {
    margin-left: 0; }
    .time-of-other__right > p:first-child {
      padding-top: 0.83333em; }
  .time-of-other__artists {
    margin-top: 2em; }
    .time-of-other__artists .accordion__title {
      padding-top: 1.39826em;
      padding-bottom: 1.39826em; }
  .time-of-other__sidebar {
    margin-left: -0.6875em;
    margin-right: -0.6875em;
    background-color: #000000; }
    .time-of-other__sidebar .list-detail {
      padding-left: 0.6875em;
      padding-right: 0.6875em; }
  .time-of-other .list-tagline {
    margin-top: 0.875em; }
  .time-of-other .tagline-item {
    line-height: 1.33333em; }
    .time-of-other .tagline-item--active {
      line-height: 1.16667em; }
  .list-social {
    margin-left: -0.6875em;
    margin-right: -0.6875em;
    overflow: hidden;
    text-align: center; }
    .list-social .social-item {
      padding: 0.75em 0 0.5em;
      width: 33.33333%;
      float: left; }
  .detail-item {
    text-align: left; }
    .detail-item__content, .detail-item__link {
      padding-left: 4.5em;
      padding-right: 0.3125em; }
    .detail-item__ico {
      display: inline-block;
      left: 1.25em;
      color: #9b9b9b; }
    .detail-item__text {
      color: #ffffff; }
    .detail-item--location .detail-item__ico {
      left: 1.25em; }
    .detail-item--clock {
      padding-top: 1.5625em;
      padding-bottom: 1.5625em; }
    .detail-item--book .detail-item__link {
      padding-bottom: 0.625em; }
    .detail-item--book .detail-item__text {
      line-height: 1.4; }
    .detail-item--price {
      padding-bottom: 0;
      border-bottom: none; }
      .detail-item--price .detail-item__content {
        margin-bottom: 1.25em; }
      .detail-item--price .btn--cta {
        border: none;
        width: 100%; }
    .detail-item--globe .detail-item__link {
      padding-top: 0.9375em;
      padding-bottom: 0.875em; }
    .detail-item--globe .detail-item__text {
      display: inline-block;
      width: 6.25em;
      line-height: 1.4; }
    .detail-item--audience .detail-item__link {
      padding-top: 1.5em;
      padding-bottom: 1.4375em; } }

.conversation {
  position: relative;
  margin: 0 auto 2.1875em;
  padding-top: 1.375em;
  padding-right: 23.125em;
  max-width: 50em;
  min-height: 32.125em;
  border: none; }
  .conversation__wrapper {
    position: relative;
    padding-top: 19%;
    padding-bottom: 20.8%;
    overflow: hidden;
    border: none;
    z-index: 0;
    clear: left; }
    .conversation__wrapper .before-down,
    .conversation__wrapper .after-down {
      position: absolute;
      top: 0;
      height: 100%;
      background-color: #ffffff; }
    .conversation__wrapper .before-down {
      left: -1%;
      padding-right: 1px;
      width: 37.1%;
      transform: skewY(13.5deg);
      transform-origin: 100% 0; }
    .conversation__wrapper .after-down {
      left: 36%;
      transform: skewY(-13.5deg);
      transform-origin: 0 0;
      width: 65%; }
    .desktop .conversation__wrapper .container:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      transform-origin: 100% 0;
      transition: transform .9s ease .7s;
      z-index: 1; }
    .desktop .conversation__wrapper.animation .container::before {
      transform: scaleX(0); }
  .conversation__triangle {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    width: 100%;
    height: 100%;
    pointer-events: none; }
    .conversation__triangle .before-up,
    .conversation__triangle .after-up {
      position: absolute;
      top: 0;
      height: 100%;
      background-color: #ffffff; }
    .conversation__triangle .before-up {
      left: -1%;
      padding-right: 1px;
      width: 65.1%;
      transform: skewY(-13.5deg);
      transform-origin: 100% 0; }
    .conversation__triangle .after-up {
      left: 64%;
      transform: skewY(13.5deg);
      transform-origin: 0 0;
      width: 37%; }
  .conversation__slider {
    position: absolute;
    top: 3.5625em;
    right: 0.1875em;
    max-width: 19.125em;
    width: 100%; }
    .conversation__slider .slick-arrow {
      position: absolute;
      bottom: -0.04348em;
      right: 0.21739em;
      border: none;
      padding: 0;
      width: 1.08696em;
      height: 1.08696em;
      background-color: transparent;
      color: #ffffff;
      cursor: pointer;
      font-size: 1.4375em;
      outline: 0; }
    .conversation__slider .slick-prev {
      right: 1.82609em; }
    .conversation__slider .slick-dots {
      display: inline-block;
      margin-top: 1em;
      margin-left: -0.3125em; }
      .conversation__slider .slick-dots li {
        display: inline-block;
        margin-left: 0.3125em;
        width: 4px;
        height: 4px;
        background-color: transparent; }
        .conversation__slider .slick-dots li button {
          display: block;
          border: none;
          border-radius: 50%;
          padding: 0;
          width: 4px;
          height: 4px;
          background-color: #ffffff;
          outline: 0;
          text-indent: -9999px;
          color: transparent;
          line-height: 0;
          cursor: pointer; }
      .conversation__slider .slick-dots .slick-active {
        width: 6px;
        height: 6px;
        background-color: transparent; }
        .conversation__slider .slick-dots .slick-active button {
          width: 6px;
          height: 6px;
          background-color: #00e6ff; }
  .conversation__title {
    color: #ffffff;
    font-family: "GT-Walsheim-Black";
    font-size: 3.225em;
    letter-spacing: -0.04341em;
    line-height: 1.2em; }
  .conversation__desc, .conversation__text-quote, .conversation__author-quote {
    font-size: 0.9em;
    line-height: 1.5em; }
  .conversation__desc {
    padding-top: 0.83333em;
    padding-bottom: 3.125em;
    color: #ffffff;
    font-family: "roboto-light"; }
  .conversation__quote {
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    padding: 0.75em 2.125em 1.6875em; }
    .conversation__quote + .conversation__quote {
      border-top: 0; }
  .conversation__text-quote {
    position: relative;
    padding-top: 3.125em;
    padding-bottom: 1.66667em;
    color: #ffffff;
    font-family: "roboto-bold"; }
  .conversation__author-quote {
    color: #00e6ff;
    font-family: "roboto-light";
    font-style: italic; }
  .conversation__slider-item {
    border: 0.625em solid #ffffff;
    background-color: #ffffff; }
  .conversation__wrapper-img {
    position: relative;
    padding-bottom: 125%; }
  .conversation__img-slide {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%); }
  .conversation .user-instagram__img {
    display: inline-block; }
  .conversation__bg-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    z-index: -1; }
    .conversation__bg-slider .slick-list,
    .conversation__bg-slider .slick-track {
      height: 100%; }
    .conversation__bg-slider:after {
      background: rgba(0, 0, 0, 0.8);
      background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.8), transparent);
      background: linear-gradient(to right, rgba(0, 0, 0, 0.8), transparent);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      .edge .conversation__bg-slider:after,
      .ie .conversation__bg-slider:after {
        display: none; }
  .conversation__bg {
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: blur(25px); }
    .edge .conversation__bg,
    .ie .conversation__bg {
      background-image: none !important; }

.highlight-work__wrapper + .conversation__wrapper {
  margin-top: -11.5%; }

.heading-instagram,
.footer-instagram {
  background-color: #ffffff; }

.heading-instagram {
  padding-bottom: 0.625em; }

.footer-instagram {
  padding-top: 0.625em; }

.user-instagram {
  float: left;
  max-width: 75%; }
  .user-instagram__img {
    vertical-align: middle;
    max-width: 1.8125em;
    border-radius: 50%; }
  .user-instagram__text {
    display: inline-block;
    vertical-align: middle;
    max-width: 85%;
    padding-left: 10px;
    color: #000000;
    font: 0.75em/1.33333em "roboto-bold"; }

.follow-instagram {
  position: relative;
  margin-top: 0.33333em;
  border: 1px solid #00a3ff;
  padding: 0.25em 0.83333em 0.25em 1.66667em;
  border-radius: 0.25em;
  color: #00a3ff;
  font: 0.75em/1.33333em "roboto-light";
  float: right; }
  .follow-instagram::before {
    content: '+';
    position: absolute;
    top: 48%;
    left: 0.83333em;
    color: #00a3ff;
    transform: translateY(-48%); }

.instagram-comment,
.instagram-like {
  color: #979797;
  font: 0.75em/1.5em "roboto-bold"; }

.instagram-like {
  margin-right: 0.83333em; }

.ico-like,
.number-like,
.ico-comment,
.number-comment {
  margin-right: 0.33333em; }

.instagram-logo {
  max-width: 4.375em;
  float: right; }

.video-instagram {
  display: block; }
  .video-instagram .instagram__ico--play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.5em;
    color: #ffffff; }

@media screen and (max-width: 47.9375em) {
  .conversation {
    padding: 1.875em 0.5625em 0;
    margin-bottom: 4.625em; }
    .conversation__wrapper {
      margin-top: 5%;
      padding-top: 23%;
      padding-bottom: 33%; }
      .conversation__wrapper::before, .conversation__wrapper::after {
        top: -90%; }
      .conversation__wrapper::before {
        width: 16.25%; }
      .conversation__wrapper::after {
        width: 83.75%; }
    .conversation__triangle::before, .conversation__triangle::after {
      top: 90%; }
    .conversation__triangle::before {
      width: 81.875%; }
    .conversation__triangle::after {
      width: 18.125%; }
    .conversation__intro {
      margin-top: 17.3%;
      max-width: 100%;
      margin-right: 0; }
    .conversation__slider {
      position: relative;
      top: 0;
      left: 0;
      margin-left: 0;
      max-width: 100%; }
      .conversation__slider .slick-arrow {
        bottom: -0.24138em;
        right: 0.27586em;
        font-size: 1.8125em; }
      .conversation__slider .slick-prev {
        right: 1.86207em; }
      .conversation__slider .slick-dots {
        margin-top: 0.75em;
        margin-left: 0.4375em; }
    .conversation__title {
      font-size: 2.23937em;
      letter-spacing: -0.03265em;
      line-height: 1.04996em;
      max-width: 3.12587em; }
    .conversation__quote {
      margin-top: 1.6875em;
      margin-right: 0;
      padding-top: 1.375em;
      padding-left: 0;
      padding-right: 0; }
    .conversation__text-quote {
      padding-top: 2.22222em;
      padding-bottom: 1.66667em; }
    .conversation__desc {
      padding-top: 1.25em;
      padding-bottom: 2.70833em; }
    .conversation__author-quote {
      margin-top: 1.5em;
      margin-bottom: 0.78571em;
      font-size: 0.875em; }
  .highlight-work__wrapper + .conversation__wrapper {
    margin-top: -30%; }
  .show-desktop {
    display: none; } }

.we-are-sam {
  border: none;
  padding-left: 7em;
  padding-right: 57.03125%;
  padding-top: 22.48062%;
  padding-bottom: 22.48062%;
  background-color: #d8d8d8;
  position: relative; }
  .desktop .we-are-sam:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    transform-origin: 100% 0;
    transition: transform 1.2s ease .3s;
    z-index: 3; }
  .we-are-sam__wrapper {
    position: relative;
    border-top: 2px solid #fff;
    overflow: hidden; }
    .desktop .we-are-sam__wrapper.animation .we-are-sam::before {
      transform: scaleX(0); }
    .we-are-sam__wrapper::before, .we-are-sam__wrapper::after {
      content: '';
      position: absolute;
      top: 0;
      background: #ffffff;
      height: 24%; }
    .we-are-sam__wrapper::before {
      left: 0;
      padding-right: 1px;
      width: 42.96875%;
      transform: skewY(14deg);
      transform-origin: 100% 0;
      z-index: 1; }
    .we-are-sam__wrapper::after {
      right: 0;
      transform: skewY(-14deg);
      transform-origin: 0 0;
      width: 57.03125%; }
  .we-are-sam__triangle {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #fff;
    width: 100%;
    height: 26%;
    z-index: 1;
    pointer-events: none; }
    .we-are-sam__triangle::before, .we-are-sam__triangle::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      background: #ffffff;
      height: 100%; }
    .we-are-sam__triangle::before {
      left: 0;
      padding-right: 1px;
      width: 63.08594%;
      transform: skewY(-14deg);
      transform-origin: 100% 0; }
    .we-are-sam__triangle::after {
      right: 0;
      transform: skewY(14deg);
      transform-origin: 0 0;
      width: 36.91406%; }
  .we-are-sam__picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 57.03125%; }
    .we-are-sam__picture img {
      width: 100%; }
  .we-are-sam__detail {
    position: relative;
    margin-right: 1.375em;
    padding-top: 0.625em;
    z-index: 2; }
    .we-are-sam__detail .btn--cta {
      margin-top: 2.85714em;
      width: 16.14286em;
      font: 0.875em/1.14286em "roboto-bold";
      letter-spacing: 0.03143em; }
      .we-are-sam__detail .btn--cta .btn__text {
        background-color: #d8d8d8; }
  .we-are-sam__title {
    position: relative;
    z-index: 2;
    color: #000000;
    font: 3.36125em/1.41316em "GT-Walsheim-Black";
    letter-spacing: -0.04165em; }
  .we-are-sam__desc {
    color: #575757;
    font: 0.875em/1.28571em "roboto-light";
    letter-spacing: 0.03143em; }

@media screen and (max-width: 47.9375em) {
  .we-are-sam {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 32.55814%; }
    .we-are-sam__wrapper::before, .we-are-sam__wrapper::after {
      top: -11%; }
    .we-are-sam__wrapper::before {
      width: 71.875%;
      transform: skewY(11deg); }
    .we-are-sam__wrapper::after {
      width: 28.125%;
      transform: skewY(-13deg); }
    .we-are-sam__triangle::before, .we-are-sam__triangle::after {
      top: 60%; }
    .we-are-sam__triangle::before {
      width: 35%; }
    .we-are-sam__triangle::after {
      width: 65%; }
    .we-are-sam__picture {
      position: relative;
      margin-top: -1.8125em;
      padding-bottom: 76%;
      width: 100%;
      height: 50%;
      border-top: 2px solid #d8d8d8;
      overflow: hidden; }
      .we-are-sam__picture::before, .we-are-sam__picture::after {
        content: '';
        position: absolute;
        top: -73%;
        background: #d8d8d8;
        height: 100%; }
      .we-are-sam__picture::before {
        left: 0;
        padding-right: 1px;
        width: 19.375%;
        transform: skewY(14deg);
        transform-origin: 100% 0; }
      .we-are-sam__picture::after {
        right: 0;
        transform: skewY(-14deg);
        transform-origin: 0 0;
        width: 80.625%; }
    .we-are-sam__triangle-picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-top: 1px solid #d8d8d8;
      border-bottom: 1px solid #d8d8d8; }
      .we-are-sam__triangle-picture::before, .we-are-sam__triangle-picture::after {
        content: '';
        display: block;
        position: absolute;
        top: 78%;
        background: #d8d8d8;
        height: 100%; }
      .we-are-sam__triangle-picture::before {
        left: 0;
        padding-right: 1px;
        width: 34.375%;
        transform: skewY(-14deg);
        transform-origin: 100% 0; }
      .we-are-sam__triangle-picture::after {
        right: 0;
        transform: skewY(14deg);
        transform-origin: 0 0;
        width: 65.625%; }
    .we-are-sam__title {
      position: relative;
      padding: 0.97436em 0.51282em 0;
      font: 2.40125em/1.2em "GT-Walsheim-Black";
      letter-spacing: -0.02923em;
      z-index: 1; }
    .we-are-sam__detail {
      margin-left: 0;
      margin-right: 0;
      padding-top: 1.75em;
      padding-left: 1.25em;
      padding-right: 1.25em; }
      .we-are-sam__detail .btn--cta {
        margin-top: 2.14286em;
        width: 100%; } }

.supporters {
  margin: 0 auto;
  max-width: 50.625em;
  text-align: center; }
  .supporters__wrapper {
    margin-bottom: 8.25em;
    padding-top: 2.625em;
    padding-bottom: 3.75em;
    background-color: rgba(216, 216, 216, 0.2);
    position: relative;
    z-index: 1; }
  .supporters__title {
    margin-bottom: 2.17683em;
    color: #4a4a4a;
    font: 1.86625em/1.0499em "GT-Walsheim-Black";
    letter-spacing: 0.01273em; }

.list-logo__item {
  display: inline-block;
  margin: 0 0.625em 0.9375em;
  vertical-align: middle; }
  [data-animation='off'] .list-logo__item {
    opacity: 0;
    transform: translateY(8px); }
  .list-logo__item:nth-child(1) {
    transition: opacity 0.35s cubic-bezier(0.62, 0.02, 0.34, 1) 0.35s; }
  .list-logo__item:nth-child(2) {
    transition: opacity 0.4s cubic-bezier(0.62, 0.02, 0.34, 1) 0.4s; }
  .list-logo__item:nth-child(3) {
    transition: opacity 0.45s cubic-bezier(0.62, 0.02, 0.34, 1) 0.45s; }
  .list-logo__item:nth-child(4) {
    transition: opacity 0.5s cubic-bezier(0.62, 0.02, 0.34, 1) 0.5s; }
  .list-logo__item:nth-child(5) {
    transition: opacity 0.55s cubic-bezier(0.62, 0.02, 0.34, 1) 0.55s; }
  .list-logo__item:nth-child(6) {
    transition: opacity 0.6s cubic-bezier(0.62, 0.02, 0.34, 1) 0.6s; }
  .list-logo__item:nth-child(7) {
    transition: opacity 0.65s cubic-bezier(0.62, 0.02, 0.34, 1) 0.65s; }
  .list-logo__item:nth-child(8) {
    transition: opacity 0.7s cubic-bezier(0.62, 0.02, 0.34, 1) 0.7s; }
  .list-logo__item:nth-child(9) {
    transition: opacity 0.75s cubic-bezier(0.62, 0.02, 0.34, 1) 0.75s; }
  .list-logo__item:nth-child(10) {
    transition: opacity 0.8s cubic-bezier(0.62, 0.02, 0.34, 1) 0.8s; }
  .list-logo__item:nth-child(11) {
    transition: opacity 0.85s cubic-bezier(0.62, 0.02, 0.34, 1) 0.85s; }
  .list-logo__item:nth-child(12) {
    transition: opacity 0.9s cubic-bezier(0.62, 0.02, 0.34, 1) 0.9s; }
  .list-logo__item:nth-child(13) {
    transition: opacity 0.95s cubic-bezier(0.62, 0.02, 0.34, 1) 0.95s; }
  .list-logo__item:nth-child(14) {
    transition: opacity 1s cubic-bezier(0.62, 0.02, 0.34, 1) 1s; }
  .list-logo__item:nth-child(15) {
    transition: opacity 1.05s cubic-bezier(0.62, 0.02, 0.34, 1) 1.05s; }
  .list-logo__item:nth-child(16) {
    transition: opacity 1.1s cubic-bezier(0.62, 0.02, 0.34, 1) 1.1s; }
  .list-logo__item:nth-child(17) {
    transition: opacity 1.15s cubic-bezier(0.62, 0.02, 0.34, 1) 1.15s; }
  .list-logo__item:nth-child(18) {
    transition: opacity 1.2s cubic-bezier(0.62, 0.02, 0.34, 1) 1.2s; }
  .list-logo__item:nth-child(19) {
    transition: opacity 1.25s cubic-bezier(0.62, 0.02, 0.34, 1) 1.25s; }
  .list-logo__item:nth-child(20) {
    transition: opacity 1.3s cubic-bezier(0.62, 0.02, 0.34, 1) 1.3s; }
  .list-logo__item:nth-child(21) {
    transition: opacity 1.35s cubic-bezier(0.62, 0.02, 0.34, 1) 1.35s; }
  .list-logo__item:nth-child(22) {
    transition: opacity 1.4s cubic-bezier(0.62, 0.02, 0.34, 1) 1.4s; }
  .list-logo__item:nth-child(23) {
    transition: opacity 1.45s cubic-bezier(0.62, 0.02, 0.34, 1) 1.45s; }
  .list-logo__item:nth-child(24) {
    transition: opacity 1.5s cubic-bezier(0.62, 0.02, 0.34, 1) 1.5s; }
  .list-logo__item:nth-child(25) {
    transition: opacity 1.55s cubic-bezier(0.62, 0.02, 0.34, 1) 1.55s; }
  .list-logo__item:nth-child(26) {
    transition: opacity 1.6s cubic-bezier(0.62, 0.02, 0.34, 1) 1.6s; }
  .list-logo__item:nth-child(27) {
    transition: opacity 1.65s cubic-bezier(0.62, 0.02, 0.34, 1) 1.65s; }
  .list-logo__item:nth-child(28) {
    transition: opacity 1.7s cubic-bezier(0.62, 0.02, 0.34, 1) 1.7s; }
  .list-logo__item:nth-child(29) {
    transition: opacity 1.75s cubic-bezier(0.62, 0.02, 0.34, 1) 1.75s; }
  .list-logo__item:nth-child(30) {
    transition: opacity 1.8s cubic-bezier(0.62, 0.02, 0.34, 1) 1.8s; }

@media screen and (max-width: 47.9375em) {
  .supporters__wrapper {
    margin-bottom: 3.75em; }
  .list-logo__item {
    margin: 0 0 0.625em;
    max-width: 49%;
    padding: 0 0.3125em; } }

.learn-at-sam {
  position: relative;
  max-width: 50em;
  margin: 0 auto; }
  .learn-at-sam__content {
    padding-top: 1em;
    padding-left: 5em; }
  .learn-at-sam__title {
    color: #000000;
    font: 3.36125em/1.41316em "GT-Walsheim-Black";
    letter-spacing: -0.04165em; }
  .learn-at-sam__desc {
    color: #575757;
    font: 0.875em/1.28571em "roboto-light";
    letter-spacing: 0.03143em; }
  .learn-at-sam__more {
    color: #575757;
    font: 0.875em/1.28571em "roboto-bold";
    letter-spacing: 0.03143em; }
  .learn-at-sam__limit {
    width: 15.71429em; }

@media screen and (max-width: 48em) {
  .learn-at-sam__content {
    padding-top: 0;
    padding-left: 0; }
  .learn-at-sam__title {
    font: 1.875em/1.43333em "GT-Walsheim-Black";
    letter-spacing: -0.04167em; }
  .learn-at-sam__limit {
    width: 100%; } }

.download__wrapper {
  position: relative;
  z-index: 1; }

.download-item {
  margin-bottom: 3.125em; }

.download__title {
  font: 2.68688em/1.20028em "GT-Walsheim-Black";
  margin-bottom: 2.32612em;
  text-align: center; }

.download__desc {
  font: 1.5625em/1.04em "GT-Walsheim-Black";
  border-bottom: 0.4em solid #fff200;
  display: block;
  padding-bottom: 0.4em; }

.download .download-list__link {
  font: 0.9em/1.5em "roboto-regular"; }

.download .download-list__ico {
  margin-right: 0.30769em;
  font-size: 1.80556em;
  line-height: 1.2em;
  vertical-align: top; }

@media screen and (min-width: 48em) {
  .download__wrapper {
    max-width: 54.8em;
    margin: 0 auto;
    padding-bottom: 4.6875em; }
  .download__desc {
    width: 27.5%;
    float: left;
    text-align: right; }
  .download__detail {
    float: left;
    width: 69%;
    padding-left: 1.75em; }
  .download .download-list__text {
    width: 93%; } }

@media screen and (max-width: 47.9375em) {
  .download__wrapper {
    padding-left: 0.625em;
    padding-right: 0.625em;
    padding-top: 2.5em;
    padding-bottom: 3.125em; }
  .download__title {
    font: 2.23937em/1.04996em "GT-Walsheim-Black";
    margin-bottom: 1.39548em; }
  .download__desc {
    font-size: 1.3125em;
    margin-bottom: 0.95238em; }
  .download-item {
    margin-bottom: 2.8125em; }
  .download .download-list {
    max-width: 100%;
    float: none; }
  .download .download-list__item {
    margin-bottom: 1.875em; }
    .download .download-list__item:last-child {
      margin-bottom: 0; }
  .download .download-list__text {
    width: 85%; } }

.quarterly__title {
  font: 1.555em/1.05024em "GT-Walsheim-Black";
  margin-bottom: 0.80386em;
  padding-bottom: 0.60289em;
  border-bottom: 1px solid #000000; }

.quarterly-list {
  margin: 0 -0.6875em 4.0625em; }
  .quarterly-list__item {
    float: left;
    padding: 0 0.6875em;
    width: 25%; }

.quarterly-item__block {
  display: block;
  width: 100%;
  margin-bottom: 0.625em;
  padding-bottom: 0.4375em;
  box-shadow: 0 0.125em 0.25em 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  transition: opacity .3s ease; }
  .quarterly-item__block:hover {
    opacity: .7; }

.quarterly-item__image {
  border-bottom: #fff200 0.5em solid; }

.quarterly-item__img {
  display: block;
  width: 100%; }

.quarterly-item__intro, .quarterly-item__desc {
  display: block;
  font: 0.9em/1.5em "roboto-light";
  color: #4a4a4a;
  letter-spacing: -0.00764em;
  padding: 0 1.04167em;
  margin-top: 0.20833em;
  overflow: hidden; }

.quarterly-item__intro {
  padding-top: 0.48611em; }

.quarterly-item__desc {
  font-family: "roboto-bold"; }

@media screen and (max-width: 47.9375em) {
  .quarterly__wrapper {
    display: block;
    margin-bottom: 1.875em; }
  .quarterly__item {
    margin-bottom: 1.875em; }
    .quarterly__item:last-child {
      margin-bottom: 0; }
  .quarterly-list {
    margin: 0 -0.4375em; }
    .quarterly-list__item {
      padding: 0 0.4375em;
      width: 50%; }
  .quarterly-item__image {
    border-bottom: #fff200 0.25em solid; }
  .quarterly-item__intro, .quarterly-item__desc {
    font: 0.625em/1.5em "roboto-light";
    letter-spacing: -0.011em;
    color: #000000;
    padding: 0 1.5em;
    margin-top: 0.3em; }
  .quarterly-item__intro {
    padding-top: 0.7em; }
  .quarterly-item__desc {
    font-family: "roboto-bold"; } }

.social-share__item, .social-share__link {
  display: inline-block; }

.social-share__icon {
  color: #000000; }
  .social-share__icon:after {
    font-size: 2.375em; }
  .social-share__icon--menu:after {
    font-size: 1.4375em; }

.social-share__link:before {
  color: #000000;
  font-size: 2.375em; }

.social-share__link--menu {
  margin-left: 0.625em;
  margin-right: 0; }
  .social-share__link--menu:before {
    font-size: 1.4375em;
    color: #ffffff; }

@media only screen and (min-width: 48em) {
  .social-share {
    float: left;
    padding-left: 0.8125em; }
    .social-share__icon, .social-share__icon--menu {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      overflow: hidden;
      max-height: 0;
      transition: max-height .3s;
      height: 100%; }
      .social-share__icon:after, .social-share__icon--menu:after {
        content: "";
        font-family: "sam";
        position: absolute;
        bottom: 1px;
        left: 0;
        text-indent: -1px;
        color: #000000; }
    .social-share .ico-sam-facebook .social-share__icon:after,
    .social-share .ico-sam-facebook .social-share__icon--menu:after {
      color: #3C5898; }
    .social-share .ico-sam-twitter .social-share__icon:after,
    .social-share .ico-sam-twitter .social-share__icon--menu:after {
      color: #00a3ff; }
    .social-share .ico-sam-insta .social-share__icon:after,
    .social-share .ico-sam-insta .social-share__icon--menu:after {
      color: #ff00ae; }
    .social-share .ico-sam-youtube .social-share__icon:after,
    .social-share .ico-sam-youtube .social-share__icon--menu:after {
      color: #f10039; }
    .social-share__link {
      position: relative;
      margin-right: 2.5em; }
      .social-share__link:before {
        transition: color .3s;
        position: relative;
        z-index: 1; }
      .social-share__link:hover:before {
        color: #ffffff; }
      .social-share__link:hover .social-share__icon {
        max-height: 100%; }
    .social-share__link--menu {
      position: relative;
      display: block; }
      .social-share__link--menu:before {
        position: relative;
        z-index: 1; }
      .social-share__link--menu:hover .social-share__icon--menu {
        max-height: 100%; } }

@media screen and (max-width: 47.9375em) {
  .social-share {
    border-bottom: 2px solid #979797;
    padding-top: 1.6875em;
    padding-bottom: 1.1875em;
    text-align: center; }
    .social-share__item {
      margin-left: 1.375em;
      margin-right: 1.375em; }
      .social-share__item:first-child {
        margin-left: 0; }
      .social-share__item:last-child {
        margin-right: 0; }
    .social-share__link--menu:before {
      color: #000000;
      font-size: 2.375em; } }

.getting-there {
  display: block;
  line-height: 1; }
  .getting-there:hover .getting-there__icon:after {
    opacity: 1; }
  .getting-there__title {
    font: 2.1875em "GT-Walsheim-Black";
    color: #000000;
    display: inline-block; }
  .getting-there__icon {
    color: #000000;
    vertical-align: middle; }
    .getting-there__icon:before {
      font-family: "sam";
      content: ""; }

@media only screen and (min-width: 48em) {
  .getting-there {
    float: right; }
    .getting-there__icon {
      position: relative;
      padding-right: 0.5625em;
      font-size: 2em;
      z-index: 1; }
      .getting-there__icon:after {
        content: '';
        width: 0.28125em;
        height: 0.28125em;
        border-radius: 50%;
        display: inline-block;
        background-color: #ff00ae;
        position: absolute;
        z-index: -1;
        top: 0.34375em;
        left: 0.21875em;
        opacity: 0;
        transition: opacity .3s ease; } }

.ie .getting-there__icon:after {
  top: 0.59375em; }

@media screen and (max-width: 47.9375em) {
  .getting-there {
    text-align: center;
    margin: 0.9375em auto 1.5625em;
    padding-right: 0.625em;
    padding-left: 0.625em; }
    .getting-there__title {
      font-size: 1.9375em; }
    .getting-there__icon {
      font-size: 1.875em;
      padding-right: 0.5625em; } }

.navigation {
  text-align: center; }
  .navigation__title {
    font-family: "roboto-bold";
    font-size: 0.875em;
    line-height: 1;
    color: #000000; }
  .navigation__link {
    color: #4c4c4c;
    font-size: 0.75em;
    text-decoration: none; }
    .navigation__link:hover {
      color: #000000; }
  .navigation__list-item {
    line-height: 1.1; }

@media only screen and (min-width: 48em) {
  .navigation__item {
    display: inline-block;
    width: 13.8%;
    margin-top: 2.75em;
    text-align: left;
    vertical-align: top; }
  .navigation__list {
    padding-top: 1.1875em; } }

@media screen and (max-width: 47.9375em) {
  .navigation {
    padding-left: 1.25em;
    padding-right: 1.25em;
    padding-top: 0.625em;
    text-align: left; }
    .navigation__item {
      border-bottom: 1px solid #979797; }
      .navigation__item:last-child {
        border-bottom-style: none; }
      .navigation__item.opened .navigation__title::before {
        transform: rotate(90deg); }
    .navigation__list {
      display: none;
      padding-bottom: 0.625em; }
    .navigation__title {
      position: relative;
      padding-bottom: 0.75em;
      padding-top: 1.0625em; }
      .navigation__title:before {
        content: '';
        position: absolute;
        display: inline-block;
        top: 50%;
        right: 3px;
        margin-top: -3px;
        width: 2px;
        height: 8px;
        background-color: #000000; }
      .navigation__title:after {
        content: '';
        position: absolute;
        display: inline-block;
        top: 50%;
        right: 0;
        width: 8px;
        height: 2px;
        background-color: #000000; }
    .navigation__link {
      display: block;
      padding-top: 0.66667em;
      padding-bottom: 0.66667em; } }

.main-footer {
  position: relative; }
  .main-footer__top {
    background-color: #d8d8d8; }
  .main-footer__heading {
    border-bottom: 2px solid #979797; }
  .main-footer__bottom {
    background-color: #ffffff;
    overflow: hidden; }
  .main-footer__link-item {
    float: left; }
  .main-footer__link {
    font-size: 0.625em;
    color: #757575;
    font-family: "roboto-bold";
    line-height: 1.1;
    letter-spacing: .5px;
    text-decoration: none; }
    .main-footer__link:hover {
      color: #000000; }

.copy-right {
  font-size: 0.625em;
  color: #757575;
  font-family: "roboto-bold";
  line-height: 1.1; }

@media only screen and (min-width: 48em) {
  .main-footer__top {
    overflow: hidden;
    padding-bottom: 1.875em;
    padding-left: 1.875em;
    padding-right: 1.875em; }
  .main-footer__heading {
    overflow: hidden;
    padding-top: 0.875em;
    padding-bottom: 1.0625em; }
  .main-footer__bottom {
    padding: 0.625em 1.875em; }
    .main-footer__bottom .copy-right {
      float: left; }
  .main-footer__link-item {
    margin-left: 1em; }
  .main-footer__link-bottom {
    float: right;
    line-height: .6; }
  .main-footer__link {
    padding-left: 0.625em; }
  .social-share {
    padding-top: 0.6875em; } }

@media screen and (max-width: 47.9375em) {
  .main-footer__bottom {
    padding: 0.75em 1.25em 0.3125em; }
  .main-footer__link-bottom {
    text-align: center; }
  .main-footer__link-item {
    width: 33.33%; }
    .main-footer__link-item:first-child {
      text-align: left; }
    .main-footer__link-item:last-child {
      text-align: right; }
  .copy-right {
    padding-bottom: 1.5625em;
    border-bottom: 1px solid #757575; }
    .copy-right__text {
      display: block;
      font-family: "roboto-regular";
      padding-top: 0.625em; }
      .copy-right__text:first-child {
        padding-top: 0.75em; } }

.banner {
  position: relative; }
  .banner__inner {
    text-align: center; }
  .banner__title {
    font: 2.3em/1 "GT-Walsheim-Black";
    color: #000000;
    display: block;
    margin-bottom: 0.375em;
    animation: slideUp .9s ease .5s 1 forwards; }
    .banner__title--big {
      font-size: 2.125em;
      margin-bottom: 0.17647em; }
  .banner__link {
    text-decoration: none;
    display: inline-block;
    line-height: 1;
    position: relative;
    padding-right: 2.5em;
    text-align: right; }
    .banner__link:hover {
      opacity: .8; }
  .banner__link-block {
    position: absolute;
    text-align: center; }
  .banner__link-title {
    font: 1em/1 "roboto-bold";
    display: inline-block;
    letter-spacing: 5px;
    color: #ff8700; }
    .banner__link-title:after {
      font-size: 2.07143em;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
    .banner__link-title--video:after {
      content: ""; }
  .banner__arrow-ico {
    height: 1.8125em;
    position: absolute;
    right: -1.8125em;
    top: 50%;
    transform: translateY(-50%); }
    .ie .banner__arrow-ico {
      width: 3.625em; }
  .banner__date {
    font: 0.9em/1 "roboto-light";
    color: #ffad00;
    text-transform: uppercase;
    letter-spacing: .5px;
    padding-right: 0.3125em;
    display: block; }
  .banner__description {
    font: 1em/1.1875em "roboto-light";
    width: 23.75em;
    max-width: 95%;
    margin: 0 auto 0.625em;
    animation: slideUp .9s ease .9s 1 forwards;
    color: #4a4a4a; }
    .banner__description--small {
      font-size: 0.9em;
      line-height: 1.31944em;
      color: #4a4a4a;
      width: 26.38889em; }
  .banner__title, .banner__description {
    opacity: 0; }
  .banner__content {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 26.25em; }
  .banner__content-white .banner__title,
  .banner__content-white .banner__description {
    color: #ffffff; }
  .banner__content-white .downward .ico-arrow-down {
    color: #ffffff; }
  .banner__image {
    width: 100%; }
  .banner__desc-link {
    color: #000000;
    text-decoration: underline; }
  .banner--hero {
    padding-top: 8.4375em;
    min-height: 35.6875em; }
    .banner--hero .banner__image {
      width: auto; }
      .ie .banner--hero .banner__image {
        width: 20em; }
    .banner--hero__content {
      max-width: 31.5625em;
      padding: 1.5625em 0 0.875em;
      margin: 2.3125em auto 0; }
    .banner--hero--invert {
      background-color: #ff00ae; }
      .banner--hero--invert:before {
        background: rgba(255, 255, 255, 0.85);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.85), transparent);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.85), transparent);
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 12.5em; }
      .banner--hero--invert .banner__title,
      .banner--hero--invert .banner__description,
      .banner--hero--invert .banner__desc-link {
        color: #ffffff; }
      .banner--hero--invert .downward .ico-arrow-down {
        color: #ffffff; }
      .banner--hero--invert .downward:hover .ico-arrow-down:before, .banner--hero--invert .downward:hover .ico-arrow-down:after {
        color: #fff200; }
  .banner--landing {
    margin-bottom: 8.125em;
    max-width: 100vw;
    overflow: hidden; }
    .banner--landing .triangle-deco {
      bottom: 3em; }
  .banner--detail {
    margin-bottom: 2.25em;
    overflow: hidden; }
    .banner--detail .decotriangle--bottom {
      bottom: -21%;
      z-index: 0; }
      .banner--detail .decotriangle--bottom:before, .banner--detail .decotriangle--bottom:after {
        background-color: #ffffff; }
      .banner--detail .decotriangle--bottom:before {
        width: 59%;
        transform: skewY(-13.5deg);
        transform-origin: 100% 0; }
      .banner--detail .decotriangle--bottom:after {
        width: 41%;
        transform: skewY(10deg);
        transform-origin: 0 0; }
  .banner__link-default {
    padding-right: 0; }
    .banner__link-default:hover {
      cursor: default;
      opacity: 1; }
    .banner__link-default .banner__link-title {
      font: 1em/1.3em "GT-Walsheim-Black";
      letter-spacing: 0; }
      .banner__link-default .banner__link-title:after {
        content: ''; }
  .banner--small .decotriangle--bottom {
    bottom: -18%;
    z-index: 0; }
    .banner--small .decotriangle--bottom:before, .banner--small .decotriangle--bottom:after {
      top: -17%; }
    .banner--small .decotriangle--bottom:before {
      width: 54%; }
    .banner--small .decotriangle--bottom:after {
      width: 46%; }
  .banner__link-learn-teach .banner__link-title,
  .banner__link-learn-teach .banner__date {
    color: #085e36; }
  .banner__link-collection .banner__link-title,
  .banner__link-collection .banner__date {
    color: #5F6345; }
  .banner__link-learn-teach, .banner__link-collection {
    padding-right: 0; }
    .banner__link-learn-teach .banner__link-title, .banner__link-collection .banner__link-title {
      font: 1em/1 "GT-Walsheim-Black";
      letter-spacing: 0; }
      .banner__link-learn-teach .banner__link-title:after, .banner__link-collection .banner__link-title:after {
        content: ''; }
    .banner__link-learn-teach .banner__date, .banner__link-collection .banner__date {
      font: 0.9em/1 "roboto-light";
      letter-spacing: 0;
      text-transform: capitalize; }

@-webkit-keyframes slideUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0); }
  to {
    opacity: 1;
    transform: none; } }

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.downward {
  display: inline-block;
  width: 1.875em;
  height: 1.875em;
  text-decoration: none; }
  .downward:hover {
    cursor: pointer; }
    .downward:hover .ico-arrow-down:before, .downward:hover .ico-arrow-down:after {
      color: #ff00ae; }
  .downward .ico-arrow-down {
    position: relative;
    display: block;
    color: #000000;
    font-size: 1.875em; }
    .downward .ico-arrow-down:before, .downward .ico-arrow-down:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: arrowpointing forwards infinite 3s cubic-bezier(0.52, 0.01, 0.16, 1); }
    .downward .ico-arrow-down:after {
      content: "";
      animation-delay: 1.5s; }

.module-header--no-banner {
  padding-top: 8.4375em; }

.module-header .title {
  padding: 0 2.67442em;
  font: 2.6875em/1.2em "GT-Walsheim-Black"; }
  .module-header .title__wrapper {
    margin-bottom: 4.375em;
    text-align: center; }

@keyframes arrowpointing {
  0%,
  30% {
    transform: translateY(0);
    opacity: 1; }
  50% {
    transform: translateY(8px);
    opacity: 0; }
  85% {
    transform: translateY(-7px);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@media only screen and (min-width: 48em) {
  .banner__content {
    padding: 1.875em; }
  .banner__date {
    margin-top: 0.3125em; }
  .banner--hero {
    padding-top: 50%; }
    .banner--hero .banner__inner {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0; }
    .banner--hero .banner__image {
      height: 40%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
    .banner--hero__content {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0); } }

@media screen and (max-width: 47.9375em) {
  .module-header .title {
    padding: 0 0.55804em;
    font: 2.23937em/1.04996em "GT-Walsheim-Black"; }
    .module-header .title__wrapper {
      margin-bottom: 2.75em; }
  .banner {
    padding-top: 3.25em; }
    .banner__link-block {
      position: relative;
      left: 0 !important;
      top: 15% !important;
      width: 100%;
      padding: 0.125em 0.625em;
      transform: none !important; }
    .banner__inner {
      position: absolute;
      bottom: 5%;
      width: 100%; }
    .banner__content {
      width: 100%;
      padding: 0.3125em 0.625em 0.625em;
      position: relative; }
    .banner__title {
      font-size: 1.875em;
      margin-bottom: 0.06667em; }
    .banner__description {
      font-size: 0.9375em;
      line-height: 1.3125em;
      margin-bottom: 0.625em; }
      .banner__description--small {
        line-height: 1.11111em;
        margin-bottom: 1.52778em; }
    .banner__link-title {
      font-size: 0.6875em;
      letter-spacing: 4px;
      margin-bottom: 0.5em; }
      .banner__link-title:after {
        font-size: 2.90909em; }
    .banner__date {
      padding-bottom: 0.3125em; }
    .banner--hero {
      padding-top: 6.25em;
      margin-bottom: 0; }
      .banner--hero .banner__image {
        max-width: 80%;
        margin: 0 auto;
        display: block; }
      .banner--hero .banner__title {
        font-size: 1.555em;
        line-height: 1.05024em;
        margin-bottom: 0.60289em; }
    .banner--landing {
      margin-bottom: 1.875em; }
      .banner--landing .banner__inner {
        bottom: 0; }
      .banner--landing .banner__image {
        width: 270%;
        margin-left: -85%; }
      .banner--landing .banner__title {
        font-size: 1.5em;
        margin-bottom: 0.25em; }
      .banner--landing .banner__description {
        font-size: 0.875em;
        line-height: 1.28571em;
        margin-bottom: 1.14286em; }
      .banner--landing .triangle-deco {
        bottom: 0.375em;
        top: auto; }
    .banner--detail {
      margin-bottom: 0.9375em;
      padding-top: 3.1875em; }
      .banner--detail .decotriangle--bottom {
        bottom: -38%; }
        .banner--detail .decotriangle--bottom:before {
          width: 44%; }
        .banner--detail .decotriangle--bottom:after {
          width: 56%;
          transform: skewY(14deg); }
    .banner--small .decotriangle--bottom {
      bottom: -14%; }
      .banner--small .decotriangle--bottom:before {
        width: 63%; }
      .banner--small .decotriangle--bottom:after {
        width: 37%;
        transform: skewY(10deg); }
    .banner--learn-teach .banner__link-block {
      position: relative; }
    .banner--learn-teach .banner__link {
      text-align: center; }
    .banner--learn-teach .banner__content {
      position: relative; } }

.newsletter .control-label {
  display: none; }

.newsletter-inner {
  background-color: #ff00ae;
  position: relative; }
  .newsletter-inner:before, .newsletter-inner:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    background: #ff00ae;
    height: 100%;
    z-index: -1; }
  .newsletter-inner:before {
    left: 0;
    transform: skewY(14deg);
    transform-origin: 100% 0;
    width: 42.57813%; }
  .newsletter-inner:after {
    right: 0;
    transform: skewY(-14deg);
    transform-origin: 0 0;
    width: 57.42188%; }

.newsletter__description {
  font: 0.9375em/1.13333em "roboto-medium";
  color: #ffffff;
  margin-bottom: 2.33333em;
  max-width: 21em; }

.newsletter .page-header > span {
  font: 2.1875em "GT-Walsheim-Black";
  color: #ffffff;
  display: inline-block;
  margin-bottom: 0.22857em; }

.newsletter .text-box {
  font: 0.875em "roboto-medium";
  color: #ffffff;
  letter-spacing: .5px;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  padding: 1.42857em 1.85714em;
  display: block; }
  .newsletter .text-box::-webkit-input-placeholder {
    color: #a40070;
    letter-spacing: .5px; }
  .newsletter .text-box::-moz-placeholder {
    color: #a40070;
    letter-spacing: .5px; }
  .newsletter .text-box:-ms-input-placeholder {
    color: #a40070;
    letter-spacing: .5px; }
  .newsletter .text-box:focus {
    outline-color: transparent; }

.newsletter .label-input,
.newsletter .control-label,
.newsletter .validation-summary-errors,
.newsletter .field-validation-error {
  display: none; }

.newsletter .form-group {
  clear: none; }

.newsletter .input-validation-error {
  background-color: #fff200;
  color: #0b0b0b; }

.newsletter .sam-Newsletter_CheckboxList {
  border: 0.125em solid #ffffff;
  border-top: none; }
  .newsletter .sam-Newsletter_CheckboxList .control-label {
    font: 0.875em "roboto-medium";
    color: #ffffff;
    display: inline-block;
    letter-spacing: .5px; }
    .newsletter .sam-Newsletter_CheckboxList .control-label:before {
      color: #ffffff; }
  .newsletter .sam-Newsletter_CheckboxList .checkbox {
    display: inline-block;
    position: relative;
    padding: 0 0 0 1.5625em; }
    .newsletter .sam-Newsletter_CheckboxList .checkbox:after {
      display: none; }
    .newsletter .sam-Newsletter_CheckboxList .checkbox input {
      position: absolute;
      left: -9999px; }
      .newsletter .sam-Newsletter_CheckboxList .checkbox input:not(:checked) + label:after {
        opacity: 0; }
      .newsletter .sam-Newsletter_CheckboxList .checkbox input:checked + label:before {
        background-color: #ffffff; }
      .newsletter .sam-Newsletter_CheckboxList .checkbox input:checked + label:after {
        opacity: 1; }
    .newsletter .sam-Newsletter_CheckboxList .checkbox label {
      cursor: pointer;
      font: 0.875em "roboto-regular";
      color: #ffffff; }
      .newsletter .sam-Newsletter_CheckboxList .checkbox label:focus {
        outline: none; }
      .newsletter .sam-Newsletter_CheckboxList .checkbox label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0.78571em;
        height: 0.78571em;
        border: 0.14286em solid #ffffff;
        background-color: transparent;
        border-radius: 0.21429em; }
      .newsletter .sam-Newsletter_CheckboxList .checkbox label:after {
        content: "";
        position: absolute;
        top: 54%;
        width: 1em;
        transform: translateY(-50%);
        left: 0;
        font-size: 1em;
        font-weight: bold;
        line-height: .8;
        color: #ff00ae;
        transition: all .2s;
        margin-top: -0.0625em; }

.newsletter .field-border {
  float: left;
  border: 0.125em solid #ffffff;
  border-right: none;
  padding: 0.125em; }

.newsletter .form-submit-border {
  position: relative;
  z-index: 1;
  border: 0.125em solid #ffffff;
  overflow: hidden;
  border-top-width: 0;
  cursor: pointer; }
  .newsletter .form-submit-border .btn {
    font: 1em "roboto-bold";
    color: #ffffff;
    text-transform: uppercase;
    width: 100%;
    border: none;
    border-color: #ffffff;
    background-color: transparent;
    cursor: pointer;
    padding: 0.9375em 1.625em;
    z-index: 2;
    height: 100%;
    transition: background-color .3s ease, color .3s ease; }
    .newsletter .form-submit-border .btn:hover {
      background-color: #ffffff;
      color: #ff00ae; }

.newsletter .field-error {
  display: none; }
  .newsletter .field-error span {
    display: none; }

.newsletter .note {
  float: right;
  margin-top: 0.9375em;
  font: 0.84375em "roboto-light";
  color: #ffffff;
  opacity: .8;
  letter-spacing: .5px; }

.newsletter .note-error {
  color: #fff200; }
  .newsletter .note-error:before {
    content: '';
    background: url("../img/icon-error.png") no-repeat;
    background-size: auto 100%;
    display: inline-block;
    width: 1.5em;
    height: 1.125em;
    padding-right: 0.3125em; }

.newsletter .newletter__success-message {
  color: #ff00ae;
  font: 1em "roboto-bold";
  display: inline-block;
  padding: 3.4375em 1.5625em; }

.newsletter .newletter__content-success {
  background-color: rgba(0, 0, 0, 0.5); }
  .newsletter .newletter__content-success .form-submit-border {
    cursor: default; }
    .newsletter .newletter__content-success .form-submit-border:hover .btn {
      animation: none; }
  .newsletter .newletter__content-success .btn {
    background-color: #ffffff;
    color: #ff00ae;
    cursor: default;
    pointer-events: none; }

.newsletter--personalize .newsletter-inner {
  padding: 2.5625em 7em 4.5625em; }

.newsletter--personalize .newsletter__description {
  max-width: inherit;
  font-size: 0.9em;
  line-height: 1.45833em; }

.newsletter--personalize .newsletter__button {
  text-align: right; }

.newsletter--personalize .btn {
  border: 0.3125em solid #ffffff;
  color: #ffffff;
  width: 100%;
  max-width: 14em;
  padding: 1.0625em;
  text-align: center; }

.newsletter--personalize .btn__text {
  background: transparent;
  padding: 0;
  font-family: "roboto-bold";
  display: inline-block; }

.newsletter--personalize .page-header {
  margin-bottom: 1.4375em; }
  .newsletter--personalize .page-header span {
    font-size: 2.0625em;
    margin-bottom: 0; }

.ie .newsletter .form-submit-border .btn {
  padding-top: 1.19375em; }

.newsletter-inner .scWebEditInput {
  display: none; }

.newsletter-inner .sam-Newsletter_CheckboxList .scWebEditInput {
  display: inline-block;
  color: #ffffff; }

@media only screen and (min-width: 48em) {
  .newsletter {
    position: relative;
    z-index: 1;
    padding-top: 14.25781%; }
    .newsletter-inner {
      padding: 0 2.5em 0.9375em; }
    .newsletter .sam-Newsletter_CheckboxList {
      width: 76%;
      float: left;
      height: 3.875em;
      padding: 1.125em 0.3125em 1.25em 1.625em;
      border-right: none; }
      .newsletter .sam-Newsletter_CheckboxList .checkbox {
        margin-left: 1.875em;
        margin-top: 0.0625em; }
    .newsletter .form-submit-border {
      width: 24%;
      float: left;
      height: 3.875em; }
    .newsletter .field-border {
      width: 27.5%; }
      .newsletter .field-border.sam-Newsletter_Email {
        border-right: 0.125em solid #ffffff;
        width: 45%; }
    .newsletter .newletter__content-success {
      position: relative; }
      .newsletter .newletter__content-success .form-submit-border {
        position: absolute;
        bottom: 0;
        right: 0;
        border: 0; }
    .newsletter--personalize .newsletter__button {
      margin-left: -0.3125em; } }

@media screen and (max-width: 47.9375em) {
  .newsletter {
    position: relative;
    z-index: 1;
    padding-top: 18.4375%; }
    .newsletter-inner {
      padding: 2.5em 1.0625em 0.9375em; }
      .newsletter-inner:before {
        width: 25.9375%; }
      .newsletter-inner:after {
        width: 74.0625%; }
    .newsletter__description {
      text-align: center;
      margin: 0 auto 2.33333em; }
    .newsletter .page-header {
      margin: 0 auto;
      text-align: center; }
    .newsletter .sam-Newsletter_CheckboxList {
      width: 100%;
      padding: 0.625em;
      clear: both; }
      .newsletter .sam-Newsletter_CheckboxList .checkbox {
        display: block;
        margin-bottom: 0.625em; }
      .newsletter .sam-Newsletter_CheckboxList .control-label {
        margin-bottom: 0.9375em; }
    .newsletter .text-box {
      padding: 0.71429em; }
    .newsletter .form-submit-border {
      width: 100%;
      margin-bottom: 0.9375em; }
      .newsletter .form-submit-border .btn {
        padding: 0.625em; }
    .newsletter .field-border {
      width: 50%;
      border-right: 0.125em solid #ffffff; }
    .newsletter .scfSingleLineTextBorder {
      border-right: none; }
    .newsletter .sam-Newsletter_Email {
      width: 100%;
      border-top: none; }
    .newsletter .note {
      margin-top: 0; }
    .newsletter .newletter__content-success {
      margin-bottom: 0.625em; }
      .newsletter .newletter__content-success .form-submit-border {
        margin-bottom: 0; }
    .newsletter .newletter__success-message {
      padding: 1.875em 1.9375em;
      min-height: 16.4375em;
      line-height: 1.25em; }
    .newsletter--personalize .newsletter-inner {
      padding: 2.5em 1.0625em 0.9375em; }
    .newsletter--personalize .newsletter__button {
      text-align: center; }
    .newsletter--personalize .page-header {
      margin-bottom: 0.5em; } }

.contact-title {
  font: 2.1875em "GT-Walsheim-Black";
  color: #000000;
  margin-bottom: 2.57143em; }

.contact-us {
  margin-bottom: 6.4375em; }
  .contact-us .text-box {
    font-size: 0.875em;
    border: none;
    width: 100%;
    padding-top: 0.71429em;
    padding-bottom: 0.71429em;
    border-bottom: 1px solid #9b9b9b;
    outline: none;
    z-index: 2;
    background-color: transparent;
    transition: all .3s; }
    .contact-us .text-box:focus {
      border-color: #007bff; }
      .contact-us .text-box:focus ~ label {
        transform: translateY(-50%); }
  .contact-us .label-input {
    font: 0.8125em "roboto-light";
    color: #979797;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transition: transform .5s; }
  .contact-us .input-validation-error {
    border-color: #e20039; }
  .contact-us .control-label {
    font: 0.875em "roboto-medium";
    color: #979797;
    display: none; }
  .contact-us .radio {
    float: left;
    padding-right: 0.625em; }
    .contact-us .radio input {
      position: absolute;
      left: -9999px; }
      .contact-us .radio input:not(:checked) ~ label:after {
        opacity: 0; }
      .contact-us .radio input:checked ~ label {
        color: #4a4a4a; }
        .contact-us .radio input:checked ~ label:after {
          opacity: 1; }
    .contact-us .radio label {
      font: 0.75em "roboto-light";
      cursor: pointer;
      color: #979797;
      padding-left: 1.5625em;
      position: relative;
      display: block; }
      .contact-us .radio label:focus {
        outline: none; }
      .contact-us .radio label:before, .contact-us .radio label:after {
        position: absolute; }
      .contact-us .radio label:before {
        content: "";
        font-size: 1em;
        color: #979797;
        top: 0.14286em;
        left: 0; }
      .contact-us .radio label:after {
        content: "";
        font-size: 1em;
        top: 0.14286em;
        left: 0;
        color: #000000;
        line-height: 1.02; }
  .contact-us .form-group {
    clear: none;
    width: 50%;
    z-index: 2;
    position: relative;
    margin-bottom: 1.42857em; }
    .contact-us .form-group.sam-singleline-half_Label {
      padding-right: 0.75em;
      float: left; }
      .contact-us .form-group.sam-singleline-half_Label ~ .sam-singleline-half_Label {
        padding-left: 0.75em;
        padding-right: 0; }
        .contact-us .form-group.sam-singleline-half_Label ~ .sam-singleline-half_Label .label-input {
          left: 0.92308em; }
    .contact-us .form-group.sam-Contact_Email {
      width: 100%;
      clear: left; }
  .contact-us .sam-multiline_Label {
    width: 100%; }
    .contact-us .sam-multiline_Label .control-label {
      display: none !important; }
  .contact-us .contac-us_message {
    position: relative;
    border: 1px solid #9b9b9b; }
    .contact-us .contac-us_message.input-validation-error {
      border: 1px solid #e20039; }
    .contact-us .contac-us_message.custom-textarea__focus {
      border-color: #007bff; }
  .contact-us .has-error .contac-us_message {
    border-color: #e20039; }
    .contact-us .has-error .contac-us_message.custom-textarea__focus {
      border-color: #007bff; }
  .contact-us .form-control {
    font-family: "roboto-light";
    font-size: 0.9375em;
    width: 100%;
    resize: none;
    padding: 2em 1.33333em 1em;
    border: none; }
    .contact-us .form-control:focus {
      outline: none; }
  .contact-us .sum-character {
    font: 0.875em/1 "roboto-light";
    color: #979797;
    position: absolute;
    bottom: 0.71429em;
    left: 1.42857em;
    display: none; }
  .contact-us .label-multiline {
    font: 0.875em/1 "roboto-light";
    color: #979797;
    position: absolute;
    top: 0.71429em;
    left: 1.42857em; }
  .contact-us__required {
    font: 0.875em/1 "roboto-light";
    color: #4c4c4c;
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: 1.57143em; }
    .contact-us__required.has-error {
      color: #ff0000; }
  .contact-us__note {
    font: 0.75em/1.41667em "roboto-light";
    color: #9b9b9b;
    font-style: italic; }
  .contact-us__bold {
    font-family: "roboto-bold";
    padding-right: 0.25em; }
  .contact-us .btn--cta {
    display: inline-block;
    padding-bottom: 0.0625em; }
  .contact-us .button-success__btn {
    font: 1.125em "roboto-bold";
    min-width: 17.22222em;
    padding: 0;
    margin: 1.38889em auto 0; }
    .contact-us .button-success__btn.btn--cta .btn__text {
      padding-top: 0.5787em;
      padding-bottom: 0.86806em; }
  .contact-us .form-submit-border .btn {
    min-width: 12.84722em;
    border: none;
    padding: 0.75em; }
  .contact-us .validation-summary-errors,
  .contact-us .field-validation-error,
  .contact-us .field-error {
    display: none; }
  .contact-us .g-recaptcha ~ .field-validation-error {
    display: block !important;
    font: 0.75em "roboto-light";
    color: #ff0000; }
  .contact-us .bg-warning {
    display: none; }
  .contact-us .sam-Contact_RadioList {
    width: 100%; }

.input-has-value .label-input {
  transform: translateY(-50%); }

.input-has-value .sum-character {
  display: block; }

.sam-Contact_RadioList {
  clear: both;
  margin-bottom: 1.5625em; }

.page-header > span {
  font: 2.1875em "GT-Walsheim-Black";
  color: #000000;
  display: inline-block;
  margin-bottom: 0.5em; }

.contact-success {
  clear: both; }
  .contact-success strong {
    font: 1.14286em "roboto-bold";
    color: #757575;
    display: inline-block;
    margin-bottom: 0.3125em; }
  .contact-success p {
    font: 0.875em "roboto-light";
    color: #979797;
    max-width: 20.35714em; }

@media only screen and (min-width: 48em) {
  .contact-us-template .col-offset-1 {
    padding-right: 2.6875em;
    padding-left: 3.875em; }
  .contact-title {
    font-size: 2.375em;
    line-height: 1.36842em;
    letter-spacing: 0.07895em;
    margin-bottom: 2.47368em; }
  .contact-us .control-label {
    margin-bottom: 2.17857em;
    display: none; }
  .contact-us .radio {
    width: 33.33333%;
    margin-bottom: 0.71429em; }
  .contact-us .form-control {
    margin-top: 2.14286em;
    padding-top: 0;
    padding-bottom: 2.93333em;
    height: 7em; }
  .contact-us .contac-us_message {
    margin-bottom: 1.75em;
    min-height: 11.73333em; }
  .contact-us__note {
    float: right;
    max-width: 22.91667em; }
  .contact-us .button-success {
    min-width: 17.11111em; } }

@media screen and (max-width: 47.9375em) {
  .contact-title {
    padding-left: 0.27778em; }
  .contact-us {
    margin-bottom: 0;
    padding-right: 0.625em;
    padding-left: 0.625em; }
    .contact-us .radio {
      width: 50%;
      margin-bottom: 1.07143em; }
    .contact-us .control-label {
      margin-bottom: 1.42857em; }
    .contact-us .form-control {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0.625em;
      height: 3.875em; }
    .contact-us .contac-us_message {
      min-height: 7.8125em;
      margin-bottom: 1.25em;
      padding-top: 1.5625em;
      padding-bottom: 1.875em; }
    .contact-us .btn--cta {
      display: block;
      margin: 1.25em auto;
      max-width: 14.25em; }
    .contact-us__note {
      text-align: center;
      padding-right: 1.66667em;
      padding-left: 1.66667em; }
    .contact-us__required {
      margin-bottom: 1.07143em; }
    .contact-us .label-multiline {
      top: 1.42857em; }
    .contact-us .field-border.sam-singleline-half_Label {
      padding-right: 0.375em; } }

.popup-highlight {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  overflow-y: auto;
  z-index: 100;
  display: none; }
  .popup-highlight__title {
    font: 1.555em/1.05024em "GT-Walsheim-Black";
    letter-spacing: -0.05908em;
    color: #ffffff; }
  .popup-highlight__close {
    color: #ffffff;
    cursor: pointer; }
    .popup-highlight__close:before {
      content: ""; }
  .popup-highlight__text-close {
    color: transparent;
    font-size: 0.0625em; }
  .popup-highlight--banner .popup-highlight__inner {
    padding: 8.125em 2.5em 2.5em;
    width: 100%;
    max-width: 78.75em; }
  .popup-highlight--banner .popup-highlight__close {
    position: absolute;
    top: 2.5em;
    right: 0.38462em;
    font-size: 1.625em; }
  .popup-highlight--banner .slider-highlight__item {
    min-height: auto; }

.slider-highlight {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 0.5em; }
  .slider-highlight__item {
    position: relative;
    margin-bottom: 0.625em; }
  .slider-highlight__img-holder {
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden; }
    .slider-highlight__img-holder .slider-highlight__image {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%); }
  .slider-highlight__image {
    width: 100%; }
  .slider-highlight__video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
    overflow: hidden; }
    .slider-highlight__video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .slider-highlight__number {
    font: 0.875em "roboto-bold";
    color: #ffffff;
    display: inline-block;
    margin-top: 0.71429em; }
  .slider-highlight__next, .slider-highlight__prev {
    font-size: 1.875em;
    float: right;
    border: 0;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    cursor: pointer; }
  .slider-highlight__next:before {
    content: ""; }
  .slider-highlight__prev:before {
    content: ""; }

.highlight-info__name {
  font: 1.3125em "GT-Walsheim-Black";
  color: #757575;
  display: inline-block; }

.highlight-info__title {
  font: 1.8125em/1.125em "GT-Walsheim-Black";
  color: #ffffff;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 0.51724em; }

.highlight-info__infor {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 1.25em;
  padding-top: 0.625em;
  padding-bottom: 0.8125em; }

.highlight-info__detail-desc {
  font: 0.875em/1.78571em "roboto-light";
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 1.25em; }

.highlight__infor--modified .highlight__infor__text {
  font: 0.875em/1.28571em "roboto-medium";
  color: #fff200;
  letter-spacing: .5px;
  padding: 0;
  border: none; }

@media only screen and (min-width: 48em) {
  .popup-highlight__title {
    position: absolute;
    top: 3.73794em;
    left: 2.17042em; }
  .popup-highlight__close {
    position: absolute;
    top: 3.03846em;
    right: 1.42308em;
    font-size: 1.4375em; }
  .popup-highlight__inner {
    padding: 8.75em 3.4375em 6.25em;
    position: relative;
    max-width: 87.5em;
    margin: 0 auto; }
  .popup-highlight__wrapper {
    display: flex; }
  .slider-highlight {
    width: 67%; }
    .slider-highlight__prev {
      padding: 0.0625em 0;
      margin-right: 0.76667em; }
    .slider-highlight__next {
      padding: 0.0625em; }
    .slider-highlight__number {
      padding-left: 0.71429em; }
  .highlight-info {
    width: 33%;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    margin-left: 2.5em;
    padding-top: 0.4375em; }
    .highlight-info__detail-desc {
      max-height: 14.14286em;
      overflow-x: auto; }
    .highlight-info__item--revert .highlight-info__detail-desc {
      margin-top: 1.25em; }
  .highlight-work__wrapper--revert .sub-title {
    max-width: 25em; } }

@media screen and (max-width: 47.9375em) {
  .popup-highlight {
    overflow-y: auto; }
    .popup-highlight__inner {
      padding-top: 1.25em;
      padding-bottom: 1.25em; }
    .popup-highlight__wrapper {
      clear: both; }
    .popup-highlight__title {
      display: block;
      width: 100%;
      margin-bottom: 13px;
      padding-left: 1.20579em; }
    .popup-highlight__close {
      font-size: 1.375em;
      text-align: right;
      display: block;
      width: 100%;
      padding-bottom: 0.40909em;
      padding-right: 0.90909em; }
    .popup-highlight--banner .popup-highlight__inner {
      padding: 4.1875em 1.25em 1.25em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 38.125em; }
    .popup-highlight--banner .popup-highlight__close {
      top: 1.65217em;
      font-size: 1.4375em;
      padding: 0; }
  .slider-highlight {
    width: 100%;
    text-align: center; }
    .slider-highlight__item {
      margin-bottom: 0.3125em; }
    .slider-highlight__prev {
      float: left;
      padding-left: 1em; }
    .slider-highlight__next {
      padding-right: 1em; }
    .slider-highlight__number {
      font-size: 0.75em;
      vertical-align: middle; }
  .highlight-info {
    padding: 0.875em 1.25em 0 0.625em; }
    .highlight-info__infor {
      padding: 1.07143em 0 1.42857em 1.42857em; }
    .highlight-info__title {
      padding-left: 0.68966em; }
    .highlight-info__name {
      padding-left: 0.95238em;
      margin-bottom: 0.33333em; }
    .highlight-info__detail-desc {
      padding-left: 1.42857em;
      padding-right: 0.71429em; } }

.category-infor {
  background-color: #f5f5f5; }

.infor__title {
  font: 0.9375em "roboto-bold";
  background-color: #eaeaea;
  display: block; }

.infor__text--small {
  font-size: 0.69307em; }

.infor__text .infor__link--light,
.infor__text .infor__text-bold {
  font-size: 1em; }

.infor__heading {
  font: 0.9em "roboto-bold";
  color: #4a4a4a;
  margin-bottom: 1.38889em; }
  .infor__heading--no-space {
    margin-bottom: 0; }

.infor__link {
  font: 0.9em "roboto-bold";
  color: #007bff;
  display: inline-block; }
  .infor__link--light {
    font-size: 0.9em;
    font-family: "roboto-regular"; }

.infor__item {
  padding-left: 0; }
  .infor__item + .infor__item {
    border-top: 0.0625em solid #979797;
    padding-top: 1.25em;
    margin-top: 1.25em; }
  .infor__item .infor__btn {
    margin: 0; }
  .infor__item .infor__group {
    padding-left: 0.97222em; }

.infor__group .infor__link--light {
  margin-top: 0; }

.download-list__link--small {
  display: block;
  margin-bottom: 1.25em; }
  .download-list__link--small .download-list__ico {
    font-size: 1.4375em;
    top: inherit;
    bottom: 0; }
  .download-list__link--small .download-list__text {
    font: 0.85em "roboto-bold"; }

@media only screen and (min-width: 48em) {
  .infor__inner {
    padding: 3.125em 3.75em 1.875em; }
    .infor__inner--small-top {
      padding-top: 1.875em;
      padding-bottom: 3.125em; }
  .infor__title {
    padding: 0.26667em 4em; }
  .infor__link {
    margin-top: 1.04167em; }
    .infor__link--light {
      margin-top: 0; }
  .infor__list-half {
    column-count: 2;
    column-gap: 1.25em; }
    .infor__list-half li {
      margin-bottom: 1.25em; }
  .wrapper-cta {
    padding: 1.375em 5.125em 1.875em; }
    .wrapper-cta .cta--full {
      margin-top: 0; } }

@media screen and (max-width: 47.9375em) {
  .infor__title {
    font-size: 0.875em;
    padding: 0.71429em 0.71429em 0.64286em 0.57143em; }
  .infor__inner {
    padding: 1.25em 0.5em; }
  .infor__link {
    margin-top: 4.64286em; }
  .infor__list-half li {
    margin-bottom: 1.25em; }
    .infor__list-half li:last-child {
      margin-bottom: 0; }
  .wrapper-cta .cta--full {
    margin-top: 0; }
  .download-list__link--small {
    display: block;
    margin-bottom: 1.8125em;
    margin-top: 1.3125em;
    padding-left: 0.6875em; }
    .download-list__link--small .download-list__ico {
      font-size: 1.5em;
      margin-right: 0.75em; }
    .download-list__link--small .download-list__text {
      font: 0.85em "roboto-bold"; } }

.infor__btn {
  margin-top: 1.55556em;
  margin-bottom: 0.83333em;
  min-width: 12.5em; }

.infor__list {
  margin-top: 1.375em;
  max-width: 25.5em; }

.rte .infor__btn:hover {
  text-decoration: none; }

@media screen and (max-width: 47.9375em) {
  .infor__list {
    padding: 0.3125em; }
  .wrapper-btn {
    padding-left: 0.625em;
    padding-right: 0.625em; } }

.detail__desc-item {
  border-top: 0.0625em solid #9b9b9b;
  padding: 1.0625em 0 0;
  font-family: "roboto-light"; }
  .detail__desc-item:last-child {
    border-bottom: 0.0625em solid #9b9b9b; }
  .detail__desc-item .btn--cta {
    width: 43%;
    margin-right: 1.25em;
    margin-bottom: 1.1875em; }

.detail__title {
  font: 1.2625em/1.18812em "GT-Walsheim-Black";
  border-bottom: 0.49505em solid #fff200;
  display: block;
  padding-bottom: 0.49505em; }

.detail__text-main {
  font-size: 0.90625em;
  font-family: "roboto-light";
  line-height: 1.51724em;
  margin-bottom: 1.17241em; }

.faq-item {
  margin-bottom: 3.125em; }
  .faq-item__desc {
    border-top: 0.0625em solid #9b9b9b; }
    .faq-item__desc:last-child {
      border-bottom: 0.0625em solid #9b9b9b; }

.faq__content {
  position: relative;
  display: none;
  background-color: #f3f3f3;
  padding: 0.9375em 1.25em 3.125em; }

.faq__title {
  font: 1.5625em/1.04em "GT-Walsheim-Black";
  border-bottom: 0.4em solid #fff200;
  display: block;
  padding-bottom: 0.4em; }

.faq__title-item {
  font: 1.0625em "roboto-light";
  padding: 0.47059em 0; }
  .faq__title-item {
    cursor: pointer; }

.faq__close {
  color: #ffffff;
  cursor: pointer;
  background-color: #000000;
  padding: 0.4375em 0.4375em 0.25em;
  position: absolute;
  bottom: 0;
  right: 0; }
  .faq__close:before {
    content: "";
    font-size: 1.25em; }

@media screen and (min-width: 48em) {
  .detail-block {
    padding: 0 11.875em 9.375em; }
  .detail__title {
    width: 34.93789%;
    float: left;
    text-align: right; }
  .detail__desc {
    width: 65.06211%;
    float: left;
    padding-left: 1.5625em; }
  .faq-wrapper {
    padding: 0 6.25em 8.125em 11.5625em; }
  .faq__title {
    width: 31.25%;
    clear: both;
    margin-bottom: -0.4em;
    text-align: right; }
  .faq__list {
    float: right;
    width: 68.75%;
    padding-left: 1.4375em; } }

@media screen and (max-width: 47.9375em) {
  .detail-block {
    position: relative;
    z-index: 1;
    padding: 0 0.625em 4.0625em; }
  .detail__desc-item {
    padding-top: 1.5625em;
    padding-bottom: 0.5em; }
    .detail__desc-item .btn--cta {
      width: 100%;
      margin-right: 0; }
  .faq-wrapper {
    padding-left: 0.625em;
    padding-right: 0.625em;
    padding-top: 2.5em; }
  .faq__title {
    font-size: 1.3125em; }
  .faq-item {
    margin-bottom: 2.8125em; } }

.top-navigation {
  margin: 0 auto 3.125em;
  padding: 0 1.9375em;
  text-align: right;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }
  .top-navigation__list, .top-navigation__logo {
    display: inline-block;
    vertical-align: middle; }
  .top-navigation__list {
    padding: 1.125em 0; }
    .top-navigation__list__item {
      float: left;
      padding: 0 2.1875em; }
    .top-navigation__list__link {
      font-size: 0.875em;
      line-height: 1.28571em;
      color: #9b9b9b;
      letter-spacing: 1.4px;
      transition: color .5s;
      position: relative;
      display: inline-block; }
      .top-navigation__list__link:before, .top-navigation__list__link:after {
        content: '';
        display: inline-block;
        height: 2px;
        position: absolute;
        bottom: -4px;
        z-index: 1;
        left: auto;
        right: 0;
        width: 0; }
      .top-navigation__list__link:before {
        background: #000000;
        transition: all 0.25s cubic-bezier(0.694, 0.048, 0.335, 1) 0.15s; }
      .top-navigation__list__link:after {
        background: #fef200;
        transition: all 0.25s cubic-bezier(0.694, 0.048, 0.335, 1); }
      .top-navigation__list__link:hover {
        color: #000000; }
        .top-navigation__list__link:hover:before, .top-navigation__list__link:hover:after {
          width: 100%;
          left: 0; }
  .top-navigation__logo a {
    display: block;
    width: 7em; }

.top-navigation--light .top-navigation__list__link {
  font-size: 0.75em; }

@media screen and (max-width: 47.9375em) {
  .top-navigation {
    display: none; } }

.explore {
  position: relative;
  margin: 7em auto 9em;
  text-align: center;
  z-index: 1; }
  .explore__title {
    font: 3.36125em/1.41316em "GT-Walsheim-Black";
    letter-spacing: -0.04165em; }
  .explore__list {
    margin: 3.75em auto 0; }
  .explore__item {
    position: relative;
    width: 25%;
    float: left;
    text-align: center; }
    .explore__item:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: #9b9b9b; }
    .explore__item:nth-child(1) .explore__link {
      padding-left: 0; }
    .explore__item:last-child:after {
      display: none; }
  .explore__ico {
    position: absolute;
    height: 0.93771em;
    width: 1.87542em;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
    .explore__ico circle,
    .explore__ico path {
      stroke-width: 2;
      stroke: #9b9b9b; }

.explore__link {
  display: inline-block;
  position: relative;
  padding: 0.25117em 2.17683em 0.25117em 0.26792em;
  color: #9b9b9b;
  font: 1.86625em/1.00469em "GT-Walsheim-Black";
  letter-spacing: -0.07502em;
  text-decoration: none; }
  .explore__link:hover {
    text-decoration: none; }

@media (max-width: 47.9375em) {
  .explore {
    margin-bottom: 2.5em;
    padding-bottom: 5.25em;
    border-bottom: 1px solid #000000; }
    .explore__title {
      font: 2.23875em/1.20045em "GT-Walsheim-Black";
      letter-spacing: -0.06253em; }
    .explore__list {
      margin-top: 3.9375em;
      margin-left: 0;
      margin-right: 0;
      padding: 0 1.1875em; }
    .explore__item {
      display: block;
      border-right: none;
      border-top: 1px solid #000000;
      width: auto;
      float: none;
      text-align: right; }
      .explore__item:after {
        display: none; }
      .explore__item:last-child {
        border-bottom: 1px solid #000000; }
      .explore__item .explore__ico-circle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
  .explore__link {
    position: relative;
    color: #757575;
    padding: 0.5627em 1.6881em 0.52251em 0.5627em;
    font: 1.555em/1.05024em "GT-Walsheim-Black";
    display: block; }
  .explore__ico {
    right: -0.60289em; } }

.highlight-work {
  position: relative;
  padding-top: 4.3125em;
  margin-top: 6.7%; }
  .highlight-work:before, .highlight-work:after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 90%;
    background-color: #f3f3f3;
    z-index: -1; }
  .highlight-work:before {
    left: 0;
    padding-right: 1px;
    transform: skewY(-13.5deg);
    background: linear-gradient(-166.5deg, #f3f3f3 0%, #f3f3f3 23.75em); }
  .highlight-work:after {
    right: 0;
    transform: skewY(13.5deg);
    background: linear-gradient(166.5deg, #f3f3f3 0%, #f3f3f3 23.75em); }
  .highlight-work > .triangle-deco {
    background: #412768;
    background: -webkit-linear-gradient(left, #412768, #4a318d);
    background: linear-gradient(to right, #412768, #4a318d); }
    .highlight-work > .triangle-deco.triangle-deco--wrapper {
      z-index: -2; }
  .highlight-work:before, .highlight-work:after {
    height: 80%; }
  .highlight-work .decotriangle--bottom {
    bottom: -6.25em; }
    .highlight-work .decotriangle--bottom:before, .highlight-work .decotriangle--bottom:after {
      background: #f3f3f3; }
  .highlight-work__wrapper {
    position: relative;
    z-index: 1; }
    .highlight-work__wrapper .title__wrapper {
      margin-bottom: 5em; }
    .highlight-work__wrapper--revert {
      z-index: 10; }
  .highlight-work .btn--cta {
    margin: 8em auto 0;
    width: 13.75em;
    text-transform: uppercase;
    z-index: 3; }
    .highlight-work .btn--cta .btn__text {
      padding-top: 0.81019em;
      padding-bottom: 0.81019em;
      background-color: #f3f3f3; }
  .highlight-work__revert-1::after {
    height: 60%; }
  .highlight-work__revert-2::after {
    height: 70%; }
  .highlight-work--revert:before, .highlight-work--revert:after {
    content: none; }
  .highlight-work__wrapper--revert .title__wrapper {
    margin-bottom: 0; }
  .highlight-work__opened {
    z-index: 2; }

.highlighted-work--reset .row:first-child .col-6,
.highlighted-work--reset .row:first-child .col-4 {
  background: #f3f3f3; }

.highlight__name {
  display: block;
  padding-bottom: 0.55556em;
  margin-bottom: 1.38889em;
  font-size: 0.9em;
  font-family: "roboto-bold";
  color: #4a4a4a;
  letter-spacing: 0.12639em;
  border-bottom: 1px solid #9b9b9b; }

.highlight__title {
  font-family: "GT-Walsheim-Black";
  font-size: 1.555em;
  line-height: 1.04502em;
  color: #000000;
  max-width: 10.04823em;
  margin-bottom: 0.68328em; }

.highlight__infor {
  border-top: 1px solid #9b9b9b; }
  .highlight__infor__text {
    padding: 0.06944em 0;
    font-size: 0.9em;
    color: #4a4a4a;
    font-family: "roboto-light";
    border-bottom: 1px solid #9b9b9b; }

.ie .highlight__svg {
  height: 22.1875em; }

.highlight__image {
  position: relative;
  display: block; }

.highlight__ico-slideshow {
  position: absolute;
  color: #ffffff;
  right: 0.625em;
  bottom: 3.125em;
  font-size: 1.5em; }

.highlight__ico--play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.25em;
  height: 3.25em;
  display: block; }
  .highlight__ico--play:after {
    content: "";
    font-family: "sam";
    font-size: 3.5em;
    font-weight: normal;
    color: #ffffff; }

.highlight__overlay {
  opacity: .4;
  background-color: #000000; }

.highlight__desc-revert {
  padding-left: 0.8em; }
  .highlight__desc-revert .highlight__name {
    text-transform: uppercase;
    letter-spacing: 0.07407em;
    line-height: 1.11111em;
    padding-bottom: 1.03704em;
    margin-bottom: 0.74074em; }
  .highlight__desc-revert .highlight__infor {
    border: none; }
  .highlight__desc-revert .highlight__infor__text {
    border: none; }

@media screen and (min-width: 48em) {
  .highlight__desc-revert {
    margin-top: -3.33333em; }
  .highlight-work {
    padding-top: 5.5em; }
    .highlight-work .row:not(:first-child) {
      margin-top: -2.6875em; }
    .highlight-work--revert {
      padding-top: 0;
      margin-top: 4em; }
      .highlight-work--revert .row--revert .col-4,
      .highlight-work--revert .row--revert .highlight__desc-revert {
        padding-left: 0.25em; }
      .highlight-work--revert .row--revert .col-4 {
        padding-right: 0.6875em; }
  .container--flexbox {
    padding-bottom: 5.625em; }
    .container--flexbox .row {
      display: flex;
      align-items: center; }
      .container--flexbox .row + .container--flexbox .row {
        margin-top: -2.3125em; }
      .container--flexbox .row--revert {
        text-align: right;
        flex-direction: row-reverse; }
        .container--flexbox .row--revert .col-6 {
          margin-right: 8.33333%; }
        .container--flexbox .row--revert .highlight__title {
          max-width: 100%; } }

@media screen and (max-width: 47.9375em) {
  .highlight-work__wrapper {
    margin-bottom: 5.1875em;
    padding-bottom: 0; }
    .highlight-work__wrapper--no-spacing {
      margin-bottom: 0; }
    .highlight-work__wrapper .title__wrapper {
      margin: auto; }
    .highlight-work__wrapper .title {
      padding-left: 0.625em;
      padding-right: 0.625em; }
    .highlight-work__wrapper .sub-title {
      padding-left: 0.9375em;
      padding-right: 0.9375em; }
  .highlight-work .btn--cta {
    margin-top: 0; }
  .highlight-work {
    margin-top: 14.7%;
    padding-top: 0;
    padding-bottom: 5.625em; }
    .highlight-work .decotriangle--bottom {
      top: 50%; }
  .highlight__ico-slideshow {
    top: 80%;
    transform: translateY(-50%);
    bottom: 0; }
  .highlight__desc-revert {
    padding-left: 0; }
  .container--flexbox .row {
    padding-bottom: 1.875em;
    padding-top: 0;
    overflow: hidden;
    position: relative; }
    .container--flexbox .row + .container--flexbox .row:before {
      content: '';
      position: absolute;
      top: 0;
      height: 2px;
      left: -0.6875em;
      right: -0.6875em;
      background-color: #757575;
      z-index: 1; }
    .container--flexbox .row--reset-align {
      text-align: center; } }

.adminssion-wrapper {
  margin-bottom: 9.375em;
  position: relative;
  z-index: 1; }

.adminssion__heading {
  font-size: 3.225em;
  font-family: "GT-Walsheim-Black";
  letter-spacing: -0.02752em;
  text-align: center;
  color: #000000;
  margin-bottom: 0.2907em; }

.adminssion__title {
  font-size: 1.1425em;
  margin-bottom: 1.09409em;
  font-family: "roboto-bold";
  color: #4a4a4a;
  letter-spacing: 0.03501em; }

.adminssion__desc {
  margin-bottom: 1.25em;
  min-height: 7.5em; }
  .adminssion__desc .adminssion__text {
    font-size: 0.9em;
    line-height: 1.45833em;
    letter-spacing: 0.03056em;
    font-family: "roboto-light";
    color: #4a4a4a; }

.adminssion .btn--cta {
  width: 100%;
  font-size: 1.08em;
  letter-spacing: -0.01319em; }
  .adminssion .btn--cta .btn__text {
    padding: 0.69444em; }

@media screen and (min-width: 48em) {
  .adminssion {
    position: relative;
    display: table;
    max-width: 50em;
    margin: 0 auto; }
    .adminssion .adminssion__item {
      display: table-cell;
      vertical-align: top;
      width: 50%;
      padding-right: 5.75em;
      text-align: right; }
      .adminssion .adminssion__item + .adminssion__item {
        padding-right: 0;
        padding-left: 5.9375em;
        text-align: left;
        border-left: 1px solid #979797; }
  .adminssion--one-column .adminssion__desc {
    min-height: auto; }
  .adminssion--one-column .adminssion__item {
    width: 100%;
    padding: 0;
    text-align: center; } }

@media screen and (max-width: 47.9375em) {
  .adminssion-wrapper {
    padding: 0 0.5em;
    margin-bottom: 3.6875em;
    border-bottom: 0.0625em solid #4a4a4a; }
  .adminssion__heading {
    font-size: 2.40125em;
    margin-bottom: 0.72879em;
    letter-spacing: -0.03644em;
    text-align: left; }
  .adminssion .adminssion__item {
    margin-bottom: 2.75em; }
    .adminssion .adminssion__item__title {
      letter-spacing: 0;
      margin-bottom: 0.76586em; }
    .adminssion .adminssion__item__desc .adminssion__text {
      line-height: 1.52778em; }
  .adminssion__desc {
    margin-bottom: 1.625em;
    min-height: 100%; }
  .adminssion .btn--cta {
    font-size: 1.125em; }
    .adminssion .btn--cta .btn__text {
      padding: 0.61111em; } }

.banner--category {
  position: relative; }
  .banner--category .banner__title {
    font-size: 2.3em;
    letter-spacing: -0.01359em;
    margin-bottom: 0.54348em; }
  .banner--category .banner__content {
    max-width: 26.66667em; }
  .banner--category .banner__description {
    position: relative;
    font: 1em/1.375em "roboto-bold";
    letter-spacing: -0.00625em;
    color: #4a4a4a;
    padding-bottom: 0.5625em; }
    .banner--category .banner__description:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 0.125em;
      max-width: 16.125em;
      width: 100%;
      background-color: #a5a5a5; }
  .banner--category .banner__text {
    font: 0.9em/1.4em "roboto-light";
    letter-spacing: -0.00694em;
    color: #4a4a4a;
    margin-bottom: 1.04167em;
    opacity: 0;
    animation: slideUp .9s ease 1.2s 1 forwards; }

@media screen and (max-width: 47.9375em) {
  .banner--category .banner__content {
    position: relative;
    max-width: 17.8125em; }
  .banner--category .banner__title {
    font-size: 1.555em;
    letter-spacing: -0.04703em;
    margin-bottom: 0.80386em; }
  .banner--category .banner__description {
    font: 0.9em/1.4em "roboto-bold";
    margin-bottom: 0.69444em;
    padding-bottom: 1.11111em; }
    .banner--category .banner__description:before {
      max-width: 18.05556em; }
  .banner--category .banner__text {
    font: 0.75em/1.4em "roboto-light";
    margin-bottom: 2.2em; } }

.cta {
  position: relative;
  display: table;
  background-color: #000000;
  padding: 0.5625em 3.75em 0.625em 1.25em; }
  .cta__text {
    font: 0.9em/2.43056em "roboto-bold";
    letter-spacing: 0.03472em;
    display: table-cell;
    vertical-align: middle;
    color: #ffffff; }
  .cta__ico {
    width: 4em;
    height: 2em;
    position: absolute;
    right: -0.6875em;
    top: 50%;
    margin-top: -1em; }
    .cta__ico circle,
    .cta__ico path {
      stroke: #ffffff;
      stroke-width: 1.5; }
  .cta__icon {
    font-size: 1.875em;
    text-align: right;
    transition: transform .5s;
    color: #ffffff; }
  .cta .cta__icon {
    position: absolute;
    right: 0.5em;
    top: 50%;
    margin-top: -0.5em; }
  .cta:hover .cta__icon {
    transform: translateX(0.3125em); }
  .cta--full {
    margin-top: 4.375em;
    width: 100%; }
  .cta--spacing {
    margin-bottom: 1.25em; }
  .cta.cta--highlight {
    background: #aaff00; }
    .cta.cta--highlight .cta__text {
      color: #000000; }
    .cta.cta--highlight .cta__icon {
      color: #000000; }

@media screen and (max-width: 47.9375em) {
  .cta {
    padding: 0.5625em 3.75em 0.625em 0.625em; }
    .cta--full {
      margin-top: 2.375em; } }

.dine__infor {
  margin-top: 0.6875em; }
  .dine__infor .dine__title {
    font-size: 0.9em;
    font-family: "roboto-bold";
    color: #4a4a4a;
    letter-spacing: -0.00694em;
    margin-bottom: 1.18056em;
    padding-bottom: 0.55556em;
    border-bottom: 0.27778em solid #9b9b9b; }
  .dine__infor .infor__link {
    font-size: 0.9em;
    font-family: "roboto-bold";
    color: #4a4a4a;
    margin-top: 0; }

@media screen and (min-width: 48em) {
  .dine-wrapper {
    margin-left: -1.875em;
    margin-right: -1.875em;
    overflow: hidden; }
  .dine__item {
    float: left;
    padding: 0 1.875em;
    width: 33.33333%; }
  .dine--one-column {
    margin-left: -1.875em;
    margin-right: -1.875em;
    overflow: hidden; }
    .dine--one-column .dine__img {
      max-width: 33.33333%;
      padding: 0 1.875em;
      float: left; }
    .dine--one-column .dine__title {
      margin-bottom: 1.875em;
      max-width: 50%; }
    .dine--one-column .dine__infor {
      padding-top: 0.375em;
      padding-left: 1.25em;
      padding-right: 6.25em;
      margin-top: 0;
      width: 66.66667%;
      float: left; }
      .dine--one-column .dine__infor .infor__link {
        color: #000000; } }

@media screen and (max-width: 47.9375em) {
  .dine {
    padding: 0 2.3125em;
    max-width: 18.75em;
    margin-left: auto;
    margin-right: auto; }
    .dine-wrapper {
      margin-bottom: 0; }
      .dine-wrapper .slick-arrow {
        font-size: 1.5625em;
        position: absolute;
        top: 4em;
        border: 0;
        background: transparent;
        color: #9b9b9b; }
      .dine-wrapper .slick-prev {
        left: 0.28em; }
      .dine-wrapper .slick-next {
        right: 0.28em; }
      .dine-wrapper .slick-dots {
        overflow: hidden;
        text-align: center;
        margin-top: 0.3125em; }
        .dine-wrapper .slick-dots li {
          position: relative;
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: transparent;
          border-radius: 50%; }
          .dine-wrapper .slick-dots li:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 4px;
            height: 4px;
            background-color: #9b9b9b;
            border-radius: 50%;
            transform: translate(-50%, -50%); }
          .dine-wrapper .slick-dots li.slick-active {
            background-color: #ff00ae;
            margin: 0 0.125em; }
            .dine-wrapper .slick-dots li.slick-active:before {
              background-color: #ff00ae; }
        .dine-wrapper .slick-dots button {
          display: none; }
    .dine__infor {
      margin-top: 0.875em; }
      .dine__infor .dine__title {
        font-size: 0.875em;
        margin-bottom: 0.42857em; }
      .dine__infor .infor__link {
        margin-top: 0; } }

.search-result .title__wrapper .title {
  font-size: 2.68688em;
  line-height: 1.04676em;
  margin-bottom: 0.20935em; }

.search-result .title__wrapper .sub-title {
  font: 1.08em/1.27315em "roboto-light";
  margin-top: 0; }

.search-result .sub-title {
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; }

.search-result__form {
  text-align: center;
  position: relative;
  max-width: 39.75em;
  margin: 4.375em auto 5.75em; }
  .search-result__form .custom-input {
    max-width: 100%; }
  .search-result__form .form__label-result,
  .search-result__form .form__control--event {
    font-size: 3.225em;
    font-family: "GT-Walsheim-Black";
    letter-spacing: -0.03004em; }
  .search-result__form .form__control--event {
    padding: 0.05814em 2.71318em 0.05814em 0.3876em;
    color: #000000; }
    .search-result__form .form__control--event::-moz-placeholder {
      color: #9b9b9b;
      opacity: .51; }
    .search-result__form .form__control--event::-webkit-input-placeholder {
      color: #9b9b9b;
      opacity: .51; }
    .search-result__form .form__control--event:-ms-input-placeholder {
      color: #9b9b9b;
      opacity: .51; }
    .search-result__form .form__control--event::-ms-input-placeholder {
      color: #9b9b9b;
      opacity: .51; }
    .search-result__form .form__control--event::-ms-clear {
      display: none; }
    .search-result__form .form__control--event.outfocus {
      color: #9b9b9b;
      opacity: .51; }
  .search-result__form .form__submit-result {
    position: absolute;
    top: 50%;
    right: 0.75em;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    transform: translateY(-50%); }
    .search-result__form .form__submit-result .icon-search {
      width: 3.75em;
      height: 3.75em;
      display: block; }
  .search-result__form .btn__close {
    padding: 0.625em;
    position: absolute;
    right: 4.6875em;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    cursor: pointer; }
    .search-result__form .btn__close .icon-search {
      width: 1.875em;
      height: 1.875em;
      display: block; }

.result__item {
  margin-bottom: 2.1875em; }

.result__title {
  font: 1.08em/1.15741em "GT-Walsheim-Black";
  margin-bottom: 0.46296em;
  max-width: 16.78241em; }
  .result__title .result__link {
    color: #000000;
    transition: color .3s; }
    .result__title .result__link:hover {
      color: #005aff; }

.result__text {
  font: 0.9em/1.11111em "roboto-light";
  color: #4a4a4a; }

.result__link-more {
  font: 0.9em/1.11111em "roboto-bold";
  color: #005aff; }

.result__empty {
  font: 1.08em/1.15741em "GT-Walsheim-Black";
  margin-bottom: 0.46296em; }

.result__show-page {
  text-align: center;
  margin: 1.33333em 0; }
  .result__show-page .show__text,
  .result__show-page .show__link {
    font: 0.9em/1.11111em "roboto-light";
    padding: 0 0.27778em; }
  .result__show-page .show__text {
    display: inline-block;
    color: #000000; }
  .result__show-page .show__link {
    color: #a4a4a4;
    transition: color .4s; }
    .result__show-page .show__link:hover, .result__show-page .show__link.active {
      color: #000000; }
  .result__show-page .show__list {
    display: inline-block; }
    .result__show-page .show__list .show__item {
      display: inline-block;
      vertical-align: midle; }

.result__pagination {
  border-top: 1px solid #9b9b9b;
  border-bottom: 1px solid #9b9b9b;
  padding: 0.1875em 0 0.0625em;
  text-align: center;
  max-width: 49.875em;
  margin: auto auto 1.33333em; }
  .result__pagination .pagination__button,
  .result__pagination .pagination__list {
    display: inline-block;
    vertical-align: middle; }
  .result__pagination .pagination__button {
    font-size: 1.875em;
    line-height: 1em;
    color: #000000;
    padding: 0 0.33333em; }
    .result__pagination .pagination__button:hover {
      color: #000000;
      transition: color .4s; }
    .result__pagination .pagination__button.disabled, .result__pagination .pagination__button.disabled:hover {
      color: #a4a4a4;
      cursor: default; }
  .result__pagination .pagination__list .pagination__link,
  .result__pagination .pagination__list .pagination__ellipsis {
    font: 1.08em/1.15741em "roboto-bold";
    color: #a4a4a4; }
  .result__pagination .pagination__list .pagination__item {
    display: inline-block;
    padding: 0 0.625em; }
    .result__pagination .pagination__list .pagination__item.active .pagination__link, .result__pagination .pagination__list .pagination__item:hover .pagination__link {
      color: #000000;
      transition: color .4s; }

.search__form .btn__close,
.form-search .btn__close {
  padding: 0.625em;
  position: absolute;
  right: 3.4375em;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  cursor: pointer; }
  .search__form .btn__close-revert,
  .form-search .btn__close-revert {
    top: 53%; }
  .search__form .btn__close .icon-close,
  .form-search .btn__close .icon-close {
    width: 1.875em;
    height: 1.875em;
    display: block; }

.form__control--event::-ms-clear {
  display: none; }

@media screen and (min-width: 48em) {
  .search-result {
    margin-bottom: 8.66667em; }
  .form-search .form__control--event {
    padding-right: 1.74419em; }
  .form-search .btn__close {
    right: 3.125em; }
  .result__list {
    overflow: hidden;
    max-width: 49.875em;
    margin: 0 auto; }
    .result__list .list__item:last-child .result__item {
      float: right;
      display: block;
      width: 75%; }
    .result__list .button__wrapper {
      display: none; }
  .result__title {
    max-width: 16.78241em; }
  .result__desc {
    max-width: 18.125em; }
  .search-result__form {
    max-width: 49.875em; } }

@media screen and (max-width: 47.9375em) {
  .search-result {
    padding: 0 0.625em; }
    .search-result .title__wrapper .title {
      font-size: 2.23937em;
      letter-spacing: -0.04103em;
      margin-bottom: 0.33491em; }
    .search-result .title__wrapper .text {
      line-height: 1.44676em; }
  .result__list {
    margin-bottom: 4.875em; }
  .result__title {
    line-height: 1.04167em;
    max-width: 100%; }
  .result__item {
    margin-bottom: 1.3125em; }
  .search-result__form {
    margin: 3.125em 0 4.375em; }
    .search-result__form .form__control--event {
      font-size: 1.555em;
      padding: 0.5627em 3.4164em 0.5627em 0.32154em; }
    .search-result__form .form__submit-result {
      height: 100%;
      right: 0; }
      .search-result__form .form__submit-result:before {
        font-size: 1.5625em; }
    .search-result__form .btn__close {
      font-size: 0.6875em; }
    .search-result__form .custom-input {
      margin-top: 0; }
  .search__form .form__control--event,
  .form-search .form__control--event {
    font-size: 1.555em;
    padding: 0.5627em 3.61736em 0.5627em 0.32154em; }
  .search__form .form__submit-result,
  .form-search .form__submit-result {
    height: 100%;
    right: 0; }
    .search__form .form__submit-result:before,
    .form-search .form__submit-result:before {
      font-size: 1.5625em; }
  .search__form .btn__close,
  .form-search .btn__close {
    font-size: 0.6875em; }
  .search__form .custom-input,
  .form-search .custom-input {
    margin-top: 0; }
  .search__form .btn__close-revert {
    right: 5.45455em; }
  .menu__search .form-search .btn__close {
    top: 41%; }
  .result__show-page,
  .result__pagination {
    display: none; } }

.form__title {
  font: 2.68688em/1.20958em "GT-Walsheim-Black";
  color: #000000;
  max-width: 6.86206em;
  margin-bottom: 1.20958em;
  margin-left: 1.04676em; }

.form__note {
  float: none; }

.form__legend {
  font-family: "roboto-bold";
  color: #4a4a4a;
  font-size: 0.9em;
  margin-bottom: 1.38889em; }

.form__text-choice {
  font-size: 0.625em;
  color: #9b9b9b;
  font-family: "roboto-bold";
  display: block;
  padding-top: 1.7em; }

.form__fieldset {
  border: 0;
  margin-bottom: 4.375em; }
  .form__fieldset--big-spacing {
    margin-bottom: 6.25em; }

.form__group--spacing-large {
  margin-bottom: 1.875em; }

.form__inner {
  padding-left: 3.0625em;
  padding-right: 1.875em;
  position: relative; }

.form__footer {
  position: relative;
  border-top: 0.125em solid #000000;
  padding-top: 4.375em;
  margin-left: 1.4375em; }

.form__group--choice .form__text-choice,
.form__group--choice .custom-input,
.form__group--choice .custom-select__styled {
  opacity: .6; }

.form__group--choice .custom-select--scroll {
  border-bottom-color: rgba(155, 155, 155, 0.6); }
  .form__group--choice .custom-select--scroll.selected {
    border-bottom-color: #9b9b9b; }

.form__group--choice.form__mandatory .form__text-choice,
.form__group--choice.form__mandatory .custom-input,
.form__group--choice.form__mandatory .custom-select__styled {
  opacity: 1; }

.form__group--choice.form__mandatory .custom-select--scroll {
  border-bottom-color: #9b9b9b; }

.form__group--choice .input-has-value {
  opacity: 1; }

.form__group--choice .selected .custom-select__styled {
  opacity: 1; }

.form__step .btn--cta .btn__text {
  text-transform: uppercase; }

.group__add-more {
  position: relative;
  padding-left: 1.875em; }
  .group__add-more .custom-input--date .custom-input__control {
    padding-bottom: 1.38889em; }

.group__add-more-modify {
  margin-bottom: 4.66667em; }

.group__title {
  position: relative;
  font-size: 0.9em;
  font-family: "roboto-bold";
  color: #9b9b9b;
  padding: 0.625em 0; }

.group__title--sub {
  font: 0.9em "roboto-regular";
  color: #9b9b9b;
  margin-bottom: 0.48611em; }
  .group__title--sub .sub__number {
    position: relative;
    float: left;
    padding-right: 0.69444em; }
    .group__title--sub .sub__number:before {
      content: '';
      position: absolute;
      top: 0.20833em;
      right: 0;
      bottom: 0;
      height: 0.83333em;
      width: 0.06944em;
      background-color: #9b9b9b; }
  .group__title--sub .sub__text {
    padding-left: 2.77778em; }

.group__add-more .selected .custom-select__styled {
  color: #4a4a4a;
  font-family: "roboto-regular"; }

.button-add-more,
.button-remove-choice {
  background: transparent;
  font-size: 0.9em;
  border: 0;
  color: #9b9b9b;
  position: relative;
  transition: color .3s;
  outline: none;
  padding: 1px;
  cursor: pointer; }
  .button-add-more:before,
  .button-remove-choice:before {
    position: absolute;
    top: 50%;
    left: 0;
    font-family: "sam";
    transform: translateY(-50%); }
  .button-add-more:hover,
  .button-remove-choice:hover {
    color: #4a4a4a; }
    .button-add-more:hover:before,
    .button-remove-choice:hover:before {
      color: #4a4a4a; }

.button-add-more {
  padding-left: 2.77778em;
  margin-top: 2.08333em;
  cursor: pointer; }
  .button-add-more:before {
    content: "";
    font-size: 0.97222em; }

.button-remove-choice {
  position: absolute;
  top: 1.45833em;
  left: 0;
  width: 1.04167em;
  height: 1.04167em;
  color: #4a4a4a; }
  .button-remove-choice:before {
    content: ""; }

.ie .button-remove-choice {
  width: 1.18056em;
  height: 1.18056em; }

.form__wrapper {
  margin-bottom: 4.375em; }
  .form__wrapper .custom-input {
    max-width: 100%;
    margin-top: 0;
    margin-left: 0; }
  .form__wrapper .btn--cta {
    min-width: 13.75em; }
  .form__wrapper .btn--small {
    min-width: auto;
    border-width: 0.0625em;
    padding-top: 0.1875em;
    padding-bottom: 0.4375em; }

.legend__number {
  color: #ffffff;
  position: relative;
  width: 1.45833em;
  margin-right: 0.69444em;
  display: inline-block;
  font-size: 0.9em;
  text-align: center; }
  .legend__number:before {
    content: '';
    border-radius: 50%;
    background-color: #4a4a4a;
    width: 1.45833em;
    height: 1.45833em;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1; }

.text-required {
  position: absolute;
  top: 1.73611em;
  right: 0;
  font-size: 0.9em;
  color: #9b9b9b;
  text-align: right; }

.tooltip {
  position: relative;
  margin-top: 20px;
  display: inline-block; }
  .tooltip:hover .tooltip__content, .tooltip:focus .tooltip__content {
    opacity: 1; }
  .tooltip__icon {
    position: relative;
    font: 0.625em/1.6em "roboto-bold";
    width: 1.6em;
    height: 1.6em;
    border-radius: 50%;
    color: #979797;
    border: 0.1em solid #979797;
    display: inline-block;
    text-align: center;
    transition: all .3s; }
    .tooltip__icon:hover, .tooltip__icon:focus {
      background-color: #4a4a4a;
      color: #ffffff;
      border-color: #4a4a4a; }
  .tooltip__content {
    font: 0.625em/1.2em "roboto-light";
    position: absolute;
    background-color: #4a4a4a;
    color: #ffffff;
    padding: 0.4em;
    text-align: center;
    min-width: 9em;
    border-radius: 0.1875em;
    transition: opacity .5s;
    opacity: 0; }
    .tooltip__content:before {
      content: '';
      position: absolute; }
  .tooltip--top .tooltip__content {
    transform: translateX(-50%);
    left: 50%;
    bottom: 25px; }
    .tooltip--top .tooltip__content:before {
      left: 50%;
      transform: translateX(-50%);
      bottom: -4px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #4a4a4a; }
  .tooltip--bottom .tooltip__content {
    transform: translateX(-50%);
    left: 50%;
    top: 30px; }
    .tooltip--bottom .tooltip__content:before {
      left: 50%;
      top: -5px;
      transform: translateX(-50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #4a4a4a; }
  .tooltip--left .tooltip__content {
    top: 50%;
    right: 25px;
    transform: translateY(-49%); }
    .tooltip--left .tooltip__content:before {
      right: -5px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid #4a4a4a;
      top: 50%;
      transform: translateY(-50%); }
  .tooltip--right .tooltip__content {
    top: 50%;
    left: 25px;
    transform: translateY(-49%); }
    .tooltip--right .tooltip__content:before {
      left: -5px;
      top: 50%;
      transform: translateY(-50%);
      border-top: 0.3125em solid transparent;
      border-bottom: 0.3125em solid transparent;
      border-right: 0.3125em solid #4a4a4a; }

.form__notify {
  padding: 2.5em 0 3.4375em;
  border-bottom: 0.125em solid #000000;
  margin-bottom: 3.75em; }

.notify__title {
  font-size: 1.08em;
  font-family: "roboto-bold";
  color: #4a4a4a;
  letter-spacing: 0.03704em;
  margin-bottom: 0.86806em; }

.group-radio .custom-radio {
  float: left; }

.group-radio .list-check {
  float: left;
  opacity: 0;
  display: none;
  transition: all .3s; }

.group-radio .custom-checkbox {
  display: inline-block;
  margin-left: 1.25em; }

.group-radio.selected .list-check {
  opacity: 1;
  display: block; }

.custom-textarea.input-has-value .custom-textarea__control {
  color: #4a4a4a;
  font-family: "roboto-regular"; }

@media screen and (max-width: 47.9375em) {
  .form__wrapper {
    margin-bottom: 2.1875em; }
  .form__fieldset {
    margin-bottom: 2.5em; }
  .form__group {
    margin-bottom: 0; }
  .form__text-choice {
    display: none; }
  .form__title {
    font-size: 2.23937em;
    margin-left: 0.2791em; }
  .form__inner {
    padding: 0 0.625em; }
    .form__inner:after {
      left: 0;
      right: 0; }
  .form__footer {
    padding-top: 3.75em;
    margin-left: 0.625em;
    margin-right: 0.625em;
    text-align: center; }
  .group__add-more {
    padding-left: 1.25em; }
  .text-required {
    top: 1.80556em; }
  .custom-textarea {
    margin-bottom: 1.875em; }
  .group-radio .custom-radio,
  .group-radio .list-check {
    float: none; }
  .group-radio .custom-checkbox {
    display: block;
    margin-left: 1.25em; }
  .group-radio .list-check .custom-checkbox:last-child {
    margin-bottom: 0; } }

section:not(.newsletter) .intro-form .help-block {
  display: block; }

section:not(.newsletter) .intro-form .field-validation-error {
  display: none; }

.registration__form--emarketing {
  padding-bottom: 0.625em; }
  .registration__form--emarketing .page-header h2 {
    font: 2.375em "GT-Walsheim-Black";
    color: #000000;
    margin-bottom: 1.84211em; }
  .registration__form--emarketing .col-md-12 {
    padding-left: 0; }
  .registration__form--emarketing .required-field .control-label:before {
    display: none; }
  .registration__form--emarketing .required-field .checkbox:after {
    display: none; }
  .registration__form--emarketing .content-form fieldset {
    border: 0;
    padding: 0; }
  .registration__form--emarketing .content-form legend {
    padding-left: 0; }
  .registration__form--emarketing .checkbox {
    padding: 0.875em 0 0;
    margin-top: 0;
    max-width: 100%; }
    .registration__form--emarketing .checkbox:after {
      display: none; }
    .registration__form--emarketing .checkbox input {
      position: absolute;
      left: -9999px;
      opacity: 0; }
      .registration__form--emarketing .checkbox input:checked ~ label {
        color: #4a4a4a; }
        .registration__form--emarketing .checkbox input:checked ~ label:before {
          background-color: #000000;
          border-color: #000000; }
        .registration__form--emarketing .checkbox input:checked ~ label:after {
          opacity: 1; }
    .registration__form--emarketing .checkbox label {
      font: 0.9em/1.52778em "roboto-light";
      cursor: pointer;
      color: #9b9b9b;
      padding-left: 1.5625em;
      position: relative;
      display: inline-block; }
      .registration__form--emarketing .checkbox label:focus {
        outline: none; }
      .registration__form--emarketing .checkbox label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.13889em;
        width: 0.83333em;
        height: 0.83333em;
        border: 0.06944em solid #979797;
        background-color: #ffffff;
        border-radius: 0.125em; }
      .registration__form--emarketing .checkbox label:after {
        position: absolute;
        transition: opacity .2s;
        background-color: transparent;
        content: "";
        color: #ffffff;
        font-family: "sam";
        left: 0;
        top: 0.13889em;
        line-height: 0.90278em; }
  .registration__form--emarketing .radio {
    padding-right: 0.625em;
    margin-top: 0.875em; }
    .registration__form--emarketing .radio input {
      position: absolute;
      left: -694.375em;
      opacity: 0; }
      .registration__form--emarketing .radio input:not(:checked) ~ label {
        color: #9b9b9b; }
        .registration__form--emarketing .radio input:not(:checked) ~ label:after {
          opacity: 0; }
      .registration__form--emarketing .radio input:checked + label {
        color: #4a4a4a; }
    .registration__form--emarketing .radio label {
      font: 0.9em/1.52778em "roboto-light";
      color: #9b9b9b;
      position: relative;
      padding-left: 1.5625em;
      cursor: pointer; }
      .registration__form--emarketing .radio label:before, .registration__form--emarketing .radio label:after {
        position: absolute; }
      .registration__form--emarketing .radio label:before {
        content: "";
        font-size: 1em;
        color: #979797;
        top: 0.125em;
        left: 0; }
      .registration__form--emarketing .radio label:after {
        content: "";
        font-size: 1em;
        top: 0.125em;
        left: 0;
        color: #000000; }
  .registration__form--emarketing .single-line {
    max-width: 100%;
    margin-top: 0;
    margin-left: 0;
    position: relative; }
    .registration__form--emarketing .single-line input {
      position: relative;
      font-size: 0.9em;
      border: none;
      width: 100%;
      padding-top: 1.18056em;
      padding-bottom: 1.45833em;
      border-bottom: 0.0625em solid #9b9b9b;
      outline: none;
      z-index: 2;
      background-color: transparent;
      font-family: "roboto-light";
      color: #979797;
      transition: all .4s; }
      .registration__form--emarketing .single-line input:focus {
        border-color: #4a4a4a;
        box-shadow: 0 0.06944em 0 #4a4a4a;
        padding-top: 2.15278em;
        padding-bottom: 0.48611em; }
      .registration__form--emarketing .single-line input:focus ~ label {
        top: 0.76389em;
        transform: none;
        font-size: 0.69444em; }
    .registration__form--emarketing .single-line label {
      font: 0.9em "roboto-light";
      color: #9b9b9b;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: all .3s; }
    .registration__form--emarketing .single-line.input-has-value input {
      font-family: "roboto-regular";
      color: #4a4a4a;
      padding-top: 2.15278em;
      padding-bottom: 0.48611em; }
    .registration__form--emarketing .single-line.input-has-value label {
      top: 0.76389em;
      transform: none;
      font-size: 0.69444em; }
  .registration__form--emarketing .custom-select--special.selected .custom-select__styled {
    color: #4a4a4a;
    font-family: "roboto-regular"; }
  .registration__form--emarketing .custom-select--special .custom-select__options-special {
    overflow-y: hidden; }
  .registration__form--emarketing .contact-us-template .btn--cta {
    display: inline-block; }
  .registration__form--emarketing .form-submit-border .btn {
    display: inline-block;
    vertical-align: middle;
    min-width: 12.84722em;
    border: none;
    padding: 0.75em; }
  .registration__form--emarketing .field-info,
  .registration__form--emarketing .field-validation-error {
    display: none; }
  .registration__form--emarketing .control-label.custom-select__styled {
    display: block; }
  .registration__form--emarketing .btn--cta-revert {
    display: inline-block;
    margin-bottom: 0.3125em; }
    .registration__form--emarketing .btn--cta-revert .form-submit-border {
      min-height: 2.93333em; }
  .registration__form--emarketing .intro-form {
    margin-bottom: 2.5em; }
    .registration__form--emarketing .intro-form > p {
      font-size: 0; }
    .registration__form--emarketing .intro-form .help-block {
      font: 0.75em/1.41667em "roboto-light";
      color: #9b9b9b;
      font-style: italic; }
      .registration__form--emarketing .intro-form .help-block b {
        font-family: "roboto-bold";
        font-style: normal;
        font-weight: normal; }
    .registration__form--emarketing .intro-form .checkbox {
      margin-bottom: 0.8125em; }
    .registration__form--emarketing .intro-form .field-validation-error {
      display: none; }
  .registration__form--emarketing .infor-form {
    margin-bottom: 6.25em; }
    .registration__form--emarketing .infor-form .control-label {
      display: none; }
    .registration__form--emarketing .infor-form .full-input {
      margin-bottom: 0; }
  .registration__form--emarketing .plan-form {
    margin-bottom: 6.25em; }
    .registration__form--emarketing .plan-form .control-label {
      font-size: 0.9em;
      font-family: "roboto-bold";
      color: #9b9b9b;
      padding: 0.625em 0;
      display: none; }
    .registration__form--emarketing .plan-form .checkbox-form .control-label {
      display: block; }
  .registration__form--emarketing .registration__attendance {
    margin-bottom: 4.375em; }
    .registration__form--emarketing .registration__attendance p {
      font-size: 0.9em;
      font-family: "roboto-bold";
      color: #9b9b9b;
      padding: 0.625em 0; }
    .registration__form--emarketing .registration__attendance .col-md-12 {
      padding-left: 2.5em; }
    .registration__form--emarketing .registration__attendance .registration__booking {
      width: 58%;
      float: left;
      margin-right: 0.625em;
      clear: none; }
    .registration__form--emarketing .registration__attendance .form-group.registration__age, .registration__form--emarketing .registration__attendance .form-group.registration__pax {
      width: 20%;
      float: left;
      clear: none; }
      .registration__form--emarketing .registration__attendance .form-group.registration__age .control-label, .registration__form--emarketing .registration__attendance .form-group.registration__pax .control-label {
        display: none; }
    .registration__form--emarketing .registration__attendance .form-group:first-child {
      padding-left: 0; }
    .registration__form--emarketing .registration__attendance .half-input .control-label {
      display: none; }
  .registration__form--emarketing .form-group {
    padding-left: 0.625em;
    padding-right: 0.625em;
    margin-bottom: 1.42857em; }
    .registration__form--emarketing .form-group.half-input {
      width: 50%;
      float: left;
      clear: none;
      min-height: 3.73333em; }
    .registration__form--emarketing .form-group.full-input {
      clear: left; }
    .registration__form--emarketing .form-group.registration__choice--date {
      clear: left;
      float: left;
      width: 58%;
      padding-left: 0; }
      .registration__form--emarketing .form-group.registration__choice--date > .control-label {
        float: left;
        font-size: 0.625em;
        color: #9b9b9b;
        font-family: "roboto-bold";
        display: block;
        padding-top: 2em;
        opacity: .6; }
        .registration__form--emarketing .form-group.registration__choice--date > .control-label:before {
          display: none; }
      .registration__form--emarketing .form-group.registration__choice--date .text-box {
        padding: 1.25em 0 1.38889em 6.94444em; }
      .registration__form--emarketing .form-group.registration__choice--date .single-line .label-input,
      .registration__form--emarketing .form-group.registration__choice--date .single-line input {
        opacity: .6; }
      .registration__form--emarketing .form-group.registration__choice--date .single-line.input-has-value .label-input {
        font-size: 0.9em;
        top: 46%;
        transform: translateY(-50%);
        opacity: 1; }
      .registration__form--emarketing .form-group.registration__choice--date .single-line.input-has-value .text-box,
      .registration__form--emarketing .form-group.registration__choice--date .single-line.input-has-value .text-box:focus {
        padding-top: 1.25em;
        padding-bottom: 1.38889em; }
      .registration__form--emarketing .form-group.registration__choice--date .single-line .text-box:focus {
        padding-top: 1.25em;
        padding-bottom: 1.38889em; }
        .registration__form--emarketing .form-group.registration__choice--date .single-line .text-box:focus ~ .label-input {
          font-size: 0.9em;
          top: 50%;
          transform: translateY(-50%); }
      .registration__form--emarketing .form-group.registration__choice--date > .field-border {
        float: right;
        width: 72%;
        clear: right; }
      .registration__form--emarketing .form-group.registration__choice--date .help-block {
        display: none; }
      .registration__form--emarketing .form-group.registration__choice--date.form__mandatory > .control-label {
        opacity: 1; }
      .registration__form--emarketing .form-group.registration__choice--date.form__mandatory .single-line .label-input,
      .registration__form--emarketing .form-group.registration__choice--date.form__mandatory .single-line input {
        opacity: 1; }
    .registration__form--emarketing .form-group.has-error .single-line.field-content {
      background-color: #fef200; }
    .registration__form--emarketing .form-group.has-error .custom-select__styled {
      background-color: #fef200; }
    .registration__form--emarketing .form-group.has-error.selected .single-line.field-content {
      background-color: transparent; }
    .registration__form--emarketing .form-group.has-error.selected .custom-select__styled {
      background-color: transparent; }
  .registration__form--emarketing .registration__choice--dropdown {
    clear: left;
    width: 40%; }
    .registration__form--emarketing .registration__choice--dropdown.custom-select--scroll {
      border-bottom-color: rgba(155, 155, 155, 0.6); }
    .registration__form--emarketing .registration__choice--dropdown .custom-select__styled.control-label {
      opacity: .6;
      font-size: 1em; }
    .registration__form--emarketing .registration__choice--dropdown.form__mandatory .custom-select__styled.control-label {
      opacity: 1; }
    .registration__form--emarketing .registration__choice--dropdown.form__mandatory.custom-select--scroll {
      border-bottom-color: #9b9b9b; }
  .registration__form--emarketing .registration__comment {
    margin-bottom: 4.375em; }
    .registration__form--emarketing .registration__comment .control-label {
      font-size: 0.9em;
      font-family: "roboto-bold";
      color: #9b9b9b;
      padding: 0.625em 0;
      display: inline-block; }
    .registration__form--emarketing .registration__comment .help-block {
      display: none; }
    .registration__form--emarketing .registration__comment > p {
      font: 0.75em/1.41667em "roboto-light";
      color: #9b9b9b;
      font-style: italic;
      margin-bottom: 2.5em; }
      .registration__form--emarketing .registration__comment > p b {
        font-style: normal;
        font-family: "roboto-bold";
        font-weight: normal; }
    .registration__form--emarketing .registration__comment .label-multiline {
      display: none; }
    .registration__form--emarketing .registration__comment p {
      display: none; }
    .registration__form--emarketing .registration__comment .has-error .control-label {
      background-color: #fef200; }
      .registration__form--emarketing .registration__comment .has-error .control-label:before {
        display: none; }
    .registration__form--emarketing .registration__comment legend {
      font-size: 0.9em;
      font-family: "roboto-bold";
      color: #9b9b9b;
      padding: 0.625em 0; }
    .registration__form--emarketing .registration__comment-revert legend {
      margin-bottom: 0; }
    .registration__form--emarketing .registration__comment-revert p {
      display: block;
      font: 0.75em/1.41667em "roboto-light";
      color: #9b9b9b;
      font-style: italic;
      padding: 0;
      margin-top: 1.25em;
      margin-bottom: 1.875em; }
  .registration__form--emarketing .registration__question .control-label {
    font-size: 0.9em;
    font-family: "roboto-bold";
    color: #9b9b9b;
    padding: 0.625em 0;
    display: inline-block; }
    .registration__form--emarketing .registration__question .control-label:before {
      display: none; }
  .registration__form--emarketing .registration__question .help-block {
    display: none; }
  .registration__form--emarketing .registration__question--decide > p {
    font-size: 0.9em;
    font-family: "roboto-bold";
    color: #9b9b9b;
    padding: 0.625em 0;
    display: inline-block; }
  .registration__form--emarketing .registration__question--decide .form-group {
    margin-bottom: 0; }
  .registration__form--emarketing .registration__question--orther {
    margin-top: 1.3125em; }
    .registration__form--emarketing .registration__question--orther > p {
      display: none; }
    .registration__form--emarketing .registration__question--orther .control-label {
      font-size: 0.9em;
      font-family: "roboto-bold";
      color: #9b9b9b;
      padding: 0.625em 0;
      display: inline-block; }
    .registration__form--emarketing .registration__question--orther .form-group:first-child {
      margin-bottom: 0; }
  .registration__form--emarketing .hidden-label .control-label {
    display: none; }
  .registration__form--emarketing .custom-select .custom-select__styled:before {
    display: none; }
  .registration__form--emarketing .validation-summary-errors {
    display: none; }
  .registration__form--emarketing .has-error .checkbox {
    background-color: #fef200; }

@media only screen and (min-width: 48em) {
  .registration__form--emarketing .plan-form .radio {
    float: left;
    width: 50%; }
  .registration__form--emarketing .plan-form .form-group {
    margin-bottom: 0; }
  .registration__form--emarketing .plan-form .checkbox-form .control-label {
    float: left;
    width: 100%; } }

@media screen and (max-width: 47.9375em) {
  .registration__form--emarketing .page-header h2 {
    margin-bottom: 1.05263em;
    margin-left: 0.26316em; }
  .registration__form--emarketing .plan-form {
    margin-bottom: 2.5em; }
    .registration__form--emarketing .plan-form .radio {
      margin-bottom: 1.25em; }
    .registration__form--emarketing .plan-form .checkbox-form {
      margin-bottom: 0; }
    .registration__form--emarketing .plan-form .form-group.half-input {
      width: 100%;
      float: none; }
  .registration__form--emarketing .registration__attendance .col-md-12 {
    padding-left: 1.875em; }
  .registration__form--emarketing .registration__attendance .form-group.registration__booking, .registration__form--emarketing .registration__attendance .form-group.registration__choice--date, .registration__form--emarketing .registration__attendance .form-group.registration__choice--dropdown {
    width: 100%;
    margin-bottom: 1.25em; }
  .registration__form--emarketing .registration__attendance .form-group.registration__choice--date .control-label {
    display: none; }
  .registration__form--emarketing .registration__attendance .form-group.registration__choice--date > .field-border {
    width: 100%; }
  .registration__form--emarketing .registration__attendance .form-group.registration__age, .registration__form--emarketing .registration__attendance .form-group.registration__pax {
    padding-left: 0;
    width: 50%; }
  .registration__form--emarketing .registration__attendance .form-group.registration__choice--dropdown, .registration__form--emarketing .registration__attendance .form-group.registration__booking {
    width: 97%; } }

.text__note {
  font: 0.75em/1.41667em "roboto-light";
  color: #9b9b9b;
  font-style: italic; }
  .text__note--bold {
    font-family: "roboto-bold";
    padding-right: 0.25em; }
  .text__note--spacing {
    margin-bottom: 2.08333em; }

.custom-control__specify {
  font-size: 0.69444em;
  border: 0.1em solid #9b9b9b;
  font-family: "roboto-light";
  color: #9b9b9b;
  padding: 0.3em 1em;
  margin-left: 1em; }
  .custom-control__specify::-moz-placeholder {
    color: #9b9b9b; }
  .custom-control__specify::-webkit-input-placeholder {
    color: #9b9b9b; }
  .custom-control__specify:-ms-input-placeholder {
    color: #9b9b9b; }
  .custom-control__specify::-ms-input-placeholder {
    color: #9b9b9b; }
  .custom-control__specify:focus {
    outline: none;
    border-color: #4a4a4a; }
    .custom-control__specify:focus::-moz-placeholder {
      color: #4a4a4a; }
    .custom-control__specify:focus::-webkit-input-placeholder {
      color: #4a4a4a; }
    .custom-control__specify:focus:-ms-input-placeholder {
      color: #4a4a4a; }
    .custom-control__specify:focus::-ms-input-placeholder {
      color: #4a4a4a; }
  @media screen and (max-width: 20em) {
    .custom-control__specify {
      width: 10.69444em; } }

.required {
  position: relative; }
  .required:before {
    content: '';
    position: absolute;
    top: 0.125em;
    left: 0;
    right: 0;
    bottom: 0.1875em;
    background-color: #fef200;
    z-index: -1; }
  .required .required__element {
    display: block; }

.required__element {
  display: none;
  position: absolute;
  z-index: 3;
  top: -2.40964em;
  right: 0;
  background-color: #ffffff;
  border: 0.125em solid #fef200;
  font-size: 0.57639em;
  font-family: "roboto-light";
  color: #000000;
  padding: 0.48193em 2.04819em 0.24096em 0;
  min-width: 9.63855em;
  text-align: center; }
  .required__element:after {
    content: "";
    font-family: "sam";
    position: absolute;
    bottom: 0.57143em;
    right: 1em;
    font-size: 0.84337em; }

.museum-location {
  max-width: 50em;
  margin: 0 auto 6.75em;
  position: relative;
  z-index: 1; }
  .museum-location .title__wrapper {
    margin-bottom: 1.25em; }
  .museum-location .title {
    letter-spacing: -0.04457em; }
  .museum-location__map {
    padding-bottom: 2.5em;
    border-bottom: 0.125em solid #000000;
    position: relative; }
    .museum-location__map:before {
      position: absolute;
      top: 0.375em;
      left: 50%;
      content: '';
      background-color: #4a4a4a;
      width: 1px;
      height: 15.6875em;
      transform: translateX(-50%); }
  .museum-location__detail {
    padding: 0.625em 0 1.125em;
    border-bottom: 0.125em solid #000000; }
  .museum-location__title {
    font: 1.125em "roboto-medium";
    color: #575757;
    padding-left: 0.38889em;
    padding-bottom: 0.33333em;
    border-bottom: 0.05556em solid #4a4a4a; }

.time__duration {
  font: 5.22917em/1 "roboto-light";
  color: #4a4a4a;
  padding: 0.03984em 0 0 0.10624em;
  margin-bottom: 0.11952em; }
  .time__duration .time__unit {
    font: 0.19124em "roboto-bold";
    display: inline-block;
    margin-left: 0.41667em;
    letter-spacing: 0.03472em; }
  .time__duration--edit {
    padding: 0.03984em 0 0 0.1992em;
    letter-spacing: -0.15936em; }
    .time__duration--edit .time__unit {
      letter-spacing: 0.03472em;
      margin-left: 0.90278em; }

.time__description {
  padding-left: 0.83333em; }
  .time__description--edit {
    padding-left: 1.45833em; }

.detail__item {
  width: 50%;
  float: left; }
  .detail__item:nth-child(1) .detail__content {
    padding-right: 5.55556em; }
  .detail__item:nth-child(2) .detail__content {
    padding-left: 5.69444em; }
  .detail__item:nth-child(2) .museum-location__title {
    padding-left: 5.16667em; }

.detail__icon {
  display: none; }

.detail__content {
  font: 0.9em/1.5em "roboto-light";
  padding: 1.18056em 0 0 0.13889em;
  color: #4a4a4a;
  letter-spacing: 0.03472em; }

.detail__text {
  margin-bottom: 1.66667em; }

.list-wrapper .list-item {
  padding-left: 0.55556em;
  position: relative; }
  .list-wrapper .list-item:before {
    width: 2px;
    height: 2px;
    position: absolute;
    left: 0.13889em;
    top: 47%;
    content: '';
    background-color: #4a4a4a;
    display: inline-block; }

.map__left {
  width: 19.375em;
  display: inline-block;
  float: left; }
  .map__left .map__title {
    text-align: right; }

.map__right {
  width: 19.375em;
  display: inline-block;
  float: right; }
  .map__right .map__title {
    text-align: left; }

.map__title {
  font: 1.14688em "roboto-black";
  margin-bottom: 1.08992em; }

.map__container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 0.125em 0.25em 0 rgba(0, 0, 0, 0.5); }

.map__image {
  display: inline-block;
  width: 100%; }
  .map__image img {
    width: 100%; }

.map__description {
  padding: 0.375em 2.8125em 0.75em;
  background-color: #ffffff;
  width: 100%;
  border-top: 0.4375em solid #fef200;
  color: #000000;
  margin-top: -0.3125em; }
  .map__description__title {
    font: 0.8125em "roboto-bold";
    display: block;
    margin-bottom: 0.375em; }
  .map__description__location {
    font: 0.71875em "roboto-light";
    display: block; }

@media screen and (max-width: 47.9375em) {
  .museum-location {
    margin-bottom: 0;
    padding: 0 0.4375em 4.4375em;
    border-bottom: 1px solid #4a4a4a; }
    .museum-location .title__wrapper {
      margin-bottom: 1.875em;
      text-align: left; }
    .museum-location__detail {
      padding-bottom: 0; }
    .museum-location__title {
      padding: 0.375em 0.375em 0.625em;
      position: relative; }
      .museum-location__title--mobile {
        padding: 0.75em 0.375em 0.6875em; }
    .museum-location__map {
      padding-bottom: 1.3125em; }
      .museum-location__map:before {
        display: none; }
  .time__duration, .time__description {
    padding: 0; }
  .map__title {
    margin-bottom: 0.4375em; }
  .map__left, .map__right {
    width: 100%; }
  .map__left {
    margin-bottom: 1.375em; }
    .map__left .map__title {
      text-align: left; }
  .detail__item {
    width: 100%; }
    .detail__item.opened .detail__icon {
      transform: translateY(-50%) rotate(90deg); }
    .detail__item:nth-child(1) .detail__content {
      padding-right: 0; }
    .detail__item:nth-child(2) .detail__content {
      padding-left: 0; }
    .detail__item:nth-child(2) .museum-location__title {
      padding-left: 0.375em; }
  .detail__icon {
    position: absolute;
    top: 47%;
    right: 0.6087em;
    display: inline-block;
    transform: translateY(-50%);
    font-size: 1.4375em; }
  .detail__content {
    display: none; } }

.gallery-module {
  position: relative;
  padding-top: 4.3125em;
  margin-top: 6.7%;
  padding-bottom: 9.375em; }
  .gallery-module:before, .gallery-module:after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 90%;
    background-color: #ffffff;
    z-index: -1; }
  .gallery-module:before {
    left: 0;
    padding-right: 1px;
    transform: skewY(-13.5deg);
    background: linear-gradient(-166.5deg, #e4e4e4 0%, #ffffff 23.75em); }
  .gallery-module:after {
    right: 0;
    transform: skewY(13.5deg);
    background: linear-gradient(166.5deg, #e4e4e4 0%, #ffffff 23.75em); }
  .gallery-module > .triangle-deco {
    background: #F99E1B;
    background: -webkit-linear-gradient(left, #F99E1B, #F99E1B);
    background: linear-gradient(to right, #F99E1B, #F99E1B); }
    .gallery-module > .triangle-deco.triangle-deco--wrapper {
      z-index: -2; }
  .gallery-module__content {
    display: block;
    margin-bottom: 3.75em; }
  .gallery-module .button__wrapper {
    display: none; }
  .gallery-module__top-bar {
    margin-top: 6.25em;
    margin-bottom: 4.375em; }
  .gallery-module .events-filter__display, .gallery-module .events-filter__type {
    width: auto; }
  .gallery-module .events-filter__type {
    margin-right: 0.8125em; }
  .gallery-module .events-filter .ico {
    color: #a4a4a4; }
    .gallery-module .events-filter .ico-grid {
      font-weight: bold; }
  .gallery-module .events-filter__item:first-child {
    margin-right: 1em; }
  .gallery-module .events-filter__item.active .ico {
    color: #000000; }
  .gallery-module__item {
    margin-bottom: 1.125em; }
  .gallery-module .more-resource {
    margin-bottom: 3.4375em; }
  .gallery-module .events-filter__display--sort {
    position: relative;
    margin-right: 1.38889em; }
    .gallery-module .events-filter__display--sort .events-filter__label {
      padding-right: 0.34722em; }
    .gallery-module .events-filter__display--sort:after {
      content: '';
      display: inline-block;
      background-color: #000000;
      width: 1px;
      height: 1.09954em;
      position: absolute;
      right: -0.34722em;
      top: 40%;
      transform: translateY(-50%); }
    .gallery-module .events-filter__display--sort .custom-select {
      width: 14.34259em; }
  .gallery-module .events-filter__display--collection .events-filter__label {
    padding-right: 1.04167em; }
  .gallery-module .events-filter__display--collection .custom-select {
    width: 10em; }
  .gallery-module .result__pagination {
    margin: 0 5.1875em; }
  .gallery-module__revert .events-filter__display--sort::after {
    display: none; }
  .gallery-module__revert .card__date {
    padding-left: 0.24108em;
    padding-right: 0.24108em;
    font-family: "roboto-light"; }
  .gallery-module .triangle-deco {
    top: 0;
    height: 7.5em;
    bottom: initial; }

.card__name, .card__title, .card__date {
  display: block;
  padding-left: 1.06075em;
  padding-right: 1.06075em;
  position: relative;
  z-index: 1; }

.card__name {
  font: 1.3125em/1.19048em "GT-Walsheim-Black";
  margin-top: 1.42857em;
  color: #000000;
  margin-bottom: 0.95238em;
  height: 2.42857em;
  overflow: hidden; }

.card__title {
  font: 1.3125em/1.19048em "roboto-light";
  color: #000000;
  margin-bottom: 1.42857em;
  height: 3.71429em;
  overflow: hidden; }

.card__date {
  font: 0.9375em "GT-Walsheim-Black";
  color: #9b9b9b; }

.view-type ul:before, .view-type ul:after {
  content: ' ';
  display: table; }

.view-type ul:after {
  clear: both; }

.view-type li {
  float: left; }

.gallery-module--list-view .gallery-module__content {
  margin-bottom: 2.5em; }
  .gallery-module--list-view .gallery-module__content .card {
    margin-bottom: 2.5em; }

.gallery-module--list-view .more-resource {
  text-align: left;
  border: none;
  background-color: transparent; }
  .gallery-module--list-view .more-resource:before, .gallery-module--list-view .more-resource:after {
    display: none; }
  .gallery-module--list-view .more-resource__image {
    border: none;
    display: none; }

.gallery-module--list-view .col-4 {
  width: 100%;
  padding: 0; }

.gallery-module--list-view .card {
  padding-left: 4.375em;
  padding-right: 4.4375em; }
  .gallery-module--list-view .card__name {
    font-size: 1.0625em;
    height: auto;
    margin-top: 0;
    margin-bottom: 0.88235em;
    padding-left: 1.29412em;
    padding-right: 1.29412em; }
  .gallery-module--list-view .card__title {
    font-size: 0.9375em;
    line-height: 1.4em;
    margin-bottom: 1em;
    height: auto;
    padding-left: 1.46667em;
    padding-right: 1.46667em; }
  .gallery-module--list-view .card__date {
    font-size: 0.875em;
    padding-left: 1.57143em;
    padding-right: 1.57143em; }

@media screen and (min-width: 48em) {
  .gallery-module .events-filter {
    max-width: 100%;
    padding-left: 4.6875em;
    padding-right: 4.6875em; } }

@media screen and (max-width: 47.9375em) {
  .events-filter__display {
    padding: 0 0.625em; }
  .gallery-module .button__wrapper {
    display: block; }
  .gallery-module__top-bar {
    margin-top: 0;
    margin-bottom: 3.25em; }
  .gallery-module .col-4 {
    width: 50%; }
  .gallery-module__content {
    padding: 0 0.4375em; }
  .gallery-module .card {
    margin: 0 0 1.875em; }
    .gallery-module .card__name {
      font-size: 0.70625em;
      margin-bottom: 0.48544em;
      height: 2.42718em; }
    .gallery-module .card__title {
      font-size: 0.6875em;
      margin-bottom: 0.63636em;
      height: 3.63636em; }
    .gallery-module .card__date {
      font-size: 0.65625em; }
  .gallery-module .events-filter {
    padding-top: 1.6875em;
    padding-bottom: 0.875em;
    position: relative; }
    .gallery-module .events-filter:after {
      height: 0.3125em; }
    .gallery-module .events-filter__display {
      width: 50%; }
      .gallery-module .events-filter__display--sort {
        margin-right: 0; }
      .gallery-module .events-filter__display--sort:after {
        display: none; }
      .gallery-module .events-filter__display--collection {
        padding-left: 1.1875em; }
    .gallery-module .events-filter__label {
      font-size: 0.75231em;
      line-height: 0.75em;
      margin-bottom: 0.83333em; }
    .gallery-module .events-filter .custom-select {
      font-size: 0.8125em; }
      .gallery-module .events-filter .custom-select__options {
        margin-top: 1.41667em; }
    .gallery-module .events-filter__type {
      position: absolute;
      top: -1.5em;
      left: 50%;
      transform: translateX(-50%); }
    .gallery-module .events-filter .ico {
      font-size: 2.1875em; }
    .gallery-module .events-filter__item:first-child {
      border-right: 0.03125em solid #979797;
      padding-right: 1.3125em;
      margin-right: 0; }
    .gallery-module .events-filter__item:nth-child(2) {
      padding-left: 1.25em; }
  .gallery-module.gallery-module--list-view .gallery-module__top-bar {
    margin-bottom: 2em; }
  .gallery-module.gallery-module--list-view .col-4 {
    width: 100%; }
  .gallery-module.gallery-module--list-view .card {
    padding: 0;
    margin-bottom: 2.0625em; }
    .gallery-module.gallery-module--list-view .card__name, .gallery-module.gallery-module--list-view .card__title, .gallery-module.gallery-module--list-view .card__date {
      padding: 0; }
    .gallery-module.gallery-module--list-view .card__name {
      font-size: 1.1em;
      height: auto; }
    .gallery-module.gallery-module--list-view .card__title {
      font-size: 0.9375em;
      margin-bottom: 0.73333em;
      height: auto; }
    .gallery-module.gallery-module--list-view .card__date {
      font-size: 0.875em; } }

@media screen and (min-width: 640px) {
  .gallery-module .card {
    margin-bottom: 3.125em; } }

.custom-select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 1em;
  text-align: left;
  color: #000000;
  width: 9.375em;
  height: 2.5em; }
  .custom-select select {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 3;
    border: 0;
    background: transparent;
    opacity: 0; }
    .custom-select select:focus {
      outline: none; }
  .custom-select__hidden {
    display: none;
    visibility: hidden;
    padding-right: 0.625em; }
  .custom-select__styled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: transparent;
    padding: 0.5em 1.625em 0.5em 0.9375em;
    transition: all .2s ease-in;
    user-select: none;
    cursor: pointer; }
    .custom-select__styled::after {
      font-family: "sam";
      content: "";
      position: absolute;
      top: 50%;
      right: 0.125em;
      z-index: 1;
      transform: translateY(-50%); }
  .custom-select__options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 10.625em;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 0.9); }
  .custom-select__item {
    margin: 0;
    padding: 0.75em 0;
    text-indent: 0.9375em;
    color: #ffffff;
    overflow: hidden;
    position: relative; }
    .custom-select__item.active {
      color: #fef200; }
      .custom-select__item.active .custom-select__item-text:after {
        width: 100%; }
    .custom-select__item:hover:not(.active) .overlay-text {
      transform: translate3d(0, 0, 0); }
    .custom-select__item:hover:not(.active) .overlay-text__inner {
      transform: translate3d(0, 0, 0); }
    .custom-select__item:hover:not(.active) .custom-select__item-text:after {
      width: 100%; }
    .custom-select__item-text {
      position: relative;
      padding-bottom: 3px; }
      .custom-select__item-text:after {
        content: '';
        width: 0;
        height: 2px;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #fef200;
        transition: width 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
  .custom-select .overlay-text {
    position: absolute;
    top: 5px;
    display: inline-block;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate3d(-100%, 0, 0);
    overflow: hidden;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 3; }
  .custom-select .overlay-text__inner {
    transform: translate3d(100%, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    color: #fef200;
    z-index: 2; }
  .custom-select--scroll {
    font-size: 0.9em;
    font-family: "roboto-light";
    width: 100%;
    height: 4.02778em;
    border-bottom: 0.06944em solid #9b9b9b; }
    .custom-select--scroll .overlay-text {
      display: none; }
    .custom-select--scroll .custom-select__options {
      background-color: transparent;
      max-height: 14.22222em; }
      .custom-select--scroll .custom-select__options ul {
        background-color: rgba(0, 0, 0, 0.9); }
      .custom-select--scroll .custom-select__options:before, .custom-select--scroll .custom-select__options:after {
        content: '';
        position: absolute; }
      .custom-select--scroll .custom-select__options:before {
        top: -0.625em;
        right: 1.25em;
        border-left: 0.625em solid transparent;
        border-right: 0.69444em solid transparent;
        border-bottom: 0.625em solid #000000; }
    .custom-select--scroll .custom-select__styled {
      padding: 1.38889em 0;
      font-size: 0.9em;
      color: #9b9b9b; }
      .custom-select--scroll .custom-select__styled:after {
        color: #9b9b9b;
        content: ""; }
    .custom-select--scroll .custom-select__item:hover, .custom-select--scroll .custom-select__item.active {
      background-color: #eeeeee;
      color: #000000; }
      .custom-select--scroll .custom-select__item:hover .custom-select__item-text, .custom-select--scroll .custom-select__item.active .custom-select__item-text {
        border-bottom: 0;
        padding-bottom: 0;
        font-family: "roboto-regular"; }
        .custom-select--scroll .custom-select__item:hover .custom-select__item-text:after, .custom-select--scroll .custom-select__item.active .custom-select__item-text:after {
          display: none; }
  .custom-select--scroll .custom-select.selected .custom-select__styled {
    color: #4a4a4a;
    font-family: "roboto-regular"; }
    .custom-select--scroll .custom-select.selected .custom-select__styled:after {
      color: #4a4a4a; }
  .custom-select--special {
    width: auto;
    min-width: 14.375em; }

.event-calendar {
  position: relative;
  z-index: 1; }
  .event-calendar__title {
    font: 3.36125em "GT-Walsheim-Black";
    color: #000000;
    letter-spacing: -.19px;
    text-align: center; }
  .event-calendar__date {
    font: 1.08em "roboto-black";
    color: #000000;
    letter-spacing: 0.21875em;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.57642em; }
  .event-calendar__inner {
    display: block;
    width: 100%;
    margin-top: 3.4375em; }
  .event-calendar .btn--cta.hidden {
    display: none; }

.events-filter {
  display: table;
  width: 100%;
  max-width: 50em;
  margin: auto;
  border-bottom: 1px solid #9b9b9b; }
  .events-filter__display {
    float: left;
    width: 50%;
    text-align: left; }
  .events-filter__type {
    float: right;
    width: 50%;
    text-align: right; }
    .events-filter__type .custom-select__styled:after {
      padding-right: 0.11574em; }
  .events-filter__label {
    font: 1.08em "roboto-bold";
    color: #a4a4a4;
    display: inline-block;
    vertical-align: top;
    padding-right: 0.5787em; }
  .events-filter__control {
    display: inline-block;
    vertical-align: top; }
  .events-filter .custom-select {
    font: 1.08em "GT-Walsheim-Black";
    height: 1.44676em;
    width: 8.33333em; }
    .events-filter .custom-select::after {
      content: '';
      width: 99%;
      border-bottom: 0.1875em solid #000000;
      display: block;
      position: absolute;
      left: 0;
      bottom: -0.46296em; }
    .events-filter .custom-select__styled {
      padding: 0 1.75em 0 0.3125em; }
    .events-filter .custom-select__styled:after {
      font-size: 0.6875em;
      right: 0.45455em; }
    .events-filter .custom-select__options {
      left: -0.06173em;
      top: 1.7284em; }
    .events-filter .custom-select__item {
      padding: 0.28935em 0; }
    .events-filter .custom-select__item:last-child {
      padding-bottom: 0.86806em; }

.events-filter__type .custom-select {
  width: 5.49769em; }

.slider-events {
  position: relative;
  width: 100%;
  max-width: 50em;
  margin: 1.66667em auto 4.8em; }
  .slider-events__item {
    float: left;
    width: 14.28571%;
    text-align: center; }
    .slider-events__item::after {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      border-bottom: 0.4375em solid #ffffff;
      z-index: -1; }
  .slider-events__link {
    position: relative;
    border-bottom: 0.4375em solid #000000;
    display: inline-block;
    vertical-align: middle;
    width: 92%;
    color: #000000;
    padding-bottom: 2.3125em;
    outline: none; }
  .slider-events__day {
    font: 1.86625em "GT-Walsheim-Black";
    color: #000000;
    text-align: left;
    padding-left: 0.9712em; }
  .slider-events__month {
    font: 0.9em "roboto-black";
    color: #4a4a4a;
    text-align: left;
    padding-left: 1.31944em; }
  .slider-events__year {
    font: 1.86625em "GT-Walsheim-Black";
    color: #000000;
    text-align: center; }
  .slider-events__symbol {
    color: #000000; }
  .slider-events__item-revert .slider-events__day, .slider-events__item-revert .slider-events__month, .slider-events__item-revert .slider-events__year, .slider-events__item-revert .slider-events__symbol {
    color: #bebebe; }
  .slider-events .slick-arrow {
    font-size: 1.875em;
    position: absolute;
    top: 0.6em;
    padding: 0.0625em;
    border: none;
    background: none;
    cursor: pointer;
    outline: none; }
  .slider-events .slick-prev {
    left: -1.43333em; }
  .slider-events .slick-next {
    right: -1.5em; }
  .slider-events .slick-disabled {
    opacity: .4;
    cursor: default; }

.slider-events__item.current .slider-events__link {
  border-bottom-color: #00e6fe; }
  .slider-events__item.current .slider-events__link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-left: 0.9375em solid transparent;
    border-right: 0.9375em solid transparent;
    border-bottom: 0.625em solid #00e6fe; }

.slider-events__item.current .slider-events__month,
.slider-events__item.current .slider-events__day,
.slider-events__item.current .slider-events__year,
.slider-events__item.current .slider-events__symbol {
  color: #00e6fe; }

@media only screen and (min-width: 48em) {
  .slider-events__item {
    overflow: hidden; }
  .slider-events__day {
    line-height: 0.71445em;
    padding-top: 0.42867em;
    padding-bottom: 1.21456em; }
  .slider-events__month {
    line-height: 1em; }
  .slider-events__link {
    padding-top: 0;
    padding-bottom: 0; }
  .slider-events__year {
    display: block;
    line-height: 0.71445em;
    margin-top: 0;
    padding-top: 0.89306em;
    padding-bottom: 1.21456em; }
  .event-calendar {
    padding-bottom: 4.44444em; }
    .event-calendar .associated__img {
      width: 39%;
      padding-right: 0.875em; }
    .event-calendar .associated__time {
      text-align: left;
      padding-top: 0.875em;
      padding-left: 3.125em; }
    .event-calendar .associated__time__date {
      line-height: 0.73643em; }
    .event-calendar .associated__time__month {
      font-family: "roboto-black";
      margin-top: 0.41667em;
      margin-bottom: 0.58333em;
      padding-bottom: 0.16667em;
      padding-left: 0;
      padding-right: 1.58333em; }
    .event-calendar .associated__time__day {
      font-family: "roboto-black";
      line-height: 0.75em; }
    .event-calendar .associated__infor__title {
      max-width: 14.85053em;
      margin-bottom: 0.38573em; }
    .event-calendar .associated__infor__link {
      font-size: 1.29625em;
      line-height: 1.06075em; }
    .event-calendar .associated__infor__star {
      margin-top: 0.20833em; }
  .slider-events__item:not(.current) .slider-events__link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.4375em;
    width: 100%;
    border-bottom: 0.5em solid #00e6fe;
    transform: scaleX(0);
    transition: transform .2s;
    transform-origin: right center;
    z-index: 5; }
  .slider-events__item:not(.current) .slider-events__link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scaleY(0);
    border-left: 0.9375em solid transparent;
    border-right: 0.9375em solid transparent;
    border-bottom: 0.625em solid #00e6fe;
    transition: all .2s .1s;
    transform-origin: bottom center; }
  .slider-events__item:not(.current) .slider-events__link .slider-events__month,
  .slider-events__item:not(.current) .slider-events__link .slider-events__day,
  .slider-events__item:not(.current) .slider-events__link .slider-events__year,
  .slider-events__item:not(.current) .slider-events__link .slider-events__symbol {
    transition: color .2s; }
  .slider-events__item:not(.current):hover .slider-events__link::before {
    transform: scaleX(1);
    transform-origin: left center; }
  .slider-events__item:not(.current):hover .slider-events__link::after {
    transform: translateX(-50%) scaleY(1);
    transform-origin: bottom center; }
  .slider-events__item:not(.current):hover .slider-events__link .slider-events__month,
  .slider-events__item:not(.current):hover .slider-events__link .slider-events__day,
  .slider-events__item:not(.current):hover .slider-events__link .slider-events__year,
  .slider-events__item:not(.current):hover .slider-events__link .slider-events__symbol {
    color: #00e6fe; }
  .firefox .slider-events__item:not(.current) .slider-events__link::before {
    width: 101%; } }

@media screen and (max-width: 47.9375em) {
  .event-calendar {
    padding-bottom: 6em; }
    .event-calendar__inner {
      margin-top: 2.6875em; }
  .events-filter {
    padding-top: 2.43056em;
    padding-bottom: 0.63657em;
    border-bottom: none; }
    .events-filter::after {
      content: '';
      display: block;
      height: 0.0625em;
      border-bottom: 0.0625em solid #9b9b9b;
      margin: 2.60417em -0.63657em 0; }
    .events-filter__display {
      padding-left: 0.5em; }
      .events-filter__display .custom-select {
        min-width: 7.8125em; }
    .events-filter__type .custom-select {
      width: 5.49769em;
      margin-right: 0.63657em; }
      .events-filter__type .custom-select .custom-select__styled {
        text-align: right;
        padding-right: 1.75em; }
    .events-filter__label {
      display: block;
      padding-right: 0.69444em; }
    .events-filter__control {
      display: block;
      margin-top: -0.375em;
      padding-right: 0.125em; }
    .events-filter .custom-select::after {
      bottom: -0.25em; }
  .slider-events {
    width: auto;
    margin: 1.50463em -0.63657em 2.02546em 0; }
    .slider-events__item {
      text-align: left;
      margin-left: 0.9375em;
      min-height: 4.25em; }
    .slider-events__link {
      width: 98%;
      min-height: 4.625em;
      padding-bottom: 0.9375em; }
      .slider-events__link::after {
        display: none; }
    .slider-events__day {
      font-size: 1.715em;
      line-height: 1.23907em;
      text-align: center;
      padding-left: 0; }
    .slider-events__month {
      font-size: 0.875em;
      padding-right: 5em;
      text-align: center; }
    .slider-events__year {
      font-size: 1.86625em;
      line-height: 1.13865em;
      padding-top: 0.26792em; }
    .slider-events .slick-prev,
    .slider-events .slick-next {
      box-shadow: 0 0 4.66667em 0.83333em #ffffff; }
    .slider-events .slick-prev {
      top: 0.46667em;
      left: 0;
      z-index: 3; }
    .slider-events .slick-next {
      top: 0.46667em;
      right: 0; }
    .slider-events .slick-next-line {
      width: 4.1875em;
      border-bottom: 0.26042em solid #000000;
      bottom: 0;
      left: 99%; }
  .events__list .associated__time {
    padding-top: 1.1875em; }
  .events__list .associated__img {
    padding-right: 8em;
    padding-left: 0.625em; }
  .events__list .associated__img:before {
    bottom: -0.125em;
    right: 1.3125em; }
  .events__list .associated__time__date {
    font-size: 2.40125em;
    line-height: 0.54659em; }
  .events__list .associated__time__month {
    font-size: 0.625em;
    padding-right: 0.4em;
    padding-bottom: 0.4em; }
  .events__list .associated__infor {
    padding-top: 0.5em;
    margin-left: 0.3125em; }
  .events__list .associated__infor__title {
    margin-bottom: 0; }
  .events__list .associated__infor__star {
    font-size: 0.75em;
    margin-top: 0.41667em;
    text-transform: inherit; } }

.hours {
  margin-bottom: 6.25em;
  position: relative;
  z-index: 1; }
  .hours__title {
    font: 3.36125em/0.94998em "GT-Walsheim-Black";
    letter-spacing: -2px;
    text-align: center;
    margin-bottom: 0.26032em; }
  .hours_desc {
    font: 0.875em/1.28571em "roboto-light";
    letter-spacing: -.1px;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 2.85714em; }
  .hours__inner {
    max-width: 50em;
    margin: auto; }

.hours-item {
  display: inline-block;
  vertical-align: top;
  width: 19.0625em;
  min-height: 9.9375em; }
  .hours-item__revert {
    width: 11.375em;
    text-align: center; }
  .hours-item__time {
    font: 2.1775em/1.5em "roboto-light";
    letter-spacing: .94px;
    padding-left: 0; }
  .hours-item__day {
    font: 0.875em/1.32857em "roboto-bold";
    letter-spacing: .2px;
    background-color: #fef200;
    margin-top: -0.14286em;
    margin-bottom: 0.85714em;
    padding: 0.28571em 0 0.14286em 0.92857em; }
  .hours-item__desc {
    font: 0.875em/1.32857em "roboto-light";
    letter-spacing: -.1px;
    color: #4a4a4a; }
    .hours-item__desc-revert {
      margin-bottom: 1.85714em; }
    .hours-item__desc-media {
      text-align: center;
      margin-top: 1em;
      margin-bottom: 1em; }
    .hours-item__desc-special {
      font: 1.3125em/1.42857em "GT-Walsheim-Black";
      letter-spacing: -.86px;
      color: #ff00ae;
      max-width: 8.57143em;
      margin-left: auto;
      margin-right: auto; }
    .hours-item__desc-link {
      font-family: "roboto-bold";
      color: #005aff; }
  .hours-item__type {
    font: 0.9375em "GT-Walsheim-Black";
    border-top: 0.06667em solid #000000;
    border-bottom: 0.06667em solid #000000;
    display: inline-block;
    vertical-align: top; }
  .hours-item__icon {
    display: block;
    margin-top: 0.125em;
    margin-bottom: 0.625em; }
  .hours-item .ico-clock {
    font-size: 36px;
    width: 36px;
    height: 36px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-top: 1px; }
    .hours-item .ico-clock::after {
      content: '';
      position: absolute;
      top: 1px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      z-index: -1;
      background: #aaff00;
      border-radius: 100%; }
    .hours-item .ico-clock__background {
      position: absolute;
      left: 2px;
      top: 2px;
      right: 2px;
      bottom: 2px;
      z-index: 1;
      border: 2px solid #ffffff;
      border-radius: 100%;
      margin-top: 0; }
  .hours-item .ico-clock-closed::after {
    background: #9b9b9b; }

.hours-navigation {
  text-align: center; }
  .hours-navigation__list {
    display: inline-block;
    vertical-align: top; }
  .hours-navigation__item {
    text-align: center; }
  .hours-navigation__link {
    color: #000000; }
  .hours-navigation__text {
    font: 1.08em/1.04977em "roboto-black";
    letter-spacing: .08px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: top;
    padding-top: 0.46296em; }
  .hours-navigation__ico {
    display: inline-block;
    vertical-align: top;
    padding-left: 0.125em;
    padding-right: 0.75em; }
    .hours-navigation__ico::before {
      font-family: "sam";
      font-size: 2.0625em; }
  .hours-navigation__location .hours-navigation__ico:before {
    content: ""; }
  .hours-navigation__admission .hours-navigation__ico:before {
    content: ""; }
  .hours-navigation__directions .hours-navigation__ico:before {
    content: ""; }

.hours-desc__title {
  font: 0.75em "roboto-bold";
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 1.66667em; }

.hours-desc__media {
  display: block;
  max-width: 80%;
  margin: 1em auto 0;
  text-align: center; }

.hours-desc__special {
  font: 1em "GT-Walsheim-Black";
  color: #ff00ae;
  text-align: center;
  text-transform: uppercase; }

.hours-desc__infor {
  display: block;
  width: 100%;
  font-family: "roboto-light";
  font-size: 0.625em;
  color: #757575;
  border-top: rgba(74, 74, 74, 0.3) 1px dotted;
  border-bottom: rgba(74, 74, 74, 0.3) 1px dotted;
  margin: 2em 0;
  padding: 1em 0;
  line-height: 1.6em; }

.hours-desc .hours-item__desc-link {
  font-family: "roboto-black";
  text-transform: uppercase; }

@media only screen and (min-width: 48em) {
  .hours__group {
    display: inline-block;
    vertical-align: top; }
  .hours__desc {
    margin-left: auto;
    margin-right: auto;
    padding-top: 0.875em; }
  .hours__revert {
    max-width: 32.14286em;
    margin-left: auto;
    margin-right: auto; }
  .desktop .hours .hours__title,
  .desktop .hours .hours_desc {
    opacity: 0;
    transition: all .9s .5s; }
  .desktop .hours .hours__title,
  .desktop .hours .hours_desc {
    transform: translate3d(0, 30px, 0); }
  .desktop .hours.animation .hours__title,
  .desktop .hours.animation .hours_desc {
    transform: none;
    opacity: 1; }
  .hours-item__revert {
    float: right; }
  .hours-navigation__list {
    width: 50em;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    padding-top: 0.9375em;
    padding-bottom: 0.5em; }
  .hours-navigation__item {
    display: inline-block;
    vertical-align: top;
    width: 33%; }
  .hours-navigation__location {
    text-align: left; }
  .hours-navigation__directions {
    text-align: right; }
  .hours__group-revert {
    width: 100%;
    text-align: center; }
    .hours__group-revert .hours-item__revert {
      float: none;
      clear: both;
      width: 100%;
      min-height: 5.625em; } }

@media screen and (max-width: 47.9375em) {
  .hours {
    position: relative;
    margin-bottom: 3.125em;
    padding: 0 1.1875em 3.125em;
    box-shadow: 0 0.0625em 0 #000000; }
    .hours__inner {
      max-width: 100%;
      margin-bottom: 0.25em; }
    .hours__title {
      font-size: 2.40125em;
      line-height: 0.95003em;
      letter-spacing: -1.42px;
      margin-bottom: 0.8329em; }
    .hours_desc {
      font-size: 0.875em;
      line-height: 1.5em;
      letter-spacing: .44px;
      text-align: center;
      margin-bottom: 0.85714em; }
  .hours-item {
    width: 100%;
    min-height: 9.5625em;
    border-bottom: none; }
    .hours-item__revert {
      min-height: auto;
      text-align: center;
      margin-bottom: 2em; }
    .hours-item__icon {
      vertical-align: top;
      width: 100%;
      display: inline-block;
      text-align: center; }
    .hours-item__time {
      padding-left: 0.17222em; }
    .hours-item__day {
      font-size: 0.9625em;
      line-height: 1.27273em;
      letter-spacing: .2px;
      margin-top: -0.25974em;
      margin-bottom: 0.58442em;
      padding-left: 1.49351em; }
    .hours-item__desc {
      line-height: 1.5em;
      letter-spacing: .44px;
      padding-left: 0.07143em; }
    .hours-item__type {
      font-size: 0.9375em;
      margin-left: 0.46667em; }
    .hours-item .ico-clock {
      margin-top: 0.05556em; }
    .hours-item__construction {
      max-width: 100%; }
  .hours-navigation__list {
    width: 100%; }
  .hours-navigation__item {
    padding: 1.25em; }
  .hours-navigation__location {
    border-bottom: 0.0625em solid #dddddd; }
  .hours-navigation__admission {
    border-bottom: 0.0625em solid #dddddd; } }

section:not(.newsletter) .page-header h2 {
  font: 2.375em "GT-Walsheim-Black";
  color: #000000;
  margin-bottom: 1.84211em; }

section:not(.newsletter) .form-normal .button-success__btn {
  margin-top: 1.38889em; }

section:not(.newsletter) .form-normal .btn--cta-revert {
  margin-bottom: 0.3125em;
  padding-right: 0.3125em; }
  section:not(.newsletter) .form-normal .btn--cta-revert .form-submit-border {
    min-height: 2.93333em; }

section:not(.newsletter) .form-normal .form-group.half-input {
  float: left;
  width: 50%;
  clear: none;
  padding-right: 0.75em; }
  section:not(.newsletter) .form-normal .form-group.half-input ~ .half-input {
    padding-left: 0.75em;
    padding-right: 0; }

section:not(.newsletter) .form-normal .form-group.has-error .checkbox {
  background-color: #fef200; }

section:not(.newsletter) .form-normal .intro-form .help-block {
  font: 0.75em/1.41667em "roboto-light";
  color: #9b9b9b;
  font-style: italic; }
  section:not(.newsletter) .form-normal .intro-form .help-block b {
    font-family: "roboto-bold";
    font-style: normal;
    font-weight: normal; }

section:not(.newsletter) .multiline-with-title > p {
  display: none; }

section:not(.newsletter) .multiline-with-title .form-group .control-label {
  font-size: 0.9em;
  font-family: "roboto-bold";
  color: #9b9b9b;
  padding: 0.625em 0;
  display: block; }

section:not(.newsletter) .multiline-with-title .form-group.has-error .control-label {
  background-color: #fef200; }

section:not(.newsletter) .multiline-without-title.has-error .custom-textarea {
  background-color: #fef200; }

section:not(.newsletter) .validation-summary-errors {
  display: none; }

section:not(.newsletter) .help-block {
  display: none; }

section:not(.newsletter) .form-group {
  margin-bottom: 1.42857em; }
  section:not(.newsletter) .form-group.has-error .field-content.single-line {
    background-color: #fef200; }
  section:not(.newsletter) .form-group.custom_radiolist .control-label, section:not(.newsletter) .form-group.custom_checkboxlist .control-label {
    display: block;
    font-size: 0.9em;
    font-family: "roboto-bold";
    color: #9b9b9b; }

section:not(.newsletter) .checkbox {
  padding: 0.875em 0 0;
  margin-top: 0;
  margin-bottom: 0.75em;
  max-width: 100%; }
  section:not(.newsletter) .checkbox:after {
    display: none; }
  section:not(.newsletter) .checkbox input {
    position: absolute;
    left: -9999px;
    opacity: 0; }
    section:not(.newsletter) .checkbox input:checked ~ label {
      color: #4a4a4a; }
      section:not(.newsletter) .checkbox input:checked ~ label:before {
        background-color: #000000;
        border-color: #000000; }
      section:not(.newsletter) .checkbox input:checked ~ label:after {
        opacity: 1; }
  section:not(.newsletter) .checkbox label {
    font: 0.9em/1.52778em "roboto-light";
    cursor: pointer;
    color: #9b9b9b;
    padding-left: 1.5625em;
    position: relative;
    display: inline-block; }
    section:not(.newsletter) .checkbox label:focus {
      outline: none; }
    section:not(.newsletter) .checkbox label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.13889em;
      width: 0.83333em;
      height: 0.83333em;
      border: 0.06944em solid #979797;
      background-color: #ffffff;
      border-radius: 0.125em; }
    section:not(.newsletter) .checkbox label:after {
      position: absolute;
      transition: opacity .2s;
      background-color: transparent;
      content: "";
      color: #ffffff;
      font-family: "sam";
      left: 0;
      top: 0.13889em;
      line-height: 0.90278em; }

section:not(.newsletter) .radio {
  padding-right: 0.625em;
  margin-top: 0.875em; }
  section:not(.newsletter) .radio input {
    position: absolute;
    left: -694.375em;
    opacity: 0; }
    section:not(.newsletter) .radio input:not(:checked) ~ label {
      color: #9b9b9b; }
      section:not(.newsletter) .radio input:not(:checked) ~ label:after {
        opacity: 0; }
    section:not(.newsletter) .radio input:checked + label {
      color: #4a4a4a; }
  section:not(.newsletter) .radio label {
    font: 0.9em/1.52778em "roboto-light";
    color: #9b9b9b;
    position: relative;
    padding-left: 1.5625em;
    cursor: pointer; }
    section:not(.newsletter) .radio label:before, section:not(.newsletter) .radio label:after {
      position: absolute; }
    section:not(.newsletter) .radio label:before {
      content: "";
      font-size: 1em;
      color: #979797;
      top: 0.125em;
      left: 0; }
    section:not(.newsletter) .radio label:after {
      content: "";
      font-size: 1em;
      top: 0.125em;
      left: 0;
      color: #000000; }

section:not(.newsletter) .single-line {
  max-width: 100%;
  margin-top: 0;
  margin-left: 0;
  position: relative; }
  section:not(.newsletter) .single-line input {
    position: relative;
    font-size: 0.9em;
    border: none;
    width: 100%;
    padding-top: 1.18056em;
    padding-bottom: 1.45833em;
    border-bottom: 0.0625em solid #9b9b9b;
    outline: none;
    z-index: 2;
    background-color: transparent;
    font-family: "roboto-light";
    color: #979797;
    transition: all .4s; }
    section:not(.newsletter) .single-line input:focus {
      padding-top: 2.15278em;
      padding-bottom: 0.48611em; }
    section:not(.newsletter) .single-line input:focus ~ label {
      top: 0.76389em;
      transform: none;
      font-size: 0.69444em; }
  section:not(.newsletter) .single-line label {
    font: 0.9em "roboto-light";
    color: #9b9b9b;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: all .3s; }
  section:not(.newsletter) .single-line.input-has-value input {
    font-family: "roboto-regular";
    color: #4a4a4a;
    padding-top: 2.15278em;
    padding-bottom: 0.48611em; }
  section:not(.newsletter) .single-line.input-has-value label {
    top: 0.76389em;
    transform: none;
    font-size: 0.69444em; }

section:not(.newsletter) .custom-select--special.selected .custom-select__styled {
  color: #4a4a4a;
  font-family: "roboto-regular"; }

section:not(.newsletter) .custom-select--special .custom-select__options-special {
  overflow-y: hidden; }

section:not(.newsletter) .contact-us-template .btn--cta {
  display: inline-block; }

section:not(.newsletter) .form-submit-border .btn {
  display: inline-block;
  vertical-align: middle;
  min-width: 12.84722em;
  border: none;
  padding: 0.75em; }

section:not(.newsletter) .control-label,
section:not(.newsletter) .field-info,
section:not(.newsletter) .field-validation-error {
  display: none; }

section:not(.newsletter) .control-label.custom-select__styled {
  display: block; }

section:not(.newsletter) .btn--cta-revert {
  display: inline-block; }

section:not(.newsletter) .sam-Contact_RadioList .control-label {
  display: block; }

.introduction-form,
.footer-form {
  margin-bottom: 1.875em; }
  .introduction-form p,
  .footer-form p {
    font-size: 0.9em;
    color: #9b9b9b; }

.content-form {
  margin-bottom: 1.875em; }
  .content-form .col-md-12 {
    width: 100%; }
  .content-form .required-field .control-label:before {
    display: none; }
  .content-form .required-field .checkbox:after {
    display: none; }
  .content-form legend {
    font: 0.9em "roboto-bold";
    color: #4a4a4a;
    margin-bottom: 0.69444em; }
    .content-form legend > span {
      color: #ffffff;
      position: relative;
      width: 1.45833em;
      margin-right: 0.69444em;
      display: inline-block;
      font-size: 0.9em;
      text-align: center; }
      .content-form legend > span:before {
        content: '';
        border-radius: 50%;
        background-color: #4a4a4a;
        width: 1.45833em;
        height: 1.45833em;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1; }
  .content-form fieldset {
    padding: 0 0 1.25em;
    border: none; }

@media only screen and (min-width: 48em) {
  section:not(.newsletter) .form-success .button-success__btn {
    padding-left: 1.875em;
    padding-right: 1.875em; } }

@media screen and (max-width: 47.9375em) {
  section:not(.newsletter) .btn--cta-revert {
    display: block;
    margin: 1.25em auto;
    max-width: 14.25em; } }

.infor__note-clock:before, .infor__note-info:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 1.875em; }

.infor__inner--border {
  border-top: 30px solid #eaeaea; }

.infor__note-clock {
  position: relative;
  padding-left: 2.77778em;
  margin-top: 2.29167em;
  margin-bottom: 3.125em; }
  .infor__note-clock:before {
    content: ""; }

.infor__note-info {
  position: relative;
  padding-left: 2.77778em; }
  .infor__note-info:before {
    content: ""; }

.infor__text-country {
  color: #000000; }

.infor__schedule {
  margin-bottom: 2.5em; }

.infor__schedule-flag {
  margin-right: 0.625em; }

.infor__schedule-detail {
  margin-top: 0.3125em; }
  .infor__schedule-detail .day {
    font: 0.9em "roboto-bold";
    display: inline-block;
    min-width: 10.625em;
    margin-right: 0.34722em;
    color: #4a4a4a;
    vertical-align: top; }

.infor__schedule-time-wrap {
  display: inline-block; }

.infor__schedule-time {
  float: left;
  min-width: 4.875em; }
  .infor__schedule-time:nth-child(3n+1) {
    clear: both; }

.border-left {
  border-left: 1px solid #979797; }

@media screen and (max-width: 47.9375em) {
  .infor__inner--border {
    padding-top: 0;
    border-top-width: 38px; }
  .infor__wrapper {
    margin-bottom: 0.625em; }
    .infor__wrapper .col-6 {
      margin-top: 1.0625em; }
  .infor__schedule {
    padding-top: 0.75em;
    margin-bottom: 0;
    border-top: 1px solid #979797; }
  .infor__schedule-detail-item {
    padding-left: 2.375em;
    margin-bottom: 0.375em; }
  .infor__schedule-flag {
    margin-right: 0.9375em; }
  .infor__note-clock {
    margin-bottom: 1.66667em; }
  .infor__note-clock, .infor__note-info {
    font-size: 0.75em;
    margin-top: 0; }
    .infor__note-clock:before, .infor__note-info:before {
      font-size: 2.25em; }
  .border-left {
    border-left: none; } }

.infor__shop-image {
  margin-bottom: 1.25em;
  width: 100%; }

.infor__shop-image-small {
  float: left;
  margin-right: 1.4375em; }

.infor__desc {
  overflow: hidden; }

.infor__text-note {
  margin-bottom: 0.3125em;
  font: 0.9em "roboto-bold";
  color: #4a4a4a; }

.infor__text--spacing-bottom-small {
  margin-bottom: 2.08333em; }

@media screen and (max-width: 47.9375em) {
  .infor__shop-image-small {
    display: none; }
  .infor__content {
    padding-left: 0.375em;
    padding-right: 0.375em; }
  .infor__text-note {
    font-size: 0.75em; } }

.date__wrapper:before, .date__wrapper:after {
  content: '';
  position: absolute;
  bottom: -1.1875em;
  width: 50%;
  height: 100%;
  background-color: #9b9b9b; }

.slider {
  position: relative;
  margin-bottom: 2.1875em; }
  .slider__wrapper {
    position: relative;
    overflow: hidden; }
  .slider__image {
    position: relative;
    width: 100%;
    z-index: 3; }
  .slider__content {
    position: absolute;
    left: 50%;
    bottom: 2.375em;
    width: 19em;
    transform: translateX(-50%);
    z-index: 6; }
  .slider__header {
    padding-bottom: 0.625em;
    margin-bottom: 1em;
    border-bottom: 1px solid #ffffff;
    text-align: center;
    display: none; }
    .slider__header.active {
      display: block; }
  .slider__title {
    font: 2.125em/1.11765em "GT-Walsheim-Black";
    margin-bottom: 0.23529em; }
  .slider__bullet-wrapper {
    position: relative;
    text-align: center; }
  .slider__text, .slider__link {
    color: #ffffff; }
  .slider__text {
    font: 0.77778em/1.28571em "roboto-regular";
    margin-bottom: 0.14286em; }
  .slider__link {
    font: 1em/1.28571em "roboto-regular"; }
  .slider__arrow-left, .slider__arrow-right {
    position: relative;
    display: inline-block;
    vertical-align: middle; }
    .slider__arrow-left.disabled, .slider__arrow-right.disabled {
      pointer-events: none; }
  .slider__arrow-left {
    margin-right: 1.6875em; }
  .slider__loading {
    opacity: 0; }
    .slider__loading.running {
      opacity: 1;
      stroke-dasharray: 100;
      stroke-dashoffset: 100;
      animation: dash 5s linear .3s forwards infinite; }
  .slider .triangle-deco {
    bottom: 0.6875em; }

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0; } }

@keyframes dash {
  to {
    stroke-dashoffset: 0; } }

.date__wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 7;
  width: 19.125em;
  height: 3.375em;
  transform: translateX(-50%);
  background-color: #9b9b9b;
  opacity: .86;
  text-align: center; }
  .date__wrapper:before {
    left: 0;
    padding-right: 1px;
    z-index: 5;
    transform: skewY(13.5deg); }
  .date__wrapper:after {
    right: 0;
    z-index: 4;
    transform: skewY(-13.5deg); }

.date__content {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  width: 100%;
  height: 5.625em;
  margin-top: 2.25em;
  transform: translate(-50%, -50%);
  display: none; }
  .date__content.active {
    display: block; }

.date__text {
  display: inline-block;
  margin: 0 0.625em;
  width: 3.4375em;
  line-height: 2em;
  color: #ffffff; }

.date__number {
  font: 2.375em/0.52632em "roboto-light"; }

.date__month {
  display: block;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.0275em;
  font: 0.875em/1.28571em "roboto-regular"; }

.bullets {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: auto;
  text-align: center; }

.bullets-item {
  margin-bottom: 0.3125em; }
  .bullets-item.active .bullet {
    width: 5px;
    height: 5px; }

.bullet {
  display: block;
  width: 3px;
  height: 3px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  border-radius: 50%;
  background-color: #00e6ff;
  cursor: pointer; }

.line-break {
  position: relative; }
  .line-break:after {
    content: '';
    position: absolute;
    top: 35%;
    right: -1.4375em;
    width: 0.5625em;
    margin-top: -0.5625em;
    border: 1px solid #ffffff;
    transform: translateX(-50%); }

@media (max-width: 47.9375em) {
  .slider {
    overflow: hidden; }
    .slider__wrapper:before, .slider__wrapper:after {
      bottom: 1.5em; }
    .slider__content {
      position: relative;
      width: 100%;
      padding-top: 0.625em;
      padding-bottom: 1.25em;
      bottom: 0;
      border-bottom: 1px solid #000000; }
    .slider__image {
      width: 176%;
      margin-left: -38%; }
    .slider__header {
      padding-left: 2.5em;
      padding-right: 2.5em; }
    .slider__text, .slider__arrow-left, .slider__arrow-right {
      color: #000000; }
    .slider__text {
      padding-top: 0.5em; }
    .slider__path {
      stroke: #000000; }
    .slider__loading {
      stroke-width: 3px; }
    .slider .triangle-deco {
      top: auto;
      bottom: 0.375em;
      z-index: 1; }
  .date__wrapper {
    position: relative;
    top: -3.125em;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 5.9375em;
    transform: translateX(0);
    background: none; }
    .date__wrapper:before, .date__wrapper:after {
      z-index: 0;
      height: 120%; }
  .date__content {
    top: 3.75em; }
  .date__number {
    font-size: 2.25em;
    line-height: 0.55556em; }
  .date__month {
    font-size: 0.75em;
    line-height: 2.5em; } }

.accordion__item {
  border-top: 1px solid #9b9b9b; }
  .accordion__item.opened .accordion__title {
    color: #ff00ae; }
  .accordion__item.opened .accordion__title:after {
    content: "";
    right: 0.33751em;
    font-size: 1.2054em; }

.accordion__item--last {
  border-bottom: 1px solid #9b9b9b; }
  .accordion__item--last .cta {
    padding-left: 0.4375em;
    padding-top: 1.625em;
    padding-bottom: 1.625em;
    background-color: #ffffff; }
    .accordion__item--last .cta--full {
      margin-top: 0; }
  .accordion__item--last .cta__text {
    font: 1.8em/1.35417em "GT-Walsheim-Black";
    color: #000000; }
  .accordion__item--last .cta__icon {
    color: #000000; }
  .accordion__item--last .cta__ico circle,
  .accordion__item--last .cta__ico path {
    stroke: #000000; }

.accordion__content {
  display: none; }

.accordion__title {
  display: block;
  position: relative;
  padding-top: 0.67502em;
  padding-bottom: 0.67502em;
  padding-right: 1.83221em;
  color: #000000;
  font: 1.29625em/1.19961em "GT-Walsheim-Black"; }
  .accordion__title::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0.15625em;
    font-family: "sam";
    font-size: 1.54291em;
    transform: translateY(-50%); }
  .accordion__title:hover:after {
    content: ""; }

.accordion__wrapper {
  padding: 0 6.25em;
  margin-bottom: 9.375em;
  position: relative;
  z-index: 1; }

.accordion--big .accordion__item {
  border-top: none; }

.accordion--big .accordion__title {
  font-size: 1.8em;
  border-bottom: 0.03472em solid #9b9b9b;
  padding: 1.00694em 1.73611em 0.90278em 0.24306em; }
  .accordion--big .accordion__title:after {
    font-size: 1.07639em;
    right: 0.625em; }

.accordion--big .accordion__content {
  background-color: #f5f5f5; }

.accordion--big .accordion__item.opened .accordion__title {
  border-bottom: none; }

.accordion--big .accordion__item.opened .accordion__title:after {
  font-size: 0.93939em;
  right: 0.54545em; }

.accordion--border {
  padding: 2.47525em 11.52758em;
  border-top: 1.9802em solid #eaeaea; }

.accordion .infor__btn .btn__text {
  min-width: 12.5em;
  padding: 0.66667em;
  color: #000000;
  background-color: #f5f5f5; }

.rte .accordion__title {
  color: #000000; }

@media screen and (min-width: 48em) {
  .accordion__wrapper--give {
    margin-bottom: 0; }
  .detail__desc-item .accordion__item.opened {
    padding-bottom: 1.25em; } }

@media screen and (max-width: 47.9375em) {
  .accordion__wrapper {
    padding: 0 1.1875em 2.1875em;
    margin-bottom: 4.375em;
    border-bottom: 1px solid #4a4a4a; }
  .accordion__item:not(.time-of-other__artists-content) .time-of-other__show-artists {
    margin-bottom: -0.38573em;
    padding-top: 1.2054em;
    padding-bottom: 1.2054em; }
  .accordion__item:not(.time-of-other__artists-content).opened .time-of-other__show-artists {
    margin-bottom: 0; }
  .accordion__title {
    padding: 1.06075em 1.83221em 0.67502em 0.48216em; }
  .accordion__content {
    margin: 0; }
  .accordion--big .accordion__content {
    margin-left: auto;
    margin-right: auto; }
  .accordion--big .accordion__title {
    font-size: 1.8125em;
    line-height: 1.04984em;
    padding: 0.75862em 1.51724em 1.03448em 0; }
  .accordion--big .accordion__item.opened .accordion__title:after {
    font-size: 0.68966em; }
  .accordion__item--last .cta--full {
    margin-top: 0; }
  .accordion__item--last .cta__text {
    font: 1.555em/1.04984em "GT-Walsheim-Black";
    color: #000000; } }

.ie11 .accordion--big .accordion__title:after {
  font-size: .65em; }

.ie11 .accordion--big .accordion__item.opened .accordion__title:after {
  font-size: .8em; }
