.contact-title {
  font: #{em(35)/1} $wal-b;
  color: $black;
  margin-bottom: em(90, 35);
}

.contact-us {
  @extend %clearfix;
  margin-bottom: em(103);

  .text-box {
    font-size: em(14);
    border: none;
    width: 100%;
    padding-top: em(10, 14);
    padding-bottom: em(10, 14);
    border-bottom: 1px solid $grey;
    outline: none;
    z-index: 2;
    background-color: transparent;
    transition: all .3s;

    &:focus {
      border-color: $blue-3;

      & ~ label {
        transform: translateY(-50%);
      }
    }
  }

  .label-input {
    font: #{em(13)/1} $robo-l;
    color: $grey-5;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transition: transform .5s;
  }

  .input-validation-error {
    border-color: $lolla-3;
  }

  .control-label {
    font: #{em(14)/1} $robo-m;
    color: $grey-5;
    display: none;
  }

  .radio {
    float: left;
    padding-right: em(10);

    input {
      position: absolute;
      left: -9999px;

      &:not(:checked) ~ label {
        &:after {
          opacity: 0;
        }
      }

      &:checked ~ label {
        color: $grey-8;

        &:after {
          opacity: 1;
        }
      }
    }

    label {
      font: #{em(12)/1} $robo-l;
      cursor: pointer;
      color: $grey-5;
      padding-left: em(25);
      position: relative;
      display: block;

      &:focus {
        outline: none;
      }

      &:before,
      &:after {
        position: absolute;
      }

      &:before {
        @extend %icon;

        content: $ico-radio;
        font-size: em(14, 14);
        color: $grey-5;
        top: em(2, 14);
        left: 0;
      }

      &:after {

        @extend %icon;

        content: $ico-radio-checked;
        font-size: em(14, 14);
        top: em(2, 14);
        left: 0;
        color: $black;
        line-height: 1.02;
      }
    }
  }

  .form-group {
    clear: none;
    width: 50%;
    z-index: 2;
    position: relative;
    margin-bottom: em(20, 14);

    &.sam-singleline-half_Label {
      padding-right: em(12);
      float: left;

      & ~ .sam-singleline-half_Label {
        padding-left: em(12);
        padding-right: 0;

        .label-input {
          left: em(12, 13);
        }
      }
    }

    &.sam-Contact_Email {
      width: 100%;
      clear: left;
      // margin-top: em(16, 14);
      // margin-bottom: em(20, 14);
    }
  }

  .sam-multiline_Label {
    width: 100%;

    .control-label {
      display: none !important; //sass-lint:disable-line no-important
    }
  }

  .contac-us_message {
    position: relative;
    border: 1px solid $grey;

    &.input-validation-error {
      border: 1px solid $lolla-3;
    }

    &.custom-textarea__focus {
      border-color: $blue-3;
    }
  }

  .has-error {
    .contac-us_message {
      border-color: $lolla-3;

      &.custom-textarea__focus {
        border-color: $blue-3;
      }
    }
  }

  .form-control {
    font-family: $robo-l;
    font-size: em(15);
    width: 100%;
    resize: none;
    padding: em(30, 15) em(20, 15) em(15, 15);
    border: none;

    &:focus {
      outline: none;
    }
  }

  .sum-character {
    font: #{em(14)}/1 $robo-l;
    color: $grey-5;
    position: absolute;
    bottom: em(10, 14);
    left: em(20, 14);
    display: none;
  }

  .label-multiline {
    font: #{em(14)}/1 $robo-l;
    color: $grey-5;
    position: absolute;
    top: em(10, 14);
    left: em(20, 14);
  }

  &__required {
    font: #{em(14)}/1 $robo-l;
    color: $grey-2;
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: em(22, 14);

    &.has-error {
      color: $red;
    }
  }

  &__note {
    font: #{em(12)}/#{em(17, 12)} $robo-l;
    color: $grey;
    font-style: italic;
  }

  &__bold {
    font-family: $robo-b;
    padding-right: em(3, 12);
  }

  .btn--cta {
    display: inline-block;
    padding-bottom: em(1);
  }

  .button-success__btn {
    font: #{em(18)/1} $robo-b;
    min-width: em(310, 18);
    padding: 0;
    margin: em(25, 18) auto 0;

    &.btn--cta {
      .btn__text {
        padding-top: em(10, 17.28);
        padding-bottom: em(15, 17.28);
      }
    }
  }

  .form-submit-border {
    .btn {
      min-width: em(222, 17.28);
      border: none;
      padding: em(12);
    }
  }

  .validation-summary-errors,
  .field-validation-error,
  .field-error {
    display: none;
  }
  
  .g-recaptcha ~ .field-validation-error {
	display: block !important;
	font: #{em(12)/1} $robo-l;
	color: $red;
  }

  .bg-warning {
    display: none;
  }

  .sam-Contact_RadioList {
    width: 100%;
  }
}

.input-has-value {
  .label-input {
    transform: translateY(-50%);
  }

  .sum-character {
    display: block;
  }
}

.sam-Contact_RadioList {
  @extend %clearfix;
  clear: both;
  margin-bottom: em(25);
}

.page-header > span {
  font: #{em(35)/1} $wal-b;
  color: $black;
  display: inline-block;
  margin-bottom: em(8);
}

.contact-success {
  clear: both;

  strong {
    font: #{em(16, 14)/1} $robo-b;
    color: $grey-1;
    display: inline-block;
    margin-bottom: em(5, 16);
  }

  p {
    font: #{em(14)/1} $robo-l;
    color: $grey-5;
    max-width: em(285, 14);
  }
}

@include minquery($breakpoint-min) {
  .contact-us-template {
    .col-offset-1 {
      padding-right: em(43, 16);
      padding-left: em(62, 16);
    }
  }

  .contact-title {
    font-size: em(38);
    line-height: em(52, 38);
    letter-spacing: em(3, 38);
    margin-bottom: em(94, 38);
  }

  .contact-us {

    .control-label {
      margin-bottom: em(30.5, 14);
      display: none;
    }

    .radio {
      width: percentage(1/3);
      margin-bottom: em(10, 14);
    }

    .form-control {
      margin-top: em(30, 14);
      padding-top: 0;
      padding-bottom: em(44, 15);
      height: em(105, 15);
    }

    .contac-us_message {
      margin-bottom: em(28);
      min-height: em(176, 15);
    }

    &__note {
      float: right;
      max-width: em(275, 12);
    }

    .button-success {
      min-width: em(308, 18);
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .contact-title {
    padding-left: em(10, 36);
  }

  .contact-us {
    margin-bottom: 0;

    padding-right: em(10);
    padding-left: em(10);

    .radio {
      width: 50%;
      margin-bottom: em(15, 14);
    }

    .control-label {
      margin-bottom: em(20, 14);
    }

    .form-control {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: em(10);
      height: em(62);
    }

    .contac-us_message {
      min-height: em(125);
      margin-bottom: em(20);
      padding-top: em(25);
      padding-bottom: em(30);
    }

    .btn--cta {
      display: block;
      margin: em(20) auto;
      max-width: em(228);
    }

    &__note {
      text-align: center;
      padding-right: em(20, 12);
      padding-left: em(20, 12);
    }

    &__required {
      margin-bottom: em(15, 14);
    }

    .label-multiline {
      top: em(20, 14);
    }

    .field-border {
      &.sam-singleline-half_Label {
        padding-right: em(6);
      }
    }
  }
}
