.infor {
  &__btn {
    margin-top: em(28, 18);
    margin-bottom: em(15, 18);
    min-width: em(225, 18);
  }

  &__list {
    margin-top: em(22);
    max-width: em(408);
  }
}

.rte {
  .infor__btn {
    &:hover {
      text-decoration: none;
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .infor {
    &__list {
      padding: em(5);
    }
  }

  .wrapper-btn {
    padding-left: em(10);
    padding-right: em(10);
  }
}
