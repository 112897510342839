
.download {
  &__wrapper {
    position: relative;
    z-index: 1;
  }

  &-item {
    @extend %clearfix;
    margin-bottom: em(50);
  }

  &__title {
    font: #{em(42.99)}/#{em(51.6, 42.99)} $wal-b;
    margin-bottom: em(100, 42.99);
    text-align: center;
  }

  &__desc {
    font: #{em(25)}/#{em(26, 25)} $wal-b;
    border-bottom: em(10, 25) solid $moon;
    display: block;
    padding-bottom: em(10, 25);
  }

  .download-list__link {
    font: #{em(14.4)}/#{em(21.6, 14.4)} $robo-r;
  }

  .download-list__ico {
    margin-right: em(8, 26);
    font-size: em(26, 14.4);
    line-height: em(31.2, 26);
    vertical-align: top;
  }
}

@media screen and (min-width: $breakpoint-min) {

  .download {
    &__wrapper {
      max-width: em(822, 15);
      margin: 0 auto;
      padding-bottom: em(75);
    }

    &__desc {
      width: percentage(220/800);
      float: left;
      text-align: right;
    }

    &__detail {
      float: left;
      width: (100- percentage(248/800));
      padding-left: em(28);
    }

    .download-list__text {
      width: 93%;
    }
  }

}

@media screen and (max-width: $breakpoint-max) {

  .download {
    &__wrapper {
      padding-left: em(10);
      padding-right: em(10);
      padding-top: em(40);
      padding-bottom: em(50);
    }

    &__title {
      font: #{em(35.83)}/#{em(37.62, 35.83)} $wal-b;
      margin-bottom: em(50, 35.83);
    }

    &__desc {
      font-size: em(21);
      margin-bottom: em(20, 21);
    }

    &-item {
      margin-bottom: em(45);
    }

    .download-list {
      max-width: 100%;
      float: none;
    }

    .download-list__item {
      margin-bottom: em(30);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .download-list__text {
      width: 85%;
    }
  }
}
