.search {
  @extend %clearfix;
  margin: 0 auto;
  padding: 0 em(110);
  max-width: em($container-width+200);
  position: relative;
  z-index: 1;

  .form__control--event {
    font-size: em(51.6);
    font-family: $wal-b;
    letter-spacing: em(-1.55, 51.6);
    padding-right: em(115, 51.6);
    color: $grey;
  }

  .form__label-event {
    width: 32%;
  }

  .custom-input {
    width: 68%;
  }
}

.search__form {
  margin: 0 auto em(67);
  position: relative;
  letter-spacing: em(-5);
  width: 100%;

  .icon-close {
    display: block;
    width: em(30);
    height: em(30);
  }
}

.custom-input {
  display: inline-block;
  position: relative;
  margin-left: em(-2);
  width: 100%;
  vertical-align: bottom;

  .form__submit-event {
    // @include size(em(49, 20), em(55, 20));

    position: absolute;
    top: 50%;
    right: 0;
    border: none;
    padding: 0;
    background-color: $white;
    cursor: pointer;
    transform: translateY(-50%);

    .icon-search {
      display: block;
      width: em(60);
      height: em(60);
    }
  }
}

@media screen and (max-width: em(1024)) {
  .search {
    .form__label-event {
      width: 35%;
    }

    .custom-input {
      width: 65%;
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .search {
    padding: 0 em(19);

    .form__label-event {
      width: 100%;
    }

    .custom-input {
      width: 100%;
    }
  }


  .search__form {
    margin-bottom: em(40);
    padding: 0 em(11);
    width: 100%;
  }

  .custom-input {
    display: block;
    margin-top: em(102);
    margin-left: 0;
    max-width: none;

    .form__submit-event {
      top: auto;
      bottom: 0;
      right: 0;
      transform: none;
    }
  }
}

@include keyframes(changeicon) {
  0% {
    transform: translate(-50%, -50%);
  }

  30% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }

  66% {
    transform: translate(-50%, 100%);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}
