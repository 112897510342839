.cta {
  position: relative;
  display: table;
  background-color: $black;
  padding: em(9) em(60) em(10) em(20);

  &__text {
    font: #{em(14.4)}/#{em(35, 14.4)} $robo-b;
    letter-spacing: em(.5, 14.4);
    display: table-cell;
    vertical-align: middle;
    color: $white;
  }

  &__ico {
    width: em(64);
    height: em(32);
    position: absolute;
    right: em(-11);
    top: 50%;
    margin-top: em(-16);

    circle,
    path {
      stroke: $white;
      stroke-width: 1.5;
    }
  }

  &__icon {
    font-size: em(30);
    text-align: right;
    transition: transform .5s;
    color: $white;
  }

  .cta__icon {
    position: absolute;
    right: em(15, 30);
    top: 50%;
    margin-top: em(-15, 30);
  }

  &:hover {
    .cta__icon {
      transform: translateX(em(5));
    }
  }

  &--full {
    margin-top: em(70);
    width: 100%;
  }

  &--spacing {
    margin-bottom: em(20);
  }

  &.cta--highlight {
    background: $lime;

    .cta__text {
      color: $black;
    }

    .cta__icon {
      color: $black;
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .cta {
    padding: em(9) em(60) em(10) em(10);

    &--full {
      margin-top: em(38);
    }
  }
}
