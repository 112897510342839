@media screen and (max-width: $breakpoint-max) {
  .menu {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    max-height: 100%;

    z-index: 99;
    background: $white;
    box-shadow: 0 em(3) em(4) 0 rgba($black, .1);
    transition: transform .3s ease;
    transform: translateZ(0);

    &.show-popup {
      z-index: 1;
    }

    &.opened {
      overflow: auto;

      .menu__btn--burger {
        .bar {
          background-color: $grey;
        }

        .bar:first-child {
          transform: translateY(8px) rotate(45deg);
        }

        .bar:nth-child(2) {
          transform: rotate(-45deg);
        }

        .bar:nth-child(3) {
          opacity: 0;
        }
      }
    }

    &.hide {
      transform: translateY(-100%);
    }

    &__top {
      position: relative;
      z-index: 1;
    }

    &__content {
      overflow: hidden;
      width: 100%;
      display: none;
      position: relative;
      z-index: 0;
    }

    &__logo {
      display: block;
      width: em(100);
      float: right;
      margin: em(15) em(19) 0;
    }

    &__btn--burger {
      margin: em(11) em(21);
      background: none;

      .bar {
        width: em(28);
      }
    }

    &__slider {
      width: 100%;
      background: $grey-10;
      padding: 0 em(19);

      .slick-list {
        overflow: visible;
      }
    }

    &__block {
      margin: em(12) em(5);
    }

    &__links {
      display: none;
    }

    &__item {
      background-color: $black;

      &.opened {
        .menu__trigger {
          color: $moon;
          border-bottom: 0;

          &:after {
            content: $ico-minus-circle;
          }
        }
      }

      &:last-child {
        .menu__trigger {
          border-bottom: none;
        }
      }
    }

    &__trigger {
      font: #{em(20.74)}/#{em(24.88, 20.74)} $wal-b;
      color: $white;
      padding: em(13, 20.74) 0;
      margin: 0 em(19, 20.74);
      display: block;
      border-bottom: $grey-2 1px solid;

      &--last {
        border-bottom: none;
      }

      &:after {
        content: $ico-plus-circle;
        font-family: $sam;
        font-size: em(29, 20.74);
        float: right;
      }
    }

    &__link {
      font: #{em(14.4)}/#{em(17.28, 14.4)} $robo-b;
      color: $grey;
      padding: em(11.5, 14.4) em(35, 14.4);
      background-color: $grey-10;
      box-shadow: 0 em(1) em(4) 0 rgba($black, .5);
      display: block;
    }

    &__footer {
      background-color: $grey-10;
      padding: em(17) 0 em(21);
      text-align: center;
      box-shadow: 0 em(1) em(4) 0 rgba($black, .5);

      &__li {
        display: inline-block;

        &:last-child {
          .menu__footer__link:after {
            display: none;
          }
        }
      }

      &__link {
        font: #{em(10)}/ #{em(12, 10)} $robo-b;
        position: relative;
        color: $white;
        letter-spacing: em(.91, 10);
        padding: 0 em(15, 10);

        &:after {
          content: '';
          position: absolute;
          right: -1px;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: $white;
        }
      }
    }

    &__time,
    .social-share,
    &__contact {
      background-color: $grey-4;
      padding: em(18);
      text-align: center;
    }

    &__time,
    .social-share {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: em(20);
        right: em(20);
        height: 1px;
        background-color: $black;
      }
    }

    &__status {
      font: #{em(14.4)}/#{em(17.28, 14.4)} $wal-b;
      margin-right: em(43, 14.4);
      margin-left: em(5, 14.4);
      display: inline-block;
      width: 28%;
    }

    .ico-clock {
      vertical-align: middle;
      display: inline-block;

      &:before {
        font-size: em(24);
      }
    }

    &__info {
      font: #{em(10)}/#{em(15, 10)} $robo-b;
      margin-left: em(31, 10);
      display: inline-block;
      text-align: center;
      width: 32%;
      vertical-align: middle;
    }

    .social-share {
      padding-bottom: em(13);
      border-bottom: none;

      &__item {
        font-size: em(12);
        margin: 0 em(21, 12);
      }
    }

    &__contact {
      &__detail {
        font: #{em(12)}/#{em(15, 12)} $robo-r;
        display: inline-block;

        &:last-child {
          margin-left: em(12, 12);
        }

        .ico-contact-us,
        .ico-email {
          vertical-align: middle;
          margin-right: em(3, 12);
        }

        .ico-contact-us {
          &:before {
            font-size: em(14, 12);
          }
        }
      }
    }

    &__search {
      position: absolute;
      left: 0;
      right: 0;

      .form-search {
        display: none;
        background: $white;
        padding: em(8) em(20) em(20);
        border-top: rgba($black, .1) 1px solid;
        border-bottom: rgba($black, .1) 1px solid;
      }

      .custom-input {
        margin-top: 0;
        margin-bottom: 0;
        font-family: $wal-b;
      }

      .form__control--event {
        @include placeholder() {
          font-family: $wal-b;
          color: rgba($grey, .51);
          padding-left: em(15, 24.88);
        }

        font-size: em(24.88);
        line-height: em(29.86, 24.88);
        padding-top: em(16, 24.88);
        padding-bottom: em(16, 24.88);
      }
    }

    &__btn-search {
      position: absolute;
      top: em(-37);
      left: em(69);
      color: $black;

      &:before {
        font-size: em(22);
      }
    }
  }
}
