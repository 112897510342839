.we-are-sam {
  border: none; //fix bug line top - bottom
  padding-left: em(112);
  padding-right: (100% - percentage(440/1024));
  padding-top: percentage(145/645);
  padding-bottom: percentage(145/645);
  background-color: $grey-4;
  position: relative;

  .desktop &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    transform-origin: 100% 0;
    transition: transform 1.2s ease .3s;
    z-index: 3;
  }

  &__wrapper {
    position: relative;
    border-top: 2px solid #fff; //fix bug line top - bottom
    overflow: hidden;

    .desktop &.animation {
      .we-are-sam::before {
        transform: scaleX(0);
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      background: $white;
      height: 24%;
    }

    &::before {
      left: 0;
      padding-right: 1px;
      width: percentage(440/1024);
      transform: skewY(14deg);
      transform-origin: 100% 0;
      z-index: 1;
    }

    &::after {
      right: 0;
      transform: skewY(-14deg);
      transform-origin: 0 0;
      width: (100% - percentage(440/1024));
    }
  }

  &__triangle {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #fff; //fix bug line top - bottom
    width: 100%;
    height: 26%;
    z-index: 1;
    pointer-events: none;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      background: $white;
      height: 100%;
    }

    &::before {
      left: 0;
      padding-right: 1px;
      width: (100% - percentage(378/1024));
      transform: skewY(-14deg);
      transform-origin: 100% 0;
    }

    &::after {
      right: 0;
      transform: skewY(14deg);
      transform-origin: 0 0;
      width: percentage(378/1024);
    }
  }

  &__picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: (100% - percentage(440/1024));

    img {
      width: 100%;
    }
  }

  &__detail {
    position: relative;
    // margin-left: em(80);
    margin-right: em(22);
    padding-top: em(10);
    z-index: 2;

    .btn--cta {
      margin-top: em(40, 14);
      width: em(226, 14);
      font: #{em(14)}/#{em(16, 14)} $robo-b;
      letter-spacing: em(.44, 14);

      .btn__text {
        background-color: $grey-4;
      }
    }
  }

  &__title {
    position: relative;
    z-index: 2;
    color: $black;
    font: #{em(53.78)}/#{em(76, 53.78)} $wal-b;
    letter-spacing: em(-2.24, 53.78);
  }

  &__desc {
    color: $black-1;
    font: #{em(14)}/#{em(18, 14)} $robo-l;
    letter-spacing: em(.44, 14);
  }
}

@media screen and  (max-width: $breakpoint-max) {

  .we-are-sam {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: percentage(210/645);

    &__wrapper {

      &::before,
      &::after {
        top: -11%;
      }

      &::before {
        width: percentage(230/320);
        transform: skewY(11deg);
      }

      &::after {
        width: (100% - percentage(230/320));
        transform: skewY(-13deg);
      }
    }

    &__triangle {

      &::before,
      &::after {
        top: 60%;
      }

      &::before {
        width: (100% - percentage(208/320));
      }

      &::after {
        width: percentage(208/320);
      }
    }

    &__picture {
      position: relative;
      margin-top: em(-29);
      padding-bottom: 76%;
      width: 100%;
      height: 50%;
      border-top: 2px solid $grey-4;
      overflow: hidden;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: -73%;
        background: $grey-4;
        height: 100%;
      }

      &::before {
        left: 0;
        padding-right: 1px;
        width: percentage(62/320);
        transform: skewY(14deg);
        transform-origin: 100% 0;
      }

      &::after {
        right: 0;
        transform: skewY(-14deg);
        transform-origin: 0 0;
        width: (100% - percentage(62/320));
      }
    }

    &__triangle-picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-top: 1px solid $grey-4;
      border-bottom: 1px solid $grey-4;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 78%;
        background: $grey-4;
        height: 100%;
      }

      &::before {
        left: 0;
        padding-right: 1px;
        width: percentage(110/320);
        transform: skewY(-14deg);
        transform-origin: 100% 0;
      }

      &::after {
        right: 0;
        transform: skewY(14deg);
        transform-origin: 0 0;
        width: (100% - percentage(110/320));
      }
    }

    &__title {
      position: relative;
      padding: em(38, 39) em(20, 39) 0;
      font: #{em(38.42)}/#{em(46.8, 39)} $wal-b;
      letter-spacing: em(-1.14, 39);
      z-index: 1;
    }

    &__detail {
      margin-left: 0;
      margin-right: 0;
      padding-top: em(28);
      padding-left: em(20);
      padding-right: em(20);

      .btn--cta {
        margin-top: em(30, 14);
        width: 100%;
      }
    }
  }
}
