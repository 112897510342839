.newsletter {
  .control-label {
    display: none;
  }

  &-inner {
    background-color: $lolla;
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      background: $lolla;
      height: 100%;
      z-index: -1;
    }

    &:before {
      left: 0;
      transform: skewY(14deg);
      transform-origin: 100% 0;
      width: percentage(436px/1024px);
    }

    &:after {
      right: 0;
      transform: skewY(-14deg);
      transform-origin: 0 0;
      width: (100% - percentage(436px/1024px));
    }
  }

  .text-left {
    @extend %clearfix;
  }

  &__description {
    font: #{em(15)}/#{em(17, 15)} $robo-m;
    color: $white;
    margin-bottom: em(35, 15);
    max-width: em(315, 15);
  }

  // .has-error {
  //   display: none;
  // }

  .page-header > span {
    font: #{em(35)/1} $wal-b;
    color: $white;
    display: inline-block;
    margin-bottom: em(8, 35);
  }

  .text-box {
    font: #{em(14)/1} $robo-m;
    color: $white;
    letter-spacing: .5px;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
    padding: em(20, 14) em(26, 14);
    display: block;

    &::-webkit-input-placeholder {
      color: $placeholder;
      letter-spacing: .5px;
    }

    &::-moz-placeholder {
      color: $placeholder;
      letter-spacing: .5px;
    }

    &:-ms-input-placeholder {
      color: $placeholder;
      letter-spacing: .5px;
    }

    &:focus {
      outline-color: transparent;
    }
  }

  .label-input,
  .control-label,
  .validation-summary-errors,
  .field-validation-error {
    display: none;
  }

  .form-group {
    clear: none;
  }

  .input-validation-error {
    background-color: $moon;
    color: $grey-3;
  }

  .sam-Newsletter_CheckboxList {
    border: em(2) solid $white;
    border-top: none;

    .control-label {
      font: #{em(14)/1} $robo-m;
      color: $white;
      display: inline-block;
      letter-spacing: .5px;

      &:before {
        color: $white;
      }
    }

    .checkbox {
      display: inline-block;
      position: relative;
      padding: 0 0 0 em(25);

      &:after {
        display: none;
      }

      input {
        position: absolute;
        left: -9999px;

        &:not(:checked) + label {
          &:after {
            opacity: 0;
          }
        }

        &:checked + label {
          &:before {
            background-color: $white;
          }

          &:after {
            opacity: 1;
          }
        }
      }

      label {
        cursor: pointer;
        font: #{em(14)/1} $robo-r;
        color: $white;

        &:focus {
          outline: none;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: em(11, 14);
          height: em(11, 14);
          border: em(2, 14) solid $white;
          background-color: transparent;
          border-radius: em(3, 14);
        }

        &:after {
          @extend %icon;
          content: $ico-tick;
          position: absolute;
          top: 54%;
          width: em(16, 16);
          transform: translateY(-50%);
          left: 0;
          font-size: em(16);
          font-weight: bold;
          line-height: .8;
          color: $lolla;
          transition: all .2s;
          margin-top: em(-1px, 16);
        }
      }
    }
  }

  .field-border {
    float: left;
    border: em(2) solid $white;
    border-right: none;
    padding: em(2);
  }

  .form-submit-border {
    position: relative;
    z-index: 1;
    border: em(2) solid $white;
    overflow: hidden;
    border-top-width: 0;
    cursor: pointer;

    // &:hover {
    //   border-width: em(5);
    //   border-top-width: em(3);

    //   .btn {
    //     animation-name: changetext;
    //     animation-duration: .3s;
    //     animation-timing-function: linear;
    //   }
    // }

    .btn {
      font: #{em(16)/1} $robo-b;
      color: $white;
      text-transform: uppercase;
      width: 100%;
      border: none;
      border-color: $white;
      background-color: transparent;
      cursor: pointer;
      padding: em(15) em(26);
      z-index: 2;
      height: 100%;
      transition: background-color .3s ease, color .3s ease;

      &:hover {
        background-color: $white;
        color: $lolla;
      }
    }
  }

  .field-error {
    display: none;

    span {
      display: none;
    }
  }

  .note {
    float: right;
    margin-top: em(15);
    font: #{em(13.5)/1} $robo-l;
    color: $white;
    opacity: .8;
    letter-spacing: .5px;
  }

  .note-error {
    color: $moon;

    &:before {
      content: '';
      background: url('../img/icon-error.png') no-repeat;
      background-size: auto 100%;
      display: inline-block;
      width: em(24);
      height: em(18);
      padding-right: em(5);
    }
  }

  .newletter__success-message {
    color: $lolla;
    font: #{em(16)/1} $robo-b;
    display: inline-block;
    padding: em(55) em(25);
  }

  .newletter__content-success {
    @extend %clearfix;
    background-color: $half-black;

    .form-submit-border {
      cursor: default;

      &:hover {
        .btn {
          animation: none;
        }
      }
    }

    .btn {
      background-color: $white;
      color: $lolla;
      cursor: default;
      pointer-events: none;
    }
  }

  &--personalize {
    .newsletter {
      &-inner {
        padding: em(41) em(112) em(73);
      }

      &__description {
        max-width: inherit;
        font-size: em(14.4);
        line-height: em(21, 14.4);
      }

      &__button {
        text-align: right;

      }
    }

    .btn {
      border: em(5) solid $white;
      color: $white;
      width: 100%;
      max-width: em(224);
      padding: em(17);
      text-align: center;

      // &:hover {
      //   .btn__text {
      //     animation-name: changetext;
      //     animation-duration: .3s;
      //     animation-timing-function: linear;
      //   }
      // }
    }

    .btn__text {
      background: transparent;
      padding: 0;
      font-family: $robo-b;
      display: inline-block;
    }

    .page-header {
      margin-bottom: em(23);

      span {
        font-size: em(33);
        margin-bottom: 0;
      }
    }
  }
}

//Fix bug button IE
.ie {
  .newsletter {
    .form-submit-border {
      .btn {
        padding-top: em(19.1);
      }
    }
  }
}

.newsletter-inner {
  .scWebEditInput {
    display: none;
  }

  .sam-Newsletter_CheckboxList {
    .scWebEditInput {
      display: inline-block;
      color: $white;
    }
  }
}

@include minquery($breakpoint-min) {
  .newsletter {
    position: relative;
    z-index: 1;
    padding-top: percentage(146px/1024px);

    &-inner {
      padding: 0 em(40) em(15);
    }

    .sam-Newsletter_CheckboxList {
      width: 76%;
      float: left;
      height: em(62);
      padding: em(18) em(5) em(20) em(26);
      border-right: none;

      .checkbox {
        margin-left: em(30);
        margin-top: em(1);
      }
    }

    .form-submit-border {
      width: 24%;
      float: left;
      height: em(62);
    }

    .field-border {
      width: 27.5%;

      &.sam-Newsletter_Email {
        border-right: em(2) solid $white;
        width: 45%;
      }
    }

    .newletter__content-success {
      position: relative;

      .form-submit-border {
        position: absolute;
        bottom: 0;
        right: 0;
        border: 0;
      }
    }

    &--personalize {
      .newsletter__button {
        margin-left: em(-5);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .newsletter {
    position: relative;
    z-index: 1;
    padding-top: percentage(59px/320px);

    &-inner {
      padding: em(40) em(17) em(15);

      &:before {
        width: percentage(83px/320px);
      }

      &:after {
        width: (100% - percentage(83px/320px));
      }
    }

    &__description {
      text-align: center;
      margin: 0 auto em(35, 15);
    }

    .page-header {
      margin: 0 auto;
      text-align: center;
    }

    .sam-Newsletter_CheckboxList {
      width: 100%;
      padding: em(10);
      clear: both;

      .checkbox {
        display: block;
        margin-bottom: em(10);
      }

      .control-label {
        margin-bottom: em(15);
      }
    }

    .text-box {
      padding: em(10, 14);
    }

    .form-submit-border {
      width: 100%;
      margin-bottom: em(15);

      .btn {
        padding: em(10);
      }
    }

    .field-border {
      width: 50%;
      border-right: em(2) solid $white;
    }

    .scfSingleLineTextBorder {
      border-right: none;
    }

    .sam-Newsletter_Email {
      width: 100%;
      border-top: none;
    }

    .note {
      margin-top: 0;
    }

    .newletter__content-success {
      margin-bottom: em(10);

      .form-submit-border {
        margin-bottom: 0;
      }
    }

    .newletter__success-message {
      padding: em(30, 16) em(31, 16);
      min-height: em(263, 16);
      line-height: em(20, 16);
    }

    &--personalize {
      .newsletter {
        &-inner {
          padding: em(40) em(17) em(15);
        }

        &__button {
          text-align: center;
        }
      }

      .page-header {
        margin-bottom: em(8);
      }
    }
  }
}
