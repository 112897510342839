section:not(.newsletter) {
  .page-header {
    h2 {
      font: #{em(38)/1} $wal-b;
      color: $black;
      margin-bottom: em(70, 38);
    }
  }

  .form-normal {
    .button-success__btn {
      margin-top: em(25, 18);
    }

    .btn--cta-revert {
      margin-bottom: em(5);
      padding-right: em(5);

      .form-submit-border {
        min-height: em(44, 15);
      }
    }

    .form-group {
      &.half-input {
        float: left;
        width: 50%;
        clear: none;
        padding-right: em(12);

        & ~ .half-input {
          padding-left: em(12);
          padding-right: 0;
        }
      }

      &.has-error {
        .checkbox {
          background-color: $yellow;
        }
      }
    }

    .intro-form {
      .help-block {
        font: #{em(12)}/#{em(17, 12)} $robo-l;
        color: $grey;
        font-style: italic;

        b {
          font-family: $robo-b;
          font-style: normal;
          font-weight: normal;
        }
      }
    }
  }

  .multiline-with-title {
    & > p {
      display: none;
    }

    .form-group {
      .control-label {
        font-size: em(14.4);
        font-family: $robo-b;
        color: $grey;
        padding: em(9, 14.4) 0;
        display: block;
      }

      &.has-error {
        .control-label {
          background-color: $yellow;
        }
      }
    }
  }

  .multiline-without-title {
    &.has-error {
      .custom-textarea {
        background-color: $yellow;
      }
    }
  }

  .validation-summary-errors {
    display: none;
  }

  .help-block {
    display: none;
  }

  .form-group {
    margin-bottom: em(20, 14);

    &.has-error {
      .field-content {
        &.single-line {
          background-color: $yellow;
        }
      }

      .custom-textarea {
        // background-color: $yellow;
      }
    }

    &.custom_radiolist,
    &.custom_checkboxlist {
      .control-label {
        display: block;
        font-size: em(14.4);
        font-family: $robo-b;
        color: $grey;
      }
    }
  }

  .checkbox {
    padding: em(14) 0 0;
    margin-top: 0;
    margin-bottom: em(12);
    max-width: 100%;

    &:after {
      display: none;
    }

    input {
      position: absolute;
      left: -9999px;
      opacity: 0;

      &:checked ~ label {
        color: $grey-8;

        &:before {
          background-color: $black;
          border-color: $black;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    label {
      font: #{em(14.4)}/#{em(22, 14.4)} $robo-l;
      cursor: pointer;
      color: $grey;
      padding-left: em(25);
      position: relative;
      display: inline-block;

      &:focus {
        outline: none;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: em(2, 14.4);
        width: em(12, 14.4);
        height: em(12, 14.4);
        border: em(1, 14.4) solid $grey-5;
        background-color: $white;
        border-radius: em(2);
      }

      &:after {
        position: absolute;
        transition: opacity .2s;
        background-color: transparent;
        content: $ico-tick;
        color: $white;
        font-family: $sam;
        left: 0;
        top: em(2, 14.4);
        line-height: em(13, 14.4);
      }
    }
  }

  .radio {
    padding-right: em(10);
    margin-top: em(14);

    input {
      position: absolute;
      left: em(-9999, 14.4);
      opacity: 0;

      &:not(:checked) ~ label {
        color: $grey;

        &:after {
          opacity: 0;
        }
      }

      &:checked + label {
        color: $grey-8;
      }
    }

    label {
      font: #{em(14.4)}/#{em(22, 14.4)} $robo-l;
      color: $grey;
      position: relative;
      padding-left: em(25);
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
      }

      &:before {
        @extend %icon;

        content: $ico-radio;
        font-size: em(16);
        color: $grey-5;
        top: em(2, 16);
        left: 0;
      }

      &:after {
        @extend %icon;

        content: $ico-radio-checked;
        font-size: em(16);
        top: em(2, 16);
        left: 0;
        color: $black;
      }
    }
  }

  .single-line {
    max-width: 100%;
    margin-top: 0;
    margin-left: 0;
    position: relative;

    input {
      position: relative;
      font-size: em(14.4);
      border: none;
      width: 100%;
      padding-top: em(17, 14.4);
      padding-bottom: em(21, 14.4);
      border-bottom: em(1) solid $grey;
      outline: none;
      z-index: 2;
      background-color: transparent;
      font-family: $robo-l;
      color: $grey-5;
      transition: all .4s;

      &:focus {
        // border-color: $grey-8;
        // box-shadow: 0  em(1, 14.4) 0 $grey-8;
        padding-top: em(31, 14.4);
        padding-bottom: em(7, 14.4);
      }

      &:focus ~ label {
        top: em(11, 14.4);
        transform: none;
        font-size: em(10, 14.4);
      }
    }

    label {
      font: #{em(14.4)/1} $robo-l;
      color: $grey;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: all .3s;
    }

    &.input-has-value {
      input {
        font-family: $robo-r;
        color: $grey-8;
        padding-top: em(31, 14.4);
        padding-bottom: em(7, 14.4);
      }

      label {
        top: em(11, 14.4);
        transform: none;
        font-size: em(10, 14.4);
      }
    }
  }

  .custom-select--special {
    &.selected {
      .custom-select__styled {
        color: $grey-8;
        font-family: $robo-r;
      }
    }

    .custom-select__options-special {
      overflow-y: hidden;
    }
  }

  .contact-us-template {
    .btn--cta {
      display: inline-block;
    }
  }

  .form-submit-border {
    .btn {
      display: inline-block;
      vertical-align: middle;
      min-width: em(222, 17.28);
      border: none;
      padding: em(12);
    }
  }

  .control-label,
  .field-info,
  .field-validation-error {
    display: none;
  }

  .control-label {
    &.custom-select__styled {
      display: block;
    }
  }

  .btn--cta-revert {
    display: inline-block;
  }

  .sam-Contact_RadioList {
    .control-label {
      display: block;
    }
  }

}

.introduction-form,
.footer-form {
  margin-bottom: em(30);

  p {
    font-size: em(14.4);
    color: $grey;
  }
}

.content-form {
  margin-bottom: em(30);

  .col-md-12 {
    width: 100%;
  }

  .required-field {
    .control-label {
      &:before {
        display: none;
      }
    }

    .checkbox {
      &:after {
        display: none;
      }
    }
  }

  legend {
    font: #{em(14.4)/1} $robo-b;
    color: $grey-8;
    margin-bottom: em(10, 14.4);

    > span {
      color: $white;
      position: relative;
      width: em(21, 14.4);
      margin-right: em(10, 14.4);
      display: inline-block;
      font-size: em(14.4);
      text-align: center;

      &:before {
        content: '';
        border-radius: 50%;
        background-color: $grey-8;
        width: em(21, 14.4);
        height: em(21, 14.4);
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }
  }

  fieldset {
    padding: 0 0 em(20);
    border: none;
  }
}

@include minquery($breakpoint-min) {
  section:not(.newsletter) {
    .form-success {
      .button-success__btn {
        padding-left: em(30);
        padding-right: em(30);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  section:not(.newsletter) {
    .btn--cta-revert {
      display: block;
      margin: em(20) auto;
      max-width: em(228);
    }
  }
}
