.form {

  &__control {
    border: none;
    width: 100%;

    &:focus {
      outline: 0;
      border-bottom: 1px solid $moon-1;
    }
  }

  &__control--event {
    border-bottom: 1px solid $grey;
    padding: em(14, 43) em(61, 43) em(14, 43) em(16, 43);
    box-sizing: border-box;
    font-size: em(43);
  }

  &__label-event {
    display: inline-block;
    max-width: em(302, 43);
    font: #{em(43, 16)}/#{em(45.15, 43)} $wal-b;
    letter-spacing: em(-1.6, 43);
    vertical-align: bottom;
  }

  &__control--error {
    border-bottom: 1px solid $lolla-3;
  }
}

.custom-textarea {
  position: relative;
  border: em(1, 14.4) solid $grey;
  padding-top: em(30);
  min-height: em(175, 14.4);
  box-shadow: 0 0 0 em(2, 14.4) $white;

  &__control,
  .form-control {
    display: inline-block;
    padding: em(10, 14.4) em(17, 14.4) em(19, 14.4);
    border: none;
    font-family: $robo-l;
    color: $black;
    font-size: em(14.4);
    width: 100%;
    outline: none;
    z-index: 2;
    background-color: transparent;
    transition: all .4s;
    height: em(175, 14.4);
    resize: none;
    overflow-y: auto;
  }

  &__control {

    &:focus {
      & ~ .custom-textarea__label {
        font-size: em(10, 14.4);
        top: em(11, 14.4);
      }
    }
  }

  .form-control  {

    &:focus {

      & ~ .label-multiline {
        font-size: em(10, 14.4);
        top: em(11, 14.4);
      }
    }
  }

  &.input-has-value {
    .label-multiline {
      font-size: em(10, 14.4);
      top: em(11, 14.4);
    }
  }

  &__focus {
    border-color: $grey-8;
    box-shadow: 0 0 0 em(1, 14.4) $grey-8;
  }

  .custom-textarea__label,
  .label-multiline {
    font: #{em(14.4)/1} $robo-l;
    color: $grey;
    position: absolute;
    z-index: 1;
    top: em(17, 14.4);
    left: em(23, 14.4);
    transition: all .3s;
  }
}

.custom-input {
  position: relative;

  &__control {
    position: relative;
    font-size: em(14.4);
    border: none;
    width: 100%;
    padding-top: em(17, 14.4);
    padding-bottom: em(21, 14.4);
    border-bottom: em(1, 14.4) solid $grey;
    outline: none;
    z-index: 2;
    background-color: transparent;
    font-family: $robo-l;
    color: $grey-5;
    transition: all .4s;

    &:focus {
      border-color: $grey-8;
      box-shadow: 0  em(1, 14.4) 0 $grey-8;
      padding-top: em(31, 14.4);
      padding-bottom: em(7, 14.4);

      & ~ .custom-input__label {
        top: em(11, 14.4);
        transform: none;
        font-size: em(10, 14.4);
      }
    }
  }

  .custom-input__label {
    font: #{em(14.4)/1} $robo-l;
    color: $grey;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: all .3s;
  }
}

.input-has-value {

  .custom-input__control {
    font-family: $robo-r;
    color: $grey-8;
    padding-top: em(31, 14.4);
    padding-bottom: em(7, 14.4);
  }

  .custom-input__label,
  .custom-textarea__label {
    top: em(11, 14.4);
    transform: none;
    font-size: em(10, 14.4);
  }

}

.custom-radio,
.custom-checkbox {
  padding-right: em(10);
  margin-top: em(14);

  .custom-checkbox__control,
  .custom-radio__control {
    position: absolute;
    left: em(-9999, 14.4);
    opacity: 0;

    &:not(:checked) ~ label {
      &:after {
        opacity: 0;
      }
    }

    &:checked ~ label {
      color: $grey-8;

      &:after {
        opacity: 1;
      }

      .custom-radio__text {
        color: $grey-8;
      }
    }
  }
}

.custom-radio {
  .custom-radio__label {
    position: relative;
    padding-left: em(25);
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
    }

    &:before {
      @extend %icon;

      content: $ico-radio;
      font-size: em(16);
      color: $grey-5;
      top: em(4, 16);
      left: 0;
    }

    &:after {
      @extend %icon;

      content: $ico-radio-checked;
      font-size: em(16);
      top: em(2, 16);
      left: 0;
      color: $black;
    }
  }

  &__text {
    font-size: em(14);
    color: $grey-5;
  }

  &__control {
    &:checked ~ label {
      .custom-control__specify {
        @include placeholder() {
          color: $grey-8;
        }
        color: $grey-8;
        border-color: $grey-8;
      }
    }
  }
}

.custom-checkbox {
  max-width: em(350);

  &--full {
    padding: em(14) 0;
    margin-top: 0;
    max-width: 100%;

    .custom-checkbox__text {
      max-width: em(500, 14.4);
    }
  }

  .custom-checkbox__control {
    position: absolute;
    left: -9999px;

    &:checked ~ label {
      &:before {
        background-color: $black;
        border-color: $black;
      }

      &:after {
        opacity: 1;
      }

      .custom-control__specify {
        @include placeholder() {
          color: $grey-8;
        }

        border-color: $grey-8;
      }
    }
  }

  .custom-checkbox__label {
    font: #{em(14.4)}/#{em(22, 14.4)} $robo-l;
    cursor: pointer;
    color: $grey;
    padding-left: em(25);
    position: relative;
    display: inline-block;

    &:focus {
      outline: none;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: em(2, 14.4);
      width: em(12, 14.4);
      height: em(12, 14.4);
      border: em(1, 14.4) solid $grey-5;
      background-color: $white;
      border-radius: em(2);
    }

    &:after {
      position: absolute;
      transition: opacity .2s;
      background-color: transparent;
      content: $ico-tick;
      color: $white;
      font-family: $sam;
      left: 0;
      top: em(2, 14.4);
      line-height: em(13, 14.4);
    }
  }

  .custom-checkbox__text {
    display: inline-block;
    vertical-align: top;
  }

  .custom-checkbox__link {
    font-family: $robo-b;
    color: $blue-3;
  }
}

%inputdate {
  .custom-input__control {
    @include placeholder() {
      color: $grey;
      opacity: 1;
      font-family: $robo-l;
    }

    padding: em(18, 14.4) 0 em(16, 14.4) em(100, 14.4);
    color: $grey-8;
    font-family: $robo-r;

    &:focus ~ .custom-input__label {
      font-size: em(14.4);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .custom-input__label {
    font-size: em(14.4);
    top: 50%;
    transform: translateY(-50%);
  }
}

.custom-input--date {
  @extend %inputdate;

  &.input-has-value {
    @extend %inputdate;
  }
}

@media screen and (max-width: $breakpoint-max) {
  .form {

    &__control--event {
      padding: em(8, 33) em(51, 33) em(7, 33) em(8, 33);
      font-size: em(33);
    }

    &__label-event {
      font: #{em(35.83)}/#{em(39.413, 35.83)} $wal-b;
      letter-spacing: em(-1.42, 35.83);
    }
  }

  .custom-radio,
  .custom-checkbox,
  .custom-input {
    margin-bottom: em(20);
  }

  .custom-checkbox,
  .custom-radio {
    padding-right: 0;
  }
}
