.banner--category {
  position: relative;

  .banner__title {
    font-size: em(36.8);
    letter-spacing: em(-.5, 36.8);
    margin-bottom: em(20, 36.8);
  }

  .banner__content {
    max-width: em(400, 15);
  }

  .banner__description {
    position: relative;
    font: #{em(16)}/#{em(22, 16)} $robo-b;
    letter-spacing: em(-.1, 16);
    color: $grey-8;
    padding-bottom: em(9, 16);

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: em(2, 16);
      max-width: em(258);
      width: 100%;
      background-color: $grey-9;
    }
  }

  .banner__text {
    font: #{em(14.4)}/#{em(20.16, 14.4)} $robo-l;
    letter-spacing: em(-.1, 14.4);
    color: $grey-8;
    margin-bottom: em(15, 14.4);
    opacity: 0;
    animation: slideUp .9s ease 1.2s 1 forwards;
  }

}

@media screen and (max-width: $breakpoint-max) {
  .banner--category {

    .banner__content {
      position: relative;
      max-width: em(285);
    }

    .banner__title {
      font-size: em(24.88);
      letter-spacing: em(-1.17, 24.88);
      margin-bottom: em(20, 24.88);
    }

    .banner__description {
      font: #{em(14.4)}/#{em(20.16, 14.4)} $robo-b;
      margin-bottom: em(10, 14.4);
      padding-bottom: em(16, 14.4);

      &:before {
        max-width: em(260, 14.4);
      }
    }

    .banner__text {
      font: #{em(12)}/#{em(14, 10)} $robo-l;
      margin-bottom: em(22, 10);
    }

  }
}
