.learn-at-sam {
  position: relative;
  max-width: em(800);
  margin: 0 auto;

  &__content {
    padding-top: em(16);
    padding-left: em(80);
  }

  &__title {
    color: $black;
    font: #{em(53.78)}/#{em(76, 53.78)} $wal-b;
    letter-spacing: em(-2.24, 53.78);
  }

  &__desc {
    color: $black-1;
    font: #{em(14)}/#{em(18, 14)} $robo-l;
    letter-spacing: em(.44, 14);
  }

  &__more {
    color: $black-1;
    font: #{em(14)}/#{em(18, 14)} $robo-b;
    letter-spacing: em(.44, 14);
  }

  &__limit {
    width: em(220, 14);
  }
}

@media screen and  (max-width: $breakpoint-min) {
  .learn-at-sam {
    &__content {
      padding-top: 0;
      padding-left: 0;
    }

    &__title {
      font: #{em(30)}/#{em(43, 30)} $wal-b;
      letter-spacing: em(-1.25, 30);
    }

    &__limit {
      width: 100%;
    }
  }
}
