.gallery-module {
  @include deco-triangle($grey-6, $white, $orange, $orange);
  padding-bottom: em(150);

  &__content {
    @extend %clearfix;
    display: block;
    margin-bottom: em(60);
  }

  .button__wrapper {
    display: none;
  }

  &__top-bar {
    margin-top: em(100);
    margin-bottom: em(70);
  }

  .events-filter {
    &__display,
    &__type {
      width: auto;
    }

    &__type {
      margin-right: em(13);
    }

    .ico {
      color: $grey-15;

      &-grid {
        font-weight: bold;
      }
    }

    &__item {
      &:first-child {
        margin-right: em(16);
      }

      &.active {
        .ico {
          color: $black;
        }
      }
    }

  }

  &__item {
    margin-bottom: em(18);
  }

  .more-resource {
    margin-bottom: em(55);
  }

  .events-filter__display {
    &--sort {
      position: relative;
      margin-right: em(24, 17.28);

      .events-filter__label {
        padding-right: em(6, 17.28);
      }

      &:after {
        content: '';
        display: inline-block;
        background-color: $black;
        width: 1px;
        height: em(19, 17.28);
        position: absolute;
        right: em(-6, 17.28);
        top: 40%;
        transform: translateY(-50%);
      }

      .custom-select {
        width: 14.34259em;
      }
    }

    &--collection {
      .events-filter__label {
        padding-right: em(18, 17.28);
      }

      .custom-select {
        width: em(162, 16.2);
      }
    }
  }

  .result {
    &__pagination {
      margin: 0 em(83);
    }
  }

  &__revert {
    .events-filter__display--sort {
      &::after {
        display: none;
      }
    }

    .card__date {
      padding-left: em(5, 20.74);
      padding-right: em(5, 20.74);
      font-family: $robo-l;
    }
  }

  .triangle-deco {
    top: 0;
    height: em(120);
    bottom: initial;
  }
}

.card {
  &__name,
  &__title,
  &__date {
    display: block;
    padding-left: em(22, 20.74);
    padding-right: em(22, 20.74);
    position: relative;
    z-index: 1;
  }

  &__name {
    font: #{em(21)}/#{em(25, 21)} $wal-b;
    margin-top: em(30, 21);
    color: $black;
    margin-bottom: em(20, 21);
    height: em(51, 21);
    overflow: hidden;
  }

  &__title {
    font: #{em(21)}/#{em(25, 21)} $robo-l;
    color: $black;
    margin-bottom: em(30, 21);
    height: em(78, 21);
    overflow: hidden;
  }

  &__date {
    font: em(15) $wal-b;
    color: $grey;
  }
}

.view-type {
  ul {
    @include clearfix;
  }

  li {
    float: left;
  }
}

.gallery-module--list-view {
  .gallery-module__content {
    margin-bottom: em(40);

    .card {
      margin-bottom: em(40);
    }
  }

  .more-resource {
    text-align: left;
    border: none;
    background-color: transparent;

    &:before,
    &:after {
      display: none;
    }

    &__image {
      border: none;
      display: none;
    }
  }

  .col-4 {
    width: 100%;
    padding: 0;
  }

  .card {
    padding-left: em(70);
    padding-right: em(71);

    &__name {
      font-size: em(17);
      height: auto;
      margin-top: 0;
      margin-bottom: em(15, 17);
      padding-left: em(22, 17);
      padding-right: em(22, 17);
    }

    &__title {
      font-size: em(15);
      line-height: em(21, 15);
      margin-bottom: em(15, 15);
      height: auto;
      padding-left: em(22, 15);
      padding-right: em(22, 15);
    }

    &__date {
      font-size: em(14);
      padding-left: em(22, 14);
      padding-right: em(22, 14);
    }
  }
}

@media screen and (min-width: $breakpoint-min) {
  .gallery-module {
    .events-filter {
      max-width: 100%;
      padding-left: em(75);
      padding-right: em(75);
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .events-filter__display {
    padding: 0 em(10);
  }

  .gallery-module {
    .button__wrapper {
      display: block;
    }

    &__top-bar {
      margin-top: 0;
      margin-bottom: em(52);
    }

    .col-4 {
      width: 50%;
    }

    &__content {
      padding: 0 em(7);
    }

    .card {
      margin: 0 0 em(30);

      &__name {
        font-size: em(11.3);
        margin-bottom: em(5, 10.3);
        height: em(25, 10.3);
      }

      &__title {
        font-size: em(11);
        margin-bottom: em(7, 11);
        height: em(40, 11);
      }

      &__date {
        font-size: em(10.5);
      }
    }

    .events-filter {
      padding-top: em(27);
      padding-bottom: em(14);
      position: relative;

      &:after {
        height: em(5);
      }

      &__display {
        width: 50%;

        &--sort {
          margin-right: 0;
        }

        &--sort:after {
          display: none;
        }

        &--collection {
          padding-left: em(19);
        }
      }

      &__label {
        font-size: em(13, 17.28);
        line-height: em(9, 12);
        margin-bottom: em(10, 12);
      }

      .custom-select {
        font-size: em(13);

        &__options {
          margin-top: em(17, 12);
        }
      }

      &__type {
        position: absolute;
        top: em(-24);
        left: 50%;
        transform: translateX(-50%);
      }

      .ico {
        font-size: em(35);
      }

      &__item {
        &:first-child {
          border-right: em(.5) solid $grey-5;
          padding-right: em(21);
          margin-right: 0;
        }

        &:nth-child(2) {
          padding-left: em(20);
        }
      }
    }

    &.gallery-module--list-view {
      .gallery-module__top-bar {
        margin-bottom: em(32);
      }

      .col-4 {
        width: 100%;
      }

      .card {
        padding: 0;
        margin-bottom: em(33);

        &__name,
        &__title,
        &__date {
          padding: 0;
        }

        &__name {
          font-size: em(17.6);
          height: auto;
        }

        &__title {
          font-size: em(15);
          margin-bottom: em(11, 15);
          height: auto;
        }

        &__date {
          font-size: em(14);
        }
      }
    }
  }
}

@media screen and (min-width: 640px) {
  .gallery-module {
    .card {
      margin-bottom: em(50);
    }
  }
}
