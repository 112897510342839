.museum-location {
  max-width: em(800);
  margin: 0 auto em(108);
  position: relative;
  z-index: 1;

  .title__wrapper {
    margin-bottom: em(20);
  }

  .title {
    letter-spacing: em(-2.3, 51.6);
  }

  &__map {
    @extend %clearfix;
    padding-bottom: em(40);
    border-bottom: em(2) solid $black;
    position: relative;

    &:before {
      position: absolute;
      top: em(6);
      left: 50%;
      content: '';
      background-color: $grey-8;
      width: 1px;
      height: em(251);
      transform: translateX(-50%);
    }
  }

  &__detail {
    @extend %clearfix;
    padding: em(10) 0 em(18);
    border-bottom: em(2) solid $black;
  }

  &__title {
    font: em(18) $robo-m;
    color: $black-1;
    padding-left: em(7, 18);
    padding-bottom: em(6, 18);
    border-bottom: em(1, 18) solid $grey-8;
  }
}

.time {
  &__duration {
    font: #{em(75.3, 14.4)}/1 $robo-l;
    color: $grey-8;
    padding: em(3, 75.3) 0 0 em(8, 75.3);
    margin-bottom: em(9, 75.3);

    .time__unit {
      font: em(14.4, 75.3) $robo-b;
      display: inline-block;
      margin-left: em(6, 14.4);
      letter-spacing: em(.5, 14.4);
    }

    &--edit {
      padding: em(3, 75.3) 0 0 em(15, 75.3);
      letter-spacing: em(-12, 75.3);

      .time__unit {
        letter-spacing: em(.5, 14.4);
        margin-left: em(13, 14.4);
      }
    }
  }

  &__description {
    padding-left: em(12, 14.4);

    &--edit {
      padding-left: em(21, 14.4);
    }
  }
}

.detail {
  &__item {
    width: 50%;
    float: left;

    &:nth-child(1) {
      .detail__content {
        padding-right: em(80, 14.4);
      }
    }

    &:nth-child(2) {
      .detail__content {
        padding-left: em(82, 14.4);
      }

      .museum-location__title {
        padding-left: em(93, 18);
      }
    }
  }

  &__icon {
    display: none;
  }

  &__content {
    font: #{em(14.4)}/#{em(21.6, 14.4)} $robo-l;
    padding: em(17, 14.4) 0 0 em(2, 14.4);
    color: $grey-8;
    letter-spacing: em(.5, 14.4);
  }

  &__text {
    margin-bottom: em(24, 14.4);
  }
}

.list-wrapper {
  .list-item {
    padding-left: em(8, 14.4);
    position: relative;
    // display: inline-block;
    // min-width: 40%;

    &:before {
      width: 2px;
      height: 2px;
      position: absolute;
      left: em(2, 14.4);
      top: 47%;
      content: '';
      background-color: $grey-8;
      display: inline-block;
    }
  }
}

.map {
  &__left {
    width: em(310);
    display: inline-block;
    float: left;

    .map__title {
      text-align: right;
    }
  }

  &__right {
    width: em(310);
    display: inline-block;
    float: right;

    .map__title {
      text-align: left;
    }
  }

  &__title {
    font: #{em(18.35)} $robo-bl;
    margin-bottom: em(20, 18.35);
  }

  &__container {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    box-shadow: 0 .125em .25em 0 $half-black;
  }

  &__image {
    display: inline-block;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__description {
    padding: em(6) em(45) em(12);
    background-color: $white;
    width: 100%;
    border-top: em(7) solid $yellow;
    color: $black;
    margin-top: em(-5);

    &__title {
      font: em(13) $robo-b;
      display: block;
      margin-bottom: em(6);
    }

    &__location {
      font: em(11.5) $robo-l;
      display: block;
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .museum-location {
    margin-bottom: 0;
    padding: 0 em(7) em(71);
    border-bottom: 1px solid $grey-8;

    .title__wrapper {
      margin-bottom: em(30);
      text-align: left;
    }

    &__detail {
      padding-bottom: 0;
    }

    &__title {
      padding: em(6) em(6) em(10);
      position: relative;

      &--mobile {
        padding: em(12) em(6) em(11);
      }
    }

    &__map {
      padding-bottom: em(21);

      &:before {
        display: none;
      }
    }
  }

  .time {
    &__duration,
    &__description {
      padding: 0;
    }
  }

  .map {
    &__title {
      margin-bottom: em(7);
    }

    &__left,
    &__right {
      width: 100%;
    }

    &__left {
      margin-bottom: em(22);

      .map__title {
        text-align: left;
      }
    }
  }

  .detail {
    &__item {
      width: 100%;

      &.opened {
        .detail__icon {
          transform: translateY(-50%) rotate(90deg);
        }
      }

      &:nth-child(1) {
        .detail__content {
          padding-right: 0;
        }
      }

      &:nth-child(2) {
        .detail__content {
          padding-left: 0;
        }

        .museum-location__title {
          padding-left: em(6);
        }
      }
    }

    &__icon {
      position: absolute;
      top: 47%;
      right: em(14, 23);
      display: inline-block;
      transform: translateY(-50%);
      font-size: em(23);
    }

    &__content {
      display: none;
    }
  }
}
