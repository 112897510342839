.faq-contact {
  @include background-gradient($white-1, $grey-4, 'vertical');
  padding: em(50) em(39) em(180);

  &__item {
    margin-bottom: em(40);

    &-big-spacing {
      margin-bottom: em(90);
    }
  }

  &__title,
  &__title-link {
    font: #{em(17.28)}/#{em(20.74, 17.28)} $robo-bl;
    margin-bottom: em(30, 17.28);
    color: $black;
    letter-spacing: em(1.2, 17.28);
    text-transform: uppercase;
  }

  &__btn {
    margin-top: em(35);

    .btn__text {
      padding: em(7, 12) em(28, 12);
      font: #{em(12)}/#{em(14.4, 12)} $robo-b;
      background: $grey-6;
    }
  }

  &__down {
    padding-top: em(50);
    padding-bottom: em(50);
    margin-bottom: em(50);
    border-top: em(3, 14.4) solid $grey-18;
    border-bottom: em(3, 14.4) solid $grey-18;
  }

  &__title {
    margin-bottom: em(50, 17.28);

    &-link {
      &:after {
        @extend %icon;
        font-size: em(14.4, 17.28);
        content: $ico-long-arrow-right-circle;
        padding-left: em(10, 14.4);
        font-weight: bold;
      }

      &:hover {
        opacity: .6;
      }
    }

    &--no-spacing {
      margin-bottom: 0;
    }
  }

  &__link-down {
    font: #{em(14.4)}/#{em(16, 14.4)} $robo-bl;
    color: $blue-3;
    text-transform: uppercase;
    display: inline-block;
    padding-left: em(30, 14.4);

    &:before {
      @extend %icon;
      content: $ico-download-circle;
      margin-left: em(-30, 14.4);
      padding-right: em(15, 14.4);
      display: inline-block;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &--visit {
    background: linear-gradient($white-1, $grey-4 10%, $white 60%);
    height: 100%;
    margin-top: em(28);
  }
}

@include minquery($breakpoint-min) {
  .contact-us-template {
    .faq-contact {
      margin-top: em(35, 16);
    }
  }
}

@media screen and (max-width: $breakpoint-max) {

  .faq-contact {
    margin-top: em(18);
    margin-left: em(11);
    margin-right: em(11);
    padding-bottom: em(105);
    text-align: center;

    &--visit {
      background: linear-gradient($white-1, $grey-4 80%);
      padding-bottom: em(205);
      margin-bottom: em(-200);
      margin-top: 0;
    }

    &__desc {
      color: $grey-8;
    }

    &__btn {
      margin-top: em(16);
    }
  }

  .list-call {
    margin-bottom: em(54);

    &__tell {
      color: $grey-8;
    }
  }
}
