.search-result {
  .title__wrapper {
    .title {
      font-size: em(42.99);
      line-height: em(45, 42.99);
      margin-bottom: em(9, 42.99);
    }

    .sub-title {
      font: #{em(17.28)}/#{em(22, 17.28)} $robo-l;
      margin-top: 0;
    }
  }

  .sub-title {
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
}

.search-result__form {
  text-align: center;
  position: relative;
  max-width: em(636);
  margin: em(70) auto  em(92);

  .custom-input {
    max-width: 100%;
  }

  .form__label-result,
  .form__control--event {
    font-size: em(51.6);
    font-family: $wal-b;
    letter-spacing: em(-1.55, 51.6);
  }

  .form__control--event {
    @include placeholder {
      color: $grey;
      opacity: .51;
    }

    padding: em(3, 51.6) em(140, 51.6) em(3, 51.6) em(20, 51.6);
    color: $black;


    &::-ms-clear {
      display: none;
    }

    &.outfocus {
      color: $grey;
      opacity: .51;
    }
  }

  .form__submit-result {
    position: absolute;
    top: 50%;
    right: em(12);
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    transform: translateY(-50%);

    .icon-search {
      width: em(60);
      height: em(60);
      display: block;
    }
  }

  .btn__close {
    padding: em(10);
    position: absolute;
    right: em(75);
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    cursor: pointer;

    .icon-search {
      width: em(30);
      height: em(30);
      display: block;
    }
  }

}

.result {

  &__item {
    margin-bottom: em(35);
  }

  &__title {
    font: #{em(17.28)}/#{em(20, 17.28)} $wal-b;
    margin-bottom: em(8, 17.28);
    max-width: em(290, 17.28);

    .result__link {
      color: $black;
      transition: color .3s;

      &:hover {
        color: $dolly;
      }
    }
  }

  &__text {
    font: #{em(14.4)}/#{em(16, 14.4)} $robo-l;
    color: $grey-8;
  }

  &__link-more {
    font: #{em(14.4)}/#{em(16, 14.4)} $robo-b;
    color: $dolly;
  }

  &__empty {
    font: #{em(17.28)}/#{em(20, 17.28)} $wal-b;
    margin-bottom: em(8, 17.28);
  }

}

.result__show-page {
  text-align: center;
  margin: em(20, 15) 0;

  .show__text,
  .show__link  {
    font: #{em(14.4)}/#{em(16, 14.4)} $robo-l;
    padding: 0 em(4, 14.4);
  }

  .show__text {
    display: inline-block;
    color: $black;
  }

  .show__link {
    color: $grey-15;
    transition: color .4s;

    &:hover,
    &.active {
      color: $black;
    }
  }

  .show__list {
    display: inline-block;

    .show__item {
      display: inline-block;
      vertical-align: midle;
    }
  }
}

.result__pagination {
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  padding: em(3) 0 em(1);
  text-align: center;
  max-width: em(798);
  margin: auto auto em(20, 15);

  .pagination__button,
  .pagination__list {
    display: inline-block;
    vertical-align: middle;
  }

  .pagination__button {
    font-size: em(30);
    line-height: em(30, 30);
    color: $black;
    padding: 0 em(10, 30);

    &:hover {
      color: $black;
      transition: color .4s;
    }

    &.disabled,
    &.disabled:hover {
      color: $grey-15;
      cursor: default;
    }
  }

  .pagination__list {

    .pagination__link,
    .pagination__ellipsis {
      font: #{em(17.28)}/#{em(20, 17.28)} $robo-b;
      color: $grey-15;
    }

    .pagination__item {
      display: inline-block;
      padding: 0 em(10);

      &.active,
      &:hover {
        .pagination__link {
          color: $black;
          transition: color .4s;
        }
      }
    }

  }
}

.search__form,
.form-search {
  .btn__close {
    padding: em(10);
    position: absolute;
    right: em(55);
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    cursor: pointer;

    &-revert {
      top: 53%;
    }

    .icon-close {
      width: em(30);
      height: em(30);
      display: block;
    }
  }
}

.form__control--event::-ms-clear {
  display: none;
}

@media screen and (min-width: $breakpoint-min) {
  .search-result {
    margin-bottom: em(130, 15);
  }

  // .search__form,
  // .form-search {
  //   .form__control--event {
  //     font-size: em(51.6);
  //     font-family: $wal-b;
  //     letter-spacing: em(-1.55, 51.6);
  //   }
  // }

  .form-search {
    .form__control--event {
      padding-right: em(90, 51.6);
    }

    .btn__close {
      right: em(50);
    }
  }

  .result {
    &__list {
      overflow: hidden;
      max-width: em(798);
      margin: 0 auto;

      .list__item:last-child  {
        .result__item {
          float: right;
          display: block;
          width: 75%;
        }
      }

      .button__wrapper {
        display: none;
      }
    }

    &__title {
      max-width: em(290, 17.28);
    }

    &__desc {
      max-width: em(290);
    }

  }

  .search-result__form {
    max-width: em(798);
  }
}

@media screen and (max-width: $breakpoint-max) {

  .search {

    &-result {
      padding: 0 em(10);

      .title__wrapper {
        .title {
          font-size: em(35.83);
          letter-spacing: em(-1.47, 35.83);
          margin-bottom: em(12, 35.83);
        }

        .text {
          line-height: em(25, 17.28);
        }
      }
    }

  }

  .result {
    &__list {
      margin-bottom: em(78, 16);
    }

    &__title {
      line-height: em(18, 17.28);
      max-width: 100%;
    }

    &__item {
      margin-bottom: em(21);
    }
  }

  .search-result__form  {
    margin: em(50) 0 em(70);


    .form__control--event {
      font-size: em(24.88);
      padding: em(14, 24.88) em(85, 24.88) em(14, 24.88) em(8, 24.88);
    }

    .form__submit-result {
      height: 100%;
      right: 0;

      &:before {
        font-size: em(25);
      }
    }

    .btn__close {
      font-size: em(11);
    }

    .custom-input {
      margin-top: 0;
    }

  }

  .search__form,
  .form-search {
    .form__control--event {
      font-size: em(24.88);
      padding: em(14, 24.88) em(90, 24.88) em(14, 24.88) em(8, 24.88);
    }

    .form__submit-result {
      height: 100%;
      right: 0;

      &:before {
        font-size: em(25);
      }
    }

    .btn__close {
      font-size: em(11);
    }

    .custom-input {
      margin-top: 0;
    }
  }

  .search__form {
    .btn__close-revert {
      // top: inherit;
      // bottom: em(10, 11);
      right: em(60, 11);
    }
  }

  .menu__search {
    .form-search {
      .btn__close {
        top: 41%;
      }
    }
  }

  .result__show-page,
  .result__pagination {
    display: none;
  }

}
