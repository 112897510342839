.supporters {
  margin: 0 auto;
  max-width: em(810);
  text-align: center;

  &__wrapper {
    margin-bottom: em(132);
    padding-top: em(42);
    padding-bottom: em(60);
    background-color: rgba($grey-4, .2);
    position: relative;
    z-index: 1;
  }


  &__title {
    margin-bottom: em(65, 29.86);
    color: $grey-8;
    font: #{em(29.86)}/#{em(31.35, 29.86)} $wal-b;
    letter-spacing: em(.38, 29.86);
  }
}

.list-logo {
  &__item {
    display: inline-block;
    margin: 0 em(10) em(15);
    vertical-align: middle;

    [data-animation='off'] & {
      opacity: 0;
      transform: translateY(8px);
    }

    @for $i from 0 through 29 {
      @include fade-in-sequence($i);
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .supporters {
    &__wrapper {
      margin-bottom: em(60);
    }
  }

  .list-logo {

    &__item {
      margin: 0 0 em(10);
      max-width: 49%;
      padding: 0 em(5);
    }
  }
}
