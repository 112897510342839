%icon-position {
  position: absolute;
  top: 50%;;
  left: 0;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: em(30);
}

.infor {
  &__inner--border {
    border-top: 30px solid $grey-14;
  }

  &__wrapper {
    @extend %clearfix;
  }

  &__note-clock {
    position: relative;
    padding-left: em(40, 14.4);
    margin-top: em(33, 14.4);
    margin-bottom: em(45, 14.4);

    &:before {
      @extend %icon;
      @extend %icon-position;
      content: $ico-clock;
    }
  }

  &__note-info {
    position: relative;
    padding-left: em(40, 14.4);

    &:before {
      @extend %icon;
      @extend %icon-position;
      content: $ico-info;
    }
  }

  &__text-country {
    color: $black;
  }

  &__schedule {
    margin-bottom: em(40);
  }

  &__schedule-flag {
    margin-right: em(10);
  }

  &__schedule-detail {
    margin-top: em(5);

    .day {
      font: #{em(14.4)/1} $robo-b;
      display: inline-block;
      min-width: em(170);
      margin-right: em(5, 14.4);
      color: $grey-8;
      vertical-align: top;
    }
  }

  &__schedule-time-wrap {
    display: inline-block;
  }

  &__schedule-time {
    float: left;
    min-width: em(78);

    &:nth-child(3n+1) {
      clear: both;
    }
  }
}

.border-left {
  border-left: 1px solid $grey-5;
}

@media screen and (max-width: $breakpoint-max) {
  .infor {
    &__inner--border {
      padding-top: 0;
      border-top-width: 38px;
    }

    &__wrapper {
      margin-bottom: em(10);

      .col-6 {
        margin-top: em(17);
      }
    }

    &__schedule {
      padding-top: em(12);
      margin-bottom: 0;
      border-top: 1px solid $grey-5;
    }

    &__schedule-detail {
      &-item {
        padding-left: em(38);
        margin-bottom: em(6);
      }
    }

    &__schedule-flag {
      margin-right: em(15);
    }

    &__note-clock {
      margin-bottom: em(20, 12);
    }

    &__note-clock,
    &__note-info {
      font-size: em(12);
      margin-top: 0;

      &:before {
        font-size: em(27, 12);
      }
    }
  }

  .border-left {
    border-left: none;
  }
}
