.adminssion-wrapper {
  margin-bottom: em(150);
  position: relative;
  z-index: 1;
}

.adminssion {
  &__heading {
    font-size: em(51.6);
    font-family: $wal-b;
    letter-spacing: em(-1.42, 51.6);
    text-align: center;
    color: $black;
    margin-bottom: em(15, 51.6);
  }

  &__title {
    font-size: em(18.28);
    margin-bottom: em(20, 18.28);
    font-family: $robo-b;
    color: $grey-8;
    letter-spacing: em(.64, 18.28);
  }

  &__desc {
    margin-bottom: em(20);
    min-height: em(120);

    .adminssion__text {
      font-size: em(14.4);
      line-height: em(21, 14.4);
      letter-spacing: em(.44, 14.4);
      font-family: $robo-l;
      color: $grey-8;
    }
  }

  .btn--cta {
    width: 100%;
    font-size: em(17.28);
    letter-spacing: em(-.19, 14.4);

    .btn__text {
      padding: em(12, 17.28);
    }
  }

}

@media screen and  (min-width: $breakpoint-min) {
  .adminssion {
    position: relative;
    display: table;
    max-width: em(800);
    margin: 0 auto;

    .adminssion__item {
      display: table-cell;
      vertical-align: top;
      width: 50%;
      padding-right: em(92);
      text-align: right;

      & + .adminssion__item {
        padding-right: 0;
        padding-left: em(95);
        text-align: left;
        border-left: 1px solid $grey-5;
      }
    }
  }

  .adminssion--one-column {

    .adminssion__desc {
      min-height: auto;
    }

    .adminssion__item {
      width: 100%;
      padding: 0;
      text-align: center;
    }
  }
}

@media screen and  (max-width: $breakpoint-max) {
  .adminssion-wrapper {
    padding: 0 em(8);
    margin-bottom: em(59);
    border-bottom: em(1) solid $grey-8;
  }

  .adminssion {

    &__heading {
      font-size: em(38.42);
      margin-bottom: em(28, 38.42);
      letter-spacing: em(-1.4, 38.42);
      text-align: left;
    }

    .adminssion__item {
      margin-bottom: em(44);

      &__title {
        letter-spacing: 0;
        margin-bottom: em(14, 18.28);
      }

      &__desc {

        .adminssion__text {
          line-height: em(22, 14.4);
        }

      }

    }

    &__desc {
      margin-bottom: em(26);
      min-height: 100%;
    }

    .btn--cta {
      font-size: em(18);

      .btn__text {
        padding: em(11, 18);
      }
    }
  }
}
