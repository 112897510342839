.top-navigation {
  margin: 0 auto em(50);
  padding: 0 em(31);
  text-align: right;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color:#fff;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  &__list,
  &__logo {
    display: inline-block;
    vertical-align: middle;
  }

  &__list {
    padding: em(18) 0;

    &__item {
      float: left;
      padding: 0 em(35);
    }

    &__link {
      font-size: em(14);
      line-height: em(18, 14);
      color: $grey;
      letter-spacing: 1.4px;
      transition: color .5s;
      position: relative;
      display: inline-block;

      &:before,
      &:after {
        content: '';
        display: inline-block;
        height: 2px;
        position: absolute;
        bottom: -4px;
        z-index: 1;
        left: auto;
        right: 0;
        width: 0;
      }

      &:before {
        background: $black;
        transition: all .25s cubic-bezier(.694, .048, .335, 1) .15s;
      }

      &:after {
        background: $yellow;
        transition: all .25s cubic-bezier(.694, .048, .335, 1);
      }

      &:hover {
        color: $black;

        &:before,
        &:after {
          width: 100%;
          left: 0;
        }
      }
    }
  }

  &__logo {
    a {
      display: block;
      width: em(224, 32);
    }
  }

}

.top-navigation--light {
  .top-navigation__list__link {
    font-size: em(12);
  }
}

@media screen and (max-width: $breakpoint-max) {
  .top-navigation {
    display: none;
  }
}
