.form {
  &__title {
    font: #{em(42.99)}/#{em(52, 42.99)} $wal-b;
    color: $black;
    max-width: em(295, 42.99);
    margin-bottom: em(52, 42.99);
    margin-left: em(45, 42.99);
  }

  &__note {
    float: none;
  }

  &__legend {
    font-family: $robo-b;
    color: $grey-8;
    font-size: em(14.4);
    margin-bottom: em(20, 14.4);
  }

  &__text-choice {
    font-size: em(10);
    color: $grey;
    font-family: $robo-b;
    display: block;
    padding-top: em(17, 10);
  }

  &__fieldset {
    border: 0;
    margin-bottom: em(70);

    &--big-spacing {
      margin-bottom: em(100);
    }
  }

  &__group {
    &--spacing-large {
      margin-bottom: em(30);
    }
  }

  &__inner {
    padding-left: em(49);
    padding-right: em(30);
    position: relative;
  }

  &__footer {
    position: relative;
    border-top: em(2) solid $black;
    padding-top: em(70);
    margin-left: em(23);
  }

  &__group--choice {
    .form__text-choice,
    .custom-input,
    .custom-select__styled {
      opacity: .6;
    }

    .custom-select--scroll {
      border-bottom-color: rgba($grey, .6);

      &.selected {
        border-bottom-color: $grey;
      }
    }

    &.form__mandatory {
      .form__text-choice,
      .custom-input,
      .custom-select__styled {
        opacity: 1;
      }

      .custom-select--scroll {
        border-bottom-color: $grey;
      }
    }

    .input-has-value {
      opacity: 1;
    }

    .selected {
      .custom-select__styled {
        opacity: 1;
      }
    }
  }

  &__step {
    .btn--cta {
      .btn__text {
        text-transform: uppercase;
      }
    }
  }
}

.group {
  &__add-more {
    position: relative;
    padding-left: em(30);

    .custom-input--date {
      .custom-input__control {
        padding-bottom: em(20, 14.4);
      }
    }
  }

  &__add-more-modify {
    margin-bottom: em(70, 15);
  }

  &__title {
    position: relative;
    font-size: em(14.4);
    font-family: $robo-b;
    color: $grey;
    padding: em(9, 14.4) 0;
  }

  &__title--sub {
    font: #{em(14.4)/1} $robo-r;
    color: $grey;
    margin-bottom: em(7, 14.4);

    .sub__number {
      position: relative;
      float: left;
      padding-right: em(10, 14.4);

      &:before {
        content: '';
        position: absolute;
        top: em(3, 14.4);
        right: 0;
        bottom: 0;
        height: em(12, 14.4);
        width: em(1, 14.4);
        background-color: $grey;
      }
    }

    .sub__text {
      padding-left: em(40, 14.4);
    }
  }

}

.group__add-more {
  .selected {
    .custom-select__styled {
      color: $grey-8;
      font-family: $robo-r;
    }
  }
}

.button-add-more,
.button-remove-choice {
  background: transparent;
  font-size: em(14.4);
  border: 0;
  color: $grey;
  position: relative;
  transition: color .3s;
  outline: none;
  padding: 1px;
  cursor: pointer;

  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    font-family: $sam;
    transform: translateY(-50%);
  }

  &:hover {
    color: $grey-8;

    &:before {
      color: $grey-8;
    }
  }

}

.button-add-more {
  padding-left: em(40, 14.4);
  margin-top: em(30, 14.4);
  cursor: pointer;

  &:before {
    content: $ico-square-plus;
    font-size: em(14, 14.4);
  }
}

.button-remove-choice {
  position: absolute;
  top: em(21, 14.4);
  left: 0;
  width: em(15, 14.4);
  height: em(15, 14.4);
  color: $grey-8;

  &:before {
    content: $ico-square-minus;
  }
}

.ie {
  .button-remove-choice {
    width: em(17, 14.4);
    height: em(17, 14.4);
  }
}

.form__wrapper {
  margin-bottom: em(70);

  .custom-input {
    max-width: 100%;
    margin-top: 0;
    margin-left: 0;
  }

  .btn--cta {
    min-width: em(220);
  }

  .btn--small {
    min-width: auto;
    border-width: em(1);
    padding-top: em(3);
    padding-bottom: em(7);
  }
}

.legend {
  &__number {
    color: $white;
    position: relative;
    width: em(21, 14.4);
    margin-right: em(10, 14.4);
    display: inline-block;
    font-size: em(14.4);
    text-align: center;

    &:before {
      content: '';
      border-radius: 50%;
      background-color: $grey-8;
      width: em(21, 14.4);
      height: em(21, 14.4);
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
}

.text-required {
  position: absolute;
  top: em(25, 14.4);
  right: 0;
  font-size: em(14.4);
  color: $grey;
  text-align: right;
}

.tooltip {
  position: relative;
  margin-top: 20px;
  display: inline-block;

  &:hover,
  &:focus {
    .tooltip__content {
      // display: block;
      opacity: 1;
    }
  }

  &__icon {
    position: relative;
    font: #{em(10)}/#{em(16, 10)} $robo-b;
    width: em(16, 10);
    height: em(16, 10);
    border-radius: 50%;
    color: $grey-5;
    border: em(1, 10) solid $grey-5;
    display: inline-block;
    text-align: center;
    transition: all .3s;

    &:hover,
    &:focus {
      background-color: $grey-8;
      color: $white;
      border-color: $grey-8;
    }
  }

  &__content {
    font: #{em(10)}/#{em(12, 10)} $robo-l;
    position: absolute;
    background-color: $grey-8;
    color: $white;
    padding: em(4, 10);
    text-align: center;
    min-width: em(90, 10);
    border-radius: em(3);
    transition: opacity .5s;
    // display: none;
    opacity: 0;

    &:before {
      content: '';
      position: absolute;
    }
  }

  &--top {
    .tooltip__content {
      transform: translateX(-50%);
      left: 50%;
      bottom: 25px;

      &:before {
        left: 50%;
        transform: translateX(-50%);
        bottom: -4px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $grey-8;
      }
    }
  }

  &--bottom {
    .tooltip__content {
      transform: translateX(-50%);
      left: 50%;
      top: 30px;

      &:before {
        left: 50%;
        top: -5px;
        transform: translateX(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $grey-8;
      }
    }
  }

  &--left {
    .tooltip__content {
      top: 50%;
      right: 25px;
      transform: translateY(-49%);

      &:before {
        right: -5px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid $grey-8;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &--right {
    .tooltip__content {
      top: 50%;
      left: 25px;
      transform: translateY(-49%);

      &:before {
        left: -5px;
        top: 50%;
        transform: translateY(-50%);
        border-top: em(5) solid transparent;
        border-bottom: em(5) solid transparent;
        border-right: em(5) solid $grey-8;
      }
    }
  }
}

.form__notify {
  padding: em(40) 0 em(55);
  border-bottom: em(2) solid $black;
  margin-bottom: em(60);
}

.notify {
  &__title {
    font-size: em(17.28);
    font-family: $robo-b;
    color: $grey-8;
    letter-spacing: em(.64, 17.28);
    margin-bottom: em(15, 17.28);
  }
}

.group-radio {
  @extend %clearfix;

  .custom-radio {
    float: left;
  }

  .list-check {
    float: left;
    opacity: 0;
    display: none;
    transition: all .3s;
  }

  .custom-checkbox {
    display: inline-block;
    margin-left: em(20);
  }

  &.selected {
    .list-check {
      opacity: 1;
      display: block;
    }
  }
}

.custom-textarea {
  &.input-has-value {
    .custom-textarea__control {
      color: $grey-8;
      font-family: $robo-r;
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .form {

    &__wrapper {
      margin-bottom: em(35);
    }

    &__fieldset {
      margin-bottom: em(40);
    }

    &__group {
      margin-bottom: 0;
    }

    &__text-choice {
      display: none;
    }

    &__title {
      font-size: em(35.83);
      margin-left: em(10, 35.83);
    }

    &__inner {
      padding: 0 em(10);

      &:after {
        left: 0;
        right: 0;
      }
    }

    &__footer {
      padding-top: em(60);
      margin-left: em(10);
      margin-right: em(10);
      text-align: center;
    }

  }

  .group__add-more {
    padding-left: em(20);
  }

  .text-required {
    top: em(26, 14.4);
  }

  .custom-textarea {
    margin-bottom: em(30);
  }

  .group-radio {

    .custom-radio,
    .list-check {
      float: none;
    }

    .custom-checkbox {
      display: block;
      margin-left: em(20);
    }

    .list-check {
      .custom-checkbox {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
