section:not(.newsletter) {
  .intro-form {
    .help-block {
      display: block;
    }

    .field-validation-error {
      display: none;
    }
  }
}

.registration__form--emarketing {
  padding-bottom: em(10);

  .page-header {
    h2 {
      font: #{em(38)/1} $wal-b;
      color: $black;
      margin-bottom: em(70, 38);
    }
  }

  .col-md-12 {
    padding-left: 0;
  }

  .required-field {
    .control-label {
      &:before {
        display: none;
      }
    }

    .checkbox {
      &:after {
        display: none;
      }
    }
  }

  .content-form {
    fieldset {
      border: 0;
      padding: 0;
    }

    legend {
      padding-left: 0;
    }
  }

  .checkbox {
    padding: em(14) 0 0;
    margin-top: 0;
    max-width: 100%;

    &:after {
      display: none;
    }

    input {
      position: absolute;
      left: -9999px;
      opacity: 0;

      &:checked ~ label {
        color: $grey-8;

        &:before {
          background-color: $black;
          border-color: $black;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    label {
      font: #{em(14.4)}/#{em(22, 14.4)} $robo-l;
      cursor: pointer;
      color: $grey;
      padding-left: em(25);
      position: relative;
      display: inline-block;

      &:focus {
        outline: none;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: em(2, 14.4);
        width: em(12, 14.4);
        height: em(12, 14.4);
        border: em(1, 14.4) solid $grey-5;
        background-color: $white;
        border-radius: em(2);
      }

      &:after {
        position: absolute;
        transition: opacity .2s;
        background-color: transparent;
        content: $ico-tick;
        color: $white;
        font-family: $sam;
        left: 0;
        top: em(2, 14.4);
        line-height: em(13, 14.4);
      }
    }
  }

  .radio {
    padding-right: em(10);
    margin-top: em(14);

    input {
      position: absolute;
      left: em(-9999, 14.4);
      opacity: 0;

      &:not(:checked) ~ label {
        color: $grey;

        &:after {
          opacity: 0;
        }
      }

      &:checked + label {
        color: $grey-8;
      }
    }

    label {
      font: #{em(14.4)}/#{em(22, 14.4)} $robo-l;
      color: $grey;
      position: relative;
      padding-left: em(25);
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
      }

      &:before {
        @extend %icon;

        content: $ico-radio;
        font-size: em(16);
        color: $grey-5;
        top: em(2, 16);
        left: 0;
      }

      &:after {
        @extend %icon;

        content: $ico-radio-checked;
        font-size: em(16);
        top: em(2, 16);
        left: 0;
        color: $black;
      }
    }
  }

  .single-line {
    max-width: 100%;
    margin-top: 0;
    margin-left: 0;
    position: relative;

    input {
      position: relative;
      font-size: em(14.4);
      border: none;
      width: 100%;
      padding-top: em(17, 14.4);
      padding-bottom: em(21, 14.4);
      border-bottom: em(1) solid $grey;
      outline: none;
      z-index: 2;
      background-color: transparent;
      font-family: $robo-l;
      color: $grey-5;
      transition: all .4s;

      &:focus {
        border-color: $grey-8;
        box-shadow: 0  em(1, 14.4) 0 $grey-8;
        padding-top: em(31, 14.4);
        padding-bottom: em(7, 14.4);
      }

      &:focus ~ label {
        top: em(11, 14.4);
        transform: none;
        font-size: em(10, 14.4);
      }
    }

    label {
      font: #{em(14.4)/1} $robo-l;
      color: $grey;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: all .3s;
    }

    &.input-has-value {
      input {
        font-family: $robo-r;
        color: $grey-8;
        padding-top: em(31, 14.4);
        padding-bottom: em(7, 14.4);
      }

      label {
        top: em(11, 14.4);
        transform: none;
        font-size: em(10, 14.4);
      }
    }
  }

  .custom-select--special {
    &.selected {
      .custom-select__styled {
        color: $grey-8;
        font-family: $robo-r;
      }
    }

    .custom-select__options-special {
      overflow-y: hidden;
    }
  }

  .contact-us-template {
    .btn--cta {
      display: inline-block;
    }
  }

  .form-submit-border {
    .btn {
      display: inline-block;
      vertical-align: middle;
      min-width: em(222, 17.28);
      border: none;
      padding: em(12);
    }
  }

  .field-info,
  .field-validation-error {
    display: none;
  }

  .control-label {
    &.custom-select__styled {
      display: block;
    }
  }

  .btn--cta-revert {
    display: inline-block;
    margin-bottom: em(5);

    .form-submit-border {
      min-height: em(44, 15);
    }
  }

  .intro-form {
    margin-bottom: em(40);

    > p {
      font-size: 0;
    }

    .help-block {
      font: #{em(12)}/#{em(17, 12)} $robo-l;
      color: $grey;
      font-style: italic;

      b {
        font-family: $robo-b;
        font-style: normal;
        font-weight: normal;
      }
    }

    .checkbox {
      margin-bottom: em(13);
    }

    .field-validation-error {
      display: none;
    }
  }

  .infor-form {
    margin-bottom: em(100);

    .control-label {
      display: none;
    }

    .full-input {
      margin-bottom: 0;
    }
  }

  .plan-form {
    margin-bottom: em(100);

    .control-label {
      font-size: em(14.4);
      font-family: $robo-b;
      color: $grey;
      padding: em(9, 14.4) 0;
      display: none;
    }

    .checkbox-form {
      @extend %clearfix;

      .control-label {
        display: block;
      }
    }
  }

  .registration__attendance {
    margin-bottom: em(70);

    p {
      font-size: em(14.4);
      font-family: $robo-b;
      color: $grey;
      padding: em(9, 14.4) 0;
    }

    .col-md-12 {
      padding-left: em(40);
    }

    .registration__booking {
      width: 58%;
      float: left;
      margin-right: em(10);
      clear: none;
    }

    .form-group {
      &.registration__age,
      &.registration__pax {
        width: 20%;
        float: left;
        clear: none;

        .control-label {
          display: none;
        }
      }

      &:first-child {
        padding-left: 0;
      }
    }

    .half-input {
      .control-label {
        display: none;
      }
    }
  }

  .form-group {
    padding-left: em(10);
    padding-right: em(10);
    margin-bottom: em(20, 14);

    &.half-input {
      width: 50%;
      float: left;
      clear: none;
      min-height: em(56, 15);
    }

    &.full-input {
      clear: left;
    }

    &.registration__choice--date {
      clear: left;
      float: left;
      width: 58%;
      padding-left: 0;

      & > .control-label {
        float: left;
        font-size: em(10);
        color: $grey;
        font-family: $robo-b;
        display: block;
        padding-top: em(20, 10);
        opacity: .6;

        &:before {
          display: none;
        }
      }

      .text-box {
        padding: em(18, 14.4) 0 em(20, 14.4) em(100, 14.4);
      }

      .single-line {
        .label-input,
        input {
          opacity: .6;
        }

        &.input-has-value {
          .label-input {
            font-size: em(14.4);
            top: 46%;
            transform: translateY(-50%);
            opacity: 1;
          }

          .text-box,
          .text-box:focus {
            padding-top: em(18, 14.4);
            padding-bottom: em(20, 14.4);
          }
        }

        .text-box:focus {
          padding-top: em(18, 14.4);
          padding-bottom: em(20, 14.4);

          ~ .label-input {
            font-size: em(14.4);
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      & > .field-border {
        float: right;
        width: 72%;
        clear: right;
      }

      .help-block {
        display: none;
      }

      &.form__mandatory {
        & > .control-label {
          opacity: 1;
        }

        .single-line {
          .label-input,
          input {
            opacity: 1;
          }
        }
      }
    }

    &.has-error {
      .single-line {
        &.field-content {
          background-color: $yellow;
        }
      }

      .custom-select__styled {
        background-color: $yellow;
      }

      &.selected {
        .single-line {
          &.field-content {
            background-color: transparent;
          }
        }

        .custom-select__styled {
          background-color: transparent;
        }
      }
    }
  }

  .registration__choice--dropdown {
    clear: left;
    width: 40%;

    &.custom-select--scroll {
      border-bottom-color: rgba($grey, .6);
    }

    .custom-select__styled {
      &.control-label {
        opacity: .6;
        font-size: em(16);
      }
    }

    &.form__mandatory {
      .custom-select__styled {
        &.control-label {
          opacity: 1;
        }
      }

      &.custom-select--scroll {
        border-bottom-color: rgba($grey, 1);
      }
    }
  }

  .registration__comment {
    margin-bottom: em(70);

    .control-label {
      font-size: em(14.4);
      font-family: $robo-b;
      color: $grey;
      padding: em(9, 14.4) 0;
      display: inline-block;
    }

    .help-block {
      display: none;
    }

    & > p {
      font: #{em(12)}/#{em(17, 12)} $robo-l;
      color: $grey;
      font-style: italic;
      margin-bottom: em(30, 12);

      b {
        font-style: normal;
        font-family: $robo-b;
        font-weight: normal;
      }
    }

    .label-multiline {
      display: none;
    }

    p {
      display: none;
    }

    .has-error {
      .control-label {
        background-color: $yellow;

        &:before {
          display: none;
        }
      }
    }

    legend {
      font-size: em(14.4);
      font-family: $robo-b;
      color: $grey;
      padding: em(9, 14.4) 0;
    }

    &-revert {
      legend {
        margin-bottom: 0;
      }

      p {
        display: block;
        font: #{em(12)}/#{em(17, 12)} $robo-l;
        color: $grey;
        font-style: italic;
        padding: 0;
        margin-top: em(20);
        margin-bottom: em(30);
      }
    }
  }

  .registration__question {
    .control-label {
      font-size: em(14.4);
      font-family: $robo-b;
      color: $grey;
      padding: em(9, 14.4) 0;
      display: inline-block;

      &:before {
        display: none;
      }
    }

    .help-block {
      display: none;
    }
  }

  .registration__question--decide {
    & > p {
      font-size: em(14.4);
      font-family: $robo-b;
      color: $grey;
      padding: em(9, 14.4) 0;
      display: inline-block;
    }

    .form-group {
      margin-bottom: 0;
    }
  }

  .registration__question--orther {
    margin-top: em(21);

    & > p {
      display: none;
    }

    .control-label {
      font-size: em(14.4);
      font-family: $robo-b;
      color: $grey;
      padding: em(9, 14.4) 0;
      display: inline-block;
    }

    .form-group:first-child {
      margin-bottom: 0;
    }
  }

  .hidden-label {
    .control-label {
      display: none;
    }
  }

  .custom-select {
    .custom-select__styled {
      &:before {
        display: none;
      }
    }
  }

  .validation-summary-errors {
    display: none;
  }

  .has-error {
    .checkbox {
      background-color: $yellow;
    }
  }
}


@include minquery($breakpoint-min) {
  .registration__form--emarketing {
    .plan-form {
      .radio {
        float: left;
        width: 50%;
      }

      .form-group {
        margin-bottom: 0;
      }

      .checkbox-form {
        .control-label {
          float: left;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .registration__form--emarketing {
    .page-header {
      h2 {
        margin-bottom: em(40, 38);
        margin-left: em(10, 38);
      }
    }

    .plan-form {
      margin-bottom: em(40);

      .radio {
        margin-bottom: em(20);
      }

      .checkbox-form {
        margin-bottom: 0;
      }

      .form-group {
        &.half-input {
          width: 100%;
          float: none;
        }
      }
    }

    .registration__attendance {
      .col-md-12 {
        padding-left: em(30);
      }

      .form-group {
        &.registration__booking,
        &.registration__choice--date,
        &.registration__choice--dropdown {
          width: 100%;
          margin-bottom: em(20);
        }

        &.registration__choice--date {
          .control-label {
            display: none;
          }

          & > .field-border {
            width: 100%;
          }
        }

        &.registration__age,
        &.registration__pax {
          padding-left: 0;
          width: 50%;
        }

        &.registration__choice--dropdown,
        &.registration__booking {
          width: 97%;
        }
      }
    }
  }
}
