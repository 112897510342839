.btn {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: $black;
  font-size: 100%;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;
  }

  &--cta {
    text-align: center;
    border: $black em(2) solid;

    transform: translateZ(0);
    padding: 0;

    .btn__text,
    .btn {
      background: $white;
      transition: color .3s ease;
      display: block;
      padding: em(17.28, 17.28);
      font: #{em(17.28)}/#{em(20.74, 17.28)} $robo-bl;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: em(-2);
      left: em(-1);
      right: em(-1);
      bottom: em(-2);
      opacity: 0;
      animation: none;
    }

    &:before {
      border: $dolly em(2) solid;
      z-index: -1;
    }

    &:after {
      border: $lolla em(2) solid;
      z-index: -2;
    }

    &:hover {
      &:before,
      &:after {
        opacity: 1;
      }

      &:before {
        animation: glitch .3s cubic-bezier(.25, .46, .45, .94) both infinite;
      }

      &:after {
        animation: glitch .3s cubic-bezier(.25, .46, .45, .94) reverse both infinite;
      }
    }
  }

  &--small {
    text-transform: uppercase;
    color: $black;
    padding: em(10) em(16);

    .btn__text,
    .ico-arrow {
      vertical-align: middle;
      display: inline-block;
    }

    .btn__text {
      padding: 0;
      font-size: em(10);
    }

    .ico-arrow {
      font-size: em(14);
      padding-right: em(10, 17);
    }
  }

}

.button__wrapper {
  margin-top: em(68);
  text-align: center;

  .btn--cta {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    max-width: em(225);

    .btn__text {
      padding: em(13);
    }
  }
}


@media screen and (max-width: $breakpoint-max) {
  .button__wrapper {
    margin-top: em(43);
  }
}

@include keyframes(glitch) {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-2px, 2px);
  }

  40% {
    transform: translate(-2px, -2px);
  }

  60% {
    transform: translate(2px, 2px);
  }

  80% {
    transform: translate(2px, -2px);
  }

  to {
    transform: translate(0);
  }
}

// @include keyframes(changetext) {
//   0% {
//     transform: translateY(0);
//   }

//   30% {
//     transform: translateY(-100%);
//     opacity: 0;
//   }

//   66% {
//     transform: translateY(100%);
//     opacity: 0;
//   }

//   100% {
//     transform: translate(0);
//     opacity: 1;
//   }
// }
