.getting-there {
  display: block;
  line-height: 1;

  &:hover {
    .getting-there__icon:after {
      opacity: 1;
    }
  }

  &__title {
    font: #{em(35)/1} $wal-b;
    color: $black;
    display: inline-block;
  }

  &__icon {
    color: $black;
    vertical-align: middle;

    &:before {
      font-family: $sam;
      content: $ico-location-pin;
    }
  }
}

@include minquery($breakpoint-min) {
  .getting-there {
    float: right;

    &__icon {
      position: relative;
      padding-right: em(9);
      font-size: em(32);
      z-index: 1;

      &:after {
        content: '';
        width: em(4.5);
        height: em(4.5);
        border-radius: 50%;
        display: inline-block;
        background-color: $lolla;
        position: absolute;
        z-index: -1;
        top: em(5.5);
        left: em(3.5);
        opacity: 0;
        transition: opacity .3s ease;
      }
    }
  }
}

//fix bug hover IE
.ie {
  .getting-there__icon {
    &:after {
      top: em(9.5);
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .getting-there {
    text-align: center;
    margin: em(15) auto em(25);
    padding-right: em(10);
    padding-left: em(10);

    &__title {
      font-size: em(31);
    }

    &__icon {
      font-size: em(30);
      padding-right: em(9);
    }
  }
}
