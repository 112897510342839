.more-resource {
  display: block;
  position: relative;
  margin-bottom: em(60);
  text-align: center;
  text-decoration: none;
  border: $grey em(1) solid;
  border-bottom: none;
  transition: opacity .3s ease;
  background-color: $white;

  &__wrapper {
    // padding-top: em(80);
    margin-bottom: em(107);
    position: relative;
    z-index: 1;

    .title__wrapper {
      margin-bottom: em(70);
    }

    &--special {
      margin-bottom: em(36);
    }
  }

  &:hover {
    opacity: .7;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    height: 12%;
    border-bottom: $grey em(1) solid;
  }

  &:before {
    left: 0;
    padding-right: 1px;
    transform-origin: 0 0;
    transform: skewY(12deg);
  }

  &:after {
    right: 0;
    transform-origin: 100% 0;
    transform: skewY(-12deg);
  }

  &__image {
    display: block;
    width: 100%;

    &__img {
      display: block;
      width: 100%;
    }
  }

  &__type,
  &__title,
  &__date {
    display: block;
    color: $black;
  }

  &__type {
    padding: em(10, 14.4);
    background-color: $moon;
    font: #{em(14.4)}/#{em(15.12, 14.4)} $robo-b;
    letter-spacing: em(1.4, 14.4);
  }

  &__title {
    height: em(66, 17.28);
    overflow-y: hidden;
    font: #{em(17.28)}/#{em(18.14, 17.28)} $wal-b;
    padding: em(11, 17.28) em(20, 17.28) 0;
    letter-spacing: em(-.36, 17.28);
  }

  &__date {
    padding-top: em(20, 17.28);
    padding-bottom: em(6, 17.28);
    font: #{em(17.28)}/#{em(18.14, 17.28)} $robo-l;
    letter-spacing: em(.21, 17.28);
    position: relative;
    z-index: 1;
  }

  //for special programmes component
  &--special {
    padding-bottom: em(23);

    .more-resource__image {
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        bottom: em(-59);
        width: 50%;
        height: 40%;
        background: $white;
      }

      &:before {
        left: 0;
        padding-right: 1px;
        transform: skewY(14.5deg);
      }

      &:after {
        right: 0;
        transform: skewY(-14.5deg);
      }
    }

    .more-resource__title {
      font: #{em(20.74)}/#{em(21.77, 20.74)} $wal-b;
      height: em(55.19, 20.74);
      position: relative;
      margin-top: em(23, 20.74);
    }

    .more-resource__text {
      font: #{em(14.4)}/#{em(21.6, 14.4)} $robo-l;
      color: $black;
      margin: em(28, 14.4) em(15, 14.4) 0;
      height: em(68, 14.4);
      overflow: hidden;
      display: block;
      position: relative;
    }

    .list-tagline {
      display: block;
      margin: em(17) em(17) 0;
      position: relative;
      min-height: em(20);

      &--empty {
        background: none;
      }
    }

    .tagline-item {
      font-size: em(14.4);
      line-height: em(15.12, 14.4);
      padding-left: 0;
      padding-right: 0;
      letter-spacing: 0;

      &:first-child {
        text-align: right;
        padding-right: em(6, 14.4);
      }

      &:last-child {
        text-align: left;
        padding-left: em(6, 14.4);
      }
    }
  }
}

.desktop &.more-resource__wrapper {
  .row {
    .col-4 {
      $start: 50%;
      $step: 10;
      $delay: .2s;
      opacity: 0;
      transition: all .9s;

      &:nth-child(1) {
        transform: translate3d(0, $start, 0);
      }

      &:nth-child(2) {
        transform: translate3d(0, 2 * $step + $start, 0);
        transition-delay: $delay, $delay;
      }

      &:nth-child(3) {
        transform: translate3d(0, 3 * $step + $start, 0);
        transition-delay: 2 * $delay, 2 * $delay;
      }
    }

    &.animation {
      .col-4 {
        &:nth-child(n) {
          transform: none;
          opacity: 1;
        }
      }
    }
  }
}

@media screen and  (max-width: $breakpoint-max) {
  .more-resource {
    margin: 0 em(19) 15%;

    &__wrapper {
      margin-bottom: em(60);
      padding-top: em(18);
      padding-bottom: em(19);
      border-bottom: 1px solid $black;

      .title__wrapper {
        text-align: left;
        margin: 0 em(19) em(32);
      }

      &--special {
        .title__wrapper {
          text-align: center;
          margin-bottom: em(51);
        }
      }
    }

    &__type {
      padding: em(8);
    }

    &__title {
      height: auto;
      padding-top: em(8);
      overflow-y: initial;
    }

    &__date {
      padding-top: em(31);
      padding-bottom: em(3);
    }

    &--special {
      // margin-left: em(9);
      // margin-right: em(9);
      padding-bottom: em(16);

      .list-tagline {
        margin-top: em(12);

        &--empty {
          min-height: auto;
        }
      }

      .more-resource__text {
        margin-top: em(25, 14.4);
      }

      .more-resource__title {
        margin-top: em(5);
        height: auto;
      }
    }
  }
}
