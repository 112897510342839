.highlight-work {
  @include deco-triangle($grey-11, $grey-11, $purple, $purple-3);

  &:before,
  &:after {
    height: 80%;
  }

  .decotriangle--bottom {
    bottom: em(-100);

    &:before,
    &:after {
      background: $grey-11;
    }
  }

  &__wrapper {
    // margin-bottom: em(58);
    // padding-bottom: em(162, 18);
    position: relative;
    z-index: 1;

    .title__wrapper {
      margin-bottom: em(80);
    }

    &--revert {
      z-index: 10;
    }
  }

  .btn--cta {
    margin: em(128) auto 0;
    width: em(220);
    text-transform: uppercase;
    z-index: 3;

    .btn__text {
      padding-top: em(14, 17.28);
      padding-bottom: em(14, 17.28);
      background-color: $grey-11;
    }
  }

  &__revert-1 {
    &::after {
      height: 60%;
    }
  }

  &__revert-2 {
    &::after {
      height: 70%;
    }
  }

  &--revert {
    &:before,
    &:after {
      content: none;
    }
  }

  &__wrapper--revert {
    .title__wrapper {
      margin-bottom: 0;
    }
  }

  &__opened {
    z-index: 2;
  }


}

.highlighted-work--reset {
  .row {
    &:first-child {
      .col-6,
      .col-4 {
        background: $grey-11;
      }
    }
  }
}

.highlight {
  &__name {
    display: block;
    padding-bottom: em(8, 14.4);
    margin-bottom: em(20, 14.4);
    font-size: em(14.4);
    font-family: $robo-b;
    color: $grey-8;
    letter-spacing: em(1.82, 14.4);
    border-bottom: 1px solid $grey;
  }

  &__title {
    font-family: $wal-b;
    font-size: em(24.88);
    line-height: em(26, 24.88);
    color: $black;
    max-width: em(250, 24.88);
    margin-bottom: em(17, 24.88);
  }

  &__infor {
    border-top: 1px solid $grey;

    &__text {
      padding: em(1, 14.4) 0;
      font-size: em(14.4);
      color: $grey-8;
      font-family: $robo-l;
      border-bottom: 1px solid $grey;
    }
  }

  &__svg {
    .ie & {
      height: em(355);
    }
  }

  &__image {
    position: relative;
    display: block;
  }

  &__ico-slideshow {
    position: absolute;
    color: $white;
    right: em(15, 24);
    bottom: em(75, 24);
    font-size: em(24);
  }

  &__ico--play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: em(52);
    height: em(52);
    display: block;

    &:after {
      content: $ico-play;
      font-family: $sam;
      font-size: em(70, 20);
      font-weight: normal;
      color: $white;
    }
  }

  &__overlay {
    opacity: .4;
    background-color: $black;
  }

  &__desc-revert {
    padding-left: em(12, 15);

    .highlight {
      &__name {
        text-transform: uppercase;
        letter-spacing: em(1, 13.5);
        line-height: em(15, 13.5);
        padding-bottom: em(14, 13.5);
        margin-bottom: em(10, 13.5);
      }

      &__infor {
        border: none;
      }

      &__infor__text {
        border: none;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-min) {
  .highlight {
    &__desc-revert {
      margin-top: em(-50, 15);
    }
  }

  .highlight-work {
    padding-top: em(88);

    .row:not(:first-child) {
      margin-top: em(-43);
    }

    &--revert {
      padding-top: 0;
      margin-top: em(60, 15);

      .row--revert {
        .col-4,
        .highlight__desc-revert {
          padding-left: em(4);
        }

        .col-4 {
          padding-right: em(11);
        }
      }
    }
  }

  .container--flexbox {
    padding-bottom: em(90);

    .row {
      display: flex;
      align-items: center;

      & + & {
        margin-top: em(-37);
      }

      &--revert {
        text-align: right;
        flex-direction: row-reverse;

        .col-6 {
          margin-right: percentage(1/12);
        }

        .highlight__title {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .highlight-work {

    &__wrapper {
      margin-bottom: em(83);
      padding-bottom: 0;

      &--no-spacing {
        margin-bottom: 0;
      }
      
      .title__wrapper {
        margin: auto;
      }

      .title {
        padding-left: em(10);
        padding-right: em(10);
      }

      .sub-title {
        padding-left: em(15);
        padding-right: em(15);
      }

    }

    .btn--cta {
      margin-top: 0;
    }
  }

  .highlight {
    &-work {
      margin-top: 14.7%;
      padding-top: 0;
      padding-bottom: em(90);

      .decotriangle--bottom {
        top: 50%;
      }
    }

    &__ico-slideshow {
      top: 80%;
      transform: translateY(-50%);
      bottom: 0;
    }

    &__desc-revert {
      padding-left: 0;
    }
  }

  .container--flexbox {
    .row {
      padding-bottom: em(30);
      padding-top: 0;
      overflow: hidden;
      position: relative;

      & + & {

        &:before {
          content: '';
          position: absolute;
          top: 0;
          height: 2px;
          left: em(-11);
          right: em(-11);
          background-color: $grey-1;
          z-index: 1;
        }
      }

      &--reset-align {
        text-align: center;
      }
    }
  }
}
