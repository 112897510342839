.wrapper {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;


  &.freeze {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.contact-us-template,
.template-form {
  padding-top: em(150);
}

.contact-us-page {
  .newsletter {
    position: relative;
    z-index: 5;
  }
}

.landing-page__wrapper {
  @include deco-triangle($grey-6, $white, $lolla, $lolla, 0);
  z-index: 1;

  .detail-block {
    position: relative;
    z-index: 2;
  }
}

@media screen and (max-width: $breakpoint-max) {
  .template-form {
    padding-left: em(10);
    padding-right: em(10);
  }
}
