.dine {
  &__infor {
    margin-top: em(11);

    .dine__title {
      font-size: em(14.4);
      font-family: $robo-b;
      color: $grey-8;
      letter-spacing: em(-.1, 14.4);
      margin-bottom: em(17, 14.4);
      padding-bottom: em(8, 14.4);
      border-bottom: em(4, 14.4) solid $grey;
    }

    .infor__link {
      font-size: em(14.4);
      font-family: $robo-b;
      color: $grey-8;
      margin-top: 0;
    }
  }
}

@media screen and (min-width: $breakpoint-min) {
  .dine {
    &-wrapper {
      margin-left: em(-30);
      margin-right: em(-30);
      overflow: hidden;
    }

    &__item {
      float: left;
      padding: 0 em(30);
      width: percentage(1/3);
    }

    &--one-column {
      margin-left: em(-30);
      margin-right: em(-30);
      overflow: hidden;

      .dine__img {
        max-width: percentage(1/3);
        padding: 0 em(30);
        float: left;
      }

      .dine__title {
        margin-bottom: em(27, 14.4);
        max-width: 50%;
      }

      .dine__infor {
        padding-top: em(6);
        padding-left: em(20);
        padding-right: em(100);
        margin-top: 0;
        width: percentage(2/3);
        float: left;

        .infor__link {
          color: $black;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .dine {
    padding: 0 em(37);
    max-width: em(300);
    margin-left: auto;
    margin-right: auto;

    &-wrapper {
      margin-bottom: 0;

      .slick-arrow {
        font-size: em(25);
        position: absolute;
        top: em(100, 25);
        border: 0;
        background: transparent;
        color: $grey;
      }

      .slick-prev {
        left: em(7, 25);
      }

      .slick-next {
        right: em(7, 25);
      }

      .slick-dots {
        overflow: hidden;
        text-align: center;
        margin-top: em(5);

        li {
          position: relative;
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: transparent;
          border-radius: 50%;

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 4px;
            height: 4px;
            background-color: $grey;
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }

          &.slick-active {
            background-color: $lolla;
            margin: 0 em(2);

            &:before {
              background-color: $lolla;
            }
          }
        }

        button {
          display: none;
        }
      }
    }

    &__infor {
      margin-top: em(14);

      .dine__title {
        font-size: em(14);
        margin-bottom: em(6, 14);
      }

      .infor__link {
        margin-top: 0;
      }

    }

  }
}
