.main-footer {
  position: relative;

  &__top {
    background-color: $grey-4;
  }

  &__heading {
    border-bottom: 2px solid $grey-5;
  }

  &__bottom {
    background-color: $white;
    overflow: hidden;
  }

  &__link-item {
    float: left;
  }

  &__link {
    font-size: em(10);
    color: $grey-1;
    font-family: $robo-b;
    line-height: 1.1;
    letter-spacing: .5px;
    text-decoration: none;

    &:hover {
      color: $black;
    }
  }
}

.copy-right {
  font-size: em(10);
  color: $grey-1;
  font-family: $robo-b;
  line-height: 1.1;
}

@include minquery($breakpoint-min) {
  .main-footer {
    &__top {
      overflow: hidden;
      padding-bottom: em(30);
      padding-left: em(30);
      padding-right: em(30);
    }

    &__heading {
      overflow: hidden;
      padding-top: em(14);
      padding-bottom: em(17);
    }

    &__bottom {
      padding: em(10) em(30);

      .copy-right {
        float: left;
      }
    }

    &__link-item {
      margin-left: em(16);
    }

    &__link-bottom {
      float: right;
      line-height: .6;
    }

    &__link {
      padding-left: em(10);
    }
  }

  .social-share {
    padding-top: em(11);
  }
}

@media screen and (max-width: $breakpoint-max) {
  .main-footer {
    &__bottom {
      padding: em(12) em(20) em(5);
    }

    &__link-bottom {
      text-align: center;
    }

    &__link-item {
      width: 33.33%;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .copy-right {
    padding-bottom: em(25);
    border-bottom: 1px solid $grey-1;

    &__text {
      display: block;
      font-family: $robo-r;
      padding-top: em(10);

      &:first-child {
        padding-top: em(12);
      }
    }
  }
}
