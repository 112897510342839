.associated {
  &-events__wrapper {
    position: relative;
    z-index: 1;
    // padding-bottom: em(2);
    padding-top: em(162, 18);
    padding-bottom: percentage(100/1000);
  }

  &__title {
    font: em(53.78) $wal-b;
    color: $black;
    letter-spacing: -.19px;
    text-align: center;
    margin-bottom: em(54, 53.78);
  }

  &__content {
    border-bottom: 2px solid $black;
  }

  &__img {
    padding-right: em(23);

    img {
      display: block;
      width: 100%;
    }

  }

  &__time {
    text-align: center;
    padding-top: em(12);

    &__date,
    &__day,
    &__hour {
      display: block;

      &.hidden {
        display: none;
      }
    }

    &__date {
      font-size: em(51.6);
      font-family: $wal-b;
      color: $black;
      line-height: em(32, 51.6);
    }

    &__day,
    &__month {

      font-family: $robo-b;
      font-size: em(12);

    }

    &__month {
      color: $black;
      letter-spacing: em(.37, 12);
      display: inline-block;
      padding: 0 em(7, 12) em(5, 12);
      border-bottom: 2px solid $grey-5;
      margin-bottom: em(8, 12);

    }

    &__day {

      color: $grey-8;
      text-transform: uppercase;

    }

    &__hour {
      font: em(14.4) $robo-l;
      color: $grey-8;
    }
  }

  &__infor {
    padding-top: em(9);

    &__title {
      margin-bottom: em(20, 21);
    }

    &__link {
      display: inline-block;
      font: #{em(20.74, 21)}/#{em(23, 20.74)} '#{$wal-b}';
      color: $black;
      letter-spacing: -.44px;
      transition: color .3s;

      &:hover {
        color: $grey;
      }
    }

    &__tag {
      display: inline-block;
      min-width: em(163, 12);
      max-width: em(250, 12);
      margin-bottom: em(14, 12);
      padding: 0 em(18, 12) 0 em(10, 12);
      background-color: $yellow;
      font-size: em(12);
      line-height: em(14, 12);
      color: $black;
      letter-spacing: 1.2px;

      .tag__highlight,
      .tag__item {
        display: inline-block;
      }

      .tag__highlight {
        font-family: $robo-b;
        padding-right: em(10, 12);
      }

      .tag__item {
        position: relative;
        font-family: $robo-l;
        padding-left: em(10, 12);
        padding-right: em(10, 12);

        &:before {
          content: '';
          position: absolute;
          top: 2px;
          bottom: 2px;
          left: 0;
          width: 1px;
          background-color: $black;
        }
      }
    }

    &__exhibition {
      font: em(14.4) $robo-l;
      letter-spacing: .25px;
      color: $grey;
    }

    &__star {
      font: em(14.4) $robo-b;
      letter-spacing: .23px;
      color: $blue-5;
    }
  }

}

@media screen and (min-width: $breakpoint-min) {
  .associated {
    display: table;
    width: 100%;
    max-width: em(800);
    margin-left: auto;
    margin-right: auto;

    & + & {

      margin-top: em(15);

    }

    &__img {
      width: 41%;
      transition: opacity .3s;

      &:hover {
        opacity: .7;
      }
    }

    &__content {
      width: 59%;
    }

    &__img,
    &__content {

      display: table-cell;
      vertical-align: middle;

    }

    &__time,
    &__infor {

      display: inline-block;
      vertical-align: top;

    }

    &__time {
      min-width: em(169);
    }

    &__infor {

      &__title {
        max-width: em(250, 20.74);
        line-height: em(30, 20.74);
      }
    }
  }

  .desktop {
    .associated {
      opacity: 0;
      transition: all .9s .5s;
      transform: translate3d(0, 50%, 0);

      &.animation {
        &:nth-child(n) {
          transform: none;
          opacity: 1;
        }
      }
    }

  }
}

@media screen and (max-width: $breakpoint-max) {
  .associated {
    position: relative;

    &__inner {
      padding: 0 em(12);
    }

    & + & {
      margin-top: em(16);
    }

    &__title {
      font-size: em(38.42);
      letter-spacing: em(-1.47, 38.42);
      line-height: em(35, 38.42);
      margin-bottom: em(29, 38.42);
    }

    &__img {
      position: relative;
      padding-right: em(110);
      display: block;

      &:before {

        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: em(100);
        height: em(2);
        background-color: $grey-5;
      }

      picture {
        display: block;
      }

    }

    &__time {

      position: absolute;
      top: 0;
      right: 0;
      max-width: em(110);
      width: 100%;
      padding-top: em(16);
      padding-left: em(10);
      text-align: left;

      &__date {
        font-size: em(51.6);
        line-height: em(34, 51.6);
      }

      &__month {
        font-size: em(12);
        letter-spacing: em(.37, 12);
        padding: 0 em(20, 12) 0 0;
        margin-bottom: 0;
      }

      &__day {
        font-size: em(12);
        letter-spacing: em(.6, 12);
        margin-top: em(2, 12);
        margin-bottom: em(-2, 12);
      }

      &__hour {
        font-size: em(14.4);
      }

    }

    &__infor {
      padding-bottom: em(13);
      border-bottom: 1px solid $black;

      &__link {
        font-size: em(20.74, 19);
        letter-spacing: em(-.44, 20.74);
      }

      &__title {
        padding-right: em(20);
        margin-bottom: em(8, 19);
      }

      &__star {
        text-transform: uppercase;
      }
    }

    &__content {
      border: 0;
      padding: 0 em(5);
    }
  }
}
