@media screen and (min-width: em(1920)) {
  body {
    font-size: 22px;
  }
}

@media screen and (min-width: em(1441)) and (max-width: em(1919)) {
  body {
    font-size: 18px;
  }
}

@media screen and (min-width: em(1024)) and (max-width: em(1440)) {
  body {
    font-size: 15px;
  }
}

@media screen and (min-width: em(768)) and (max-width: em(1023)) {
  body {
    font-size: 12px;
  }
}

@media screen and (min-width: $breakpoint-min) {
  .hidden-desktop {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-max) {
  .container {
    width: 100%;
  }

  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10 {
    width: 100%;
  }

  .col-pull-6,
  .col-push-5,
  .col-pull-2,
  .col-pull-1 {
    left: 0;
  }

  .col-offset-1 {
    margin-left: 0;
  }

  .col-offset-revert-1 {
    margin-right: 0;
  }

  .title {
    font-size: em(39);
    line-height: em(46.8, 39);

    &__wrapper {
      margin-bottom: em(9);
    }
  }

  .sub-title {
    max-width: none;
    margin-top: em(13, 14.4);
  }

  .triangle-deco {
    top: em(-23);

    &--bottom {
      top: 50%;
    }

    &--right {
      &:before {
        width: 87%;
      }

      &:after {
        width: 13%;
      }
    }
  }

  .hidden-mobile {
    display: none;
  }

  .landing-page__wrapper {
    margin-top: -3%;
  }

}

