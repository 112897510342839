.examples {
  .wrapper {
    margin: 0 auto;
    width: em(962);
  }

  section {
    overflow: hidden;
  }

  .test {
    width: 100%;
    overflow: hidden;
    max-width: 100%;
    height: auto;

    .ie & {
      height: em(382);
    }
  }

  .banner {
    width: em(512);
    margin: em(50) auto;
    text-align: center;
  }

  .col-3 {
    float: left;
    width: 33.33%;
    padding: em(20);
  }

  .hexagon {
    position: relative;
    margin-bottom: 15%;

    svg {
      .ie & {
        height: em(214);
      }
    }

    &__wrapper {
      @extend %clearfix;
    }

    &__border {
      content: '';
      position: absolute;
      top: 0;
      left: em(10);
      right: em(10);
      bottom: 0;
      margin-top: 15%;
      border-left: $black em(1) solid;
      border-right: $black em(1) solid;
    }

    &__top,
    &__bottom {
      &:before,
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 50%;
        height: em(1);
        background: $black;
      }
    }

    &__top {
      position: absolute;
      top: 0;
      width: 100%;

      &:before {
        transform: skewY(-14deg);
        transform-origin: 0 0;
      }

      &:after {
        right: 0;
        transform: skewY(14deg);
        transform-origin: 100% 0;
      }
    }

    &__bottom {
      position: absolute;
      bottom: 0;
      width: 100%;

      &:before {
        transform: skewY(14deg);
        transform-origin: 0 0;
      }

      &:after {
        right: 0;
        transform: skewY(-14deg);
        transform-origin: 100% 0;
      }
    }

    &__content {
      padding: em(20);
      margin: 0 em(10);
      position: relative;
    }
  }
}

@media screen and (max-width: 5000px) {
  .examples {
    font-size: 22px;
  }
}

@media screen and (max-width: 1700px) {
  .examples {
    font-size: 18px;
  }
}

@media screen and (max-width: 1200px) {
  .examples {
    font-size: 16px;
  }
}

@media screen and (max-width: 1023px) {

  .examples {
    .wrapper {
      width: 100%;
    }

    .banner {
      width: 70%;
    }

    .col-3 {
      width: 100%;
      padding: em(20) 0;
    }

    .hexagon {
      margin-bottom: 14%;

      &__border {
        margin-top: 14%;
      }
    }
  }
}
