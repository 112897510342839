.exhibition {
  @include hex-border(13.5deg, $grey, em(1), 0, 12%);

  display: block;
  position: relative;
  margin-bottom: em(60);
  padding-bottom: em(11);
  text-align: center;
  text-decoration: none;

  z-index: 1;

  &:hover {
    .exhibition__overlay {
      opacity: .2;
    }
  }

  &__overlay {
    fill: $black;
    opacity: 0;
    transition: opacity .3s ease;
  }

  &:after {
    content: $ico-location-pin;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: $sam;
    color: $black;
    font-size: em(30);
    margin-top: em(8, 30);
  }

  &--no-location {
    .exhibition__status {
      margin-bottom: em(25, 20.74);
    }

    &:after {
      display: none;
    }
  }

  &__wrapper {
    //@include deco-triangle($grey-6, $white, $lolla);

    margin-bottom: em(150);
    text-align: center;
    position: relative;
    z-index: 1;

    .container {
      margin-top: em(70);
    }

    .title__wrapper {
      margin-bottom: em(2);
    }

    &--learning {
      // @include deco-triangle($grey-6, $white, $lolla);

      margin-bottom: em(118);

      .container {
        margin-top: 0;
      }
    }
  }

  &__past {
    font: #{em(14.4)}/#{em(14.4, 17.28)} $robo-b;
    letter-spacing: em(.25, 14.4);
    color: $grey;
    position: relative;
    z-index: 1;
    text-decoration: none;
    margin-left: em(15, 14.4);

    // &:after {
    //   content: $ico-long-arrow-right-circle;
    //   font-family: $sam;
    //   font-size: em(15, 14.4);
    //   margin-left: em(4, 14.4);
    //   vertical-align: middle;
    //   line-height: em(16, 14.4);
    // }

    &:hover {
      opacity: .7;
    }

    &__arrow-ico {
      height: em(15, 14.4);
      margin-left: em(4, 14.4);
      vertical-align: middle;
      line-height: em(16, 14.4);

      .ie & {
        width: em(30, 14.4);
      }

      path,
      circle {
        stroke: $grey;
        stroke-width: 2.5;
      }
    }
  }

  &__image {
    .ie & {
      height: em(256);
    }
  }

  &__title,
  &__status,
  &__info {
    display: block;
    margin-left: em(22, 20.74);
    margin-right: em(22, 20.74);
  }

  &__title,
  &__status {
    color: $black;
  }

  &__title {
    $t-height: 23.77 * 3;

    font: #{em(20.74)}/#{em(23.77, 20.74)} $wal-b;
    letter-spacing: em(-.44, 20.74);
    height: em($t-height, 20.74);
    overflow: hidden;
    margin-top: em(5, 20.74);
  }

  &__status {
    position: relative;
    font: #{em(20.74)}/#{em(24.88, 20.74)} $robo-l;
    letter-spacing: em(.25, 20.74);
    margin-top: em(16, 20.74);
    padding-bottom: em(15, 20.74);
    border-bottom: $black 1px solid;
  }

  &__info {
    font: #{em(14.4)}/#{em(17.28, 14.4)} $robo-bl;
    letter-spacing: em(-.17, 14.4);
    color: $grey;
    margin-top: em(8, 14.4);
  }

  &--other {
    @include hex-border(13.5deg, $grey, em(1), em(9), 14%);

    text-align: left;

    &:after {
      display: none;
    }

    .exhibition__time {
      margin-bottom: em(5, 24);
    }

    .tagline-item {
      padding-left: em(18, 12);
      padding-right: em(24, 12);
      font: #{ em(12)}/#{em(16, 12)} $robo-l;

      &:first-child {
        padding-right: em(20, 12);
      }

      &:last-child {
        padding-left: em(23, 12);
      }

      &--active {
        font-family: $robo-b;
        line-height: em(14, 12);
      }
    }
  }

  &--learning {
    .exhibition__title {
      margin: em(15, 20.74) em(25, 20.74) 0;
      padding-bottom: em(19, 20.74);
      border-bottom: $grey-5 1px solid;
      height: auto;
    }

    .exhibition__text {
      margin-top: em(17, 15.4);
      padding: 0 em(15, 15.4);
      height: em(91, 15.4);
      overflow: hidden;
    }
  }

  &__other-content {
    display: block;
    margin: 0 em(19) 0 em(23);
    padding-bottom: em(11);
  }

  &__heading {
    display: block;
    border-bottom: $black 1px solid;
    padding-bottom: em(9);
  }

  &__time {
    display: block;
    margin-top: em(30, 24);
    color: $black;
    font: #{em(24)}/#{em(20, 24)} $robo-l;
    letter-spacing: em(.25, 24);
  }

  &__text {
    display: inline-block;
    color: $grey-8;
    font: #{em(15.4)}/#{em(21.74, 15.4)} $robo-l;
    letter-spacing: em(-.1, 15.4);
  }

  &__text-title {
    display: block;
    color: $black;
    font: #{em(19.6)}/#{em(18.62, 19.6)} $wal-b;
    margin: em(18, 19.6) 0 em(12, 19.6);
  }
}

.other-exhibition__wrapper {
  padding-top: em(48);
  padding-bottom: em(78);
}

.desktop &.exhibition__wrapper,
.desktop &.other-exhibition__wrapper {
  .row {
    .col-4 {
      $start: 50%;
      $step: 10;
      $delay: .2s;
      opacity: 0;
      transition: all .9s;

      &:nth-child(1) {
        transform: translate3d(0, $start, 0);
      }

      &:nth-child(2) {
        transform: translate3d(0, 2 * $step + $start, 0);
        transition-delay: $delay, $delay;
      }

      &:nth-child(3) {
        transform: translate3d(0, 3 * $step + $start, 0);
        transition-delay: 2 * $delay, 2 * $delay;
      }
    }

    &.animation {
      .col-4 {
        &:nth-child(n) {
          transform: none;
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max) {

  .exhibition {
    margin: 0 em(19) 14.5%;

    &:after {
      font-size: em(27);
    }

    &__wrapper {
      padding-bottom: em(17);

      &--learning {
        padding-top: em(8);
        box-shadow: 0 em(1) 0 $black;
        margin-bottom: em(78);
        padding-bottom: em(67);
      }

      .title__wrapper {
        margin-bottom: em(13);
      }
    }

    &__title {
      margin-top: em(4, 20.74);
    }

    &__status {
      margin-top: em(8, 20.74);
      padding-bottom: em(11, 20.74);

      &:after {
        margin-top: em(11, 20.74);
      }
    }

    &__info {
      margin-top: em(6, 14.4);
    }

    &__other-content {
      margin: 0 em(19) 0 em(18);
      padding-bottom: 0;
    }

    &__time {
      margin-top: em(10, 24);
    }

    &__text-title {
      margin: em(22, 19.6) 0 em(6, 19.6);
    }

    &--learning {
      margin-bottom: 19%;

      .exhibition__title {
        margin-top: em(11, 20.74);
        padding-bottom: em(17, 20.74);
      }

      .exhibition__text {
        margin-top: em(14, 19.6);
        height: auto;
      }
    }
  }

  .exhibition--other {

    .tagline-item {
      padding-left: em(20, 12);
      padding-right: em(18, 12);
      font: #{ em(12)}/#{em(16, 12)} $robo-l;

      &:first-child {
        padding-left: em(12, 12);
        padding-right: em(16, 12);
      }

      &:last-child {
        padding-left: em(21, 12);
      }
    }
  }
}
