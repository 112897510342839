.popup-highlight {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black;
  overflow-y: auto;
  z-index: 100;
  display: none;

  &__title {
    font: #{em(24.88)}/#{em(26.13, 24.88)} $wal-b;
    letter-spacing: em(-1.47, 24.88);
    color: $white;
  }

  &__close {
    color: $white;
    cursor: pointer;

    &:before {
      @extend %icon;
      content: $ico-cancel;
    }
  }

  &__text-close {
    color: transparent;
    font-size: em(1);
  }

  &--banner {
    .popup-highlight__inner {
      padding: em(130) em(40) em(40);
      width: 100%;
      max-width: em(1260);
    }

    .popup-highlight__close {
      position: absolute;
      top: em(65, 26);
      right: em(10, 26);
      font-size: em(26);
    }

    .slider-highlight__item {
      min-height: auto;
    }
  }
}

.slider-highlight {
  @extend %clearfix;
  border-bottom: 1px solid $half-white;
  padding-bottom: em(8);

  &__item {
    position: relative;
    margin-bottom: em(10, 16);
  }

  &__img-holder {
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;

    .slider-highlight__image {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
    }
  }

  &__image {
    width: 100%;
  }

  &__video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__number {
    font: #{em(14)/1} $robo-b;
    color: $white;
    display: inline-block;
    margin-top: em(10, 14);
  }

  &__next,
  &__prev {
    @extend %icon;
    font-size: em(30);
    float: right;
    border: 0;
    background-color: transparent;
    color: $white;
    outline: none;
    cursor: pointer;
  }

  &__next {
    &:before {
      content: $ico-long-arrow-right-circle;
    }
  }

  &__prev {
    &:before {
      content: $ico-long-arrow-left-circle;
    }
  }
}

.highlight-info {
  &__name {
    font: #{em(21)/1} $wal-b;
    color: $grey-1;
    display: inline-block;
  }

  &__title {
    font: #{em(29)}/#{em(18)} $wal-b;
    color: $white;
    display: block;
    border-bottom: 1px solid $half-white;
    padding-bottom: em(15, 29);
  }

  &__infor {
    border-bottom: 1px solid $half-white;
    margin-bottom: em(20);
    padding-top: em(10);
    padding-bottom: em(13);
  }

  &__detail-desc {
    font: #{em(14)}/#{em(25, 14)} $robo-l;
    color: $half-white;
    margin-bottom: em(20, 16);
  }
}

.highlight__infor {
  &--modified {
    .highlight__infor {
      &__text {
        font: #{em(14)}/#{em(18, 14)} $robo-m;
        color: $moon;
        letter-spacing: .5px;
        padding: 0;
        border: none;
      }
    }
  }
}

@include minquery($breakpoint-min) {
  .popup-highlight {
    &__title {
      position: absolute;
      top: em(93, 24.88);
      left: em(54, 24.88);
    }

    &__close {
      position: absolute;
      top: em(79, 26);
      right: em(37, 26);
      font-size: em(23);
    }

    &__inner {
      padding: em(140) em(55) em(100);
      position: relative;
      max-width: em(1400);
      margin: 0 auto;
    }

    &__wrapper {
      display: flex;
    }
  }

  .slider-highlight {
    width: 67%;

    &__prev {
      padding: em(1) 0;
      margin-right: em(23, 30);
    }

    &__next {
      padding: em(1);
    }

    &__number {
      padding-left: em(10, 14);
    }
  }

  .highlight-info {
    width: 33%;
    border-top: 1px solid $half-white;
    border-bottom: 1px solid $half-white;
    margin-left: em(40);
    padding-top: em(7);

    &__detail-desc {
      max-height: em(198, 14);
      overflow-x: auto;
    }

    &__item--revert {
      .highlight-info__detail-desc {
        // min-height: em(370, 13.125);
        margin-top: em(20);
      }
    }
  }

  .highlight-work {
    &__wrapper--revert {
      .sub-title {
        max-width: em(400);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .popup-highlight {
    overflow-y: auto;

    &__inner {
      padding-top: em(20);
      padding-bottom: em(20);
    }

    &__wrapper {
      clear: both;
    }

    &__title {
      display: block;
      width: 100%;
      margin-bottom: 13px;
      padding-left: em(30, 24.88);
    }

    &__close {
      font-size: em(22);
      text-align: right;
      display: block;
      width: 100%;
      padding-bottom: em(9, 22);
      padding-right: em(20, 22);
    }

    &--banner {
      .popup-highlight__inner {
        padding: em(67) em(20) em(20);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: em(610);
      }

      .popup-highlight__close {
        top: em(38, 23);
        font-size: em(23);
        padding: 0;
      }
    }
  }

  .slider-highlight {
    width: 100%;
    text-align: center;

    &__item {
      margin-bottom: em(5);
    }

    &__prev {
      float: left;
      padding-left: em(30, 30);
    }

    &__next {
      padding-right: em(30, 30);
    }

    &__number {
      font-size: em(12);
      vertical-align: middle;
    }
  }

  .highlight-info {
    padding: em(14) em(20) 0 em(10);

    &__infor {
      padding: em(15, 14) 0 em(20, 14) em(20, 14);
    }

    &__title {
      padding-left: em(20, 29);
    }

    &__name {
      padding-left: em(20, 21);
      margin-bottom: em(7, 21);
    }

    &__detail-desc {
      padding-left: em(20, 14);
      padding-right: em(10, 14);
    }
  }
}
