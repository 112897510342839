.quarterly {
  // &__item {
  //   margin-bottom: em(65);
  // }

  &__title {
    font: #{em(24.88)}/#{em(26.13, 24.88)} $wal-b;
    margin-bottom: em(20, 24.88);
    padding-bottom: em(15, 24.88);
    border-bottom: 1px solid $black;
  }

  &-list {
    @extend %clearfix;
    margin: 0 em(-11) em(65);

    &__item {
      float: left;
      padding: 0 em(11);
      width: 25%;
    }
  }

  &-item {
    &__block {
      display: block;
      width: 100%;
      margin-bottom: em(10);
      padding-bottom: em(7);
      box-shadow: 0 em(2) em(4) 0 rgba($black, .5);
      background-color: $white;
      transition: opacity .3s ease;

      &:hover {
        opacity: .7;
      }
    }

    &__image {
      border-bottom: $moon em(8) solid;
    }

    &__img {
      display: block;
      width: 100%;
    }

    &__intro,
    &__desc {
      display: block;
      font: #{em(14.4)}/#{em(21.6, 14.4)} $robo-l;
      color: $grey-8;
      letter-spacing: em(-.11, 14.4);
      padding: 0 em(15, 14.4);
      margin-top: em(3, 14.4);
      overflow: hidden;
    }

    &__intro {
      padding-top: em(7, 14.4);
    }

    &__desc {
      font-family: $robo-b;
    }
  }
}

@media screen and  (max-width: $breakpoint-max) {

  .quarterly {

    &__wrapper {
      display: block;
      margin-bottom: em(30);
    }

    &__item {
      margin-bottom: em(30);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-list {
      margin: 0 em(-7);

      &__item {
        padding: 0 em(7);
        width: 50%;
      }
    }

    &-item {

      &__image {
        border-bottom: $moon em(4) solid;
      }

      &__intro,
      &__desc {
        font: #{em(10)}/#{em(15, 10)} $robo-l;
        letter-spacing: em(-.11, 10);
        color: $black;
        padding: 0 em(15, 10);
        margin-top: em(3, 10);
      }

      &__intro {
        padding-top: em(7, 10);
      }

      &__desc {
        font-family: $robo-b;
      }
    }
  }
}
