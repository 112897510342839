@import "variables";

@font-face {
  font-family: 'sam';
  src:  url('#{$icomoon-font-path}/sam.eot?wwzd2q');
  src:  url('#{$icomoon-font-path}/sam.eot?wwzd2q#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/sam.ttf?wwzd2q') format('truetype'),
    url('#{$icomoon-font-path}/sam.woff?wwzd2q') format('woff'),
    url('#{$icomoon-font-path}/sam.svg?wwzd2q#sam') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sam' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-plus-hover {
  &:before {
    content: $ico-plus-hover;
  }
}
.ico-arrow-down-bold {
  &:before {
    content: $ico-arrow-down-bold;
  }
}
.ico-list {
  &:before {
    content: $ico-list;
  }
}
.ico-radio-inner {
  &:before {
    content: $ico-radio-inner;
  }
}
.ico-radio-checked {
  &:before {
    content: $ico-radio-checked;
  }
}
.ico-radio {
  &:before {
    content: $ico-radio;
  }
}
.ico-warning {
  &:before {
    content: $ico-warning;
  }
}
.ico-square-plus {
  &:before {
    content: $ico-square-plus;
  }
}
.ico-square-minus {
  &:before {
    content: $ico-square-minus;
  }
}
.ico-arrow-right-thick {
  &:before {
    content: $ico-arrow-right-thick;
  }
}
.ico-grid {
  &:before {
    content: $ico-grid;
  }
}
.ico-oval {
  &:before {
    content: $ico-oval;
  }
}
.ico-hexagon {
  &:before {
    content: $ico-hexagon;
  }
}
.ico-arrow-down {
  &:before {
    content: $ico-arrow-down;
  }
}
.ico-arrow-left {
  &:before {
    content: $ico-arrow-left;
  }
}
.ico-arrow-right {
  &:before {
    content: $ico-arrow-right;
  }
}
.ico-arrow-up {
  &:before {
    content: $ico-arrow-up;
  }
}
.ico-book {
  &:before {
    content: $ico-book;
  }
}
.ico-bus {
  &:before {
    content: $ico-bus;
  }
}
.ico-calendar {
  &:before {
    content: $ico-calendar;
  }
}
.ico-cancel {
  &:before {
    content: $ico-cancel;
  }
}
.ico-car {
  &:before {
    content: $ico-car;
  }
}
.ico-clock {
  &:before {
    content: $ico-clock;
  }
}
.ico-comment {
  &:before {
    content: $ico-comment;
  }
}
.ico-contact-us {
  &:before {
    content: $ico-contact-us;
  }
}
.ico-document {
  &:before {
    content: $ico-document;
  }
}
.ico-donate {
  &:before {
    content: $ico-donate;
  }
}
.ico-download {
  &:before {
    content: $ico-download;
  }
}
.ico-download-circle {
  &:before {
    content: $ico-download-circle;
  }
}
.ico-educator {
  &:before {
    content: $ico-educator;
  }
}
.ico-email {
  &:before {
    content: $ico-email;
  }
}
.ico-email-black {
  &:before {
    content: $ico-email-black;
  }
}
.ico-eye-open {
  &:before {
    content: $ico-eye-open;
  }
}
.ico-facebook {
  &:before {
    content: $ico-facebook;
  }
}
.ico-filter {
  &:before {
    content: $ico-filter;
  }
}
.ico-food {
  &:before {
    content: $ico-food;
  }
}
.ico-glasses {
  &:before {
    content: $ico-glasses;
  }
}
.ico-globe {
  &:before {
    content: $ico-globe;
  }
}
.ico-hamburger {
  &:before {
    content: $ico-hamburger;
  }
}
.ico-info {
  &:before {
    content: $ico-info;
  }
}
.ico-insta {
  &:before {
    content: $ico-insta;
  }
}
.ico-itinerary {
  &:before {
    content: $ico-itinerary;
  }
}
.ico-left-circle {
  &:before {
    content: $ico-left-circle;
  }
}
.ico-like {
  &:before {
    content: $ico-like;
  }
}
.ico-link {
  &:before {
    content: $ico-link;
  }
}
.ico-location-pin {
  &:before {
    content: $ico-location-pin;
  }
}
.ico-long-arrow-left-circle {
  &:before {
    content: $ico-long-arrow-left-circle;
  }
}
.ico-long-arrow-right-circle {
  &:before {
    content: $ico-long-arrow-right-circle;
  }
}
.ico-minus {
  &:before {
    content: $ico-minus;
  }
}
.ico-minus-circle {
  &:before {
    content: $ico-minus-circle;
  }
}
.ico-mrt {
  &:before {
    content: $ico-mrt;
  }
}
.ico-pen {
  &:before {
    content: $ico-pen;
  }
}
.ico-photos {
  &:before {
    content: $ico-photos;
  }
}
.ico-play {
  &:before {
    content: $ico-play;
  }
}
.ico-plus {
  &:before {
    content: $ico-plus;
  }
}
.ico-plus-circle {
  &:before {
    content: $ico-plus-circle;
  }
}
.ico-right-circle {
  &:before {
    content: $ico-right-circle;
  }
}
.ico-sam-facebook {
  &:before {
    content: $ico-sam-facebook;
  }
}
.ico-sam-insta {
  &:before {
    content: $ico-sam-insta;
  }
}
.ico-sam-twitter {
  &:before {
    content: $ico-sam-twitter;
  }
}
.ico-sam-youtube {
  &:before {
    content: $ico-sam-youtube;
  }
}
.ico-search {
  &:before {
    content: $ico-search;
  }
}
.ico-share {
  &:before {
    content: $ico-share;
  }
}
.ico-shop {
  &:before {
    content: $ico-shop;
  }
}
.ico-slideshow {
  &:before {
    content: $ico-slideshow;
  }
}
.ico-target-audience {
  &:before {
    content: $ico-target-audience;
  }
}
.ico-tick {
  &:before {
    content: $ico-tick;
  }
}
.ico-tick-circle {
  &:before {
    content: $ico-tick-circle;
  }
}
.ico-tickets {
  &:before {
    content: $ico-tickets;
  }
}
.ico-tri-down {
  &:before {
    content: $ico-tri-down;
  }
}
.ico-tri-up {
  &:before {
    content: $ico-tri-up;
  }
}
.ico-twitter {
  &:before {
    content: $ico-twitter;
  }
}
.ico-videos {
  &:before {
    content: $ico-videos;
  }
}
.ico-wheelchair {
  &:before {
    content: $ico-wheelchair;
  }
}
.ico-youtube {
  &:before {
    content: $ico-youtube;
  }
}
.ico-zoom-in {
  &:before {
    content: $ico-zoom-in;
  }
}
.ico-zoom-out {
  &:before {
    content: $ico-zoom-out;
  }
}

