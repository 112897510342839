.conversation {
  position: relative;
  margin: 0 auto em(35);
  padding-top: em(22);
  padding-right: em(370);
  max-width: em(800);
  min-height: em(514);
  border: none;

  &__wrapper {
    position: relative;
    padding-top: percentage(190/1000);
    padding-bottom: percentage(208/1000);
    overflow: hidden;
    border: none;
    z-index: 0;
    clear: left;

    .before-down,
    .after-down {
      position: absolute;
      top: 0;
      height: 100%;
      background-color: $white;
    }

    .before-down {
      left: -1%;
      padding-right: 1px;
      width: 37.1%;
      transform: skewY(13.5deg);
      transform-origin: 100% 0;
    }

    .after-down {
      left: 36%;
      transform: skewY(-13.5deg);
      transform-origin: 0 0;
      width: 65%;
    }

    .desktop & {
      .container {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          background-color: $white;
          transform-origin: 100% 0;
          transition: transform .9s ease .7s;
          z-index: 1;
        }
      }

      &.animation {
        .container::before {
          transform: scaleX(0);
        }
      }
    }
  }

  &__triangle {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .before-up,
    .after-up {
      position: absolute;
      top: 0;
      height: 100%;
      background-color: $white;
    }

    .before-up {
      left: -1%;
      padding-right: 1px;
      width: 65.1%;
      transform: skewY(-13.5deg);
      transform-origin: 100% 0;
    }

    .after-up {
      left: 64%;
      transform: skewY(13.5deg);
      transform-origin: 0 0;
      width: 37%;
    }
  }

  &__slider {
    position: absolute;
    top: em(57);
    right: em(3);
    max-width: em(306);
    width: 100%;

    .slick-arrow {
      position: absolute;
      bottom: em(-1, 23);
      right: em(5, 23);
      border: none;
      padding: 0;
      width: em(25, 23);
      height: em(25, 23);
      background-color: transparent;
      color: $white;
      cursor: pointer;
      font-size: em(23);
      outline: 0;
    }

    .slick-prev {
      right: em(42, 23);
    }

    .slick-dots {
      display: inline-block;
      margin-top: em(16);
      margin-left: em(-5);

      li {
        display: inline-block;
        margin-left: em(5);
        width: 4px;
        height: 4px;
        background-color: transparent;

        button {
          display: block;
          border: none;
          border-radius: 50%;
          padding: 0;
          width: 4px;
          height: 4px;
          background-color: $white;
          outline: 0;
          text-indent: -9999px;
          color: transparent;
          line-height: 0;
          cursor: pointer;
        }
      }

      .slick-active {
        width: 6px;
        height: 6px;
        background-color: transparent;

        button {
          width: 6px;
          height: 6px;
          background-color: $blue;
        }
      }
    }
  }

  &__title {
    color: $white;
    font-family: $wal-b;
    font-size: em(51.6);
    letter-spacing: em(-2.24, 51.6);
    line-height: em(61.92, 51.6);
  }

  &__desc,
  &__text-quote,
  &__author-quote {
    font-size: em(14.4);
    line-height: em(21.6, 14.4);
  }

  &__desc {
    padding-top: em(12, 14.4);
    padding-bottom: em(45, 14.4);
    color: $white;
    font-family: $robo-l;
  }

  &__quote {
    border-top: 1px solid $grey-5;
    border-bottom: 1px solid $grey-5;
    padding: em(12) em(34) em(27);

    & + .conversation__quote {
      border-top: 0;
    }
  }

  &__text-quote {
    position: relative;
    padding-top: em(45, 14.4);
    padding-bottom: em(24, 14.4);
    color: $white;
    font-family: $robo-b;
  }

  &__author-quote {
    color: $blue;
    font-family: $robo-l;
    font-style: italic;
  }

  &__slider-item {
    border: em(10) solid $white;
    background-color: $white;
  }

  &__wrapper-img {
    position: relative;
    padding-bottom: 125%;
  }

  &__img-slide {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .user-instagram__img {
    display: inline-block;
  }

  &__bg-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid #fff; //fix line -top bottom
    border-bottom: 1px solid #fff; //fix line -top bottom
    z-index: -1;

    .slick-list,
    .slick-track {
      height: 100%;
    }

    &:after {
      @include background-gradient(rgba($black, .8), transparent, 'horizontal');

      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .edge &,
      .ie & {
        display: none;
      }
    }
  }

  &__bg {
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: blur(25px);

    .edge &,
    .ie & {
      background-image: none !important; //sass-lint:disable-line no-important
    }
  }
}

.highlight-work__wrapper + .conversation__wrapper {
  margin-top: -1 * percentage(115/1000);
}

.heading-instagram,
.footer-instagram {
  @extend %clearfix;
  background-color: $white;
}

.heading-instagram {
  padding-bottom: em(10);
}

.footer-instagram {
  padding-top: em(10);
}

.user-instagram {
  float: left;
  max-width: 75%;

  &__img {
    vertical-align: middle;
    max-width: em(29);
    border-radius: 50%;
  }

  &__text {
    display: inline-block;
    vertical-align: middle;
    max-width: 85%;
    padding-left: 10px;
    color: $black;
    font: #{em(12)}/#{em(16, 12)} $robo-b;
  }
}

.follow-instagram {
  position: relative;
  margin-top: em(4, 12);
  border: 1px solid $blue-2;
  padding: em(3, 12) em(10, 12) em(3, 12) em(20, 12);
  border-radius: em(3, 12);
  color: $blue-2;
  font: #{em(12)}/#{em(16, 12)} $robo-l;
  float: right;

  &::before {
    content: '+';
    position: absolute;
    top: 48%;
    left: em(10, 12);
    color: $blue-2;
    transform: translateY(-48%);
  }
}

.instagram-comment,
.instagram-like {
  color: $grey-5;
  font: #{em(12)}/#{em(18, 12)} $robo-b;
}

.instagram-like {
  margin-right: em(10, 12);
}

.ico-like,
.number-like,
.ico-comment,
.number-comment {
  margin-right: em(4, 12);
}


.instagram-logo {
  max-width: em(70);
  float: right;
}

.video-instagram {
  display: block;

  .instagram__ico--play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: em(56);
    color: $white;
  }
}

@media screen and  (max-width: $breakpoint-max) {
  .conversation {
    padding: em(30) em(9) 0;
    margin-bottom: em(74);

    &__wrapper {
      margin-top: 5%;
      padding-top: percentage(230/1000);
      padding-bottom: percentage(330/1000);

      &::before,
      &::after {
        top: -90%;
      }

      &::before {
        width: percentage(52/320);
      }

      &::after {
        width: (100% - percentage(52/320));
      }
    }

    &__triangle {

      &::before,
      &::after {
        top: 90%;
      }

      &::before {
        width: (100% - percentage(58/320));
      }

      &::after {
        width: percentage(58/320);
      }
    }

    &__intro {
      margin-top: percentage(173/1000);
      max-width: 100%;
      margin-right: 0;
    }

    &__slider {
      position: relative;
      top: 0;
      left: 0;
      margin-left: 0;
      max-width: 100%;

      .slick-arrow {
        bottom: em(-7, 29);
        right: em(8, 29);
        font-size: em(29);
      }

      .slick-prev {
        right: em(54, 29);
      }

      .slick-dots {
        margin-top: em(12);
        margin-left: em(7);
      }

    }

    &__title {
      font-size: em(35.83);
      letter-spacing: em(-1.17, 35.83);
      line-height: em(37.62, 35.83);
      max-width: em(112, 35.83);
    }

    &__quote {
      margin-top: em(27);
      margin-right: 0;
      padding-top: em(22);
      padding-left: 0;
      padding-right: 0;
    }

    &__text-quote {
      padding-top: em(32, 14.4);
      padding-bottom: em(24, 14.4);
    }

    &__desc {
      padding-top: em(18, 14.4);
      padding-bottom: em(39, 14.4);
    }

    &__author-quote {
      margin-top: em(21, 14);
      margin-bottom: em(11, 14);
      font-size: em(14);
    }
  }

  .highlight-work__wrapper + .conversation__wrapper {
    margin-top: -30%;
  }
  
  .show-desktop {
    display: none;
  }
}

