%icon {
  font-family: $sam;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  @extend %clearfix;

  width: em($container-width);
  max-width: 100%;
  margin: 0 auto;
  padding: 0 em($grid-gutter/2);
}

.row {
  @extend %clearfix;

  position: relative;
  margin-left: em(-$grid-gutter/2);
  margin-right: em(-$grid-gutter/2);

  &--flex {
    @media screen and (min-width: $breakpoint-min) {
      display: flex;
    }
  }
}

[class^='col-'],
[class*=' col-'] {
  padding-left: em($grid-gutter/2);
  padding-right: em($grid-gutter/2);
  float: left;
  position: relative;
  // z-index: 1;
}

.loading-layer {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: em(2000);
    z-index: 100;
    background-color: $half-white;
  }
}

.col-2 {
  width: percentage(1/6);
}

.col-4 {
  width: percentage(1/3);
}

.col-3 {
  width: percentage(1/4);
}

.col-5 {
  width: percentage(5/12);
}

.col-6,
.col-xs-6 {
  width: percentage(6/12);
}

.col-7 {
  width: percentage(7/12);
}

.col-8 {
  width: percentage(8/12);
}

.col-9 {
  width: percentage(9/12);
}

.col-10 {
  width: percentage(10/12);
}

.col-offset-1 {
  margin-left: percentage(1/12);
}

.col-push-5 {
  left: percentage(5/12);
}

.col-pull-2 {
  left: percentage(2/12);
}

.col-pull-1 {
  left: percentage(1/12);
}

.col-pull-6 {
  left: percentage(-6/12);
}

.hidden {
  display: none !important; //sass-lint:disable-line no-important
}

.title {
  font: #{em(51.6)}/#{em(61.92, 51.6)} $wal-b;
  color: $black;

  &__wrapper {
    text-align: center;
    margin-bottom: em(70);
    position: relative;
    z-index: 1;
  }
}

.sub-title {
  display: block;
  font: #{em(14.4)}/#{em(21.6, 14.4)} $robo-l;
  color: $grey-8;
  max-width: 50%;
  margin: em(14, 14.4) auto 0;
}

.desktop {
  .title,
  .sub-title {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
    transition: all .9s ease .5s;
  }

  .sub-title {
    transition-delay: .9s;
  }

  .animation {
    .title,
    .sub-title {
      transform: none;
      opacity: 1;
    }
  }
}

.img-responsive {
  display: block;
  height: auto;
  width: 100%;
}

.triangle-deco {
  position: absolute;
  left: 0;
  bottom: 50%;
  height: 50%;
  right: 50%;
  z-index: -2;
  background: $black;
  transform: skewY(13.5deg);
  transform-origin: 0 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    right: -100%;
    transform: skewY(-26deg);
    background: inherit;
    transform-origin: 0 100%;
  }

  &--revert {
    transform: skewY(-13.5deg);

    &:after {
      transform: skewY(26deg);
    }
  }
}

.decotriangle--bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: none;
  transform: none;
  z-index: -2;

  &:before,
  &:after {
    top: 0;
    width: 50%;
    height: 100%;
    content: '';
    position: absolute;
  }

  &:before {
    left: 0;
    padding-right: 1px;
    transform: skewY(13.5deg);
  }

  &:after {
    transform: skewY(-13.5deg);
    right: 0;
  }
}

.decotriangle--left {
  &:before {
    width: 36.5%;
    transform-origin: 100% 0;
  }

  &:after {
    transform-origin: 0 0;
    width: 63.5%;
  }
}

.decotriangle--right {
  &:before {
    width: 63.5%;
    transform-origin: 100% 0;
  }

  &:after {
    transform-origin: 0 0;
    width: 36.5%;
  }
}

.decotriangle--revert {
  &:before {
    transform: skewY(-13.5deg);
  }

  &:after {
    transform: skewY(13.5deg);
  }
}

.ico-clock {
  &--on,
  &--close {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50%;
      z-index: 0;
    }

    &:before {
      position: relative;
      z-index: 1;
    }
  }

  &--on {

    &:after {
      background: $lime;
    }
  }

  &--close {

    &:after {
      background: $red;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba($black, .95);
  z-index: 10;
  display: none;

  &.on {
    display: block;
  }
}

.rte {
  p {
    font: #{em(14.4)}/#{em(21, 14.4)} $robo-l;
    letter-spacing: em(-.1);
    color: $grey-2;

    > a {
      font-size: em(15, 13.5);
    }
  }

  a {
    color: $blue-3;
    font-size: em(14.4, 14.4);

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-family: $robo-b;
    font-weight: normal;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  li {
    position: relative;
  }

  ul {
    li {
      padding-left: em(15);

      &:before {
        @extend %icon;
        content: $ico-oval;
        font-size: em(3);
        line-height: em(21, 3);
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  &--general-bodycopy {
    li {
      font: #{em(14.4)}/#{em(21, 14.4)} $robo-l;
      letter-spacing: em(-.1);
      color: $grey-2;
    }
  }

  &--faq {
    font: #{em(14.4)}/#{em(21, 14.4)} $robo-l;
    color: $grey-2;
    letter-spacing: em(-.1);

    p,
    li {
      font-size: 1em;
    }
  }

  &--registration {
    p {
      font: #{em(14.4)}/#{em(21, 14.4)} $robo-l;
      color: $grey-8;
      letter-spacing: em(-.1);
    }
  }

  &--registration-note {
    p {
      font: #{em(12)}/#{em(17, 12)} $robo-l;
      color: $grey;
    }
  }
}

.rte--time {
  p {
    padding-top: em(30, 14.4);
    color: $black;
    font: #{em(14.4)}/#{em(21.6, 14.4)} $robo-l;
    letter-spacing: 0;
  }
}

.rte--s-contact {
  p {
    font: #{em(14.4)}/#{em(22, 14.4)} $robo-l;
    color: $grey-1;
  }

  ul {
    margin-bottom: em(90);
  }

  li {
    margin-bottom: em(20);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.rte--faq {
  a {
    font-family: $robo-m;
  }
}

.rte--artists {
  p {
    color: $grey-1;
    font-family: $robo-b;
  }
}

.arrow-ico {
  circle,
  path {
    fill: none;
    stroke: $black;
    stroke-width: 2;
    stroke-linecap: round;
  }
}
