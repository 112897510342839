.social-share {
  &__item,
  &__link {
    display: inline-block;
  }

  &__icon {
    color: $black;

    &:after {
      font-size: em(38);
    }

    &--menu {
      &:after {
        font-size: em(23);
      }
    }
  }

  &__link {
    &:before {
      color: $black;
      font-size: em(38);
    }

    &--menu {
      margin-left: em(10);
      margin-right: 0;

      &:before {
        font-size: em(23);
        color: $white;
      }
    }
  }
}

@include minquery($breakpoint-min) {
  .social-share {
    float: left;
    padding-left: em(13);

    &__icon,
    &__icon--menu {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      overflow: hidden;
      max-height: 0;
      transition: max-height .3s;
      height: 100%;

      &:after {
        content: $ico-hexagon;
        font-family: $sam;
        position: absolute;
        bottom: 1px;
        left: 0;
        text-indent: -1px;
        color: $black;
      }
    }

    .ico-sam-facebook {
      .social-share__icon:after,
      .social-share__icon--menu:after {
        color: $blue-4;
      }
    }

    .ico-sam-twitter {
      .social-share__icon:after,
      .social-share__icon--menu:after {
        color: $blue-2;
      }
    }

    .ico-sam-insta {
      .social-share__icon:after,
      .social-share__icon--menu:after {
        color: $lolla;
      }
    }

    .ico-sam-youtube {
      .social-share__icon:after,
      .social-share__icon--menu:after {
        color: $lolla-2;
      }
    }

    &__link {
      position: relative;
      margin-right: em(40);

      &:before {
        transition: color .3s;
        position: relative;
        z-index: 1;
      }

      &:hover {
        &:before {
          color: $white;
        }

        .social-share__icon {
          max-height: 100%;
        }
      }
    }

    &__link--menu {
      position: relative;
      display: block;

      &:before {
        position: relative;
        z-index: 1;
      }

      &:hover {
        .social-share__icon--menu {
          max-height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .social-share {
    border-bottom: 2px solid $grey-5;
    padding-top: em(27);
    padding-bottom: em(19);
    text-align: center;

    &__item {
      margin-left: em(22);
      margin-right: em(22);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__link--menu {
      &:before {
        color: $black;
        font-size: em(38);
      }
    }
  }
}

