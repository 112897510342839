.navigation {
  text-align: center;

  &__title {
    font-family: $robo-b;
    font-size: em(14);
    line-height: 1;
    color: $black;
  }

  &__link {
    color: $grey-2;
    font-size: em(12);
    text-decoration: none;

    &:hover {
      color: $black;
    }
  }

  &__list-item {
    line-height: 1.1;
  }
}

@include minquery($breakpoint-min) {
  .navigation {
    &__item {
      display: inline-block;
      width: 13.8%;
      margin-top: em(44);
      text-align: left;
      vertical-align: top;
    }

    &__list {
      padding-top: em(19);
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .navigation {
    padding-left: em(20);
    padding-right: em(20);
    padding-top: em(10);
    text-align: left;

    &__item {
      border-bottom: 1px solid $grey-5;

      &:last-child {
        border-bottom-style: none;
      }

      &.opened {
        .navigation__title::before {
          transform: rotate(90deg);
        }
      }
    }

    &__list {
      display: none;
      padding-bottom: em(10);
    }

    &__title {
      position: relative;
      padding-bottom: em(12);
      padding-top: em(17);

      &:before {
        content: '';
        position: absolute;
        display: inline-block;
        top: 50%;
        right: 3px;
        margin-top: -3px;
        width: 2px;
        height: 8px;
        background-color: $black;
      }

      &:after {
        content: '';
        position: absolute;
        display: inline-block;
        top: 50%;
        right: 0;
        width: 8px;
        height: 2px;
        background-color: $black;
      }
    }

    &__link {
      display: block;
      padding-top: em(8, 12);
      padding-bottom: em(8, 12);
    }
  }
}
