.explore {
  position: relative;
  margin: em(105, 15) auto em(144);
  text-align: center;
  z-index: 1;

  &__title {
    font: #{em(53.78)}/#{em(76, 53.78)} $wal-b;
    letter-spacing: em(-2.24, 53.78);
  }

  &__list {
    @extend %clearfix;

    margin: em(60) auto 0;
  }

  &__item {
    position: relative;
    width: percentage(1/4);
    float: left;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: $grey;
    }

    &:nth-child(1) {

      .explore__link {
        padding-left: 0;
      }
    }

    &:last-child:after {
      display: none;
    }
  }

  &__ico {
    position: absolute;
    height: em(28, 29.86);
    width: em(56, 29.86);
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    circle,
    path {
      stroke-width: 2;
      stroke: $grey;
    }
  }
}

// .explore__ico-circle {
//   display: inline-block;
//   position: relative;
//   width: em(28, 29.86);
//   height: em(28, 29.86);
//   margin-left: em(10, 29.86);
//   vertical-align: em(-8, 29.86);
//   border: 2px solid $grey;
//   border-radius: 50%;
//   background-color: $white;
// }

// .explore__ico-arrow {
//   display: inline-block;
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;


//   &:before {
//     content: $ico-arrow-right-thick;
//     position: absolute;
//     top: 50%;
//     right: em(8, 11);
//     color: $grey;
//     font-family: $sam;
//     font-size: em(10, 29.86);
//     transform: translateY(-50%);
//     transition: .5s;
//   }

//   &:after {
//     content: '';
//     position: absolute;
//     top: 50%;
//     left: em(6, 11);
//     height: 2px;
//     width: em(10, 11);
//     font-size: em(11, 29.86);
//     background-color: $grey;
//     transform: translateY(-50%);
//     transition: .6s;
//   }
// }

.explore__link {
  display: inline-block;
  position: relative;
  padding: em(7.5, 29.86) em(65, 29.86) em(7.5, 29.86) em(8, 29.86);
  color: $grey;
  font: #{em(29.86)}/#{em(30, 29.86)} $wal-b;
  letter-spacing: em(-2.24, 29.86);
  text-decoration: none;

  &:hover {
    text-decoration: none;
    // .explore__ico-circle {
    //   &:after {
    //     content: '';
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     width: 4px;
    //     height: 2px;
    //     background-color: $grey;
    //     transform: translate(-50%, -50%);
    //     transition: .3s;

    //   }
    // }

    // .explore__ico-arrow {

    //   &:before {
    //     right: em(-22, 11);
    //     transition: .4s;
    //   }

    //   &:after {
    //     left: em(14, 11);
    //     width: em(28, 11);
    //     margin-left: em(2, 11);
    //     transition: .5s;
    //   }
    // }
  }
}

@media (max-width: $breakpoint-max) {
  .explore {
    margin-bottom: em(40);
    padding-bottom: em(84);
    border-bottom: 1px solid $black;

    &__title {
      font: #{em(35.82)}/#{em(43, 35.82)} $wal-b;
      letter-spacing: em(-2.24, 35.82);
    }

    &__list {
      margin-top: em(63);
      margin-left: 0;
      margin-right: 0;
      padding: 0 em(19);
    }

    &__item {
      display: block;
      border-right: none;
      border-top: 1px solid  $black;
      width: auto;
      float: none;
      text-align: right;

      &:after {
        display: none;
      }

      &:last-child {
        border-bottom: 1px solid  $black;
      }

      .explore__ico-circle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .explore__link {
    position: relative;
    color: $grey-1;
    padding: em(14, 24.88) em(42, 24.88) em(13, 24.88) em(14, 24.88);
    font: #{em(24.88)}/#{em(26.13, 24.88)} $wal-b;
    display: block;
  }

  .explore__ico {
    right: em(-15, 24.88);
  }
}
