.text__note {
  font: #{em(12)}/#{em(17, 12)} $robo-l;
  color: $grey;
  font-style: italic;

  &--bold {
    font-family: $robo-b;
    padding-right: em(3, 12);
  }

  &--spacing {
    margin-bottom: em(25, 12);
  }
}

.custom-control__specify {
  @include placeholder() {
    color: $grey;
  }

  font-size: em(10, 14.4);
  border: em(1, 10) solid $grey;
  font-family: $robo-l;
  color: $grey;
  padding: em(3, 10) em(10, 10);
  margin-left: em(10, 10);


  &:focus {
    @include placeholder() {
      color: $grey-8;
    }

    outline: none;
    border-color: $grey-8;
  }

  @media screen and (max-width: em(320)) {
    width: em(154, 14.4);
  }
}

.required {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: em(2);
    left: 0;
    right: 0;
    bottom: em(3);
    background-color: $yellow;
    z-index: -1;
  }

  .required__element {
    display: block;
  }
}

.required__element {
  display: none;
  position: absolute;
  z-index: 3;
  top: em(-20, 8.3);
  right: 0;
  background-color: $white;
  border: em(2) solid $yellow;
  font-size: em(8.3, 14.4);
  font-family: $robo-l;
  color: $black;
  padding: em(4, 8.3) em(17, 8.3) em(2, 8.3) 0;
  min-width: em(80, 8.3);
  text-align: center;

  &:after {
    content: $ico-warning;
    font-family: $sam;
    position: absolute;
    bottom: em(4, 7);
    right: em(7, 7);
    font-size: em(7, 8.3);
  }
}
