.detail {
  &__desc-item {
    border-top: em(1) solid $grey;
    padding: em(17) 0 0;
    font-family: $robo-l;

    &:last-child {
      border-bottom: em(1) solid $grey;
    }

    .btn--cta {
      width: 43%;
      margin-right: em(20);
      margin-bottom: em(19);
    }
  }

  &__title {
    font: #{em(20.2)}/#{em(24, 20.2)} $wal-b;
    border-bottom: em(10, 20.2) solid $moon;
    display: block;
    padding-bottom: em(10, 20.2);
  }

  &__text-main {
    font-size: em(14.5);
    font-family: $robo-l;
    line-height: em(22, 14.5);
    margin-bottom: em(17, 14.5);
  }
}

.faq {
  &-item {
    @extend %clearfix;
    margin-bottom: em(50);

    &__desc {
      border-top: em(1) solid $grey;

      &:last-child {
        border-bottom: em(1) solid $grey;
      }
    }
  }

  &__content {
    position: relative;
    display: none;
    background-color: $grey-11;
    padding: em(15) em(20) em(50);
  }

  &__title {
    font: #{em(25)}/#{em(26, 25)} $wal-b;
    border-bottom: em(10, 25) solid $moon;
    display: block;
    padding-bottom: em(10, 25);
  }

  &__title-item {
    font: #{em(17)/1} $robo-l;
    padding: em(8, 17) 0;

    & {
      cursor: pointer;
    }
  }

  &__close {
    color: $white;
    cursor: pointer;
    background-color: $black;
    padding: em(7) em(7) em(4);
    position: absolute;
    bottom: 0;
    right: 0;

    &:before {
      @extend %icon;
      content: $ico-arrow-up;
      font-size: em(20);
    }
  }
}

@media screen and (min-width: $breakpoint-min) {
  .detail {
    &-block {
      padding: 0 em(190) em(150);
    }

    &__title {
      width: percentage(225/644);
      float: left;
      text-align: right;
    }

    &__desc {
      width: (100- percentage(225/644));
      float: left;
      padding-left: em(25);
    }
  }

  .faq {
    &-wrapper {
      padding: 0 em(100) em(130) em(185);
    }

    &__title {
      width: percentage(225/720);
      clear: both;
      margin-bottom: em(-10, 25);
      text-align: right;
    }

    &__list {
      float: right;
      width: (100- percentage(225/720));
      padding-left: em(23);
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .detail {
    &-block {
      position: relative;
      z-index: 1;
      padding: 0 em(10) em(65);
    }

    &__desc-item {
      padding-top: em(25);
      padding-bottom: em(8);

      .btn--cta {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  .faq {
    &-wrapper {
      padding-left: em(10);
      padding-right: em(10);
      padding-top: em(40);
    }

    &__title {
      font-size: em(21);
    }

    &-item {
      margin-bottom: em(45);
    }
  }
}
