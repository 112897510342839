.hours {
  margin-bottom: em(100);
  position: relative;
  z-index: 1;

  &__title {
    font: #{em(53.78)}/#{em(51.09, 53.78)} $wal-b;
    letter-spacing: -2px;
    text-align: center;
    margin-bottom: em(14, 53.78);
  }

  &_desc {
    font: #{em(14)}/#{em(18, 14)} $robo-l;
    letter-spacing: -.1px;
    text-align: center;
    color: $grey-8;
    margin-bottom: em(40, 14);
  }

  &__inner {
    max-width: em(800, 16);
    margin: auto;
  }
}

.hours-item {
  display: inline-block;
  vertical-align: top;
  width: em(305, 16);
  min-height: em(159, 16);


  &__revert {
    width: em(182, 16);
    text-align: center;
  }

  &__time {
    font: #{em(34.84)}/#{em(52.26, 34.84)} $robo-l;
    letter-spacing: .94px;
    padding-left: 0;
  }

  &__day {
    font: #{em(14)}/#{em(18.6, 14)} $robo-b;
    letter-spacing: .2px;
    background-color: $yellow;
    margin-top: em(-2, 14);
    margin-bottom: em(12, 14);
    padding: em(4, 14) 0 em(2, 14) em(13, 14);
  }

  &__desc {
    font: #{em(14)}/#{em(18.6, 14)} $robo-l;
    letter-spacing: -.1px;
    color: $grey-8;

    &-revert {
      margin-bottom: em(26, 14);
    }

    &-media {
      text-align: center;
      margin-top: em(14, 14);
      margin-bottom: em(14, 14);
    }

    &-special {
      font: #{em(21)}/#{em(30, 21)} $wal-b;
      letter-spacing: -.86px;
      color: $lolla;
      max-width: em(180, 21);
      margin-left: auto;
      margin-right: auto;
    }

    &-link {
      font-family: $robo-b;
      color: $dolly;
    }
  }

  &__type {
    font: em(15) $wal-b;
    border-top: em(1, 15) solid $black;
    border-bottom: em(1, 15) solid $black;
    display: inline-block;
    vertical-align: top;
  }

  &__icon {
    display: block;
    margin-top: em(2, 16);
    margin-bottom: em(10, 16);
  }

  .ico-clock {
    font-size: 36px;
    width: 36px;
    height: 36px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-top: 1px;

    &::after {
      content: '';
      position: absolute;
      top: 1px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      z-index: -1;
      background: $lime;
      border-radius: 100%;
    }

    &__background {
      position: absolute;
      left: 2px;
      top: 2px;
      right: 2px;
      bottom: 2px;
      z-index: 1;
      border: 2px solid $white;
      border-radius: 100%;
      margin-top: 0;
    }
  }

  .ico-clock-closed {
    &::after {
      background: $grey;
    }
  }
}

.hours-navigation {
  text-align: center;

  &__list {
    display: inline-block;
    vertical-align: top;
  }

  &__item {
    text-align: center;
  }

  &__link {
    color: $black;
  }

  &__text {
    font: #{em(17.28)}/#{em(18.14, 17.28)} $robo-bl;
    letter-spacing: .08px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: top;
    padding-top: em(8, 17.28);
  }

  &__ico {
    display: inline-block;
    vertical-align: top;
    padding-left: em(2, 16);
    padding-right: em(12, 16);

    &::before {
      font-family: $sam;
      font-size: em(33);
    }
  }

  &__location {
    .hours-navigation__ico:before {
      content: $ico-location-pin;
    }
  }

  &__admission {
    .hours-navigation__ico:before {
      content: $ico-tickets;
    }
  }

  &__directions {
    .hours-navigation__ico:before {
      content: $ico-bus;
    }
  }
}

.hours-desc {
  &__title {
    font: em(12) $robo-b;
    text-align: center;
    color: $grey-8;
    margin-bottom: em(20, 12);
  }

  &__media {
    display: block;
    max-width: 80%;
    margin: em(14, 14) auto 0;
    text-align: center;
  }

  &__special {
    font: em(16) $wal-b;
    color: $lolla;
    text-align: center;
    text-transform: uppercase;
  }

  &__infor {
    display: block;
    width: 100%;
    font-family: $robo-l;
    font-size: em(10);
    color: $grey-1;
    border-top: rgba($grey-8, .3) 1px dotted;
    border-bottom: rgba($grey-8, .3) 1px dotted;
    margin: em(20, 10) 0;
    padding: em(10, 10) 0;
    line-height: em(16, 10);
  }

  .hours-item__desc-link {
    font-family: $robo-bl;
    text-transform: uppercase;
  }
}

@include minquery($breakpoint-min) {
  .hours {
    &__group {
      display: inline-block;
      vertical-align: top;
    }

    &__desc {
      margin-left: auto;
      margin-right: auto;
      padding-top: em(14, 16);
    }

    &__revert {
      max-width: em(450, 14);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .desktop {
    .hours {
      .hours__title,
      .hours_desc {
        opacity: 0;
        transition: all .9s .5s;
      }

      .hours__title,
      .hours_desc {
        transform: translate3d(0, 30px, 0);
      }

      &.animation {
        .hours__title,
        .hours_desc {
          transform: none;
          opacity: 1;
        }
      }
    }
  }

  .hours-item {
    &__revert {
      float: right;
    }
  }

  .hours-navigation {
    &__list {
      width: em(800, 16);
      border-top: 1px solid $grey-7;
      border-bottom: 1px solid $grey-7;
      padding-top: em(15, 16);
      padding-bottom: em(8, 16);
    }

    &__item {
      display: inline-block;
      vertical-align: top;
      width: 33%;
    }

    &__location {
      text-align: left;
    }

    &__directions {
      text-align: right;
    }
  }

  .hours__group-revert {
    width: 100%;
    text-align: center;

    .hours-item {
      &__revert {
        float: none;
        clear: both;
        width: 100%;
        min-height: em(90, 16);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .hours {
    position: relative;
    margin-bottom: em(50);
    padding: 0 em(19) em(50);
    box-shadow: 0 em(1) 0 $black;

    &__inner {
      max-width: 100%;
      margin-bottom: em(4, 16);
    }

    &__title {
      font-size: em(38.42);
      line-height: em(36.5, 38.42);
      letter-spacing: -1.42px;
      margin-bottom: em(32, 38.42);
    }

    &_desc {
      font-size: em(14);
      line-height: em(21, 14);
      letter-spacing: .44px;
      text-align: center;
      margin-bottom: em(12, 14);
    }
  }

  .hours-item {
    width: 100%;
    min-height: em(153, 16);
    border-bottom: none;

    &__revert {
      min-height: auto;
      text-align: center;
      margin-bottom: em(32, 16);
    }

    &__icon {
      vertical-align: top;
      width: 100%;
      display: inline-block;
      text-align: center;
    }

    &__time {
      padding-left: em(6, 34.84);
    }

    &__day {
      font-size: em(15.4);
      line-height: em(19.6, 15.4);
      letter-spacing: .2px;
      margin-top: em(-4, 15.4);
      margin-bottom: em(9, 15.4);
      padding-left: em(23, 15.4);
    }

    &__desc {
      line-height: em(21, 14);
      letter-spacing: .44px;
      padding-left: em(1, 14);
    }

    &__type {
      font-size: em(15);
      margin-left: em(7, 15);
    }

    .ico-clock {
      margin-top: em(2, 36);
    }

    &__construction {
      max-width: 100%;
    }
  }

  .hours-navigation {
    &__list {
      width: 100%;
    }

    &__item {
      padding: em(20, 16);
    }

    &__location {
      border-bottom: em(1, 16) solid $grey-7;
    }

    &__admission {
      border-bottom: em(1, 16) solid $grey-7;
    }
  }
}
