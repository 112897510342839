.sponsors {
  color: $black;
  width: percentage(10/12);
  margin-left: percentage(1/12);
  letter-spacing: em(-5);

  .cta--full {
    margin-top: 0;
    margin-bottom: em(50);
  }

  .cta {
    .cta__icon {
      right: em(24, 30);
    }
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    padding-bottom: em(20);

    .title__wrapper {
      margin-bottom: em(82);
    }
  }

  &__item {
    letter-spacing: normal;
    position: relative;
    display: inline-block;
    width: percentage(1/3);
    vertical-align: top;
    text-align: center;
    margin-bottom: em(23);

    &.opened {
      .ico-plus-circle {
        &:before {
          transform: rotate(45deg);
        }
      }

      .sponsors__trigger {
        &:before,
        &:after {
          opacity: 1;
          transform: translate(0, 0);
        }
      }
    }
  }

  &__trigger {
    margin-bottom: em(14);
    position: relative;
    width: em(224);
    display: block;

    &:before,
    &:after {
      opacity: 0;
      transform: translate(0, #{em(-10)});
      transition: transform .4s ease, opacity .4s ease;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: em(-24);
      height: em(9);
      left: 0;
      width: 100%;
      background-color: $black;
    }

    &:after {
      content: '';
      position: absolute;
      border-bottom: $black em(10) solid;
      border-left: transparent em(10) solid;
      border-right: transparent em(10) solid;
      bottom: em(-15);
      margin-left: em(-11);
      left: 50%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__type {
    font: #{em(12)}/#{em(14, 12)} $robo-b;
    letter-spacing: em(1.2, 12);
    color: $yellow-1;
  }

  &__img {
    display: block;
    margin: em(7) auto 0;
  }

  .ico-plus-circle {
    position: relative;
    margin-top: em(12);
    display: inline-block;
    width: em(144);

    &:before {
      display: inline-block;
      margin: 0 auto;
      padding: 0 em(3);
      text-align: center;
      color: $grey-5;
      font-size: em(24);
      background: $white;
      position: relative;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $grey-5;
      top: 50%;
      left: 0;
      z-index: 0;
    }
  }

  &__content {
    display: none;
    text-align: left;
    background-color: $white-3;
    padding: em(44) em(84);
    border-top: $black 2px solid;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $dolly;
    }

    .btn--cta {
      color: $black;

      span {
        background-color: $white-3;
      }
    }
  }

  &__title,
  &__text {
    display: block;
    font-size: em(14.4);
    line-height: em(21.6, 14.4);
    margin-bottom: em(23, 14.4);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: $robo-b;
    color: $grey-8;
  }

  &__text {
    font-size: em(14.4);
    line-height: em(21.6, 14.4);
    margin-bottom: em(23, 14.4);
    font-family: $robo-l;
    color: $grey-12;
  }

  &__link {
    color: $dolly;
  }
}

@media screen and (min-width: $breakpoint-min) {
  .sponsors {
    &__item {

      &:nth-child(3n + 1) {
        .sponsors__content {
          margin-right: -200%;
        }

        .sponsors__content::before {
          left: 0;
        }
      }

      &:nth-child(3n + 2) {
        .sponsors__trigger {
          margin-left: auto;
          margin-right: auto;
        }

        .sponsors__content {
          margin-left: -100%;
          margin-right: -100%;

          &:before {
            left: percentage(1/3);
          }
        }
      }

      &:nth-child(3n) {
        .sponsors__trigger {
          margin-left: auto;
        }

        .sponsors__content {
          margin-left: -200%;

          &:before {
            right: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-max) {
  .sponsors {
    color: $black;
    width: 100%;
    margin-left: 0;

    &__wrapper {
      .title__wrapper {
        margin-bottom: em(40);
        padding: 0 em(19);
      }

      .sub-title {
        margin-top: em(28, 14.4);
      }
    }

    &__item {
      width: 100%;
      margin-bottom: em(45);
    }

    &__trigger {
      width: 100%;
      padding: 0 em(8);

      &:before {
        width: em(230);
        left: 50%;
        margin-left: em(-115);
      }
    }

    &__content {
      padding: em(44) em(20);
      margin: 0 em(8);
      border-top: 0;
    }

    .ico-plus-circle {
      width: 100%;
    }
  }
}
