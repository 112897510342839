.time-of-other {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: em(100);
  max-width: em(822);
  padding: 0 em($grid-gutter/2);

  &__heading {
    padding: 0 em(20) em(50) em(250);
  }

  &__content {
    @extend %clearfix;
  }

  &__sidebar,
  &__right {
    float: left;
  }

  &__sidebar {
    margin-right: em(12);
    border-top: 1px solid $grey;
    width: em(226);
  }

  &__right {
    margin-left: em(11);
    border-top: 1px solid $grey;
    width: em(550);

    &.rte--time {
      padding-top: em(22);

      p {
        padding-top: 0;
        margin-bottom: em(28);
      }
    }

    .rte--artists {
      p {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    color: $black;
    font-family: $wal-b;
    font-size: em(51.6);
    letter-spacing: 0;
    line-height: em(61.92, 51.6);
  }

  &__artists {
    margin-top: em(26);

    .time-of-other__show-artists {
      display: block;
      position: relative;
      padding-top: em(13, 20.74);
      padding-bottom: em(13, 20.74);
      padding-right: em(38, 20.74);
      color: $black;
      font: #{em(20.74)}/#{em(24.88, 20.74)} $wal-b;

      &:hover {
        text-decoration: none;
      }

      &::after {
        position: absolute;
        top: 50%;
        right: em(5, 32);
        font-size: em(30, 20.74);
        transform: translateY(-50%);

        .ie & {
          font-size: em(15, 23.2);
        }
      }
    }

    & .opened {

      .time-of-other__show-artists {
        color: $lolla;

        &::after {
          font-size: em(32, 20.74);

          .ie & {
            font-size: em(10, 23.2);
          }
        }
      }
    }
  }

  &__artists-content {

    &.opened {
      padding-bottom: em(30);

      .time-of-other__show-artists {
        color: $lolla;

        &::after {
          content: $ico-minus;
          right: em(7, 20.74);
          font-size: em(25, 20.74);
        }
      }
    }
  }
}

.list-tagline {
  display: inline-block;
  background-color: $moon;
  letter-spacing: -5px;
}

.tagline-item {
  position: relative;
  padding: 0 em(22, 12) 0 em(21, 12);
  color: $black;
  font: #{em(12)}/#{em(18, 12)} $robo-l;
  letter-spacing: em(.7, 12);
  text-decoration: none;
  display: inline-block;

  &:first-child {
    padding-right: em(25, 12);
  }

  & + .tagline-item::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: em(12, 12);
    background-color: $black;
    transform: translateY(-50%);
  }

  &--active {
    font-family: $robo-b;
    letter-spacing: em(1.2, 12);
    line-height: em(14.4, 12);
  }
}

.detail-item {
  border-bottom: 1px solid $grey;
  padding-top: em(26);
  padding-bottom: em(26);
  text-align: right;

  &__content,
  &__link {
    display: block;
    position: relative;
    padding-left: em(5);
    padding-right: em(40);
  }

  &__link {
    color: $black;
    text-decoration: none;
  }

  &__text {
    display: block;
    padding-right: em(12, 14.4);
    color: $black;
    font-family: $robo-b;
    font-size: em(14.4);
    letter-spacing: 0;
    line-height: em(17.28, 14.4);

    &--light {
      color: $grey-2;
      font-family: $robo-l;
      line-height: em(21.6, 14.4);
    }
  }

  &__ico {
    position: absolute;
    top: 50%;
    right: em(8);
    transform: translateY(-50%);

    &::before {
      font-family: $sam;
      font-size: em(31);
    }
  }

  &--calendar {

    .detail-item__ico {

      &::before {
        content: $ico-calendar;
      }
    }
  }

  &--location {

    .detail-item__ico {
      right: em(13);

      &::before {
        content: $ico-location-pin;
      }
    }
  }

  &--clock {
    padding-bottom: em(23);

    .detail-item__ico {

      &::before {
        content: $ico-clock;
      }
    }
  }

  &--price {
    padding-top: em(14);
    padding-bottom: em(18);

    .detail-item__content {
      margin-bottom: em(16);
    }

    .detail-item__ico {

      &:before {
        content: $ico-tickets;
      }
    }

    .btn--cta {
      width: 100%;

      .btn__text {
        padding: em(15, 17.28);
        font-family: $robo-b;
        background-color: $white;
      }
    }
  }

  &--book {
    padding-top: 0;
    padding-bottom: 0;

    .detail-item__link {
      padding-top: em(15);
      padding-bottom: em(17);

      &:hover {
        background-color: $black;
        color: $grey;

        .detail-item__text {
          color: $white;
        }
      }
    }

    .detail-item__text {
      display: inline-block;
      margin: 0 auto;
      width: em(128, 14.4);
    }

    .detail-item__ico {

      &::before {
        content: $ico-book;
      }
    }
  }

  &--audience,
  &--globe {
    padding-top: 0;
    padding-bottom: 0;

    .detail-item__link {
      padding-top: em(26);
      padding-bottom: em(26);

      &:hover {
        background-color: $black;
        color: $grey;
        transition: all .3s;

        .detail-item__text {
          color: $white;
        }
      }
    }
  }

  &--globe {

    .detail-item__ico {

      &::before {
        content: $ico-globe;
      }
    }
  }

  &--audience {

    .detail-item__ico {

      &::before {
        content: $ico-target-audience;
      }
    }
  }
}

.list-social {
  display: block;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;

  .social-item {
    display: inline-block;
    padding: em(12) em(28) em(6) em(5);

    &:before {
      color: $white;
    }

    &--facebook {
      padding-right: em(23);
    }
  }

  .social_link {
    position: relative;
    display: inline-block;
    width: em(25);
    height: em(25);
    color: $black;
    background-color: $white;
    text-align: center;
    text-decoration: none;
    transform: translateZ(0);

    .ico-facebook {
      font-size: em(20);
    }

    .ico-email-black {
      font-size: em(15);
    }

    &:hover {

      .ico-twitter {
        color: $blue-1;
      }

      .ico-facebook {
        color: $blue-4;
      }

      .ico-email-black {
        color: $lolla;
      }

      &:before {
        transition: transform .3s ease-out;
        transform: scaleY(1.01);
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: color .2s ease-out .1s;
    }
  }
}

@include minquery($breakpoint-min) {
  .time-of-other {
    &__heading {
      display: inline-block;
      vertical-align: top;
      text-align: center;
    }

    &__title {
      text-align: left;
    }
  }
}

@media screen and (max-width: $breakpoint-max) {

  .time-of-other {

    &__heading {
      padding: em(50) 0 em(40);
    }

    &__title {
      font-size: em(38.42);
      letter-spacing: em(-1.47, 38.42);
      line-height: em(36.5, 38.42);
    }

    &__sidebar,
    &__right {
      width: auto;
      float: none;
    }

    &__right {
      margin-left: 0;

      > p {

        &:first-child {
          padding-top: em(12, 14.4);
        }
      }
    }

    &__artists {
      margin-top: em(32);

      .accordion__title {
        padding-top: em(29, 20.74);
        padding-bottom: em(29, 20.74);
      }
    }

    &__sidebar {
      margin-left: em(-11);
      margin-right: em(-11);
      background-color: $black;

      .list-detail {
        padding-left: em(11);
        padding-right: em(11);
      }
    }

    .list-tagline {
      margin-top: em(14);
    }

    .tagline-item {
      line-height: em(16, 12);

      &--active {
        line-height: em(14, 12);
      }
    }
  }

  .list-social {

    margin-left: em(-11);
    margin-right: em(-11);
    overflow: hidden;
    text-align: center;

    .social-item {
      padding: em(12) 0 em(8);
      width: percentage(1/3);
      float: left;
    }
  }

  .detail-item {
    text-align: left;

    &__content,
    &__link {
      padding-left: em(72);
      padding-right: em(5);
    }

    &__ico {
      display: inline-block;
      left: em(20);
      color: $grey;
    }

    &__text {
      color: $white;
    }

    &--location {

      .detail-item__ico {
        left: em(20);
      }
    }

    &--clock {
      padding-top: em(25);
      padding-bottom: em(25);
    }

    &--book {

      .detail-item__link {
        padding-bottom: em(10);
      }

      .detail-item__text {
        line-height: 1.4;
      }
    }

    &--price {
      padding-bottom: 0;
      border-bottom: none;

      .detail-item__content {
        margin-bottom: em(20);
      }

      .btn--cta {
        border: none;
        width: 100%;
      }
    }

    &--globe {

      .detail-item__link {
        padding-top: em(15);
        padding-bottom: em(14);
      }

      .detail-item__text {
        display: inline-block;
        width: em(100);
        line-height: 1.4;
      }
    }

    &--audience {
      // border-bottom: none; fix bug missing line on mobile

      .detail-item__link {
        padding-top: em(24);
        padding-bottom: em(23);
      }
    }
  }
}
