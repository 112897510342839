$icomoon-font-path: "fonts" !default;

$ico-plus-hover: "\e923";
$ico-arrow-down-bold: "\e955";
$ico-list: "\e932";
$ico-radio-inner: "\e954";
$ico-radio-checked: "\e952";
$ico-radio: "\e953";
$ico-warning: "\e94f";
$ico-square-plus: "\e950";
$ico-square-minus: "\e951";
$ico-arrow-right-thick: "\e94e";
$ico-grid: "\e90b";
$ico-oval: "\e900";
$ico-hexagon: "\e909";
$ico-arrow-down: "\e901";
$ico-arrow-left: "\e902";
$ico-arrow-right: "\e903";
$ico-arrow-up: "\e904";
$ico-book: "\e905";
$ico-bus: "\e906";
$ico-calendar: "\e907";
$ico-cancel: "\e908";
$ico-car: "\e90a";
$ico-clock: "\e90c";
$ico-comment: "\e90d";
$ico-contact-us: "\e90e";
$ico-document: "\e90f";
$ico-donate: "\e910";
$ico-download: "\e911";
$ico-download-circle: "\e912";
$ico-educator: "\e913";
$ico-email: "\e914";
$ico-email-black: "\e915";
$ico-eye-open: "\e916";
$ico-facebook: "\e917";
$ico-filter: "\e918";
$ico-food: "\e919";
$ico-glasses: "\e91a";
$ico-globe: "\e91b";
$ico-hamburger: "\e91c";
$ico-info: "\e91d";
$ico-insta: "\e91e";
$ico-itinerary: "\e91f";
$ico-left-circle: "\e920";
$ico-like: "\e921";
$ico-link: "\e922";
$ico-location-pin: "\e92b";
$ico-long-arrow-left-circle: "\e92d";
$ico-long-arrow-right-circle: "\e92f";
$ico-minus: "\e930";
$ico-minus-circle: "\e931";
$ico-mrt: "\e933";
$ico-pen: "\e934";
$ico-photos: "\e935";
$ico-play: "\e936";
$ico-plus: "\e937";
$ico-plus-circle: "\e938";
$ico-right-circle: "\e939";
$ico-sam-facebook: "\e93a";
$ico-sam-insta: "\e93b";
$ico-sam-twitter: "\e93c";
$ico-sam-youtube: "\e93d";
$ico-search: "\e93e";
$ico-share: "\e93f";
$ico-shop: "\e940";
$ico-slideshow: "\e941";
$ico-target-audience: "\e942";
$ico-tick: "\e943";
$ico-tick-circle: "\e944";
$ico-tickets: "\e945";
$ico-tri-down: "\e946";
$ico-tri-up: "\e947";
$ico-twitter: "\e948";
$ico-videos: "\e949";
$ico-wheelchair: "\e94a";
$ico-youtube: "\e94b";
$ico-zoom-in: "\e94c";
$ico-zoom-out: "\e94d";

