@function strip-units($value) {
  @return ($value / ($value * 0 + 1));
}

@function em($pxval, $base: $em-base) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1em;
}

@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }

  $base: $rem-base;
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1rem;
}

@mixin minquery($width, $ratio: false) {
  @if $ratio {
    @media only screen and (min-width: $width) and (min-device-pixel-ratio: $ratio) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $width) {
      @content;
    }
  }
}

@mixin placeholder {
  &::-moz-placeholder { @content }
  &::-webkit-input-placeholder { @content }
  &:-ms-input-placeholder { @content }
  &::-ms-input-placeholder { @content }
}

@mixin clearfix() {
  &:before,
  &:after {
    content: ' '; // 1
    display: table; // 2
  }

  &:after {
    clear: both;
  }
}

@mixin hex-border($angle, $color, $width, $offset, $offset-top) {
  .border {
    position: absolute;
    top: 0;
    left: #{$offset};
    right: #{$offset};
    bottom: 0;
    margin-top: #{$offset-top};
    border-left: #{$color} #{$width} solid;
    border-right: #{$color} #{$width} solid;
    pointer-events: none;

    &__top,
    &__bottom {
      position: absolute;
      width: 100%;
      height: $offset-top;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 50%;
        height: 100%;
      }
    }

    &__top {
      top: 0;

      &:before,
      &:after {
        border-top: #{$color} #{$width} solid;
      }

      &:before {
        left: 0;
        padding-right: 1px;
        transform: skewY(-#{$angle});
        transform-origin: 0 0;
      }

      &:after {
        right: 0;
        transform: skewY(#{$angle});
        transform-origin: 100% 0;
      }
    }

    &__bottom {
      bottom: 1px;

      &:before,
      &:after {
        border-bottom: #{$color} #{$width} solid;
      }

      &:before {
        left: 0;
        padding-right: 1px;
        transform: skewY(#{$angle});
        transform-origin: 0 0;
      }

      &:after {
        right: 0;
        transform: skewY(-#{$angle});
        transform-origin: 100% 0;
      }
    }
  }
}

@mixin deco-triangle($color1, $color2, $color3, $color4: $color3, $index: -1, $padding-top: em(69), $margin-top: 6.7%) {
  position: relative;
  padding-top: $padding-top;
  margin-top: $margin-top;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 90%;
    background-color: $color2;

    z-index: $index;
  }

  &:before {
    left: 0;
    padding-right: 1px; //fix bug missing 1px on some browser
    transform: skewY(-13.5deg);
    background: linear-gradient(-166.5deg, $color1 0%, $color2 em(380));
  }

  &:after {
    right: 0;
    transform: skewY(13.5deg);
    background: linear-gradient(166.5deg, $color1 0%, $color2 em(380));
  }

  > .triangle-deco {
    @include background-gradient($color3, $color4, 'horizontal');

    &.triangle-deco--wrapper {
      z-index: $index - 1;
    }
  }
}

@mixin highlight-triangle($color1, $color2, $color3, $color4, $color5, $color6) {
  position: relative;
  padding-top: em(69);
  padding-bottom: em(140);
  margin-top: 6.7%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 55%;
    background-color: $color1;

    z-index: 0;
  }

  &:before {
    left: 0;
    padding-right: 1px; //fix bug missing 1px on some browser
    transform: skewY(-13.5deg);
    background: linear-gradient(-166.5deg, $color1 0%, $color1 em(500));
  }

  &:after {
    right: 0;
    transform: skewY(13.5deg);
    background: linear-gradient(166.5deg, $color1 0%, $color1 em(500));
  }

  .triangle-deco {
    &:before {
      background-color: $color3;
      background: linear-gradient(to right, $color3 0%, $color4 27%, $color4 49%, $color4 100%);
    }

    &:after {
      background-color: $color5;
      background: linear-gradient(to right, $color5 0%, $color5 68%, $color6 100%);
    }
  }
}

@mixin fade-in-sequence($index) {
  &:nth-child(#{$index + 1}) {
    transition: opacity #{(.35 + $index * .05)}s cubic-bezier(.62, .02, .34, 1) #{(.35 + $index * .05)}s;
  }
}
